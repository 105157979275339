import * as React from "react";

import { StatusStyled } from "./Status-styles";

type StatusProps = {
  // status:
  //   | "PENDING"
  //   | "CANCELLED"
  //   | "COMPLETED"
  //   | "READY"
  //   | "PROGRESSING"
  //   | "Payed"
  //   | "Unpayed"
  //   | "Loading";
  status?: string;
};

const handleColor = (status?: string) => {
  switch (status) {
    case "PENDING":
      return {
        background: "#5bc0de1f",
        color: "#39a3c2",
      };
    case "NEW ORDER":
      return {
        background: "#5bc0de1f",
        color: "#39a3c2",
      };
    case "CANCELLED":
      return {
        background: "#FDF4F7",
        color: "#B93E5C",
      };
    case "COMPLETED":
      return {
        background: "#ECF4DF",
        color: "#75BD00",
      };
    case "COMPLETED ":
      return {
        background: "#ECF4DF",
        color: "#75BD00",
      };
    case "REQUEST_ACCEPTED":
      return {
        background: "#ECF4DF",
        color: "#75BD00",
      };
    case "READY":
      return {
        background: "#FCEAD4",
        color: "#EC8000",
      };
    case "ACCEPTED":
      return {
        background: "#FCEAD4",
        color: "#EC8000",
      };
    case "PROGRESSING":
      return {
        background: "#FFF2D9",
        color: "#FEB201",
      };
    case "Paid":
      return {
        background: "#ECF4DF",
        color: "#75BD00",
      };
    case "Unpaid":
      return {
        background: "#FDF4F7",
        color: "#B93E5C",
      };
    case "REQUEST_REJECTED":
      return {
        background: "#FDF4F7",
        color: "#B93E5C",
      };
    case "Loading":
      return {
        background: "#FFF2D9",
        color: "#FEB201",
      };
    default:
      break;
  }
};

export const Status = React.memo(({ status }: StatusProps): JSX.Element => {
  return (
    <StatusStyled
      align="center"
      variant="subtitle2"
      background={handleColor(status)?.background || ""}
      color={handleColor(status)?.color || ""}
    >
      {status?.replace("_", " ")}
    </StatusStyled>
  );
});
