/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMutation } from "react-query";

import { LazyLoadingImage } from "..";
import { SnackbarContext } from "../../App";

import { StoreItemsService } from "../../services/openApi";

import DefaultImage from "../../img/Default_Image_Thumbnail.png";

import { Upload, uploadDimension } from "./UploadPicture-styles";

type UploadPictureProps = {
  msgError?: string;
  onLoad?: (img: Blob) => void;
  onLoadId?: (img: number) => void;
  onLoadGallery?: (imgs: number[]) => void;
  uriImage?: string;
  urlsGallery?: string[];
  inputIds?: number[];
  mode: "gallery" | "basic";
  type?: "cover" | "normal";
  maxImages?: number;
  loading?: boolean;
};

export const UploadPicture = ({
  msgError,
  onLoad,
  onLoadId,
  onLoadGallery,
  uriImage,
  urlsGallery,
  inputIds,
  mode,
  type,
  maxImages,
  loading,
}: UploadPictureProps): JSX.Element => {
  const { saveImage } = StoreItemsService;
  const { setSnack } = React.useContext(SnackbarContext);

  const fileUploader = React.useRef<HTMLInputElement>(null);
  const [images, setImages] = React.useState<string[]>([""]);
  const [listBlob, setListBlob] = React.useState<Blob[]>([]);
  const [idsImages, setIdsImages] = React.useState<number[]>([]);
  const [indexPosition, setIndexPosition] = React.useState<number>(0);

  React.useEffect(() => {
    if (uriImage) {
      setImages([uriImage]);
    } else if (urlsGallery) {
      setImages([""].concat([...urlsGallery]));
    } else setImages([""]);
    if (inputIds) {
      setIdsImages(inputIds);
    }
  }, [loading]);

  const handleClick = (index: number) => {
    !uploadFile?.isLoading && fileUploader.current?.click();
    setIndexPosition(index);
  };

  const uploadFile = useMutation(saveImage, {
    onSuccess: (data) => {
      if (data?.image_id) {
        setIdsImages((prevIdsImages) => {
          const arrayIds = [...prevIdsImages];
          if (indexPosition === 0) arrayIds.push(data.image_id);
          else {
            arrayIds[indexPosition] = data.image_id;
          }
          return arrayIds;
        });
      }
    },
  });

  const handleUpload = (fileArray: FileList | null) => {
    // gallery upload
    if (mode === "gallery") {
      const arrayImage = [...images];
      if (fileArray && fileArray?.length <= 5) {
        Array.from(fileArray)?.forEach((element) => {
          if (onLoadGallery) {
            uploadFile?.mutate({ imagefile: element });
          }
          if (indexPosition === 0) {
            arrayImage.push(URL.createObjectURL(element));
          } else if (indexPosition > 0) {
            arrayImage[indexPosition] = URL.createObjectURL(element);
          }
        });
        setImages(arrayImage);
      } else {
        setSnack({
          open: true,
          severity: "error",
          message: "You are only allowed to upload a maximum of 5 files",
        });
      }
    }
    // basic upload
    if (mode === "basic") {
      if (fileArray && fileArray[0]) {
        setImages([URL.createObjectURL(fileArray[0])]);
        if (onLoad) setListBlob([fileArray[0]]);
        if (onLoadId) {
          uploadFile?.mutate({ imagefile: fileArray[0] });
        }
      }
    }

    // if (fileArray && fileArray[0] !== null && fileArray[0] !== undefined) {
    //   await setImages((prevImages) => {
    //     const arrayImage = [...(prevImages || [])];
    //     if (mode === "gallery") {
    //       if (indexPosition === 0) {
    //         arrayImage.push(URL.createObjectURL(fileArray[0]));
    //       } else if (indexPosition > 0) {
    //         arrayImage[indexPosition] = URL.createObjectURL(fileArray[0]);
    //       }
    //     } else arrayImage[0] = URL.createObjectURL(fileArray[0]);
    //     return arrayImage;
    //   });
    //   await setListBlob((prevListBlob) => {
    //     const arrayImage = [...(prevListBlob || [])];
    //     arrayImage.push(fileArray[0]);
    //     return arrayImage;
    //   });
    //   if (onLoadId || onLoadGallery) {
    //     uploadFile?.mutate({ imagefile: fileArray[0] });
    //   }
    // }
    if (fileUploader.current?.value) fileUploader.current.value = "";
  };

  const handleDelete = (index: number) => {
    if (mode === "gallery") {
      setImages((prevImages) => {
        const arrayImage = [...(prevImages || [])];
        arrayImage.splice(index, 1);
        return arrayImage;
      });
      setIdsImages((prevIdsImages) => {
        const arrayIdsImages = [...(prevIdsImages || [])];
        arrayIdsImages.splice(index - 1, 1);
        return arrayIdsImages;
      });
    } else {
      setImages([""]);
    }
    if (onLoadId) {
      idsImages.splice(0, 1);
    }
    setListBlob((prevListBlob) => {
      const arrayImage = [...(prevListBlob || [])];
      arrayImage.splice(index, 1);
      return arrayImage;
    });
  };

  React.useEffect(() => {
    if (mode === "gallery" && onLoadGallery) {
      onLoadGallery(idsImages);
    } else if (onLoadId) {
      onLoadId(idsImages[0]);
    } else if (onLoad) {
      onLoad(listBlob[0]);
    }
  }, [listBlob, idsImages]);

  return (
    <Upload required={!!msgError} type={type} mode={mode}>
      {loading ? (
        <Skeleton
          variant="rectangular"
          width={uploadDimension(type).width}
          height={uploadDimension(type).height}
          sx={{ borderRadius: "20px" }}
        />
      ) : (
        <>
          {images?.map((item, index) => (
            <div
              role="button"
              tabIndex={index}
              onKeyUp={() => handleClick(index)}
              onClick={() => handleClick(index)}
              className={`upload ${
                index === 0 && images?.length - 1 === maxImages && "disabled"
              }`}
              key={index}
            >
              {item ? (
                <>
                  <IconButton
                    size="small"
                    className="btn-delete"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete(index);
                    }}
                    disabled={uploadFile?.isLoading}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                  <LazyLoadingImage
                    alt="file preview"
                    src={item}
                    placeholderSrc={DefaultImage}
                    className="picture-preview"
                  />
                  {uploadFile?.isLoading && idsImages?.length === 0 && (
                    <CircularProgress sx={{ position: "absolute" }} />
                  )}
                  {uploadFile?.isLoading && idsImages?.length <= index - 1 && (
                    <CircularProgress sx={{ position: "absolute" }} />
                  )}
                  {uploadFile?.isLoading && indexPosition === index && (
                    <CircularProgress sx={{ position: "absolute" }} />
                  )}
                </>
              ) : (
                <IconButton
                  className="btn-upload"
                  disabled={uploadFile?.isLoading}
                >
                  <AddIcon />
                </IconButton>
              )}
            </div>
          ))}
        </>
      )}
      <input
        ref={fileUploader}
        type="file"
        onChange={(e) => {
          handleUpload(e.target.files);
        }}
        accept=".jpeg, .bmp, .png, .gif, .jpg"
        multiple={mode === "gallery" ? true : false}
      />
      {msgError && (
        <Typography variant="body2" className="error">
          {msgError}
        </Typography>
      )}
    </Upload>
  );
};
