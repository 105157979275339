import { MediaAppLink } from "./MediaAppLink";

import { Page } from "./GeneralSetting-style";

export const GeneralSetting = (): JSX.Element => {
  return (
    <Page className="scrolbar">
      <MediaAppLink />
    </Page>
  );
};
