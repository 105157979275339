import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border: 1px solid #dddddd;
  border-radius: 20px;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dddddd;
    padding: 20px;
    .title {
      font-weight: 600;
      color: ${({ theme }) => theme.palette.secondary.main};
    }
  }
  .list-categories {
    max-height: calc(100vh - 210px);
    overflow-x: auto;
    .input-search {
      margin: 20px;
    }
  }
`;
