import styled from "styled-components";

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0 20px 20px 20px;
  .header-action {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    .btn-print {
      background-color: #fff;
      svg {
        margin-right: 5px;
      }
    }
  }
  .loader-container {
    width: 100%;
    height: 100%;
    position: relative;
    margin: auto 0;
  }

  .loader-container > * {
    position: absolute;
    display: inline-block;
    left: 0;
    width: 2rem;
    height: 2rem;
    background: ${(props) => props.theme.palette.secondary.main};
    top: 50%;
    border-radius: 50%;
    animation: loading 4s infinite;
    transform: scale(0.1);
    opacity: 0;

    &:nth-child(1) {
      animation-delay: 0.5s;
    }
    &:nth-child(2) {
      animation-delay: 1s;
    }
    &:nth-child(3) {
      animation-delay: 1.5s;
    }
    &:nth-child(4) {
      animation-delay: 2s;
    }
  }

  @keyframes loading {
    50% {
      transform: scale(1.25);
      left: 50%;
      opacity: 1;
    }
    100% {
      transform: scale(0.1);
      left: 100%;
      opacity: 0;
    }
  }
`;

export const pageStyle = `
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @page {
    size: A4;
    margin: 0 1mm 7mm 1mm;
  }
`;
