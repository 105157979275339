/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Skeleton from "@mui/material/Skeleton";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { ReactSortable } from "react-sortablejs";
import { useQuery, useMutation, useQueryClient } from "react-query";
import debounce from "lodash/debounce";

import { Button, Dialog } from "../../commons";
import { CollectionFormDialog } from "..";

import { SnackbarContext } from "../../App";
import { serviceUnavailable, successfulOperation } from "../../utils/message";
import { FilterCollectionsService } from "../../services/openApi";

import { EditIcon, TrashIcon } from "../../img/icon";

import { CollectionStyled } from "./Collections-style";

type modalState = {
  open: boolean;
  title: string;
  action?: "add" | "delete" | "update";
};

type CollectionsProps = {
  onSelected: (id: number) => void;
};

interface ItemType {
  id: number;
  idFilter: number;
  name: string;
  status?: boolean;
}

export const Collections = ({ onSelected }: CollectionsProps): JSX.Element => {
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);
  const {
    getFilterCollections,
    deleteFilterCollection,
    sortFilterCollections,
    updateStatus,
  } = FilterCollectionsService;
  const arrayListFilterCollectionsloading = new Array(9).fill("");

  const [openModal, setOpenModal] = React.useState<modalState>({
    open: false,
    title: "",
  });
  const [openStatus, setOpenStatus] = React.useState<modalState>({
    open: false,
    title: "",
  });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [idCollection, setIdCollection] = React.useState<number | undefined>();

  const [listState, setListState] = React.useState<ItemType[]>([]);
  const [listSort, setListSort] = React.useState<Array<any>>([]);
  const [status, setStatus] = React.useState<boolean>(false);

  const listFilterCollections = useQuery(
    "getFilterCollections",
    () => getFilterCollections(),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  React.useEffect(() => {
    if (listFilterCollections?.data) {
      const data = listFilterCollections?.data;
      const arrayCollection: ItemType[] = [];
      data?.forEach((item: any) => {
        arrayCollection.push({
          id: item.sorting_index,
          idFilter: item.id,
          name: item.name,
          status: item?.status,
        });
      });
      setListState(arrayCollection);
    }
  }, [listFilterCollections?.data]);

  const updateStatusCollection = useMutation(
    () => updateStatus(idCollection!, { status }),
    {
      onSuccess: () => {
        queryClient.refetchQueries("getFilterCollections");
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        setOpenStatus({
          open: false,
          title: "",
        });
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const deleteCollection = useMutation(
    () => deleteFilterCollection(idCollection!),
    {
      onSuccess: () => {
        queryClient.refetchQueries("getFilterCollections");
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        setOpenModal({
          open: false,
          title: "",
        });
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const sortCollection = useMutation(
    () => sortFilterCollections({ collections: listSort }),
    {
      onSuccess: () => {
        queryClient.refetchQueries("getFilterCollections");
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const handleCloseModal = () => {
    setOpenModal({
      open: false,
      title: "",
    });
    setIdCollection(undefined);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOrder = () => {
    const arraySortable: any[] = [];
    listState?.forEach((item, index) => {
      arraySortable.push({
        id: item.idFilter,
        sorting_index: index + 1,
      });
    });
    setListSort(arraySortable);
  };

  const sendOrder = React.useCallback(
    debounce(() => sortCollection.mutate(), 2000),
    []
  );

  return (
    <CollectionStyled>
      <div className="header">
        <Typography variant="h2" className="title">
          Collections
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            setOpenModal({
              open: true,
              title: "Add New Collection",
              action: "add",
            });
            setIdCollection(undefined);
          }}
        >
          Add
        </Button>
      </div>
      <div className="list-collection scrolbar">
        {listFilterCollections.isLoading ? (
          arrayListFilterCollectionsloading?.map((_, index) => (
            <Box
              key={index}
              display="flex"
              height={45}
              alignItems="center"
              gap={2}
              padding="0 16px"
            >
              <Skeleton variant="rectangular" height={20} width={15} />
              <Skeleton variant="text" width="100%" />
              <Skeleton
                component={MoreVertIcon}
                variant="circular"
                width={26}
                height={26}
                sx={{ background: " #fff", color: "text.secondary" }}
              />
            </Box>
          ))
        ) : (
          <MenuList>
            <ReactSortable
              list={listState}
              setList={setListState}
              animation={200}
              delay={2}
              onSort={async () => {
                await handleOrder();
                sendOrder();
              }}
            >
              {listState?.map((item) => (
                <MenuItem
                  key={item?.idFilter}
                  selected={item?.idFilter === idCollection}
                  onClick={() => {
                    setIdCollection(item?.idFilter);
                    onSelected(item?.idFilter);
                  }}
                >
                  <ListItemIcon>
                    <DragIndicatorIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="subtitle2" className="subtitle2">
                      {item?.name}
                    </Typography>
                  </ListItemText>
                  <Switch
                    checked={item?.status || false}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(event) => {
                      setOpenStatus({
                        open: true,
                        title: "Update Status",
                        action: "update",
                      });
                      setStatus(event.target.checked);
                      setIdCollection(item?.idFilter);
                    }}
                    color="secondary"
                  />
                  <IconButton onClick={handleClick}>
                    <MoreVertIcon />
                  </IconButton>
                </MenuItem>
              ))}
            </ReactSortable>
          </MenuList>
        )}
        {!listFilterCollections.isLoading &&
          listFilterCollections?.data?.length === 0 && (
            <Typography variant="body2" className="message-list">
              You need to add collection
            </Typography>
          )}
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={() => {
            setOpenModal({
              open: true,
              title: "Update Collection",
              action: "update",
            });
            handleClose();
          }}
        >
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            setOpenModal({
              open: true,
              title: "Delete",
              action: "delete",
            });
          }}
        >
          <ListItemIcon>
            <TrashIcon />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
      {(openModal.action === "add" || openModal.action === "update") && (
        <CollectionFormDialog
          idCollection={idCollection}
          open={openModal.open}
          title={openModal.title}
          action={openModal.action}
          onClose={handleCloseModal}
        />
      )}
      {openModal.action === "delete" && (
        <Dialog
          open={openModal.open}
          title={openModal.title}
          action="delete"
          handleContent={() => idCollection && deleteCollection.mutate()}
          loading={deleteCollection.isLoading}
          onClose={() =>
            setOpenModal({
              open: false,
              title: "",
            })
          }
        >
          <Typography>Are you sure to delete this collection?</Typography>
        </Dialog>
      )}
      {openStatus.open && (
        <Dialog
          open={openStatus.open}
          title={openStatus.title}
          action="update"
          handleContent={() => updateStatusCollection.mutate()}
          loading={updateStatusCollection.isLoading}
          onClose={() =>
            setOpenStatus({
              open: false,
              title: "",
              action: "update",
            })
          }
        >
          <Typography>
            Are you sure to change status this filter collection?
          </Typography>
        </Dialog>
      )}
    </CollectionStyled>
  );
};
