import styled from "styled-components";

export const CollectionStyled = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 20px;
  .header {
    display: flex;
    height: 70px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dddddd;
    padding: 20px;
    .title {
      font-weight: 600;
      color: ${({ theme }) => theme.palette.secondary.main};
    }
  }
  .list-collection {
    display: flex;
    flex: 1;
    max-height: calc(100vh - 210px);
    flex-direction: column;
    overflow-y: auto;
    .subtitle2 {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .message-list {
      margin: auto 0;
    }
  }
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
    .MuiAutocomplete-input {
    padding: 4px;
  }
`;
