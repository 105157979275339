import styled from "styled-components";

export const ComponentToPrint = styled.div`
  display: flex;
  min-height: calc(100vh - 165px);
  flex: 1;
  flex-direction: column;
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
  gap: 16px;
  .meiz-Log-print {
    width: 200px;
  }
  .title {
    width: 100%;
    font-weight: 600;
  }
  .div-show {
    border: 2px solid ${(props) => props.theme.palette.secondary.main};
    border-radius: 20px;
    padding: 16px;
    margin-top: 8px;
  }
  .customer-infos {
    display: flex;
    align-items: center;
    font-weight: 600;
    color: #39a3c2;
    gap: 10px;
  }
  .order-summary {
    display: flex;
    flex-direction: column;
    border: 2px solid #5bc0de;
    border-radius: 10px;
    .order-summary-header {
      background: #edfaff;
      border-radius: 10px 10px 0px 0px;
      padding: 8px;
    }
    .rows {
      display: grid;
      grid-template-columns: 1fr 150px 150px 150px;
    }
  }
  .specil-request {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-height: max-content;
    border: 2px solid ${(props) => props.theme.palette.secondary.main};
    padding: 10px;
    border-radius: 15px;
  }
  .total-details {
    display: grid;
    min-width: 100%;
    grid-template-columns: 1fr 1fr;
    background: #edfaff;
    border-radius: 15px;
    padding: 10px;
    gap: 8px;
  }
  .total-advance {
    display: grid;
    min-width: 100%;
    grid-template-columns: 1fr 1fr;
    background: #b7e7f9;
    border-radius: 15px;
    padding: 10px;
    gap: 8px;
  }
`;
