/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class ReviewsService {

    /**
     * get reviews list
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getReviewsList(
        requestBody?: {
            store_id?: number;
            search?: string;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/settings/reviews/getReviewsList`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * update review status
     * @param id ID of review
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static updateReviewStatus(
        id: number,
        requestBody?: {
            status?: boolean;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/settings/reviews/updateReviewStatus/${id}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

}