import React from "react";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { LoadingButton, TextField } from "../../../commons";

import {
  successfulOperation,
  serviceUnavailable,
} from "../../../utils/message";
import { SnackbarContext } from "../../../App";

import { DiscountService } from "../../../services/openApi";

import { Save2Icon } from "../../../img/icon";

import { Page } from "./WelcomeDiscount-style";
import { FormDiscountType } from "./WelcomeDiscount-type";

export const WelcomeDiscount = (): JSX.Element => {
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);
  const { getDiscount, saveDiscount, updateDiscount } = DiscountService;

  const [stateForm, setStateForm] = React.useState<FormDiscountType>();

  const dataDiscount = useQuery("getDiscount", () => getDiscount(), {
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const createDiscount = useMutation(saveDiscount, {
    onSuccess: () => {
      queryClient.refetchQueries("getDiscount");
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const editDiscount = useMutation(
    () =>
      updateDiscount(dataDiscount?.data?.[0]?.id || 0, {
        value: stateForm?.value,
        status: stateForm?.status,
        message_en: stateForm?.messageEn,
        message_ar: stateForm?.messageAr,
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries("getDiscount");
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  React.useEffect(() => {
    const data = dataDiscount.data;
    if (data) {
      setStateForm({
        value: data?.[0]?.value,
        status: data?.[0]?.status,
        messageEn: data?.[0]?.message_en,
        messageAr: data?.[0]?.message_ar,
      });
    }
  }, [dataDiscount.data]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = event.target;
    setStateForm({
      ...stateForm,
      [name]: name === "status" ? checked : value,
    });
  };

  const handleSave = () => {
    if (dataDiscount?.data?.[0]?.id) {
      editDiscount.mutate();
    } else {
      createDiscount.mutate({
        value: stateForm?.value,
        status: stateForm?.status,
        message_en: stateForm?.messageEn,
        message_ar: stateForm?.messageAr,
      });
    }
  };

  return (
    <Page>
      <FormControlLabel
        value="activate"
        control={
          <Switch
            name="status"
            checked={stateForm?.status || false}
            onChange={handleChange}
            color="secondary"
          />
        }
        label="Activate Welcome Discount"
        labelPlacement="start"
        className="switch"
      />
      <div className="from">
        {stateForm?.status && (
          <TextField
            name="value"
            label="Discount Value"
            value={stateForm?.value || ""}
            onChange={handleChange}
            color="secondary"
            className="item1"
          />
        )}
        <TextField
          name="messageEn"
          label="Your Text In English"
          value={stateForm?.messageEn || ""}
          onChange={handleChange}
          multiline
          rows={5}
          color="secondary"
          className="item2"
        />
        <TextField
          name="messageAr"
          label="Your Text In Arabic"
          value={stateForm?.messageAr || ""}
          onChange={handleChange}
          multiline
          rows={5}
          color="secondary"
          className="item3"
        />
      </div>
      <LoadingButton
        variant="contained"
        color="secondary"
        className="btn-save"
        onClick={handleSave}
        loading={
          dataDiscount?.isLoading ||
          createDiscount?.isLoading ||
          editDiscount?.isLoading
        }
      >
        Save
        <Save2Icon />
      </LoadingButton>
    </Page>
  );
};
