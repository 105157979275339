/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class RoleStoreUserAuthenticationService {

    /**
     * get admin info
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getAuthenticatedUserInfo(): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/storeUser/getAuthenticatedUserInfo`,
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Change Store User password
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static changePassword(
        requestBody: {
            old_password?: string;
            new_password?: string;
            confirmation_password?: string;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/storeUser/changePassword`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

}