import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";

import { useQuery, useMutation, useQueryClient } from "react-query";

import { TextField, Dialog, UploadPicture } from "../../commons";
import { SnackbarContext } from "../../App";

import { serviceUnavailable, successfulOperation } from "../../utils/message";

import { RestaurantService } from "../../services/openApi";
import { RestaurantsFormDialogType } from "./restaurantsFormDialog-Types";
import { Validation, Error } from "./restaurantsFormDialogTypes-Validation";

type RestaurantsFormDialogProps = {
  open: boolean;
  title: string;
  action: "delete" | "add" | "update" | undefined;
  onClose: () => void;
  idRestaurant?: number;
};

const restaurantFormInitial: RestaurantsFormDialogType = {
  email: "",
  logofile: undefined,
  name: "",
  password: "",
  phone: undefined,
};

export const RestaurantsFormDialog = ({
  open,
  title,
  action,
  onClose,
  idRestaurant,
}: RestaurantsFormDialogProps): JSX.Element => {
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);
  const [restaurantForm, setRestaurantForm] =
    React.useState<RestaurantsFormDialogType>(restaurantFormInitial);
  const [restaurantFormError, setRestaurantFormError] = React.useState<Error>(
    {}
  );

  const { createRestaurant, getRestaurantById, updateRestaurant } =
    RestaurantService;

  const getRestaurantDetails = useQuery(
    ["getRestaurantById", idRestaurant],
    () => getRestaurantById(idRestaurant!),
    {
      onSuccess: (data) => {},
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
      enabled: !!idRestaurant,
    }
  );

  React.useEffect(() => {
    if (getRestaurantDetails?.data) {
      const data = getRestaurantDetails?.data;
      setRestaurantForm({
        email: data.email || "",
        logofile: undefined,
        name: data.name || "",
        password: "",
        phone: data.phone ? parseInt(data.phone.substring(3)) : undefined,
      });
    }
  }, [getRestaurantDetails.data]);

  const addRestaurant = useMutation(createRestaurant, {
    onSuccess: async () => {
      await setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
      onClose();
      queryClient.fetchQuery("getAllReastaurants");
      queryClient.fetchQuery(["getRestaurantById", idRestaurant]);
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const editRestaurant = useMutation(
    () => updateRestaurant(idRestaurant!, restaurantForm),
    {
      onSuccess: async () => {
        await setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        onClose();
        queryClient.fetchQuery("getAllReastaurants");
        queryClient.fetchQuery(["getRestaurantById", idRestaurant]);
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setRestaurantForm({
      ...restaurantForm,
      [name]: value,
    });
    setRestaurantFormError({
      ...restaurantFormError,
      [name]: "",
    });
  };

  const handlePicture = (img: Blob) => {
    setRestaurantForm({
      ...restaurantForm,
      logofile: img,
    });
    setRestaurantFormError({
      ...restaurantFormError,
      logofile: "",
    });
  };

  const handleSubmit = () => {
    const validation = Validation(restaurantForm, !!idRestaurant);
    if (Object.entries(validation).length === 0) {
      if (idRestaurant) {
        editRestaurant.mutate();
      } else {
        addRestaurant.mutate({ ...restaurantForm });
      }
    } else {
      setRestaurantFormError({ ...validation });
    }
  };

  return (
    <Dialog
      open={open}
      title={title}
      maxWidth="sm"
      handleContent={handleSubmit}
      onClose={onClose}
      action={action}
      loadingUseQuery={getRestaurantDetails.isLoading}
      loading={addRestaurant.isLoading || editRestaurant.isLoading}
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <Box display="flex" alignContent="center">
          {getRestaurantDetails.isLoading ? (
            <Skeleton
              variant="rectangular"
              height={100}
              width={100}
              sx={{ borderRadius: "20px" }}
            />
          ) : (
            <UploadPicture
              mode="basic"
              uriImage={getRestaurantDetails?.data?.uri}
              onLoad={handlePicture}
              msgError={restaurantFormError?.logofile}
            />
          )}
          <Typography
            variant="subtitle2"
            style={{ margin: "auto 30px", color: "#dad8d8" }}
          >
            The picture must have a size 400px * 400px
          </Typography>
        </Box>
        {getRestaurantDetails.isLoading ? (
          <>
            <Skeleton
              variant="rectangular"
              height={46}
              sx={{ borderRadius: "30px" }}
            />
            <Skeleton
              variant="rectangular"
              height={46}
              sx={{ borderRadius: "30px" }}
            />
            <Skeleton
              variant="rectangular"
              height={46}
              sx={{ borderRadius: "30px" }}
            />
            <Skeleton
              variant="rectangular"
              height={46}
              sx={{ borderRadius: "30px" }}
            />
          </>
        ) : (
          <>
            <TextField
              name="name"
              label="Restaurant Name"
              value={restaurantForm?.name}
              onChange={handleChange}
              color="secondary"
              error={!!restaurantFormError?.name}
              helperText={restaurantFormError?.name}
            />
            <TextField
              name="phone"
              type="number"
              label="Phone Number"
              value={restaurantForm?.phone || ""}
              onChange={handleChange}
              color="secondary"
              error={!!restaurantFormError?.phone}
              helperText={restaurantFormError?.phone}
              inputProps={{ maxLength: 8 }}
            />
            <TextField
              name="email"
              label="E-Mail"
              value={restaurantForm?.email}
              onChange={handleChange}
              color="secondary"
              error={!!restaurantFormError?.email}
              helperText={restaurantFormError?.email}
              inputProps={{
                autocomplete: "new-password",
                form: {
                  autocomplete: "off",
                },
              }}
            />
            <TextField
              name="password"
              type="password"
              label="Password"
              value={restaurantForm?.password}
              onChange={handleChange}
              color="secondary"
              error={!!restaurantFormError?.password}
              helperText={restaurantFormError?.password}
              inputProps={{
                autocomplete: "new-password",
                form: {
                  autocomplete: "off",
                },
              }}
            />
          </>
        )}
      </Box>
    </Dialog>
  );
};
