import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { LoadingButton, TextField } from "../../../commons";
import { Page } from "./UserRoles-style";
import { Button } from "../../../commons";
import { EditIcon, TrashIcon, LockIcon, SaveIcon } from "../../../img/icon";
import { AdministratorPersmission } from "./AdministratorPermission";
import Table, { ColumnsProps } from "../../../commons/Table";

type UserRolesProps = {};

export type RoleType = {
  id?: number;
  name?: string;
  readonly?: boolean;
};
export const UserRoles = (props: UserRolesProps) => {
  const [listRolesState, setListRolesState] = React.useState<RoleType[]>([
    { id: 1, name: "USERA", readonly: true },
    { id: 2, name: "USERB", readonly: true },
    { id: 3, name: "USERC", readonly: true },
    { id: 4, name: "USERD", readonly: true },
  ]);
  const [roleState, setRoleState] = React.useState<RoleType>();
  const [roleSelectedState, setRoleSelectedState] = React.useState<RoleType>();

  const btnOptionDisabled = listRolesState?.findIndex(
    (o) => o.readonly === false
  );

  if (roleSelectedState)
    return (
      <AdministratorPersmission
        item={roleSelectedState}
        cancel={() => setRoleSelectedState(undefined)}
      />
    );

  const handleChangeRole = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setRoleState({
      ...roleState,
      [name]: value,
    });
  };

  const handleCancelEdit = (index: number) => {
    setListRolesState((prevRoleState) => {
      const arrayRoleState = [...(prevRoleState || [])];
      arrayRoleState[index] = {
        ...arrayRoleState[index],
        readonly: true,
      };
      return arrayRoleState;
    });
    setRoleState(undefined);
  };
  const handleEnableEdit = (index: number) => {
    setListRolesState((prevRoleState) => {
      const arrayRoleState = [...(prevRoleState || [])];
      arrayRoleState[index] = {
        ...arrayRoleState[index],
        readonly: false,
      };
      setRoleState(arrayRoleState[index]);
      return arrayRoleState;
    });
  };
  const columns: ColumnsProps = [
    {
      header: "ID",
      accessor: "id",
    },
    {
      header: "Name",
      accessor: "name",
      headerCellProps: { align: "center" },
      cellProps: { align: "center" },
    },
    {
      header: "Actions",
      accessor: "actions",
      headerCellProps: { align: "center" },
      cellProps: { align: "center", width: "300px" },
    },
  ];

  const renderTableRows = () => {
    return (
      listRolesState.map((item, index) => ({
        id: item?.id,
        name: item?.readonly ? (
          <Typography variant="body2" align="center" className="value-element">
            {item?.name}
          </Typography>
        ) : (
          <TextField
            name="name"
            value={roleState?.name || ""}
            onChange={handleChangeRole}
            color="secondary"
            fullWidth
          />
        ),
        actions: (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={1}
          >
            {item?.readonly ? (
              <Button
                variant="contained"
                className="btn-edit"
                onClick={() => handleEnableEdit(index)}
                disabled={btnOptionDisabled !== -1}
              >
                Edit
                <EditIcon />
              </Button>
            ) : (
              <Button variant="contained" className="btn-edit">
                Save
                <SaveIcon />
              </Button>
            )}
            {item?.readonly ? (
              <Button
                variant="contained"
                className="btn-delete"
                disabled={btnOptionDisabled !== -1}
              >
                Delete <TrashIcon />
              </Button>
            ) : (
              <Button
                variant="contained"
                className="btn-delete"
                onClick={() => handleCancelEdit(index)}
              >
                Cancel
              </Button>
            )}
            <Button
              variant="contained"
              className="btn-permission"
              onClick={() => setRoleSelectedState(item)}
            >
              permission <LockIcon />
            </Button>
          </Box>
        ),
      })) || []
    );
  };
  return (
    <Page>
      <Box display="flex" height={45} justifyContent="center" gap={2}>
        <TextField
          type="text"
          name="enter_role_name"
          label="Enter Role Name"
          color="secondary"
          fullWidth
        />
        <LoadingButton
          variant="contained"
          color="secondary"
          style={{ width: "16em" }}
        >
          Add New Roles
        </LoadingButton>
      </Box>
      <Box display="flex" flex="1" marginTop={3}>
        <Table
          columns={columns}
          data={renderTableRows()}
          emptyMessage="No data available"
        />
      </Box>
    </Page>
  );
};
