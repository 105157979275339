import styled from "styled-components";

export const Page = styled.div`
  display: flex;
  background-color: #fff;
  padding: 25px 20px 20px 25px;
  border: 1px solid #dddddd;
  border-radius: 0px 20px 20px 20px;
  .message-title {
    font-weight: 600;
    margin-left: 46px;
  }
  .wrapper-class {
    border: 1px solid #dddddd;
    border-radius: 20px;
  }

  .rdw-editor-main {
    height: 250px;
    overflow-x: auto;
    padding: 0 10px;
  }
  .toolbar-class {
    border: none;
    border-bottom: 1px solid #dddddd;
    border-radius: 20px 20px 0 0;
    padding: 8px;
  }
  .rdw-editor-toolbar {
    margin-bottom: 0;
  }
  .rdw-option-wrapper {
    border-radius: 8px;
  }
  .rdw-dropdown-wrapper {
    border-radius: 8px;
  }
  .rdw-option-active {
    box-shadow: none;
    background-color: #5bc0de;
    border-radius: 5px;
  }
`;
