/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class StoreDeliveryRegionsService {

    /**
     * Save store region
     * @param id ID of region
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static saveStoreRegion(
        id: number,
        requestBody?: {
            start_time?: string;
            end_time?: string;
            catering_delivery_fees?: number;
            restaurant_store_id?: number;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/restaurant/deliveryRegion/saveStoreRegion/${id}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Update store region
     * @param id ID of store region
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static updateStoreRegion(
        id: number,
        requestBody?: {
            start_time?: string;
            end_time?: string;
            catering_delivery_fees?: number;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/restaurant/deliveryRegion/updateStoreRegion/${id}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Save store region area
     * @param id ID of store region
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static saveStoreRegionArea(
        id: number,
        requestBody?: {
            start_time?: string;
            end_time?: string;
            catering_delivery_fees?: number;
            area_id?: number;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/restaurant/deliveryRegion/saveStoreRegionArea/${id}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Update store region are
     * @param id ID of store region area
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static updateStoreRegionArea(
        id: number,
        requestBody?: {
            start_time?: string;
            end_time?: string;
            catering_delivery_fees?: number;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/restaurant/deliveryRegion/updateStoreRegionArea/${id}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * get store region details
     * @param id ID of store region
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getStoreRegionDetails(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/admins/restaurant/deliveryRegion/storeRegionDetails/${id}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * get store area list by store region id
     * @param id ID of store region
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getStoreRegionDetails1(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/admins/restaurant/deliveryRegion/getAreaDetailsbyRegionId/${id}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * get store region area details
     * @param id ID of store region area
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getStoreRegionAreaDetails(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/admins/restaurant/deliveryRegion/storeRegionAreaDetails/${id}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * get store region list
     * @param id ID of store
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getStoreRegionList(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/admins/restaurant/deliveryRegion/getStoreRegionList/${id}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * delete store region
     * @param id ID of store region
     * @returns any Successful operation
     * @throws ApiError
     */
    public static deleteStoreRegion(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/api/admins/restaurant/deliveryRegion/deleteStoreRegion/${id}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * delete store region area
     * @param id ID of store region area
     * @returns any Successful operation
     * @throws ApiError
     */
    public static deleteStoreRegionArea(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/api/admins/restaurant/deliveryRegion/deleteStoreRegionArea/${id}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

}