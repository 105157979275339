import * as React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import { useQuery } from "react-query";

import { Dialog, Select } from "../../commons";
import { SnackbarContext } from "../../App";
import { serviceUnavailable } from "../../utils/message";

import { RoleRestaurantRegionsService } from "../../services/openApi";

type RegionFormRestaurantDialogProps = {
  open: boolean;
  title: string;
  action?: "delete" | "add" | "update" | "block" | "unBlock";
  onClose: () => void;
  onReturn: (value: string) => void;
};

export const RegionFormRestaurantDialog = ({
  open,
  title,
  action,
  onClose,
  onReturn,
}: RegionFormRestaurantDialogProps): JSX.Element => {
  const { setSnack } = React.useContext(SnackbarContext);
  const { regionList } = RoleRestaurantRegionsService;
  const [idRegion, setIdRegion] = React.useState<string>("");

  const getAllRegionList = useQuery("getRegionsList", regionList, {
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const handleChange = (value: unknown) => {
    setIdRegion(value as string);
  };

  const handleAdd = async () => {
    await onReturn(idRegion);
    await onClose();
    setIdRegion("");
  };

  return (
    <Dialog
      open={open}
      title={title}
      maxWidth="sm"
      handleContent={handleAdd}
      onClose={onClose}
      action={action}
      loading={getAllRegionList?.isLoading}
      loadingUseQuery={!idRegion}
    >
      <Box p="2px">
        <Select
          label="Select Region"
          onChange={(event) => handleChange(event.target.value)}
          style={{ width: 300 }}
          color="secondary"
        >
          {getAllRegionList?.data?.map((item: any) => (
            <MenuItem key={item?.id} value={item?.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Dialog>
  );
};
