import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import NumberFormat, { NumberFormatProps } from "react-number-format";

import { TextField, UploadPicture } from "../../commons";
import { FormStyled } from "./StoreForm-styles";

import { StoreFormType } from "../../modules/Stores/FormStore/FormStore-types";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

type StoreFormProps<T> = {
  data?: StoreFormType;
  formValidation?: T;
  uriLogo?: string;
  uriThumb?: string;
  uriImage?: string;
  loading?: boolean;
  onHandleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onHandlePicture: (name: string, img?: Blob) => void;
  onHandleDropOffTimeFrom?: (value?: string) => void;
  onHandleDropOffTimeTo?: (value?: string) => void;
  onHandleCleanDropOffTime?: () => void;
};

const NumberFormatCustom = React.forwardRef<NumberFormatProps, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        format="##"
      />
    );
  }
);

export const StoreForm = <T extends { [key: string]: any }>({
  data,
  formValidation,
  uriLogo,
  uriThumb,
  uriImage,
  loading,
  onHandleChange,
  onHandlePicture,
  onHandleDropOffTimeFrom,
  onHandleDropOffTimeTo,
  onHandleCleanDropOffTime,
}: StoreFormProps<T>): JSX.Element => {
  const [dropOffTime, setDropOffTime] = React.useState<boolean>(false);
  return (
    <FormStyled>
      <div className="form-column">
        <Box display="flex" flexDirection="column" gap={2}>
          {loading ? (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={46}
              sx={{ borderRadius: "30px" }}
            />
          ) : (
            <TextField
              name="name_en"
              value={data?.name_en || ""}
              label="Title in English"
              color="secondary"
              onChange={onHandleChange}
              error={!!formValidation?.name_en}
              helperText={formValidation?.name_en}
            />
          )}
          {loading ? (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={119}
              sx={{ borderRadius: "30px" }}
            />
          ) : (
            <TextField
              name="description_en"
              label="Description in English"
              value={data?.description_en || ""}
              multiline
              rows={3}
              color="secondary"
              onChange={onHandleChange}
              error={!!formValidation?.description_en}
              helperText={formValidation?.description_en}
            />
          )}
          {loading ? (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={46}
              sx={{ borderRadius: "30px" }}
            />
          ) : (
            <TextField
              name="catering_comission"
              value={data?.catering_comission || ""}
              type="number"
              label="Catering Comission"
              color="secondary"
              onChange={onHandleChange}
              error={!!formValidation?.catering_comission}
              helperText={formValidation?.catering_comission}
            />
          )}
          {loading ? (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={46}
              sx={{ borderRadius: "30px" }}
            />
          ) : (
            <TextField
              type="number"
              name="debit_card_fee"
              value={data?.debit_card_fee || ""}
              label="Debit Card fee (%)"
              color="secondary"
              onChange={onHandleChange}
              error={!!formValidation?.debit_card_fee}
              helperText={formValidation?.debit_card_fee}
            />
          )}

          {loading ? (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={46}
              sx={{ borderRadius: "30px" }}
            />
          ) : (
            <TextField
              type="number"
              name="max_catering_per_day"
              label="Max catering per day"
              value={data?.max_catering_per_day || ""}
              color="secondary"
              onChange={onHandleChange}
              error={!!formValidation?.max_catering_per_day}
              helperText={formValidation?.max_catering_per_day}
            />
          )}
          {loading ? (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={46}
              sx={{ borderRadius: "30px" }}
            />
          ) : (
            <TextField
              name="email"
              value={data?.email || ""}
              label="Email"
              color="secondary"
              onChange={onHandleChange}
              error={!!formValidation?.email}
              helperText={formValidation?.email}
            />
          )}
          {loading ? (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={46}
              sx={{ borderRadius: "30px" }}
            />
          ) : (
            <TextField
              name="delivery_type"
              value={data?.delivery_type || ""}
              label="Delivery Type"
              color="secondary"
              onChange={onHandleChange}
              error={!!formValidation?.delivery_type}
              helperText={formValidation?.delivery_type}
              disabled
            />
          )}
        </Box>
        <Box display="flex" flexDirection="column" gap={2}>
          {loading ? (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={46}
              sx={{ borderRadius: "30px" }}
            />
          ) : (
            <TextField
              name="name_ar"
              value={data?.name_ar || ""}
              label="Title in Arabic"
              color="secondary"
              onChange={onHandleChange}
              error={!!formValidation?.name_ar}
              helperText={formValidation?.name_ar}
            />
          )}
          {loading ? (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={119}
              sx={{ borderRadius: "30px" }}
            />
          ) : (
            <TextField
              name="description_ar"
              label="Description in Arabic"
              value={data?.description_ar || ""}
              multiline
              rows={3}
              color="secondary"
              onChange={onHandleChange}
              error={!!formValidation?.description_ar}
              helperText={formValidation?.description_ar}
            />
          )}
          {loading ? (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={46}
              sx={{ borderRadius: "30px" }}
            />
          ) : (
            <TextField
              name="value_added_tax"
              value={data?.value_added_tax || ""}
              label="Value Added Tax"
              type="number"
              color="secondary"
              onChange={onHandleChange}
              error={!!formValidation?.value_added_tax}
              helperText={formValidation?.value_added_tax}
            />
          )}
          {loading ? (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={46}
              sx={{ borderRadius: "30px" }}
            />
          ) : (
            <TextField
              name="catering_advance"
              value={data?.catering_advance || ""}
              label="Catering Advance (%)"
              type="number"
              color="secondary"
              onChange={onHandleChange}
              error={!!formValidation?.catering_advance}
              helperText={formValidation?.catering_advance}
            />
          )}

          {loading ? (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={46}
              sx={{ borderRadius: "30px" }}
            />
          ) : (
            <TextField
              name="credit_card_fee"
              value={data?.credit_card_fee || ""}
              type="number"
              label="Credit Card fee- Visa and Mastercard (%)"
              color="secondary"
              onChange={onHandleChange}
              error={!!formValidation?.credit_card_fee}
              helperText={formValidation?.credit_card_fee}
            />
          )}
          {loading ? (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={46}
              sx={{ borderRadius: "30px" }}
            />
          ) : (
            <TextField
              name="phone"
              label="Phone"
              value={data?.phone || ""}
              type="number"
              color="secondary"
              onChange={onHandleChange}
              error={!!formValidation?.phone}
              helperText={formValidation?.phone}
            />
          )}
          {loading ? (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={46}
              sx={{ borderRadius: "30px" }}
            />
          ) : (
            <ClickAwayListener onClickAway={() => setDropOffTime(false)}>
              <Box position="relative" display="flex" flexDirection="column">
                <TextField
                  name="drop_off_time"
                  label="Drop off Time"
                  value={data?.drop_off_time || ""}
                  color="secondary"
                  onClick={() => setDropOffTime((v) => !v)}
                  autoComplete="off"
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            onHandleCleanDropOffTime &&
                              onHandleCleanDropOffTime();
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                {dropOffTime && (
                  <Box className="drop_top_time">
                    <TextField
                      color="secondary"
                      value={data?.drop_off_time?.substring(0, 2) || ""}
                      InputProps={{
                        inputComponent: NumberFormatCustom as any,
                      }}
                      onBlur={(e) =>
                        onHandleDropOffTimeFrom &&
                        onHandleDropOffTimeFrom(e.target.value)
                      }
                      autoFocus
                    />
                    -
                    <TextField
                      color="secondary"
                      value={
                        data?.drop_off_time?.substring(
                          data?.drop_off_time?.indexOf("-") === -1
                            ? 3
                            : data?.drop_off_time?.indexOf("-"),
                          data?.drop_off_time?.length
                        ) || ""
                      }
                      InputProps={{
                        inputComponent: NumberFormatCustom as any,
                      }}
                      onBlur={(e) =>
                        onHandleDropOffTimeTo &&
                        onHandleDropOffTimeTo(e.target.value)
                      }
                      disabled={!data?.drop_off_time}
                    />
                  </Box>
                )}
              </Box>
            </ClickAwayListener>
          )}
        </Box>
        <Box
          display="flex"
          gap="5px"
          flexDirection="column"
          alignItems="center"
        >
          <Typography variant="subtitle2" style={{ color: "#dad8d8" }}>
            Logo
          </Typography>

          <UploadPicture
            uriImage={uriLogo}
            mode="basic"
            onLoad={(result) => onHandlePicture("logofile", result)}
            msgError={formValidation?.logofile}
            loading={loading}
          />

          <Typography variant="subtitle2" style={{ color: "#dad8d8" }}>
            Cover Image
          </Typography>
          <UploadPicture
            uriImage={uriThumb}
            mode="basic"
            onLoad={(result) => onHandlePicture("thumbfile", result)}
            msgError={formValidation?.thumbfile}
            loading={loading}
          />
          <Typography variant="subtitle2" style={{ color: "#dad8d8" }}>
            Menu Image
          </Typography>
          <UploadPicture
            uriImage={uriImage}
            mode="basic"
            onLoad={(result) => onHandlePicture("imagefile", result)}
            msgError={formValidation?.imagefile}
            loading={loading}
          />

          <Typography
            variant="subtitle2"
            align="center"
            className="description"
          >
            The pictures must have a size 400px * 400px
          </Typography>
        </Box>
      </div>
    </FormStyled>
  );
};
