import * as React from "react";
import Grid from "@mui/material/Grid";
import GoogleMapReact from "google-map-react";

import { Dialog, TextField } from "../../commons";

import { API_KEY_GOOGLE_MAP } from "../../utils/config";

export type AddressFormDialogProps = {
  open: boolean;
  title: string;
  action?: "delete" | "add" | "update" | "view";
  onClose: () => void;
};

type AnyReactComponentPros = { lat: number; lng: number; text: string };

const AnyReactComponent = ({ lat, lng, text }: AnyReactComponentPros) => (
  <div>{text}</div>
);

const handleApiLoaded = (map: any, maps: any) => {};

const defaultPosition = {
  center: {
    lat: 59.95,
    lng: 30.33,
  },
  zoom: 11,
};

export const AddressFormDialog = ({
  open,
  title,
  action,
  onClose,
}: AddressFormDialogProps): JSX.Element => {
  return (
    <Dialog
      open={open}
      title={title}
      maxWidth="lg"
      handleContent={() => {}}
      onClose={onClose}
      action={action}
      positionbtnaction={action === "view" ? "end" : "center"}
    >
      <Grid container width={700} spacing={2}>
        <Grid item xs={6}>
          <TextField
            label="Address Title"
            fullWidth
            disabled={action === "view"}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Phone Numbe"
            fullWidth
            disabled={action === "view"}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Address Line 1"
            fullWidth
            disabled={action === "view"}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Address Line 2"
            fullWidth
            disabled={action === "view"}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField label="City" fullWidth disabled={action === "view"} />
        </Grid>
        <Grid item xs={6}>
          <TextField label="Sates" fullWidth disabled={action === "view"} />
        </Grid>
        <Grid item xs={6}>
          <TextField label="Country" fullWidth disabled={action === "view"} />
        </Grid>
        <Grid item xs={6}>
          <TextField label="Zipcode" fullWidth disabled={action === "view"} />
        </Grid>
      </Grid>
      <div style={{ height: "200px", width: "100%", marginTop: 30 }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: API_KEY_GOOGLE_MAP || "" }}
          defaultCenter={defaultPosition.center}
          defaultZoom={defaultPosition.zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        >
          <AnyReactComponent lat={59.955413} lng={30.337844} text="My Marker" />
        </GoogleMapReact>
      </div>
    </Dialog>
  );
};
