import { Route, Redirect, RouteProps } from "react-router-dom";

type PrivateRouteProps = {
  component: any;
} & RouteProps;

export const PrivateRoute = ({
  component: Component,
  ...rest
}: PrivateRouteProps) => {
  const token = localStorage.getItem("token");

  return (
    <Route
      {...rest}
      render={(props) => {
        // if not logged
        if (!token) {
          return <Redirect to="/" />;
        }
        return <Component {...props} />;
      }}
    />
  );
};
