import * as React from "react";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { Button, TreeItem, Dialog } from "../../commons";
import { RegionsCardFormDialog } from "..";
import { SnackbarContext } from "../../App";
import { serviceUnavailable, successfulOperation } from "../../utils/message";

import { RegionsService } from "../../services/openApi";

import { EditIcon, TrashIcon } from "../../img/icon";

import { Container } from "./RegionsCard-style";

type RegionCardProps = {
  onSelectRegion?: (id?: number) => void;
};

type openFormState = {
  open: boolean;
  title: string;
  action?: "delete" | "add" | "update";
};

export const RegionCard = ({
  onSelectRegion,
}: RegionCardProps): JSX.Element => {
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);
  const { regionList, deleteRegion } = RegionsService;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [openModal, setOpenModal] = React.useState<openFormState>({
    open: false,
    title: "",
  });
  const [idRegion, setIdRegion] = React.useState<number | undefined>();

  const ListRegions = useQuery("getRegionsList", () => regionList(), {
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const removeRegion = useMutation(deleteRegion, {
    onSuccess: () => {
      queryClient.refetchQueries("getRegionsList");
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
      setOpenModal({
        open: false,
        title: "",
      });
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setOpenModal({
      open: true,
      title: "Edit Region",
      action: "update",
    });
    handleClose();
  };

  const handleDelete = () => {
    setOpenModal({
      open: true,
      title: "Delete",
      action: "delete",
    });
    handleClose();
  };

  return (
    <Container>
      <div className="header">
        <Typography variant="h2" className="title">
          Region
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={() =>
            setOpenModal({
              open: true,
              title: "Add Region",
              action: "add",
            })
          }
        >
          Add Region
        </Button>
      </div>
      <div className="list-regions scrolbar">
        {ListRegions?.data?.map((item: any) => (
          <TreeItem
            key={item?.id}
            id={item?.id}
            name={item?.name}
            onAction={(event) => {
              handleClick(event);
            }}
            onSelect={(id) => {
              onSelectRegion && onSelectRegion(id);
              setIdRegion(id);
            }}
          />
        ))}
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={handleEdit} className="item-edit">
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <TrashIcon />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
      {openModal.action === "delete" && (
        <Dialog
          open={openModal.open}
          title={openModal.title}
          action="delete"
          handleContent={() => idRegion && removeRegion.mutate(idRegion)}
          loading={removeRegion.isLoading}
          onClose={() => {
            setOpenModal({
              open: false,
              title: "",
            });
            setIdRegion(undefined);
          }}
        >
          <Typography>Are you sure to delete this region?</Typography>
        </Dialog>
      )}
      {(openModal.action === "add" || openModal.action === "update") && (
        <RegionsCardFormDialog
          idRegion={idRegion}
          open={openModal.open}
          title={openModal.title}
          action={openModal.action}
          onClose={() => {
            setOpenModal({
              open: false,
              title: "",
            });
            setIdRegion(undefined);
          }}
        />
      )}
    </Container>
  );
};
