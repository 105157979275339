import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import AnimatedNumbers from "react-animated-numbers";

import { LazyLoadingImage } from "../../commons";

import { CardStyled } from "./CradStatus-styles";

import {
  framePending,
  frameProcessing,
  frameCompleted,
  frameCancelled,
} from "../../img/frame";

type CardStatusCustomerPorps = {
  status: "activeCount" | "unblockedCount" | "blockedCount" | "usersCount";
  loading?: boolean;
  count?: number;
  selected?: boolean;
  onClick?: () => void;
};

export const CardStatusCustomer = ({
  status,
  loading,
  count,
  selected,
  onClick,
}: CardStatusCustomerPorps): JSX.Element => {
  const handleFrame = (value: string): string | undefined => {
    switch (value) {
      case "usersCount":
        return framePending;
      case "unblockedCount":
        return frameProcessing;
      case "activeCount":
        return frameCompleted;
      case "blockedCount":
        return frameCancelled;
    }
  };

  const showStatus = (value: string) => {
    switch (value) {
      case "usersCount":
        return "Total Customers";
      case "activeCount":
        return "Active Customers";
      case "blockedCount":
        return "Blocked Customers";
      case "unblockedCount":
        return "Unblocked Customers";
      default:
        return "";
    }
  };

  return (
    <CardStyled onClick={onClick} selected={selected}>
      {loading ? (
        <Skeleton
          variant="rectangular"
          component={Box}
          display="flex"
          flex={1}
          height={100}
          borderRadius="20px"
        />
      ) : (
        <>
          <LazyLoadingImage
            alt="carting Menu"
            src={handleFrame(status)}
            width="100%"
            height={100}
          />
          <div className="details">
            <Box
              display="flex"
              width="100%"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="h1" align="center" className="count">
                {count && <AnimatedNumbers animateToNumber={count} />}
              </Typography>
              <Typography variant="body1" align="center" className="status">
                {showStatus(status)}
              </Typography>
            </Box>
          </div>
        </>
      )}
    </CardStyled>
  );
};
