import * as React from "react";
import Box from "@mui/material/Box";
import Typgraphy from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Skeleton from "@mui/material/Skeleton";
import Checkbox from "@mui/material/Checkbox";
import voucher_codes from "voucher-code-generator";
import moment from "moment";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { TextField, Dialog, Button, NumberFormatCustom } from "../../commons";

import { SnackbarContext } from "../../App";
import { successfulOperation, serviceUnavailable } from "../../utils/message";
import {
  CateringCouponService,
  FilterCollectionsService,
} from "../../services/openApi";

import { FormCouponModalType } from "./FormCouponModal-type";
import { Error, Validation } from "./FormCouponModalTypes-validation";

type FormCouponModalProps = {
  idCoupon?: number;
  open: boolean;
  title: string;
  action?: "add" | "update" | "delete";
  onClose: () => void;
};
const couponFormInitial: FormCouponModalType = {
  title_en: "",
  short_description_en: "",
  title_ar: "",
  short_description_ar: "",
  discount: undefined,
  meiz_contribution: undefined,
  start_date: "",
  end_date: "",
  code: "",
  type: "Item",
  status: true,
  coupons: [
    {
      store: undefined,
      items: [""],
    },
  ],
};
export const FormCouponModal = ({
  idCoupon,
  open,
  title,
  action,
  onClose,
}: FormCouponModalProps): JSX.Element => {
  const { setSnack } = React.useContext(SnackbarContext);
  const {
    saveCateringCoupon,
    getAllItemsList,
    getCateringCouponDetails,
    updateCateringCoupon,
  } = CateringCouponService;
  const { getAllStoresList } = FilterCollectionsService;
  const queryClient = useQueryClient();
  const [couponFormError, setCouponFormError] = React.useState<Error>({});
  const [formState, setFormState] =
    React.useState<FormCouponModalType>(couponFormInitial);

  const listStores = useQuery("getAllStoresList", () => getAllStoresList(), {
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const listItems = useQuery("getAllItemsList", () => getAllItemsList(), {
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const couponDetails = useQuery(
    ["getCateringCouponDetails", idCoupon],
    () =>
      getCateringCouponDetails({
        catering_coupon_id: idCoupon,
      }),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
      enabled: !!idCoupon,
    }
  );

  React.useEffect(() => {
    if (couponDetails.data) {
      const dataCoupon = couponDetails.data?.[0];
      const arrayCoupons: any[] = [];
      const arrayItem: string[] = [];
      dataCoupon?.coupons_stores?.forEach((item: any) => {
        item?.items?.forEach((element: any) => {
          arrayItem.push(element?.item_id);
        });
        arrayCoupons.push({
          store: item?.id,
          items: arrayItem,
        });
      });
      setFormState({
        code: dataCoupon?.code,
        title_en: dataCoupon?.coupons_translations?.[0]?.title,
        short_description_en:
          dataCoupon?.coupons_translations?.[0]?.short_description,
        title_ar: dataCoupon?.coupons_translations?.[1]?.title,
        short_description_ar:
          dataCoupon?.coupons_translations?.[1]?.short_description,
        discount: dataCoupon?.discount,
        start_date: dataCoupon?.start_date,
        end_date: dataCoupon?.end_date,
        meiz_contribution: dataCoupon?.meiz_contribution,
        minimum_order_amount: dataCoupon?.minimum_order_amount,
        total_usage_limit: dataCoupon?.total_usage_limit,
        limit_per_user: dataCoupon?.limit_per_user,
        type: dataCoupon?.type,
        status: dataCoupon?.status,
        coupons: !dataCoupon?.all_stores ? arrayCoupons : [],
        all_stores: dataCoupon?.all_stores,
      });
    }
  }, [couponDetails.data]);

  const saveCoupon = useMutation(saveCateringCoupon, {
    onSuccess: () => {
      queryClient.refetchQueries("getCatringCouponsList");
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
      onClose();
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const editCoupon = useMutation(updateCateringCoupon, {
    onSuccess: () => {
      queryClient.refetchQueries("getCatringCouponsList");
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
      onClose();
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const handleGenerateCouponCode = () => {
    setFormState({
      ...formState,
      code: voucher_codes.generate({
        length: 6,
        count: 1,
      })[0],
    });
    setCouponFormError({
      ...couponFormError,
      code: "",
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target as HTMLInputElement;
    setFormState({
      ...formState,
      [name]: value,
      all_stores: value === "Item" ? false : formState.all_stores,
    });
    setCouponFormError({
      ...couponFormError,
      [name]: "",
    });
  };

  const handleChangeDate =
    (props: "start_date" | "end_date") => (value: Date | null) => {
      setFormState({
        ...formState,
        [props]: moment(value).format("YYYY-MM-DD"),
      });
      setCouponFormError({
        ...couponFormError,
        [props]: "",
      });
    };

  const handleChangeStore = (array: any[]) => {
    setFormState((prevFormState) => {
      const arrayFormState = { ...prevFormState };
      const arrayCoupons: any[] = [];
      array?.forEach((item: any) =>
        arrayCoupons.push({
          store: item?.id,
        })
      );
      return {
        ...arrayFormState,
        coupons: arrayCoupons,
      };
    });
  };

  const handleChangeStoreItem = (index: number, id: number) => {
    setFormState((prevFormState) => {
      const arrayFormState = { ...prevFormState };
      const arrayCoupons = [...(arrayFormState?.coupons || [])];
      arrayCoupons[index] = {
        ...arrayCoupons[index],
        store: id,
        items: [""],
      };
      return {
        ...arrayFormState,
        coupons: arrayCoupons,
      };
    });
  };

  const handleChangeItem = (index: number, array: any[]) => {
    const arrayIds = array?.map((item) => item?.item_id) || [];
    setFormState((prevFormState) => {
      const arrayFormState = { ...prevFormState };
      const arrayCoupons = [...(arrayFormState?.coupons || [])];
      arrayCoupons[index] = {
        ...arrayCoupons[index],
        items: arrayIds,
      };
      return {
        ...arrayFormState,
        coupons: arrayCoupons,
      };
    });
  };

  const handleAdd = () => {
    setFormState((prevFormState) => {
      const arrayFormState = { ...prevFormState };
      const arrayCoupons = [...(arrayFormState?.coupons || [])];
      arrayCoupons.push({
        store: undefined,
        items: [""],
      });
      return {
        ...arrayFormState,
        coupons: arrayCoupons,
      };
    });
  };

  const handleRemove = (index: number) => {
    setFormState((prevFormState) => {
      const arrayFormState = { ...prevFormState };
      const arrayCoupons = [...(arrayFormState?.coupons || [])];
      arrayCoupons.splice(index, 1);
      return {
        ...arrayFormState,
        coupons: arrayCoupons,
      };
    });
  };

  const handleSave = () => {
    const validation = Validation(formState, !!idCoupon);
    if (Object.entries(validation).length === 0) {
      if (idCoupon) {
        editCoupon.mutate({
          catering_coupon_id: idCoupon,
          title_en: formState?.title_en,
          title_ar: formState?.title_ar,
          short_description_en: formState?.short_description_en,
          short_description_ar: formState?.short_description_ar,
          status: formState?.status,
          coupons: formState?.coupons,
          type: formState?.type,
          all_stores: formState?.all_stores,
        });
      } else {
        saveCoupon.mutate(formState);
      }
    } else setCouponFormError({ ...validation });
  };
  return (
    <Dialog
      open={open}
      title={title}
      action={action}
      handleContent={handleSave}
      loading={saveCoupon?.isLoading || editCoupon?.isLoading}
      loadingUseQuery={
        couponDetails.isLoading || formState?.all_stores
          ? !formState?.all_stores
          : !!formState?.coupons?.find(
              (item: any) => item?.store === undefined
            ) ||
            (formState?.type === "Item" &&
              !!formState?.coupons?.filter(
                (coupon: any) =>
                  !coupon?.items?.findIndex((item: any) => item === "")
              )?.[0])
      }
      onClose={onClose}
      maxWidth="lg"
    >
      <Box
        display="grid"
        width={650}
        gridTemplateColumns="1fr 1fr"
        gap={2}
        ml={7}
        mr={7}
      >
        {couponDetails.isLoading ? (
          <Skeleton
            variant="rectangular"
            width="100%"
            height={46}
            sx={{ borderRadius: "30px" }}
          />
        ) : (
          <TextField
            name="title_en"
            label="Title (en)"
            value={formState.title_en || ""}
            onChange={handleChange}
            color="secondary"
            error={!!couponFormError?.title_en}
            helperText={couponFormError?.title_en}
          />
        )}
        {couponDetails.isLoading ? (
          <Skeleton
            variant="rectangular"
            width="100%"
            height={46}
            sx={{ borderRadius: "30px" }}
          />
        ) : (
          <TextField
            name="title_ar"
            label="Title (ar)"
            value={formState.title_ar || ""}
            onChange={handleChange}
            color="secondary"
            error={!!couponFormError?.title_ar}
            helperText={couponFormError?.title_ar}
          />
        )}
        {couponDetails.isLoading ? (
          <Skeleton
            variant="rectangular"
            width="100%"
            height={46}
            sx={{ borderRadius: "30px" }}
          />
        ) : (
          <TextField
            name="short_description_en"
            label="Short Description(en)"
            value={formState.short_description_en || ""}
            onChange={handleChange}
            color="secondary"
            error={!!couponFormError?.short_description_en}
            helperText={couponFormError?.short_description_en}
          />
        )}
        {couponDetails.isLoading ? (
          <Skeleton
            variant="rectangular"
            width="100%"
            height={46}
            sx={{ borderRadius: "30px" }}
          />
        ) : (
          <TextField
            name="short_description_ar"
            label="Short Description(ar)"
            value={formState.short_description_ar || ""}
            onChange={handleChange}
            color="secondary"
            error={!!couponFormError?.short_description_ar}
            helperText={couponFormError?.short_description_ar}
          />
        )}
        {couponDetails.isLoading ? (
          <Skeleton
            variant="rectangular"
            width="100%"
            height={46}
            sx={{ borderRadius: "30px" }}
          />
        ) : (
          <TextField
            name="code"
            label="Coupon Code"
            value={formState?.code || ""}
            onChange={handleChange}
            color="secondary"
            error={!!couponFormError?.code}
            helperText={couponFormError?.code}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleGenerateCouponCode}
                    sx={{ mr: "-18px", mt: "-1px" }}
                    disabled={!!idCoupon}
                  >
                    Generate
                  </Button>
                </InputAdornment>
              ),
              // readOnly: true,
            }}
            disabled={!!idCoupon}
          />
        )}
        {couponDetails.isLoading ? (
          <Skeleton
            variant="rectangular"
            width="100%"
            height={46}
            sx={{ borderRadius: "30px" }}
          />
        ) : (
          <TextField
            name="discount"
            label="Pourcentage Discount%"
            value={formState?.discount || ""}
            onChange={handleChange}
            color="secondary"
            error={!!couponFormError?.discount}
            helperText={couponFormError?.discount}
            // onBlur={(e) =>
            //   setFormState({
            //     ...formState,
            //     discount:
            //       (formState?.discount || 0) <=
            //       (formState?.meiz_contribution || 0)
            //         ? undefined
            //         : formState?.discount,
            //   })
            // }
            InputProps={{
              inputComponent: NumberFormatCustom as any,
            }}
            disabled={!!idCoupon}
          />
        )}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          {couponDetails.isLoading ? (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={46}
              sx={{ borderRadius: "30px" }}
            />
          ) : (
            <DatePicker
              label="Start Date"
              openTo="year"
              value={formState?.start_date || null}
              onChange={handleChangeDate("start_date")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  color="secondary"
                  onChange={handleChange}
                  error={!!couponFormError?.start_date}
                  helperText={couponFormError?.start_date}
                />
              )}
              disabled={!!idCoupon}
            />
          )}
          {couponDetails.isLoading ? (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={46}
              sx={{ borderRadius: "30px" }}
            />
          ) : (
            <DatePicker
              label="End Date"
              openTo="year"
              value={formState?.end_date || null}
              onChange={handleChangeDate("end_date")}
              minDate={
                formState?.start_date
                  ? new Date(formState?.start_date || "")
                  : undefined
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="end_date"
                  color="secondary"
                  onChange={handleChange}
                  error={!!couponFormError?.end_date}
                  helperText={couponFormError?.end_date}
                />
              )}
              disabled={!!idCoupon}
            />
          )}
        </LocalizationProvider>
        {couponDetails.isLoading ? (
          <Skeleton
            variant="rectangular"
            width="100%"
            height={46}
            sx={{ borderRadius: "30px" }}
          />
        ) : (
          <TextField
            name="meiz_contribution"
            label="Meiz Contribution %"
            value={formState?.meiz_contribution || ""}
            onChange={handleChange}
            color="secondary"
            error={!!couponFormError?.meiz_contribution}
            helperText={couponFormError?.meiz_contribution}
            InputProps={{
              inputComponent: NumberFormatCustom as any,
            }}
            // onBlur={(e) =>
            //   setFormState({
            //     ...formState,
            //     meiz_contribution:
            //       (formState?.discount || 0) <=
            //       (formState?.meiz_contribution || 0)
            //         ? undefined
            //         : formState?.meiz_contribution,
            //   })
            // }
            disabled={!!idCoupon}
          />
        )}
        {couponDetails.isLoading ? (
          <Skeleton
            variant="rectangular"
            width="100%"
            height={46}
            sx={{ borderRadius: "30px" }}
          />
        ) : (
          <TextField
            name="minimum_order_amount"
            label="Minimum Order Amount"
            type="number"
            value={formState?.minimum_order_amount || ""}
            onChange={handleChange}
            color="secondary"
            disabled={!!idCoupon}
          />
        )}
        {couponDetails.isLoading ? (
          <Skeleton
            variant="rectangular"
            width="100%"
            height={46}
            sx={{ borderRadius: "30px" }}
          />
        ) : (
          <TextField
            name="total_usage_limit"
            label="Total Usage Limit"
            type="number"
            value={formState?.total_usage_limit || ""}
            onChange={handleChange}
            color="secondary"
            disabled={!!idCoupon}
          />
        )}
        {couponDetails.isLoading ? (
          <Skeleton
            variant="rectangular"
            width="100%"
            height={46}
            sx={{ borderRadius: "30px" }}
          />
        ) : (
          <TextField
            name="limit_per_user"
            label="Limit Per User"
            type="number"
            value={formState?.limit_per_user || ""}
            onChange={handleChange}
            color="secondary"
            disabled={!!idCoupon}
          />
        )}
        <Box display="flex" alignItems="center" gap={2} mb={2}>
          <Typgraphy variant="subtitle2" sx={{ color: "secondary.main" }}>
            Applyed to
          </Typgraphy>
          <RadioGroup
            name="type"
            row
            value={formState?.type || ""}
            aria-labelledby="demo-row-radio-buttons-group-label"
            onChange={handleChange}
          >
            <FormControlLabel
              value="Item"
              control={
                couponDetails.isLoading ? (
                  <Skeleton
                    variant="circular"
                    width={24}
                    height={24}
                    sx={{ borderRadius: "30px", m: "9px" }}
                  />
                ) : (
                  <Radio color="secondary" />
                )
              }
              label="Menu"
              disabled={!!idCoupon}
            />
            <FormControlLabel
              value="Store"
              control={
                couponDetails.isLoading ? (
                  <Skeleton
                    variant="circular"
                    width={24}
                    height={24}
                    sx={{ borderRadius: "30px", m: "9px" }}
                  />
                ) : (
                  <Radio color="secondary" />
                )
              }
              label="Store"
              disabled={!!idCoupon}
            />
          </RadioGroup>
        </Box>
        <Box display="flex" alignItems="center" gap={2} mb={2}>
          <Typgraphy variant="subtitle2" sx={{ color: "secondary.main" }}>
            Status
          </Typgraphy>
          <RadioGroup
            name="status"
            row
            value={formState?.status || false}
            aria-labelledby="demo-row-radio-buttons-group-label"
            onChange={handleChange}
          >
            <FormControlLabel
              value={true}
              control={
                couponDetails.isLoading ? (
                  <Skeleton
                    variant="circular"
                    width={24}
                    height={24}
                    sx={{ borderRadius: "30px", m: "9px" }}
                  />
                ) : (
                  <Radio color="secondary" />
                )
              }
              label="Active"
              disabled={!!idCoupon}
            />
            <FormControlLabel
              value={false}
              control={
                couponDetails.isLoading ? (
                  <Skeleton
                    variant="circular"
                    width={24}
                    height={24}
                    sx={{ borderRadius: "30px", m: "9px" }}
                  />
                ) : (
                  <Radio color="secondary" />
                )
              }
              label="Inactive"
              disabled={!!idCoupon}
            />
          </RadioGroup>
        </Box>
      </Box>
      {formState?.type === "Store" && (
        <Autocomplete
          multiple
          disablePortal
          id="store"
          value={
            formState?.coupons && formState.coupons[0]?.store
              ? formState?.coupons?.map((coupon: any) =>
                  listStores?.data?.find(
                    (element: any) => element?.id === coupon?.store
                  )
                )
              : []
          }
          options={listStores.data || []}
          filterSelectedOptions
          onChange={(_, value) => value && handleChangeStore(value)}
          getOptionLabel={(option: any) => option?.name || ""}
          renderInput={(params) => (
            <TextField {...params} label="Store" color="secondary" />
          )}
          loading={listStores?.isLoading}
          sx={{ ml: 7, mr: 7 }}
          disabled={formState.all_stores}
        />
      )}
      {formState?.type === "Item" && (
        <Box
          display="grid"
          gridTemplateColumns="317px 317px 45px"
          gap={2}
          ml={7}
        >
          {formState?.coupons?.map((coupon, index) => (
            <React.Fragment key={index}>
              {couponDetails.isLoading ? (
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={46}
                  sx={{ borderRadius: "30px" }}
                />
              ) : (
                <Autocomplete
                  disablePortal
                  id="store"
                  value={
                    listStores?.data?.find(
                      (item: any) => item?.id === coupon.store
                    ) || null
                  }
                  options={listStores.data || []}
                  getOptionLabel={(option: any) => option?.name || ""}
                  filterSelectedOptions
                  onChange={(_, value) =>
                    value?.id && handleChangeStoreItem(index, value.id)
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Store" color="secondary" />
                  )}
                  loading={listStores?.isLoading}
                />
              )}
              {couponDetails.isLoading ? (
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={46}
                  sx={{ borderRadius: "30px" }}
                />
              ) : (
                <Autocomplete
                  disablePortal
                  multiple
                  id="item"
                  value={
                    coupon.items && coupon.items?.[0] !== ""
                      ? coupon.items?.map((item: any) =>
                          listItems?.data?.find(
                            (element: any) => element?.item_id === item
                          )
                        )
                      : []
                  }
                  options={
                    listItems?.data?.filter(
                      (element: any) =>
                        element.restaurant_store_id === coupon.store
                    ) || []
                  }
                  getOptionLabel={(option: any) => option?.name}
                  filterSelectedOptions
                  onChange={(_, value) =>
                    value && handleChangeItem(index, value)
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Menu" color="secondary" />
                  )}
                  loading={listItems?.isLoading}
                />
              )}
              {(formState?.coupons?.length || 0) === index + 1 ? (
                <IconButton
                  onClick={handleAdd}
                  sx={{
                    color: "secondary.main",
                    margin: "auto",
                  }}
                  size="small"
                >
                  <AddCircleOutlineIcon />
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => handleRemove(index)}
                  sx={{
                    color: "error.main",
                    margin: "auto",
                  }}
                  size="small"
                >
                  <RemoveCircleOutlineIcon />
                </IconButton>
              )}
            </React.Fragment>
          ))}
        </Box>
      )}
      <Box mt={1} ml={7} mr={7}>
        <FormControlLabel
          control={
            <Checkbox
              checked={formState.all_stores || false}
              color="secondary"
              onChange={(e) =>
                setFormState({
                  ...formState,
                  all_stores: e.target.checked,
                })
              }
            />
          }
          label="Select All Stores"
          labelPlacement="end"
          disabled={formState.type === "Item"}
        />
      </Box>
    </Dialog>
  );
};
