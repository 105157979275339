import isEmpty from "validator/lib/isEmpty";
import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";
import isByteLength from "validator/lib/isByteLength";

import { StoreFormType } from "./FormStore-types";
import { message } from "../../../utils/message";

export type Error = {
  name_en?: string;
  name_ar?: string;
  catering_comission?: string;
  value_added_tax?: string;
  catering_advance?: string;
  credit_card_fee?: string;
  debit_card_fee?: string;
  delivery_type?: string;
  email?: string;
  phone?: string;
  description_en?: string;
  description_ar?: string;
  max_catering_per_day?: string;
  logofile?: string;
  thumbfile?: string;
  imagefile?: string;
};

export const validation = (values: StoreFormType): Error => {
  const errors: Error = {};

  Object.keys(values).forEach((key: string) => {
    switch (key) {
      case "name_en":
        if (isEmpty(values?.name_en || "")) {
          errors.name_en = message.storeForm.nameEn;
        }
        break;
      case "name_ar":
        if (isEmpty(values?.name_ar || "")) {
          errors.name_ar = message.storeForm.nameAr;
        }
        break;
      case "catering_comission":
        if (!values?.catering_comission) {
          errors.catering_comission = message.storeForm.cateringComission;
        }
        break;
      case "value_added_tax":
        if (!values?.value_added_tax) {
          errors.value_added_tax = message.storeForm.valueAddedTax;
        }
        break;
      case "catering_advance":
        if (!values?.catering_advance) {
          errors.catering_advance = message.storeForm.cancellationDays;
        }
        break;
      case "credit_card_fee":
        if (!values?.credit_card_fee) {
          errors.credit_card_fee = message.storeForm.creditCardFee;
        }
        break;
      case "debit_card_fee":
        if (!values?.debit_card_fee) {
          errors.debit_card_fee = message.storeForm.debitCardFee;
        }
        break;
      case "delivery_type":
        if (!values?.delivery_type) {
          errors.delivery_type = message.storeForm.deliveryType;
        }
        break;
      case "email":
        if (!values?.email) {
          errors.email = message.storeForm.email;
        } else if (!isEmail(values.email?.toString() || "")) {
          errors.email = message.storeForm.emailInvalid;
        }
        break;
      case "phone":
        if (!values?.phone) {
          errors.phone = message.storeForm.phone;
        } else if (
          !isMobilePhone(values.phone?.toString() || "") ||
          !isByteLength(values.phone?.toString() || "", { min: 8, max: 8 })
        ) {
          errors.phone = message.storeForm.invalidPhone;
        }
        break;
      case "description_en":
        if (!values?.description_en) {
          errors.description_en = message.storeForm.descriptionEn;
        }
        break;
      case "description_ar":
        if (!values?.description_ar) {
          errors.description_ar = message.storeForm.descriptionAr;
        }
        break;
      case "max_catering_per_day":
        if (!values?.max_catering_per_day) {
          errors.max_catering_per_day = message.storeForm.maxCateringPerDay;
        }
        break;
      case "logofile":
        if (!values?.logofile) {
          errors.logofile = message.storeForm.logofile;
        }
        break;
      case "thumbfile":
        if (!values?.thumbfile) {
          errors.thumbfile = message.storeForm.thumbfile;
        }
        break;
      case "imagefile":
        if (!values?.imagefile) {
          errors.imagefile = message.storeForm.imagefile;
        }
        break;

      default:
        break;
    }
  });
  return errors;
};
