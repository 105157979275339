import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { TextField, Dialog, UploadPicture } from "../../commons";
import { serviceUnavailable, successfulOperation } from "../../utils/message";
import { RestaurantGlobalCategoriesService } from "../../services/openApi";

import { SnackbarContext } from "../../App";

import { CategoryFormDialogType } from "./CategoryFormDialog-types";

type CategoryFormDialogProps = {
  open: boolean;
  title: string;
  action?: "delete" | "add" | "update";
  onClose: () => void;
  idCategory?: number;
};

export const CategoryFormDialog = ({
  open,
  title,
  action,
  onClose,
  idCategory,
}: CategoryFormDialogProps): JSX.Element => {
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);
  const { saveCategory, getGlobalCategoriyById, updateCategoryById } =
    RestaurantGlobalCategoriesService;

  const [categoryForm, setCategoryForm] =
    React.useState<CategoryFormDialogType>();

  const getCategoryDetails = useQuery(
    "getGlobalCategoriyById",
    () => getGlobalCategoriyById(idCategory!),
    {
      onSuccess: (data) => {
        setCategoryForm({
          name_en: data?.name_en,
          name_ar: data?.name_ar,
          slug: data?.slug,
        });
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
      enabled: !!idCategory,
    }
  );

  const addCategory = useMutation(saveCategory, {
    onSuccess: async () => {
      await setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
      onClose();
      queryClient.fetchQuery("getGlobalCategories");
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const editCategory = useMutation(
    () => updateCategoryById(idCategory!, { ...categoryForm }),
    {
      onSuccess: async () => {
        await setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        onClose();
        queryClient.fetchQuery("getGlobalCategories");
        getCategoryDetails.remove();
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCategoryForm({
      ...categoryForm,
      [name]: value,
    });
  };

  const handlePicture = (img: Blob) => {
    setCategoryForm({
      ...categoryForm,
      imagefile: img,
    });
  };

  const handleSubmit = () => {
    if (idCategory) {
      editCategory.mutate();
    } else {
      addCategory.mutate({ ...categoryForm });
    }
  };

  return (
    <Dialog
      open={open}
      title={title}
      maxWidth="sm"
      handleContent={handleSubmit}
      onClose={async () => {
        await getCategoryDetails.remove();
        onClose();
      }}
      action={action}
      loadingUseQuery={getCategoryDetails.isLoading}
      loading={addCategory.isLoading || editCategory.isLoading}
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <Box display="flex" alignContent="center">
          {getCategoryDetails?.isLoading ? (
            <Skeleton
              variant="rectangular"
              height={100}
              width={100}
              sx={{ borderRadius: "20px" }}
            />
          ) : (
            <UploadPicture
              uriImage={getCategoryDetails?.data?.image?.uri}
              mode="basic"
              onLoad={handlePicture}
            />
          )}
          <Typography
            variant="subtitle2"
            style={{ margin: "auto 30px", color: "#dad8d8" }}
          >
            Square Image with Max 512px * 512px
          </Typography>
        </Box>
        {getCategoryDetails?.isLoading ? (
          <>
            <Skeleton
              variant="rectangular"
              height={46}
              sx={{ borderRadius: "30px" }}
            />
            <Skeleton
              variant="rectangular"
              height={46}
              sx={{ borderRadius: "30px" }}
            />
            <Skeleton
              variant="rectangular"
              height={46}
              sx={{ borderRadius: "30px" }}
            />
          </>
        ) : (
          <>
            <TextField
              name="slug"
              value={categoryForm?.slug || ""}
              onChange={handleChange}
              label="Registration Code"
              color="secondary"
            />
            <TextField
              name="name_en"
              value={categoryForm?.name_en || ""}
              onChange={handleChange}
              label="Name in english"
              color="secondary"
            />
            <TextField
              name="name_ar"
              value={categoryForm?.name_ar || ""}
              onChange={handleChange}
              label="Name in arabic"
              color="secondary"
            />
          </>
        )}
      </Box>
    </Dialog>
  );
};
