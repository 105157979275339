/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Admin } from '../models/Admin';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class AuthenticationService {

    /**
     * get admin info
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getAuthenticatedUserInfo(): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/admins/getAuthenticatedUserInfo`,
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * get admin info
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static changePassword(
        requestBody: {
            old_password?: string;
            new_password?: string;
            confirmation_password?: string;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/changePassword`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * get admin info
     * @returns any Successful operation
     * @throws ApiError
     */
    public static verifyToken(): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/admins/verifyToken`,
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Admin sign up
     * @param requestBody
     * @returns Admin Successful operation
     * @throws ApiError
     */
    public static signUp(
        requestBody: {
            name?: string;
            email?: string;
            mobile?: number;
            password?: string;
        },
    ): CancelablePromise<Admin> {
        return __request({
            method: 'POST',
            path: `/api/admins/signUp`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Admin verify sign Up
     * @param authorization Authorization token
     * @param requestBody
     * @returns Admin Successful operation
     * @throws ApiError
     */
    public static verifySignUp(
        authorization: string,
        requestBody: {
            email_otp?: string;
        },
    ): CancelablePromise<Admin> {
        return __request({
            method: 'POST',
            path: `/api/admins/verifySignUp`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * signIn
     * @param requestBody Pass admin credentials
     * @returns any success
     * @throws ApiError
     */
    public static signIn(
        requestBody: {
            email: string;
            password: string;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/signIn`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * admin resend OTP
     * @returns any Successful operation
     * @throws ApiError
     */
    public static resendOtp(): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/resendOTP`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * admin logout
     * @returns any Successful operation
     * @throws ApiError
     */
    public static logout(): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/logOut`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * send reset password email
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static sendResetPasswordMail(
        requestBody?: {
            email?: string;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/sendResetPasswordMail`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * verify reset token validity
     * @param id token
     * @returns any Successful operation
     * @throws ApiError
     */
    public static verifyResetToken(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/verifyResetToken/${id}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * verify reset token validity
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static resetPassword(
        requestBody?: {
            password?: string;
            confirmation_password?: string;
            token?: string;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/resetPassword`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

}