/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class RestaurantGlobalCategoriesService {

    /**
     * List of categories
     * @param requestBody search value
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getGlobalCategories(
        requestBody?: {
            search?: string;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/restaurant/category/index`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * List of categories
     * @param id ID of category
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getGlobalCategoriyById(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/admins/restaurant/category/${id}`,
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * save a category
     * @param formData
     * @returns any Successful operation
     * @throws ApiError
     */
    public static saveCategory(
        formData: {
            name_en?: string;
            name_ar?: string;
            slug?: string;
            imagefile?: Blob;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/restaurant/category/store`,
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * update a category
     * @param id ID of category
     * @param formData
     * @returns any Successful operation
     * @throws ApiError
     */
    public static updateCategoryById(
        id: number,
        formData: {
            name_en?: string;
            name_ar?: string;
            slug?: string;
            imagefile?: Blob;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/restaurant/category/update/${id}`,
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * update a category status
     * @param id ID of category
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static updateFilterStatus(
        id: number,
        requestBody: {
            status?: boolean;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/restaurant/category/updateStatus/${id}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * delete category
     * @param id ID of category
     * @returns any Successful operation
     * @throws ApiError
     */
    public static deleteCategory(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/api/admins/restaurant/category/delete/${id}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * sort category
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static sortItemGlobalCategories(
        requestBody?: {
            /**
             * globalCategories ids
             */
            globalCategories?: Array<any>;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/restaurant/category/sortItemGlobalCategories`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * sort filters
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static sortItemFilters(
        requestBody?: {
            /**
             * filters ids
             */
            filters?: Array<any>;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/restaurant/filter/sortItemFilters`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

}