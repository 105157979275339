/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Skeleton from "@mui/material/Skeleton";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ReactSortable } from "react-sortablejs";
import { useQuery, useMutation, useQueryClient } from "react-query";
import debounce from "lodash/debounce";

import { Button, Input, TreeItem, Dialog } from "../../commons";
import { SubCategoryFormDialog } from "..";
import { StoreSubcategoriesService } from "../../services/openApi";
import { serviceUnavailable, successfulOperation } from "../../utils/message";
import { SnackbarContext } from "../../App";

import { EditIcon, TrashIcon } from "../../img/icon";

import { Container } from "./categories-styles";

interface ItemType {
  id: number;
  name: string;
  status: number;
}

interface SortType {
  id: number;
  sorting_index: number;
}

type openFormState = {
  open: boolean;
  title: string;
  action?: "delete" | "add" | "update";
};

type CategoriesProps = {
  onSelect: (id: number) => void;
};

export const Categories = ({ onSelect }: CategoriesProps): JSX.Element => {
  const { idStore } = useParams<{ idStore?: string }>();
  const queryClient = useQueryClient();
  const {
    getSubcategories,
    deleteSubCategory,
    updateSubCategoryStatus,
    sortSubcategory,
  } = StoreSubcategoriesService;
  const { setSnack } = React.useContext(SnackbarContext);

  const [state, setState] = React.useState<ItemType[]>([]);
  const [listSort, setListSort] = React.useState<SortType[]>([]);

  const [openForm, setOpenForm] = React.useState<openFormState>({
    open: false,
    title: "",
    action: undefined,
  });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openDelete, setOpenDelete] = React.useState<openFormState>({
    open: false,
    title: "",
    action: undefined,
  });
  const [openStatus, setOpenStatus] = React.useState<openFormState>({
    open: false,
    title: "",
    action: undefined,
  });
  const [idSubCategory, setIdSubCategory] = React.useState<number | undefined>(
    undefined
  );
  const [status, setStatus] = React.useState<boolean | undefined>(undefined);
  const open = Boolean(anchorEl);

  const listSubcategories = useQuery(
    ["getSubcategories", idStore],
    () => getSubcategories(+idStore!),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
      enabled: !!idStore,
    }
  );

  React.useEffect(() => {
    if (listSubcategories?.data) {
      const data = listSubcategories?.data?.subcategories;
      const arraySubCategories: ItemType[] = [];
      data?.forEach((item: any) => {
        arraySubCategories.push({
          id: item.id,
          name: item?.name,
          status: item?.status,
        });
      });
      setState(arraySubCategories);
    }
  }, [listSubcategories?.data]);

  const removeSubCategory = useMutation(deleteSubCategory, {
    onSuccess: () => {
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
      queryClient.refetchQueries(["getSubcategories", idStore]);
      setOpenDelete({
        open: false,
        title: "",
        action: "delete",
      });
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const editSubCategoryStatus = useMutation(
    () => updateSubCategoryStatus(idSubCategory!, { status }),
    {
      onSuccess: () => {
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        queryClient.refetchQueries(["getSubcategories", idStore]);
        setOpenStatus({
          open: false,
          title: "",
          action: "update",
        });
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const sortSubCategories = useMutation(
    () => sortSubcategory({ subcategories: listSort }),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["getSubcategories", idStore]);
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditCategory = () => {
    setOpenForm({
      open: true,
      title: "Edit Categorie",
      action: "update",
    });
    handleClose();
  };

  const handleDeleteCategory = () => {
    setOpenDelete({
      open: true,
      title: "Delete",
      action: "delete",
    });
    handleClose();
  };

  const arrayListSubCategoriesloading = new Array(9).fill("");

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const arraySubCategories: ItemType[] = [];
    listSubcategories?.data?.subcategories?.forEach((item: any) => {
      arraySubCategories.push({
        id: item.id,
        name: item?.name,
        status: item?.status,
      });
    });
    const result = arraySubCategories.filter((o) =>
      o.name.toLowerCase().includes(event.target.value)
    );
    setState(result);
  };

  const handleOrder = () => {
    const arraySortable: SortType[] = [];
    state?.forEach((item, index) => {
      arraySortable.push({
        id: item.id,
        sorting_index: index + 1,
      });
    });
    setListSort(arraySortable);
  };

  const sendOrder = React.useCallback(
    debounce(() => sortSubCategories.mutate(), 2000),
    []
  );

  return (
    <Container>
      <div className="header">
        <Typography variant="h2" className="title">
          Categories
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={() =>
            setOpenForm({
              open: true,
              title: "Add Categorie",
              action: "add",
            })
          }
        >
          Add Categorie
        </Button>
      </div>
      <div className="list-categories scrolbar">
        <Input
          placeholder="Categories"
          onChange={handleSearch}
          startAdornment={
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          }
          className="input-search"
        />

        {listSubcategories?.isLoading ? (
          <>
            {arrayListSubCategoriesloading?.map((_, index) => (
              <Box
                key={index}
                display="flex"
                height={45}
                alignItems="center"
                gap={2}
                padding="0 22px"
              >
                <Skeleton variant="rectangular" height={20} width={15} />
                <Skeleton variant="text" width="100%" />
                <Skeleton
                  variant="rectangular"
                  height={15}
                  width={55}
                  sx={{ borderRadius: 3 }}
                />
                <Skeleton
                  component={MoreVertIcon}
                  variant="circular"
                  width={26}
                  height={26}
                  sx={{ background: " #fff", color: "text.secondary" }}
                />
              </Box>
            ))}
          </>
        ) : (
          <ReactSortable
            list={state}
            setList={setState}
            animation={200}
            delay={2}
            onSort={async () => {
              await handleOrder();
              sendOrder();
            }}
          >
            {state?.map((item) => (
              <TreeItem
                id={item.id!}
                key={item.id}
                name={item?.name}
                status={item?.status}
                onActive={(event) => {
                  setOpenStatus({
                    open: true,
                    title: "Update Status",
                    action: "update",
                  });
                  setStatus(event.target.checked);
                  setIdSubCategory(item?.id);
                }}
                onAction={(event) => {
                  handleClick(event);
                  setIdSubCategory(item?.id);
                }}
                onSelect={(id) => onSelect(id)}
              />
            ))}
          </ReactSortable>
        )}
      </div>
      {openForm.open && (
        <SubCategoryFormDialog
          id={idStore}
          idSubCategory={idSubCategory}
          open={openForm.open}
          title={openForm.title}
          action={openForm.action}
          onClose={() => {
            setOpenForm({
              open: false,
              action: undefined,
              title: "",
            });
            setIdSubCategory(undefined);
          }}
        />
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={handleEditCategory} className="item-edit">
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDeleteCategory}>
          <ListItemIcon>
            <TrashIcon />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
      <Dialog
        open={openDelete.open}
        title={openDelete.title}
        action="delete"
        handleContent={() =>
          idSubCategory && removeSubCategory.mutate(idSubCategory)
        }
        loading={removeSubCategory.isLoading}
        onClose={() =>
          setOpenDelete({
            open: false,
            title: "",
            action: "delete",
          })
        }
      >
        <Typography>Are you sure to delete this category?</Typography>
      </Dialog>
      <Dialog
        open={openStatus.open}
        title={openStatus.title}
        action="update"
        handleContent={() => editSubCategoryStatus.mutate()}
        loading={editSubCategoryStatus.isLoading}
        onClose={() =>
          setOpenStatus({
            open: false,
            title: "",
            action: "update",
          })
        }
      >
        <Typography>Are you sure to change status this category?</Typography>
      </Dialog>
    </Container>
  );
};
