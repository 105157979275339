import * as React from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { useReactToPrint } from "react-to-print";

import { Button } from "../../../../../commons";
import {
  OrderToPrintStore,
  OrderStatusStoreDialog,
} from "../../../../../components";

import { PrinterIcon } from "../../../../../img/icon";

import { Page, pageStyle } from "./OrdersDetailsStore-style";

type openFormState = {
  open: boolean;
  title: string;
  action?: "update";
};

export const OrdersDetailsStore = (): JSX.Element => {
  const { id } = useParams<{ id?: string }>();

  const componentRef = React.useRef<HTMLInputElement | null>(null);

  const [openForm, setOpenForm] = React.useState<openFormState>({
    open: false,
    title: "",
  });

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle,
    copyStyles: true,
    removeAfterPrint: true,
  });
  return (
    <Page>
      <div className="header-action">
        <Button
          variant="contained"
          color="secondary"
          onClick={() =>
            setOpenForm({
              open: true,
              title: "Change Status",
              action: "update",
            })
          }
        >
          Change Order Status
        </Button>

        <Button
          variant="outlined"
          color="secondary"
          className="btn-print"
          onClick={handlePrint}
        >
          <PrinterIcon />
          Print
        </Button>
      </div>

      <Box style={{ border: "1px solid #dddddd", borderRadius: 20 }}>
        <OrderToPrintStore ref={componentRef} />
      </Box>

      {openForm.open && (
        <OrderStatusStoreDialog
          idOrder={id ? +id : undefined}
          open={openForm.open}
          title={openForm.title}
          action={openForm.action}
          onClose={() =>
            setOpenForm({
              open: false,
              title: "",
            })
          }
        />
      )}
    </Page>
  );
};
