import styled from "styled-components";

export const CardStyled = styled.button<{ selected?: boolean }>`
  position: relative;
  flex: 1;
  min-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  opacity: 1;
  transition: transform 0.5s ease-out, opacity 0.3s linear,
    box-shadow 0.5s linear;
  overflow: unset;
  border: none;
  padding: 0;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
  }
  .details {
    position: absolute;
    display: flex;
    width: 100%;
    flex-direction: column;
    .title {
      color: #fff;
      font-weight: 700;
    }
    .count {
      color: #fff;
      font-weight: 700;
    }
    .status {
      color: #fff;
      font-weight: 600;
    }
  }
  transform: ${(props) => (props.selected ? "scale(1.06)" : "none")};
  box-shadow: ${(props) =>
    props.selected ? "rgb(38, 57, 77) 0px 10px 20px -10px" : "none"};
  &:hover {
    opacity: 0.8;
    transform: scale(1.06);
    box-shadow: rgb(38, 57, 77) 0px 10px 20px -10px;
  }
`;
