import React from "react";
import { RouteComponentProps, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockIcon from "@mui/icons-material/Lock";
import { useMutation } from "react-query";

import { Input, Button } from "../../commons";

import { SnackbarContext } from "../../App";
import { serviceUnavailable, successfulOperation } from "../../utils/message";

import { AuthenticationService } from "../../services/openApi";

import { meizLog } from "../../img";

import { ForgetPasswordForm } from "./ForgetPassword-style";
import { validation } from "./ForgetPassword-validation";

export type passwordState = {
  password?: string;
  confirmationPassword?: string;
};

export const ForgetPassword = (props: RouteComponentProps): JSX.Element => {
  const { sendResetPasswordMail, resetPassword } = AuthenticationService;
  const { token } = useParams<{ token?: string }>();

  const { setSnack } = React.useContext(SnackbarContext);
  const [email, setEmail] = React.useState<string>("");
  const [error, setError] = React.useState<string>("");

  const [passwordForm, setPasswordForm] = React.useState<passwordState>({
    password: "",
    confirmationPassword: "",
  });
  const [errorPassword, setErrorPassword] = React.useState<passwordState>();

  const sendMail = useMutation(sendResetPasswordMail, {
    onSuccess: () => {
      setSnack({
        message: "We have e-mailed your password reset link",
        severity: "success",
        open: true,
      });
    },
    onError: () => {
      setSnack({
        message: serviceUnavailable,
        severity: "error",
        open: true,
      });
    },
  });

  const changePassword = useMutation(resetPassword, {
    onSuccess: () => {
      setSnack({
        message: successfulOperation,
        severity: "success",
        open: true,
      });
    },
    onError: () => {
      setSnack({
        message: serviceUnavailable,
        severity: "error",
        open: true,
      });
    },
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setError("");
  };

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPasswordForm({
      ...passwordForm,
      [name]: value,
    });
    setErrorPassword({
      ...errorPassword,
      [name]: "",
    });
  };

  const handleRoutingSignIn = () => {
    props.history.push("/");
  };

  const handleSendMail = () => {
    if (!email) {
      setError("This field is required");
    } else {
      sendMail.mutate({ email });
    }
  };

  const handleReset = () => {
    const verify = validation(passwordForm);
    if (Object.entries(verify).length === 0) {
      changePassword.mutate({
        token,
        password: passwordForm.password,
        confirmation_password: passwordForm.confirmationPassword,
      });
    } else {
      setErrorPassword({
        ...verify,
      });
    }
  };

  return (
    <ForgetPasswordForm>
      <img src={meizLog} className="meiz-Log" alt="meiz-Log" />
      <Box
        display="flex"
        flexDirection="column"
        gap={3}
        marginTop={8}
        paddingLeft={8}
        paddingRight={8}
      >
        <Typography variant="h1" color="text" className="title-welcome-1">
          Reset your password
        </Typography>
        <Typography variant="h3" color="text" className="title-welcome-2">
          {!token
            ? "Please enter your email address to reset your password."
            : "Please enter your new password."}
        </Typography>
        {!token ? (
          <Input
            name="email"
            placeholder="Email Address"
            value={email}
            onChange={handleChange}
            startAdornment={
              <InputAdornment position="end">
                <MailOutlineIcon />
              </InputAdornment>
            }
            msgError={error}
          />
        ) : (
          <>
            <Input
              name="password"
              type="password"
              placeholder="Password"
              value={passwordForm.password || ""}
              startAdornment={
                <InputAdornment position="end">
                  <LockIcon />
                </InputAdornment>
              }
              onChange={handleChangePassword}
              msgError={errorPassword?.password}
            />
            <Input
              name="confirmationPassword"
              type="password"
              placeholder="Confirmation password"
              value={passwordForm.confirmationPassword || ""}
              startAdornment={
                <InputAdornment position="end">
                  <LockIcon />
                </InputAdornment>
              }
              onChange={handleChangePassword}
              msgError={errorPassword?.confirmationPassword}
            />
          </>
        )}
        {!token ? (
          <Button
            variant="contained"
            className="btn-reset"
            onClick={handleSendMail}
          >
            Send mail
          </Button>
        ) : (
          <Button
            variant="contained"
            className="btn-reset"
            onClick={handleReset}
          >
            Reset
          </Button>
        )}
        <Button
          variant="contained"
          color="secondary"
          onClick={handleRoutingSignIn}
          sx={{ height: 52 }}
        >
          Sign in
        </Button>
      </Box>
    </ForgetPasswordForm>
  );
};
