import * as React from "react";

import Table, { ColumnsProps } from "../../../../commons/Table";
import { Page } from "./Revenue-style";

type RevenueProps = {};

export const Revenue = (props: RevenueProps) => {
  const columns: ColumnsProps = [
    {
      header: "Restaurant Name",
      accessor: "name",
    },
    {
      header: "Month",
      accessor: "month",
    },
    {
      header: "Total Orders Amount",
      accessor: "totalOrderAmount",
    },
    {
      header: "Bank Charges 2.5%",
      accessor: "bankChargers",
    },
    {
      header: "Meiz Commission",
      accessor: "meizCommission",
    },
    {
      header: "Due to Restaurants",
      accessor: "dueToRestaurents",
    },
  ];
  const renderTableRows = () => [];
  return (
    <Page>
      <Table
        columns={columns}
        data={renderTableRows()}
        emptyMessage="No data available"
      />
    </Page>
  );
};
