import styled from "styled-components";

export const Page = styled.div`
  display: grid;
  grid-template-rows: 100px 50px 1fr;
  gap: 20px;
  padding: 20px;
  .page-action {
    display: grid;
    /* grid-template-columns: minmax(400px, 770px) 250px; */
    grid-template-columns: 1fr;
    justify-content: space-between;
    gap: 20px;
    .btn-add {
      height: 42px;
    }
  }
  .user-image {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    object-fit: contain;
  }
  .btn-block {
    height: 35px;
    min-width: 90px;
    background-color: #fcead4;
    color: #ec8000;
    svg {
      margin-left: 8px;
    }
    box-shadow: none;
  }
  .btn-unblock {
    height: 35px;
    min-width: 90px;
    background-color: #e9f9ff;
    color: #219cc0;
    svg {
      margin-left: 8px;
    }
    box-shadow: none;
  }
  .btn-edit {
    height: 35px;
    background-color: #ecf4df;
    color: #75bd00;
    svg {
      margin-left: 10px;
    }
    box-shadow: none;
  }
  .btn-delete {
    height: 35px;
    background-color: #fdf4f7;
    color: #b93e5c;
    svg {
      margin-left: 10px;
    }
    box-shadow: none;
  }
`;
