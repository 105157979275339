import styled from "styled-components";

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  .search {
    display: grid;
    grid-template-columns: 2fr 1fr 150px;
    gap: 20px;
    .MuiOutlinedInput-input {
      padding: 4px;
    }
    .btn-search {
      box-shadow: none;
      svg {
        margin-right: 5px;
      }
    }
  }
`;
