import styled from "styled-components";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { Select as SelectMui, SelectProps } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";

type InputProps = SelectProps & {
  label?: string;
  msgError?: string;
  placeholder?: string;
  loading?: boolean;
};

export const Select = ({
  label,
  msgError,
  children,
  multiple,
  placeholder,
  loading,
  ...restProps
}: InputProps): JSX.Element => {
  return (
    <>
      {loading ? (
        <Skeleton
          variant="rectangular"
          width="100%"
          height={46}
          sx={{ borderRadius: "30px" }}
        />
      ) : (
        <FormControlStyled
          fullWidth
          error={msgError !== "" && msgError !== undefined}
          label={label ? 1 : 0}
        >
          <InputLabel htmlFor="component-filled">{label}</InputLabel>
          <SelectMui
            label={label}
            inputProps={{ className: "MuiSelect" }}
            {...restProps}
            multiple={multiple}
          >
            {placeholder && (
              <MenuItem disabled>
                <Typography sx={{ color: "#bcbcbc" }}>
                  {placeholder}{" "}
                </Typography>
              </MenuItem>
            )}
            {children}
          </SelectMui>
          {msgError && <FormHelperText> {msgError} </FormHelperText>}
        </FormControlStyled>
      )}
    </>
  );
};

const FormControlStyled = styled(FormControl)<{ label?: number }>`
  border: 0;
  .MuiSelect {
    background-color: #ffffff;
    padding: 13px;
    border-radius: 30px;
  }
  .MuiInputLabel-root {
    top: -4px;
    color: ${(props) => (props.error ? "#f67070" : "#c4c2c2")};
  }
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${(props) => (props.error ? "#f67070" : "#dddddd")};
    border-radius: 30px;
  }
`;
