import * as React from "react";
import Box from "@mui/material/Box";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import LinearProgress from "@mui/material/LinearProgress";
import { useQuery } from "react-query";
import moment from "moment";

import { TextField } from "../../../../../commons";

import { SnackbarContext } from "../../../../../App";
import { serviceUnavailable } from "../../../../../utils/message";

import { RoleStoreUserStatementOfAccountService } from "../../../../../services/openApi";

import { Page } from "./StatementOfAccounts-style";

type StatementOfAccountsStoreProps = {};

interface Column {
  id:
    | "date"
    | "type"
    | "orderStatus"
    | "description"
    | "reference"
    | "totalOrder"
    | "pgwAmountRecived"
    | "pgwRate"
    | "meizCommission"
    | "pgwCharges"
    | "balance";
  label: string;
  minWidth?: number;
  align?: "center";
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: "date", label: "Date", minWidth: 170, align: "center" },
  { id: "type", label: "Type", minWidth: 170, align: "center" },
  {
    id: "orderStatus",
    label: "Order Status",
    minWidth: 150,
    align: "center",
  },
  {
    id: "description",
    label: "Description",
    minWidth: 150,
    align: "center",
  },
  {
    id: "reference",
    label: "Reference #",
    minWidth: 150,
    align: "center",
  },
  {
    id: "totalOrder",
    label: "Total Order",
    minWidth: 150,
    align: "center",
  },
  {
    id: "pgwAmountRecived",
    label: "PGW Amount Recived",
    minWidth: 170,
    align: "center",
  },
  {
    id: "pgwRate",
    label: "PGW Rate",
    minWidth: 150,
    align: "center",
  },
  {
    id: "meizCommission",
    label: "Meiz Commission",
    minWidth: 150,
    align: "center",
  },

  {
    id: "pgwCharges",
    label: "PGW Charges",
    minWidth: 150,
    align: "center",
  },
  {
    id: "balance",
    label: "Balance",
    minWidth: 150,
    align: "center",
  },
];

interface Data {
  date: string;
  type: string;
  orderStatus: string;
  description: string;
  reference: string;
  totalOrder: string;
  pgwAmountRecived: string;
  pgwRate: string;
  meizCommission: string;
  pgwCharges: string;
  balance: string;
}

function createData(
  date: string,
  type: string,
  orderStatus: string,
  description: any,
  reference: any,
  totalOrder: any,
  pgwAmountRecived: any,
  pgwRate: any,
  meizCommission: any,
  pgwCharges: any,
  balance: any
): Data {
  return {
    date,
    type,
    orderStatus,
    description,
    reference,
    totalOrder,
    pgwAmountRecived,
    pgwRate,
    meizCommission,
    pgwCharges,
    balance,
  };
}

export const StatementOfAccountsStore = (
  props: StatementOfAccountsStoreProps
) => {
  const { setSnack } = React.useContext(SnackbarContext);

  const { getStatementOfAccount } = RoleStoreUserStatementOfAccountService;

  const [dateFrom, setDateFrom] = React.useState<Date | null>(new Date());
  const [dateTo, setDateTo] = React.useState<Date | null>(new Date());

  const getListStatementOfAccount = useQuery(
    [
      "getStatementOfAccount",
      moment(dateFrom).format("YYYY-MM-DD"),
      moment(dateTo).format("YYYY-MM-DD"),
    ],
    () =>
      getStatementOfAccount({
        start_date: moment(dateFrom).format("YYYY-MM-DD"),
        end_date: moment(dateTo).format("YYYY-MM-DD"),
      }),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const rows = getListStatementOfAccount?.data?.statements
    ?.map((item: any) =>
      createData(
        item?.updated_at,
        item?.type,
        item?.order_current_status?.order_status_translation?.display_status ||
          "_",
        item?.payment_method || item?.description,
        item?.id,
        `QAR ${item?.order_total_price || "_"}`,
        `QAR ${item?.payed_amount || "_"}`,
        `${item?.store?.credit_card_fee || 0} %`,
        `QAR ${item?.meiz_commission_value || "_"}`,
        `QAR ${item?.pgw_charges_value || "_"}`,
        `QAR ${item?.balance || "_"}`
      )
    )
    .concat({
      date: "",
      type: "",
      orderStatus: "",
      description: "",
      reference: "Total",
      totalOrder: `QAR  ${
        getListStatementOfAccount?.data?.totalOrders?.toFixed(2) || "_"
      }`,
      pgwAmountRecived: ` QAR ${
        getListStatementOfAccount?.data?.totalOrderAmountReceived?.toFixed(2) ||
        "_"
      } `,
      pgwRate: ` QAR ${
        getListStatementOfAccount?.data?.totalOrderMeizComissions?.toFixed(2) ||
        "_"
      }`,
      meizCommission: ` QAR ${
        getListStatementOfAccount?.data?.totalOrderMeizComissions?.toFixed(2) ||
        "_"
      }`,
      pgwCharges: ` QAR  ${
        getListStatementOfAccount?.data?.totalOrderPGWCharges?.toFixed(2) || "_"
      }`,
      balance: ` QAR ${
        getListStatementOfAccount?.data?.totalOrderBalance?.toFixed(2) || "_"
      }`,
    });

  return (
    <Page className="scrolbar">
      <div className="header-action">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="From"
            value={dateFrom}
            inputFormat="dd/MM/yyyy"
            onChange={(newValue) => {
              setDateFrom(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} color="secondary" fullWidth />
            )}
            maxDate={dateTo}
          />
          <DatePicker
            label="To"
            value={dateTo}
            inputFormat="dd/MM/yyyy"
            onChange={(newValue) => {
              setDateTo(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} color="secondary" fullWidth />
            )}
            minDate={dateFrom}
          />
        </LocalizationProvider>
      </div>
      <div className="container-table">
        <Box
          display="grid"
          gap={1}
          p={3}
          bgcolor="#fff"
          sx={{
            gridTemplateColumns: "2fr 1fr",
            alignItems: "center",
          }}
        >
          <Typography variant="h3" align="left" sx={{ fontWeight: 600 }}>
            Statement of accounts
          </Typography>

          <Typography variant="body1" align="right">
            {dateFrom && (
              <>
                From : {dateFrom?.getDate()}/
                {dateFrom && dateFrom?.getMonth() + 1}/{dateFrom?.getFullYear()}
              </>
            )}{" "}
            {dateTo && (
              <>
                to : {dateTo?.getDate()}/{dateTo && dateTo?.getMonth() + 1}/
                {dateTo?.getFullYear()}
              </>
            )}
          </Typography>
        </Box>
        <TableContainer
          sx={{ maxHeight: "calc(100vh - 288px)", minHeight: 127 }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {rows?.map((row: any, index: number) => {
                return (
                  <TableRow hover role="checkbox" key={index} tabIndex={-1}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          className={
                            rows?.length === index + 1 &&
                            column.id === "reference"
                              ? "total"
                              : ""
                          }
                        >
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>

          {!rows && (
            <Box position="absolute" width="calc(100vw - 322px)" bgcolor="#fff">
              {getListStatementOfAccount?.isLoading && (
                <LinearProgress color="secondary" />
              )}
              <Typography
                align="center"
                sx={{ position: "relative", width: "100%", p: 2 }}
              >
                No data availble
              </Typography>
            </Box>
          )}
        </TableContainer>
      </div>
    </Page>
  );
};
