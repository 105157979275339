import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Skeleton from "@mui/material/Skeleton";
import { useQuery, useQueryClient, useMutation } from "react-query";
import moment from "moment";

import { Dialog, Select } from "../../commons";

import { SnackbarContext } from "../../App";
import { serviceUnavailable, successfulOperation } from "../../utils/message";
import {
  DashboardOrdersService,
  OrderCancellingReasonsService,
} from "../../services/openApi";

type OrderStatusDialogProps = {
  idOrder?: number;
  open: boolean;
  title: string;
  action?: "update";
  onClose: () => void;
  page?: number;
  rowsPerPage?: number;
  filterStatus?: any;
};

export const OrderStatusDialog = ({
  idOrder,
  open,
  title,
  action,
  onClose,
  page,
  rowsPerPage,
  filterStatus,
}: OrderStatusDialogProps): JSX.Element => {
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);
  const { getOrderStatusList, getOrderDetails, updateOrderStatus } =
    DashboardOrdersService;
  const { getDashboardCancelReasonsList } = OrderCancellingReasonsService;

  const [idStatus, setIdStatus] = React.useState<number | undefined>();
  const [idReason, setIdReason] = React.useState<number | undefined>();

  const orderDetails = useQuery(
    ["getOrderDetails", idOrder],
    () => getOrderDetails(idOrder!),
    {
      onSuccess: (data) => {
        setIdStatus(data?.order_current_status?.id);
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
      enabled: !!idOrder,
    }
  );

  const statusList = useQuery(
    "getOrderStatusList",
    () => getOrderStatusList(),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const cancelReasonsList = useQuery(
    "getDashboardCancelReasonsList",
    () => getDashboardCancelReasonsList(),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const updateStatus = useMutation(
    () =>
      updateOrderStatus({
        status_id: idStatus,
        order_id: idOrder ? idOrder?.toString() : undefined,
        cancel_reason_id: idReason ? idReason?.toString() : undefined,
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries([
          "getOrdersList",
          page,
          rowsPerPage,
          filterStatus,
        ]);
        queryClient.refetchQueries(["getOrderDetails", idOrder]);
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        onClose();
      },
      onError: async (error: any) => {
        const messageError = await error?.body?.messsage;
        setSnack({
          open: true,
          severity: "error",
          message: messageError,
        });
      },
    }
  );

  return (
    <Dialog
      open={open}
      title={title}
      maxWidth="sm"
      handleContent={updateStatus.mutate}
      loadingUseQuery={orderDetails?.isLoading}
      loading={updateStatus.isLoading}
      onClose={onClose}
      action={action}
    >
      <Box display="flex" width={350} flexDirection="column">
        <Grid
          container
          spacing={1}
          style={{ marginBottom: 24, paddingLeft: 16 }}
        >
          <Grid item xs={4}>
            <Typography
              variant="subtitle2"
              style={{
                fontWeight: 500,
              }}
            >
              Order ID
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 400,
              }}
            >
              {orderDetails?.isLoading ? <Skeleton variant="text" /> : idOrder}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography
              variant="subtitle2"
              style={{
                fontWeight: 500,
              }}
            >
              Store Name
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 400,
              }}
            >
              {orderDetails?.isLoading ? (
                <Skeleton variant="text" />
              ) : (
                orderDetails?.data?.store?.store_translation?.name
              )}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography
              variant="subtitle2"
              style={{
                fontWeight: 500,
              }}
            >
              Created
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 400,
              }}
            >
              {orderDetails?.isLoading ? (
                <Skeleton variant="text" />
              ) : (
                orderDetails?.data?.iso_date &&
                moment(orderDetails?.data?.iso_date).format(
                  "DD MMM YYYY hh:mm a"
                )
              )}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography
              variant="subtitle2"
              style={{
                fontWeight: 500,
              }}
            >
              Delivery date
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 400,
              }}
            >
              {orderDetails?.isLoading ? (
                <Skeleton variant="text" />
              ) : (
                orderDetails?.data?.picked_time_iso_date &&
                moment(orderDetails?.data?.picked_time_iso_date).format(
                  "DD MMM YYYY hh:mm"
                )
              )}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="subtitle2"
              style={{
                fontWeight: 500,
              }}
            >
              Order Total
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 400,
              }}
            >
              {orderDetails?.isLoading ? (
                <Skeleton variant="text" />
              ) : (
                `QAR ${
                  orderDetails?.data?.order_total_price?.toLocaleString(
                    "en-US"
                  ) || 0
                }`
              )}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography
              variant="subtitle2"
              style={{
                fontWeight: 500,
              }}
            >
              Payment Status
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 400,
              }}
            >
              {orderDetails?.isLoading ? (
                <Skeleton variant="text" />
              ) : (
                orderDetails?.data?.order_current_payment_status
                  ?.order_payment_status_translation?.display_status
              )}
            </Typography>
          </Grid>
        </Grid>
        <Select
          value={idStatus || ""}
          onChange={(e) => {
            setIdStatus(e.target.value as number);
            setIdReason(undefined);
          }}
          label="Order Stauts"
          color="secondary"
          style={{ marginBottom: idStatus === 7 ? 24 : 0 }}
          loading={orderDetails?.isLoading}
        >
          {statusList?.data?.map((item: any) => (
            <MenuItem value={item?.id} key={item?.id}>
              {item?.display_status}
            </MenuItem>
          ))}
        </Select>

        {idStatus === 7 && (
          <Select
            value={idReason || ""}
            onChange={(e) => setIdReason(e.target.value as number)}
            label="Cancel Reason"
            color="secondary"
          >
            {cancelReasonsList?.data?.map((item: any) => (
              <MenuItem value={item?.id} key={item?.id}>
                {item?.reason}
              </MenuItem>
            ))}
          </Select>
        )}
      </Box>
    </Dialog>
  );
};
