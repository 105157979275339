import styled from "styled-components";

export const Page = styled.div`
  padding: 20px;
  .btn-save {
    svg {
      margin-left: 5px;
    }
  }
`;
