import * as React from "react";
import ListItem from "@mui/material/ListItem";
import { NavLink, NavLinkProps } from "react-router-dom";

export type AppMenuItemComponentProps = {
  className?: string;
  link?: string | null; // because the InferProps props allows alows null value
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  children: React.ReactNode;
};

export const AppMenuItemComponent = ({
  className,
  link,
  onClick,
  children,
}: AppMenuItemComponentProps): JSX.Element => {
  // If link is not set return the orinary ListItem
  if (!link || typeof link !== "string") {
    return (
      <ListItem
        button
        className={className}
        children={children}
        onClick={onClick}
      />
    );
  }

  // Return a LitItem with a link component
  return (
    <ListItem
      button
      className={className}
      children={children}
      component={React.forwardRef((props: NavLinkProps, ref: any) => (
        <NavLink
          exact
          {...props}
          isActive={(_, location) => {
            return location.pathname.includes(link);
          }}
          innerRef={ref}
        />
      ))}
      to={link}
    />
  );
};
