import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import SearchIcon from "@mui/icons-material/Search";
import LinearProgress from "@mui/material/LinearProgress";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { ReactSortable } from "react-sortablejs";
import debounce from "lodash/debounce";

import { TextField, Button, Input, Dialog } from "../../../commons";
import { TableRowRequirement } from "../../../components";
import { SnackbarContext } from "../../../App";
import {
  serviceUnavailable,
  successfulOperation,
} from "../../../utils/message";

import { RestaurantRequirementsService } from "../../../services/openApi";

import { Page } from "./requirements-style";

type openFormState = {
  open: boolean;
  title: string;
  action?: "delete" | "add" | "update";
};

type requirementFormState = {
  id?: number;
  name_en?: string;
  name_ar?: string;
  status?: boolean;
};

interface SortType {
  id: number;
  sorting_index: number;
}

interface ItemType {
  id: number;
  nameRequirementEn: string;
  nameRequirementAr: string;
}
export const Requirements = () => {
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);
  const {
    getRequirements,
    deleteRequirement,
    saveRequirement,
    sortItemFilters,
    updateRequirement,
  } = RestaurantRequirementsService;

  const [requirementForm, setRequirementForm] =
    React.useState<requirementFormState>();
  const [search, setSearch] = React.useState("");
  const [state, setState] = React.useState<ItemType[]>([]);
  const [listSort, setListSort] = React.useState<SortType[]>([]);
  const [openDelete, setOpenDelete] = React.useState<openFormState>({
    open: false,
    title: "",
    action: undefined,
  });
  const [idRequirement, setIdRequirement] = React.useState<number | undefined>(
    undefined
  );

  const listRequirements = useQuery(
    "getRequirements",
    () => getRequirements({ search }),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  React.useEffect(() => {
    if (listRequirements.data) {
      const data = listRequirements.data;
      const arrayList: ItemType[] = [];
      data?.forEach((item: any) =>
        arrayList.push({
          id: item.id,
          nameRequirementEn: item?.requiremenets_translations?.[0]?.name || "",
          nameRequirementAr: item?.requiremenets_translations?.[1]?.name || "",
        })
      );
      setState(arrayList);
    }
  }, [listRequirements.data]);

  const addRequirment = useMutation(saveRequirement, {
    onSuccess: () => {
      queryClient.fetchQuery("getRequirements");
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
      setRequirementForm({});
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const editRequirment = useMutation(
    () =>
      updateRequirement(requirementForm?.id!, {
        ...requirementForm,
      }),
    {
      onSuccess: () => {
        queryClient.fetchQuery("getRequirements");
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        handleClickAway();
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const removeRequirement = useMutation(deleteRequirement, {
    onSuccess: () => {
      queryClient.fetchQuery("getRequirements");
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
      setOpenDelete({
        open: false,
        title: "",
        action: "delete",
      });
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const sortRequirements = useMutation(
    () => sortItemFilters({ requirements: listSort }),
    {
      onSuccess: () => {
        queryClient.refetchQueries("getRequirements");
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleChangeForm = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setRequirementForm({
      ...requirementForm,
      [name]: value,
    });
  };

  const handleClickAway = () => {
    // setRequirementForm({});
  };

  const handleOrder = () => {
    const arraySortable: SortType[] = [];
    state?.forEach((item, index) => {
      arraySortable.push({
        id: item.id,
        sorting_index: index + 1,
      });
    });
    setListSort(arraySortable);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sendOrder = React.useCallback(
    debounce(() => sortRequirements.mutate(), 2000),
    []
  );

  const handleSubmit = () => {
    if (requirementForm?.id) {
      editRequirment.mutate();
    } else {
      addRequirment.mutate({
        ...requirementForm,
        status: true,
      });
    }
  };

  return (
    <Page className="scrolbar">
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <TextField
            name="name_en"
            label="Requirement(En)"
            value={requirementForm?.name_en || ""}
            onChange={handleChangeForm}
            color="secondary"
            fullWidth
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            name="name_ar"
            label="Requirement(Ar)"
            value={requirementForm?.name_ar || ""}
            onChange={handleChangeForm}
            color="secondary"
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            color="secondary"
            className="btn-add"
            onClick={handleSubmit}
            disabled={
              addRequirment.isLoading ||
              !requirementForm?.name_en ||
              !requirementForm?.name_ar
            }
          >
            {requirementForm?.id ? "Save change" : "Add Requirement"}
          </Button>
        </Grid>
      </Grid>
      <Input
        value={search}
        onChange={handleChange}
        onKeyPress={(e) => e.key === "Enter" && listRequirements.refetch()}
        placeholder="Search"
        startAdornment={
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        }
      />
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box bgcolor="#fff" width="100%" borderRadius={2}>
          <TableRowRequirement index={-1} key={0} data={state[0]} />
          {listRequirements?.isLoading && <LinearProgress color="secondary" />}
          <ReactSortable
            list={state}
            setList={setState}
            animation={200}
            onSort={async () => {
              await handleOrder();
              sendOrder();
            }}
          >
            {state?.map((item) => (
              <TableRowRequirement
                index={item?.id}
                key={item?.id}
                data={item}
                selected={item?.id === requirementForm?.id}
                onDelete={(id) => {
                  setIdRequirement(id);
                  setOpenDelete({
                    open: true,
                    title: "Delete",
                    action: "delete",
                  });
                }}
                onEdit={(data) => {
                  setRequirementForm({
                    id: data?.id,
                    name_en: data?.nameRequirementEn,
                    name_ar: data?.nameRequirementAr,
                    status: data?.chosen,
                  });
                }}
              />
            ))}
          </ReactSortable>
        </Box>
      </ClickAwayListener>
      <Dialog
        open={openDelete.open}
        title={openDelete.title}
        action="delete"
        handleContent={() => {
          idRequirement && removeRequirement.mutate(idRequirement);
        }}
        loading={removeRequirement.isLoading}
        onClose={() =>
          setOpenDelete({
            open: false,
            title: "",
            action: "delete",
          })
        }
      >
        <Typography>Are you sure to delete this requirement?</Typography>
      </Dialog>
    </Page>
  );
};
