import styled from "styled-components";

export const Page = styled.div`
  display: grid;
  grid-template-rows: 70px 1fr;
  padding: 20px;
  .page-action {
    display: grid;
    height: 45px;
    grid-template-columns: minmax(400px, 1fr);
    justify-content: space-between;
    gap: 20px;
    .btn-edit-restaurant {
      background-color: #fff;
      svg {
        margin-right: 10px;
      }
    }
    .btn-add {
      box-shadow: none;
    }
  }
  .lazy-load-image-background.blur.lazy-load-image-loaded > img {
    object-fit: cover;
    border-radius: 5px;
  }
  .btn-view {
    height: 35px;
    background-color: #fcead4;
    color: #ec8000;
    svg {
      margin-left: 10px;
    }
    box-shadow: none;
  }
  .btn-edit {
    height: 35px;
    background-color: #ecf4df;
    color: #75bd00;
    svg {
      margin-left: 10px;
    }
    box-shadow: none;
  }
  .btn-delete {
    height: 35px;
    background-color: #fdf4f7;
    color: #b93e5c;
    svg {
      margin-left: 10px;
    }
    box-shadow: none;
  }
`;
