import * as React from "react";
import { Autocomplete, Box, Grid, Typography } from "@mui/material";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import { useMutation } from "react-query";

import { Button, InputTags, LoadingButton, TextField } from "../../../commons";
import { DashboardNotificationsService } from "../../../services/openApi";
import { Page } from "./Emails-style";
import { SnackbarContext } from "../../../App";
import {
  serviceUnavailable,
  successfulOperation,
} from "../../../utils/message";

export type emailState = {
  subject?: string;
  body?: string;
  send_to?: Array<string>;
  custom_emails?: Array<string>;
};

const initialState: emailState = {
  subject: "",
  body: "",
  send_to: [],
  custom_emails: [],
};

const sendToOptions = [
  { label: "Customers", value: "users" },
  { label: "Stores", value: "store_users" },
];
export const Emails = () => {
  const { setSnack } = React.useContext(SnackbarContext);
  const [emailToSend, setEmailToSend] =
    React.useState<emailState>(initialState);
  const [emailError, setEmailError] = React.useState<emailState>(initialState);
  const [mailToSend, setMailToSend] = React.useState<EditorState>(() =>
    EditorState.createEmpty()
  );

  const { sendEmailNotifications } = DashboardNotificationsService;

  const handleMailToSend = (value: EditorState) => {
    if (value) setMailToSend(value);
  };
  const handleMailToSendConvertToString = () => {
    const content = draftToHtml(convertToRaw(mailToSend.getCurrentContent()));
    setEmailToSend({
      ...emailToSend,
      body: content,
    });
    setEmailError({
      ...emailError,
      body: "",
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEmailToSend({
      ...emailToSend,
      [name]: value,
    });
    setEmailError({
      ...emailError,
      [name]: "",
    });
  };

  function handleChangeAutoCompleteMultiple<T>(args: T[]) {
    const arrayUsers: string[] = [];
    args?.forEach((item: any) => {
      item.value && arrayUsers.push(item.value);
    });
    setEmailToSend({
      ...emailToSend,
      send_to: arrayUsers,
    });
  }
  const handleDelete = (value: any) => {
    const newtags = emailToSend?.custom_emails?.filter((val) => val !== value);
    setEmailToSend({ ...emailToSend, custom_emails: newtags });
  };

  const handleTagsChange = (data: string) => {
    const arrayTags: string[] | undefined = emailToSend.custom_emails;
    arrayTags?.push(data);
    setEmailToSend({ ...emailToSend, custom_emails: arrayTags });
  };

  const sendEmail = useMutation(sendEmailNotifications, {
    onSuccess: () => {
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });
  const handleSubmit = () => {
    sendEmail.mutate(emailToSend);
  };
  return (
    <Page className="scrolbar">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Autocomplete
            options={sendToOptions}
            getOptionLabel={(option: any) => option.label || ""}
            multiple
            filterSelectedOptions
            onChange={(_, value) => handleChangeAutoCompleteMultiple(value)}
            renderInput={(params) => (
              <TextField {...params} label="Send To" color="secondary" />
            )}
          />
        </Grid>
        <Grid item xs={12} className="input-tags">
          <InputTags
            label="Custom Emails"
            style={{ width: "100%" }}
            list={emailToSend.custom_emails}
            onTagsChanged={(data) => handleTagsChange(data)}
            onDelete={(data) => handleDelete(data)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="subject"
            label="Subject"
            value={emailToSend.subject}
            onChange={handleChange}
            color="secondary"
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography className="message-title">English</Typography>
        </Grid>
        <Grid item xs={12}>
          <Editor
            editorState={mailToSend}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            onEditorStateChange={handleMailToSend}
            placeholder="Tape your Email Text Here ... "
            onBlur={handleMailToSendConvertToString}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" height={45} justifyContent="flex-end" gap={2}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                setEmailToSend(initialState);
                setMailToSend(EditorState.createEmpty());
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
              loading={sendEmail.isLoading}
              style={{ width: "8em" }}
              disabled={
                emailToSend?.send_to?.length === 0 || !emailToSend?.subject
              }
            >
              Send
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </Page>
  );
};
