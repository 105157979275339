import * as React from "react";
import { RouteComponentProps } from "react-router-dom";

import { Tab, Tabs } from "../../../commons";
import { FormStore } from "../FormStore";
import { ManagementUser } from "../ManagementUser";
import { ManagementWorkingHours } from "../ManagementWorkingHours";
import { ManagementDeliveryRegions } from "../ManagementDeliveryRegions";
import { StorePolicy } from "../StorePolicy";

import { Page, BoxPanel } from "./ManagementStore-style";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <BoxPanel>{children}</BoxPanel>}
    </div>
  );
}

export const ManagementStore = (props: RouteComponentProps) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Page className="scrolbar">
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        TabIndicatorProps={{
          style: {
            display: "none",
            height: "40px",
          },
        }}
        style={{ margin: "0 20px" }}
      >
        <Tab
          label="Edit store"
          index={0}
          selected={value === 0}
          {...a11yProps(0)}
        />
        <Tab
          label="Manager Info"
          index={1}
          selected={value === 1}
          {...a11yProps(1)}
        />
        <Tab
          label="working hours"
          index={2}
          selected={value === 2}
          {...a11yProps(2)}
        />
        <Tab
          label="Delivery regions"
          index={3}
          selected={value === 3}
          {...a11yProps(3)}
        />
        <Tab
          label="Store Policy"
          index={4}
          selected={value === 4}
          {...a11yProps(4)}
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <FormStore {...props} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ManagementUser />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ManagementWorkingHours />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ManagementDeliveryRegions />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <StorePolicy />
      </TabPanel>
    </Page>
  );
};
