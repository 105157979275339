import * as React from "react";

import { AppMenuItem } from "../../commons";

import { ListStyled } from "./AppMenu-styles";

import {
  appMenuItems,
  appMenuItemsBusiness,
  appMenuItemsMarketing,
  appMenuItemsRestaurant,
  appMenuItemsStore,
  appMenuType,
} from "../../utils";

type AppMenuProps = {
  reduce: boolean;
};

export const AppMenu = ({ reduce }: AppMenuProps): JSX.Element => {
  const kind = localStorage.getItem("kind") || "";
  const nameRole = localStorage.getItem("nameRole") || "";

  const getTypeAdmin = (type?: string): appMenuType[] => {
    switch (type) {
      case "Marketing":
        return appMenuItemsMarketing;
      case "Business Support":
        return appMenuItemsBusiness;
      default:
        return appMenuItems;
    }
  };

  return (
    <ListStyled disablePadding reduce={reduce.toString()} className="scrolbar">
      {kind === "admin" &&
        getTypeAdmin(nameRole)?.map((item, index) => (
          <AppMenuItem key={index} {...item} reduce={reduce} />
        ))}
      {kind === "restaurant" &&
        appMenuItemsRestaurant.map((item, index) => (
          <AppMenuItem {...item} key={index} reduce={reduce} />
        ))}
      {kind === "store_user" &&
        appMenuItemsStore.map((item, index) => (
          <AppMenuItem {...item} key={index} reduce={reduce} />
        ))}
    </ListStyled>
  );
};
