import isEmpty from "validator/lib/isEmpty";
import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";
import isByteLength from "validator/lib/isByteLength";
import { UserFormType } from "./userFormDialog-type";
import { message } from "../../utils/message";

export type Error = {
  name?: string;
  email?: string;
  mobile?: string;
  imagefile?: string;
  password?: string;
  role?: string;
};

export const validation = (values: UserFormType, edit?: boolean): Error => {
  const errors: Error = {};
  Object.keys(values).forEach((key: string) => {
    switch (key) {
      case "name":
        if (isEmpty(values?.name || "")) {
          errors.name = message.userForm.name;
        }
        break;
      case "email":
        if (isEmpty(values.email?.toString() || "")) {
          errors.email = message.userForm.email;
        } else if (!isEmail(values.email?.toString() || "")) {
          errors.email = message.userForm.emailInvalid;
        }
        break;
      case "mobile":
        if (isEmpty(values.mobile?.toString() || "")) {
          errors.mobile = message.userForm.mobile;
        } else if (
          !isMobilePhone(values.mobile?.toString() || "") ||
          !isByteLength(values.mobile?.toString() || "", { min: 8, max: 8 })
        ) {
          errors.mobile = message.userForm.mobileInvalid;
        }
        break;
      case "imagefile":
        if (!values?.imagefile) {
          errors.imagefile = message.userForm.imagefile;
        }
        break;
      case "password":
        if (!edit)
          if (!values?.password) {
            errors.password = message.userForm.password;
          }
        break;
      case "role":
        if (!values?.role) {
          errors.role = message.userForm.role;
        }
        break;
      default:
        break;
    }
  });
  return errors;
};
