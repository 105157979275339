import React from "react";
import { Box, Typography } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import { EditIcon, TrashIcon } from "../../../img/icon";

type QuestionItemType = {
  id: number;
  question: string;
  response: string;
  questionAr: string;
  responseAr: string;
  loading?: boolean;
  onAction: (id: number, action: string) => void;
};
export function QuestionItem(props: QuestionItemType) {
  const { id, question, response, questionAr, responseAr, loading, onAction } =
    props;

  const [view, setView] = React.useState("");

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    nextView: string
  ) => {
    setView("");
    onAction(id, nextView);
  };

  return (
    <Box
      display="grid"
      gridTemplateColumns="1fr 1fr 40px"
      alignItems="center"
      height={150}
      gap={2}
      mt={2}
    >
      {loading ? (
        <>
          <Skeleton
            variant="rectangular"
            height="100%"
            sx={{
              borderRadius: "20px",
            }}
          />
          <Skeleton
            variant="rectangular"
            height="100%"
            sx={{
              borderRadius: "20px",
            }}
          />
          <Skeleton
            variant="rectangular"
            height={80}
            width={42}
            sx={{
              borderRadius: "4px",
            }}
          />
        </>
      ) : (
        <>
          <Box
            height="100%"
            sx={{
              border: "1px solid #dddddd",
              borderRadius: "20px",
              padding: "10px 20px ",
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                variant="subtitle1"
                color="secondary"
                style={{
                  fontWeight: 600,
                  marginBottom: "10px",
                }}
              >
                {question}
              </Typography>
            </Box>
            <Typography
              variant="subtitle1"
              className="scrolbar"
              sx={{ height: 90 }}
            >
              {response}
            </Typography>
          </Box>
          <Box
            height="100%"
            sx={{
              border: "1px solid #dddddd",
              borderRadius: "20px",
              padding: "10px 20px ",
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                variant="subtitle1"
                color="secondary"
                style={{
                  fontWeight: 600,
                  marginBottom: "10px",
                }}
              >
                {questionAr}
              </Typography>
            </Box>
            <Typography
              variant="subtitle1"
              className="scrolbar"
              sx={{ height: 90 }}
            >
              {responseAr}
            </Typography>
          </Box>

          <ToggleButtonGroup
            orientation="vertical"
            value={view}
            exclusive
            onChange={handleChange}
          >
            <ToggleButton value="edit" aria-label="list">
              <EditIcon />
            </ToggleButton>
            <ToggleButton value="delete" aria-label="module">
              <TrashIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </>
      )}
    </Box>
  );
}
