/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class RoleStoreUserStoreCalendarService {

    /**
     * get fully booked days
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getFbDaysByStoreId(
        requestBody?: {
            restaurant_store_id: number;
            date: string;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/storeUser/store/getFBDaysByStoreId`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * adding fully booked days
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static addFullyBooked(
        requestBody?: {
            start_date: string;
            end_date: string;
            restaurant_store_id: number;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/storeUser/store/addFullyBooked`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * deleting fully booked days
     * @param id ID of fully booked day
     * @returns any Successful operation
     * @throws ApiError
     */
    public static deleteFullyBooked(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/api/storeUser/store/deleteFullyBooked/${id}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

}