import { Route, Switch } from "react-router-dom";

import {
  // Dashboard,
  Orders,
  Restaurants,
  Stores,
  FormStore,
  ManagementStore,
  CartingMenu,
  FormCartingMenu,
  FormCatringMenuDuplicate,
  Costumers,
  Profile,
  CostumesProfile,
  Reviews,
  GlobalGategory,
  Filters,
  Requirements,
  CalendarEvent,
  OrdersDetails,
  FilterCollections,
  Offers,
  Regions,
  StatementOfAccounts,
  PaymentsRequests,
  StoresPayments,
  NotFound,
  GeneralSetting,
  SendNotifications,
  FAQS,
  OtherCharges,
  OrdersDetailsStoreFormAdmin,
  WelcomeDiscount,
  Coupons,
  // account restaurant
  OrderRestaurant,
  OrdersDetailsRestaurant,
  StoreRestaurant,
  FormStoreRestaurant,
  CalendarRestaurant,
  ManagementStoreRestaurant,
  CartingMenuRestaurant,
  ReportRestaurant,
  // account store
  OrderStore,
  OrdersDetailsStore,
  StoreStores,
  CalendarStores,
  CateringReports,
  RolesPermission,
  ReportStore,
  CartingMenuStore,
  PaymentRequestsStore,
  StatementOfAccountsStore,
} from "../../modules";

export function getKindContentAdmin() {
  return (
    <Switch>
      {/* <Route path="/main/dashboard" component={Dashboard} exact /> */}
      <Route path="/main/orders" component={Orders} exact />
      <Route
        path="/main/orders/order-details/:id"
        component={OrdersDetails}
        exact
      />
      <Route
        path="/main/orders/order-store-details/:id"
        component={OrdersDetailsStoreFormAdmin}
        exact
      />
      <Route path="/main/restaurants" component={Restaurants} exact />
      <Route path="/main/restaurants/:id/stores" component={Stores} exact />
      <Route
        path="/main/restaurants/:id/stores/add-store"
        component={FormStore}
        exact
      />
      <Route
        path="/main/restaurants/:id/stores/:idStore/edit-store"
        component={ManagementStore}
        exact
      />
      <Route
        path="/main/restaurants/:id/stores/:idStore/calendar"
        component={CalendarEvent}
        exact
      />
      <Route
        path="/main/restaurants/:id/stores/:idStore/categorie-&-carting-menu"
        component={CartingMenu}
        exact
      />
      <Route
        path="/main/restaurants/:id/stores/:idStore/categorie-&-carting-menu/form-menu"
        component={FormCartingMenu}
        exact
      />
      <Route
        path="/main/restaurants/:id/stores/:idStore/categorie-&-carting-menu/form-menu-duplicate"
        component={FormCatringMenuDuplicate}
        exact
      />
      <Route
        path="/main/restaurants/:id/stores/:idStore/categorie-&-carting-menu/form-menu/:idMenu"
        component={FormCartingMenu}
        exact
      />
      <Route path="/main/customers" component={Costumers} exact />
      <Route
        path="/main/customers/customer-profile/:idCutomer"
        component={CostumesProfile}
        exact
      />
      <Route path="/main/offers" component={Offers} exact />
      <Route path="/main/coupons" component={Coupons} exact />
      <Route path="/main/list-reviews" component={Reviews} exact />
      <Route path="/main/profile" component={Profile} exact />
      <Route
        path="/main/payments/statement-of-accounts"
        component={StatementOfAccounts}
        exact
      />
      <Route
        path="/main/payments/stores-payments"
        component={StoresPayments}
        exact
      />
      <Route
        path="/main/payments/payment-requests"
        component={PaymentsRequests}
        exact
      />
      <Route
        path="/main/payments/other-charges"
        component={OtherCharges}
        exact
      />
      <Route
        path="/main/settings/global-categories"
        component={GlobalGategory}
        exact
      />
      <Route path="/main/settings/filters" component={Filters} exact />
      <Route
        path="/main/settings/filter-collection"
        component={FilterCollections}
        exact
      />
      <Route
        path="/main/settings/requirements"
        component={Requirements}
        exact
      />
      <Route path="/main/settings/regions" component={Regions} exact />
      <Route
        path="/main/settings/general-setting"
        component={GeneralSetting}
        exact
      />
      <Route path="/main/settings/FAQS" component={FAQS} exact />
      <Route
        path="/main/settings/welcome-discount"
        component={WelcomeDiscount}
        exact
      />
      <Route
        path="/main/send-notifications"
        component={SendNotifications}
        exact
      />
      <Route path="/main/reports/catering" component={CateringReports} exact />
      <Route
        path="/main/Roles-&-permission"
        component={RolesPermission}
        exact
      />

      <Route path="/*" component={NotFound} />
    </Switch>
  );
}

export function getKindContentMarketing() {
  return (
    <Switch>
      <Route
        path="/main/send-notifications"
        component={SendNotifications}
        exact
      />

      <Route path="/*" component={NotFound} />
    </Switch>
  );
}

export function getKindContentBusiness() {
  return (
    <Switch>
      <Route path="/main/orders" component={Orders} exact />
      <Route
        path="/main/orders/order-details/:id"
        component={OrdersDetails}
        exact
      />
      <Route path="/main/restaurants" component={Restaurants} exact />
      <Route path="/main/restaurants/:id/stores" component={Stores} exact />
      <Route
        path="/main/restaurants/:id/stores/add-store"
        component={FormStore}
        exact
      />
      <Route
        path="/main/restaurants/:id/stores/:idStore/edit-store"
        component={ManagementStore}
        exact
      />
      <Route
        path="/main/restaurants/:id/stores/:idStore/calendar"
        component={CalendarEvent}
        exact
      />
      <Route
        path="/main/restaurants/:id/stores/:idStore/categorie-&-carting-menu"
        component={CartingMenu}
        exact
      />
      <Route
        path="/main/restaurants/:id/stores/:idStore/categorie-&-carting-menu/form-menu"
        component={FormCartingMenu}
        exact
      />
      <Route
        path="/main/restaurants/:id/stores/:idStore/categorie-&-carting-menu/form-menu/:idMenu"
        component={FormCartingMenu}
        exact
      />
      <Route
        path="/main/settings/global-categories"
        component={GlobalGategory}
        exact
      />
      <Route path="/main/settings/filters" component={Filters} exact />
      <Route
        path="/main/settings/requirements"
        component={Requirements}
        exact
      />
      <Route path="/main/settings/regions" component={Regions} exact />
      <Route path="/main/settings/regions" component={Regions} exact />

      <Route path="/*" component={NotFound} />
    </Switch>
  );
}

export function getKindContentRestaurant() {
  return (
    <Switch>
      <Route path="/restaurant/orders" component={OrderRestaurant} exact />
      <Route
        path="/restaurant/orders/order-details/:id"
        component={OrdersDetailsRestaurant}
        exact
      />
      <Route path="/restaurant/store" component={StoreRestaurant} exact />
      <Route
        path="/restaurant/store/add-store"
        component={FormStoreRestaurant}
        exact
      />
      <Route
        path="/restaurant/store/:id/calendar"
        component={CalendarRestaurant}
        exact
      />
      <Route
        path="/restaurant/store/:id/management-store"
        component={ManagementStoreRestaurant}
        exact
      />
      <Route
        path="/restaurant/store/:id/categorie-&-carting-menu"
        component={CartingMenuRestaurant}
        exact
      />
      <Route path="/restaurant/reports" component={ReportRestaurant} exact />
      <Route path="/*" component={NotFound} />
    </Switch>
  );
}

export function getKindContentStore() {
  return (
    <Switch>
      <Route path="/store/orders" component={OrderStore} exact />
      <Route
        path="/store/orders/order-details/:id"
        component={OrdersDetailsStore}
        exact
      />
      <Route path="/store/stores" component={StoreStores} exact />
      <Route
        path="/store/stores/:id/calendar"
        component={CalendarStores}
        exact
      />
      <Route
        path="/store/stores/:id/categorie-&-carting-menu"
        component={CartingMenuStore}
        exact
      />
      <Route path="/store/reports" component={ReportStore} exact />
      <Route
        path="/store/payments/payment-requests"
        component={PaymentRequestsStore}
        exact
      />
      <Route
        path="/store/payments/statement-of-accounts"
        component={StatementOfAccountsStore}
        exact
      />
      <Route path="/*" component={NotFound} />
    </Switch>
  );
}
