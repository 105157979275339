/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import LinearProgress from "@mui/material/LinearProgress";
import SearchIcon from "@mui/icons-material/Search";
import { ReactSortable } from "react-sortablejs";
import { useMutation, useQuery, useQueryClient } from "react-query";
import debounce from "lodash/debounce";

import { Button, Input, Dialog } from "../../commons";
import { AssignCollectionDialog, TableRow } from "..";

import { SnackbarContext } from "../../App";
import { serviceUnavailable, successfulOperation } from "../../utils/message";

import { FilterCollectionsService } from "../../services/openApi";

import { AssignCollectionStyled } from "./AssignCollection-style";

type openFormState = {
  open: boolean;
  title: string;
  action?: "delete" | "add" | "update" | "assign";
};

type AssignCollectionProps = {
  idCollection?: number;
};

interface ItemType {
  id: number;
  idItem: number;
  nameStore: string;
  nameMenu: string;
  type: string;
}

export const AssignCollection = ({
  idCollection,
}: AssignCollectionProps): JSX.Element => {
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);
  const {
    getFilterCollectionDetails,
    removeElementsFromCollection,
    sortFilterCollectionItems,
    sortFilterCollectionStores,
  } = FilterCollectionsService;

  const [openModal, setOpenModal] = React.useState<openFormState>({
    open: false,
    title: "",
    action: undefined,
  });

  const [item, setItem] =
    React.useState<{ type?: string; element_id?: number }>();
  const [listSort, setListSort] = React.useState<Array<any>>([]);

  const [listState, setListState] = React.useState<ItemType[]>([]);

  const collectionDetails = useQuery(
    ["getFilterCollectionDetails", idCollection],
    () => getFilterCollectionDetails({ filter_collection_id: idCollection }),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
      enabled: !!idCollection,
    }
  );

  const storesId = collectionDetails?.data?.[0]?.collection_stores?.map(
    (item: any) => item?.id
  );

  const menusId = collectionDetails?.data?.[0]?.collection_items?.map(
    (item: any) => item?.id
  );

  React.useEffect(() => {
    const arrayITems: ItemType[] = [];
    if (collectionDetails?.data?.[0]) {
      collectionDetails?.data?.[0]?.collections?.forEach((item: any) => {
        arrayITems.push({
          id: item.sorting_index,
          idItem: item.element_id,
          nameStore: item.store_name,
          nameMenu: item.item_name,
          type: collectionDetails?.data?.[0]?.type,
        });
      });
      setListState(arrayITems);
    }
  }, [collectionDetails?.data]);

  const removeItem = useMutation(() => removeElementsFromCollection(item), {
    onSuccess: () => {
      queryClient.refetchQueries("getFilterCollections");
      queryClient.refetchQueries(["getFilterCollectionDetails", idCollection]);
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
      setOpenModal({
        open: false,
        title: "",
      });
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const sortCollectionItems = useMutation(
    () => sortFilterCollectionItems({ elements: listSort }),
    {
      onSuccess: () => {
        queryClient.refetchQueries("getFilterCollections");
        queryClient.refetchQueries([
          "getFilterCollectionDetails",
          idCollection,
        ]);
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const sortCollectionStore = useMutation(
    () => sortFilterCollectionStores({ elements: listSort }),
    {
      onSuccess: () => {
        queryClient.refetchQueries("getFilterCollections");
        queryClient.refetchQueries([
          "getFilterCollectionDetails",
          idCollection,
        ]);
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (collectionDetails?.data) {
      const arrayITems: ItemType[] = [];
      if (collectionDetails?.data?.[0]?.type === "Item") {
        const ResultItem =
          collectionDetails?.data?.[0]?.collection_items?.filter(
            (item: any) =>
              item?.item_translations?.[0]?.name
                ?.toLowerCase()
                ?.includes(event.target.value.toLowerCase()) ||
              item?.item_translations?.[1]?.name
                ?.toLowerCase()
                ?.includes(event.target.value.toLowerCase())
          );

        ResultItem?.forEach((item: any) => {
          arrayITems.push({
            id: item.sorting_index,
            idItem: item.element_id,
            nameStore: item.store_name,
            nameMenu: item.item_name,
            type: collectionDetails?.data?.[0]?.type,
          });
        });
      } else if (collectionDetails?.data?.[0]?.type === "Store") {
        const ResultStore = collectionDetails?.data?.[0]?.collection_stores
          ?.toLowerCase()
          ?.filter(
            (item: any) =>
              item?.item_translations?.[0]?.name?.includes(
                event.target.value.toLowerCase()
              ) ||
              item?.item_translations?.[1]?.name
                ?.toLowerCase()
                ?.includes(event.target.value.toLowerCase())
          );
        ResultStore?.forEach((item: any) => {
          arrayITems.push({
            id: item.sorting_index,
            idItem: item.element_id,
            nameStore: item.store_name,
            nameMenu: item.item_name,
            type: collectionDetails?.data?.[0]?.type,
          });
        });
      }
      setListState(arrayITems);
    }
  };

  const handleOrder = () => {
    const arraySortable: any[] = [];
    listState?.forEach((item, index) => {
      arraySortable.push({
        id: item.idItem,
        sorting_index: index + 1,
      });
    });
    setListSort(arraySortable);
  };

  const sendOrderItem = React.useCallback(
    debounce(() => sortCollectionItems.mutate(), 2000),
    []
  );

  const sendOrderStore = React.useCallback(
    debounce(() => sortCollectionStore.mutate(), 2000),
    []
  );

  return (
    <AssignCollectionStyled>
      <div className="header">
        <Typography variant="h2" className="title">
          {collectionDetails?.data?.[0]?.name}
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={() =>
            setOpenModal({
              open: true,
              title: "Assign",
              action: "assign",
            })
          }
          disabled={!collectionDetails?.data?.[0]?.name}
        >
          Assign
        </Button>
      </div>
      <Box p={2}>
        <Input
          placeholder="Search"
          onChange={handleSearch}
          startAdornment={
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          }
          style={{ marginBottom: 30 }}
        />

        <TableRow index={-1} key={0} data={listState[0]} />
        {collectionDetails?.isLoading && <LinearProgress color="secondary" />}
        <ReactSortable
          list={listState}
          setList={setListState}
          animation={200}
          onSort={async () => {
            await handleOrder();
            collectionDetails?.data?.[0]?.type === "Item"
              ? sendOrderItem()
              : sendOrderStore();
          }}
        >
          {listState?.map((item, index) => (
            <TableRow
              index={index}
              key={index}
              data={item}
              onDelete={(id, type) => {
                setItem({
                  element_id: id,
                  type: type,
                });
                setOpenModal({
                  open: true,
                  title: "Delete",
                  action: "delete",
                });
              }}
            />
          ))}
        </ReactSortable>
        {listState.length === 0 && (
          <Typography
            variant="body2"
            align="center"
            sx={{ margin: "15px auto" }}
          >
            No data available
          </Typography>
        )}
      </Box>
      {openModal.action === "assign" && (
        <AssignCollectionDialog
          idCollection={idCollection}
          open={openModal.open}
          nameCollectionEn={
            collectionDetails?.data?.[0]?.collection_translations[0]?.name
          }
          nameCollectionAr={
            collectionDetails?.data?.[0]?.collection_translations[1]?.name
          }
          title={openModal.title}
          action={openModal.action}
          storesId={storesId}
          menusId={menusId}
          type={collectionDetails?.data?.[0]?.type}
          onClose={() =>
            setOpenModal({
              open: false,
              title: "",
            })
          }
        />
      )}
      {openModal.action === "delete" && (
        <Dialog
          open={openModal.open}
          title={openModal.title}
          action="delete"
          handleContent={() => removeItem?.mutate()}
          loading={removeItem?.isLoading}
          onClose={() =>
            setOpenModal({
              open: false,
              title: "",
            })
          }
        >
          <Typography>
            Are you sure to delete this collection details?
          </Typography>
        </Dialog>
      )}
    </AssignCollectionStyled>
  );
};
