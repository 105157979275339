/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class StoreWorkingHoursService {

    /**
     * get store Working Hours
     * @param id ID of store
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getHoursByStoreId(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/admins/restaurant/storeWorking/getHoursByStoreId/${id}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * get store Working Hour details
     * @param id ID of working day
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getWorkingHourDetails(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/admins/restaurant/storeWorking/show/${id}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Save store Working Hours
     * @param id ID of store
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static saveWorkingHours(
        id: number,
        requestBody?: {
            start_time: string;
            end_time: string;
            week_day?: string;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/restaurant/storeWorking/store/${id}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * update store Working Hours
     * @param id ID of store working day id
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static updateWorkingHours(
        id: number,
        requestBody?: {
            start_time: string;
            end_time: string;
            week_day: string;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/restaurant/storeWorking/update/${id}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * update store Working Hours for all days
     * @param id ID of store
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static applyWorkingHoursForAll(
        id: number,
        requestBody?: {
            start_time: string;
            end_time: string;
            /**
             * week days
             */
            week_days?: Array<string>;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/restaurant/storeWorking/applyForAll/${id}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * delete store Working Hours
     * @param id ID of store working day
     * @returns any Successful operation
     * @throws ApiError
     */
    public static deleteWorkingHours(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/api/admins/restaurant/storeWorking/delete/${id}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * get store Working Hours
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getWorkingHoursByStoreId(
        requestBody: {
            store_id?: number;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/mobile/store/getWorkingHoursByStoreId`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

}