import styled from "styled-components";
import { List, ListProps } from "@mui/material";

type ListStyledProps = ListProps & {
  reduce?: string;
};

export const ListStyled = styled(List)<ListStyledProps>`
  padding-right: ${({ reduce }) => (reduce === "true" ? "0" : "20px")};
  transition: padding-right 300ms ease-in-out;
  .MuiButtonBase-root {
    height: 45px;
    border-radius: ${({ reduce }) =>
      reduce === "true" ? "0" : "0 30px 30px 0"};
    transition: border-radius 300ms ease-in-out;
  }
`;
