import styled from "styled-components";
import Typography from "@mui/material/Typography";

export const StatusStyled = styled(Typography)<{
  background: string;
  color: string;
}>`
  width: max-content;
  background-color: ${(props) => props.background};
  color: ${(props) => props.color};
  border-radius: 30px;
  padding: 5px 10px;
  /* margin: 0 auto; */
`;
