import React from "react";
import styled from "styled-components";
import InputLabel from "@mui/material/InputLabel";
import { FilledInputProps } from "@mui/material/FilledInput";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputBase from "@mui/material/InputBase";

type InputProps = FilledInputProps & {
  label?: string;
  msgError?: string;
  dataTestId?: string;
  multiline?: boolean;
  rows?: number | string;
  required?: boolean;
  maxLength?: number;
};

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      msgError,
      dataTestId,
      multiline,
      rows,
      style,
      required,
      inputProps,
      maxLength,
      endAdornment,
      ...restProps
    },
    ref
  ) => {
    return (
      <FormControlStyled
        variant="filled"
        error={msgError !== "" && msgError !== undefined}
        style={style}
        required={required || false}
        // label={Boolean(label)}
        label={label ? 1 : 0}
      >
        <InputLabel htmlFor="component-filled" shrink>
          {label}
        </InputLabel>

        <InputBase
          ref={ref}
          inputProps={{
            className: "mainInput",
            "data-testid": dataTestId,
            maxLength,
          }}
          {...restProps}
          multiline={multiline}
          rows={rows}
          endAdornment={endAdornment}
        />
        {msgError && <FormHelperText> {msgError} </FormHelperText>}
      </FormControlStyled>
    );
  }
);

const FormControlStyled = styled(FormControl)<{ label: number }>`
  width: 100%;
  outline: 0;
  .MuiInputBase-root {
    background-color: #ffffff;
    border: 1px solid ${(props) => (props.error ? "#f67070" : "#dddddd")};
    border-radius: 30px;
    &:focus-within {
      border: 1px solid
        ${(props) =>
          props.error ? "#f67070" : props.theme.palette.secondary.main};
      border-radius: 30px;
      svg {
        fill: ${(props) =>
          props.error ? "#f67070" : props.theme.palette.secondary.main};
      }
    }
  }
  .mainInput {
    overflow: hidden;
    padding: ${(props) => (props.label ? "25px 19px 6px" : "12px")};
    border-radius: 30px;
  }

  .MuiInputLabel-root {
    left: 12px;
  }

  .MuiInputBase-multiline {
    padding: 0;
  }
  .Mui-disabled {
    background: #f1f2fb;
  }
  .MuiInputAdornment-root {
    margin-left: 20px;
    svg {
      fill: ${(props) => props.error && "#f67070"};
    }
  }
`;
