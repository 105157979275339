import * as React from "react";
import { useParams, RouteComponentProps } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import Skeleton from "@mui/material/Skeleton";
import IconButton from "@mui/material/IconButton";
// import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useQuery } from "react-query";

import { Accordion, Dialog } from "../../../commons";
import Table, { ColumnsProps } from "../../../commons/Table";
import { AddressFormDialog } from "../../../components";

import { SnackbarContext } from "../../../App";
import { CustomersService } from "../../../services/openApi";
import { serviceUnavailable } from "../../../utils/message";

// import { EditIcon, TrashIcon, EyeIcon } from "../../../img/icon";

import { Page } from "./CostumesProfile-styles";

type openFormState = {
  open: boolean;
  title: string;
  action: "delete" | "add" | "update" | "view" | undefined;
};

export const CostumesProfile = (props: RouteComponentProps): JSX.Element => {
  const { getCustomerDetails } = CustomersService;
  const { idCutomer } = useParams<{ idCutomer?: string }>();

  const { setSnack } = React.useContext(SnackbarContext);

  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [openFormDialog, setOpenFormDialog] = React.useState<openFormState>({
    open: false,
    title: "",
    action: undefined,
  });
  const [openDelete, setOpenDelete] = React.useState<openFormState>({
    open: false,
    title: "",
    action: undefined,
  });

  const getDetailsCustomer = useQuery(
    ["getCustomerDetails", idCutomer],
    () => getCustomerDetails(+idCutomer!),
    {
      enabled: !!idCutomer,
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const columnsAddress: ColumnsProps = [
    {
      header: "Area",
      accessor: "area",
    },
    {
      header: "Region",
      accessor: "region",
    },
    {
      header: "Street Number",
      accessor: "streetNumber",
    },
    {
      header: "Building Number",
      accessor: "buildingNumber",
    },
  ];

  const renderTableRowsAddress = () => {
    return getDetailsCustomer?.data?.addresses?.map((address: any) => ({
      area: (
        <Typography variant="subtitle2" style={{ margin: "7px " }}>
          {address?.area?.area_translation?.name}
        </Typography>
      ),
      region: (
        <Typography variant="subtitle2" style={{ margin: "7px " }}>
          {address?.region?.region_translation?.name}
        </Typography>
      ),
      streetNumber: (
        <Typography variant="subtitle2" style={{ margin: "7px " }}>
          {address?.street_number}
        </Typography>
      ),
      buildingNumber: (
        <Typography variant="subtitle2" style={{ margin: "7px " }}>
          {address?.building_number}
        </Typography>
      ),
    }));
  };

  // const renderTableRowsAddress = () => [
  //   {
  //     addressLine1: (
  //       <Typography variant="subtitle2" style={{ margin: "7px " }}>
  //         {" "}
  //         rue any where 1{" "}
  //       </Typography>
  //     ),
  //     addressLine2: "rue any where 2",
  //     city: "doha",
  //     states: "Qatar",
  //     zipCode: "1234",
  //     actions: (
  //       <Box display="flex" justifyContent="center" gap={1}>
  //         <Button
  //           variant="contained"
  //           className="btn-view"
  //           onClick={(e) => {
  //             e.stopPropagation();
  //             setOpenFormDialog({
  //               open: true,
  //               title: "View Address",
  //               action: "view",
  //             });
  //           }}
  //         >
  //           View <EyeIcon />
  //         </Button>
  //         <Button
  //           variant="contained"
  //           className="btn-edit"
  //           onClick={(e) => {
  //             e.stopPropagation();
  //             setOpenFormDialog({
  //               open: true,
  //               title: "Edit Address",
  //               action: "update",
  //             });
  //           }}
  //         >
  //           Edit
  //           <EditIcon />
  //         </Button>
  //         <Button
  //           variant="contained"
  //           className="btn-delete"
  //           onClick={(e) => {
  //             e.stopPropagation();
  //             setOpenDelete({
  //               open: true,
  //               title: "Delete",
  //               action: "delete",
  //             });
  //           }}
  //         >
  //           Delete <TrashIcon />
  //         </Button>
  //       </Box>
  //     ),
  //   },
  // ];

  const columnsOrders: ColumnsProps = [
    {
      header: "ID",
      accessor: "id",
    },
    {
      header: "Status",
      accessor: "status",
    },
    {
      header: "Total",
      accessor: "total",
    },
    {
      header: "Created",
      accessor: "created",
    },
    {
      header: "Actions",
      accessor: "actions",
      headerCellProps: { align: "center" },
      cellProps: { align: "center", width: "80px" },
    },
  ];

  const renderTableRowsOrders = () => {
    return getDetailsCustomer?.data?.orders
      ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      ?.map((order: any) => ({
        id: (
          <Typography variant="subtitle2" style={{ margin: "7px " }}>
            {order?.id}
          </Typography>
        ),
        status: (
          <Typography variant="subtitle2" style={{ margin: "7px " }}>
            {
              order?.order_current_status?.order_status_translations?.[0]
                ?.display_status
            }
          </Typography>
        ),
        total: (
          <Typography variant="subtitle2" style={{ margin: "7px " }}>
            {order?.order_total_price} QAR
          </Typography>
        ),
        created: (
          <Typography variant="subtitle2" style={{ margin: "7px " }}>
            {order?.iso_date}
          </Typography>
        ),
        actions: (
          <IconButton
            onClick={() =>
              props.history.push(
                `/main/orders/order-store-details/${order?.id}`
              )
            }
          >
            <VisibilityIcon />
          </IconButton>
        ),
      }));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Page className="scrolbar">
      <div className="basic-details">
        <Typography
          variant="subtitle1"
          style={{ fontWeight: 600, marginBottom: 10 }}
        >
          Basic Details
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap={1}
        >
          <Typography
            variant="subtitle2"
            sx={{ display: "flex", flex: 1, color: "#231F56", fontWeight: 500 }}
          >
            Name :{" "}
            {getDetailsCustomer?.isLoading ? (
              <Skeleton variant="text" sx={{ minWidth: 150, marginLeft: 1 }} />
            ) : (
              getDetailsCustomer?.data?.fullname
            )}
          </Typography>

          <Typography
            variant="subtitle2"
            sx={{ display: "flex", flex: 1, color: "#231F56", fontWeight: 500 }}
          >
            Phone Number :{" "}
            {getDetailsCustomer?.isLoading ? (
              <Skeleton variant="text" sx={{ minWidth: 150, marginLeft: 1 }} />
            ) : (
              getDetailsCustomer?.data?.mobile
            )}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ display: "flex", flex: 1, color: "#231F56", fontWeight: 500 }}
          >
            E-mail :{" "}
            {getDetailsCustomer?.isLoading ? (
              <Skeleton variant="text" sx={{ minWidth: 150, marginLeft: 1 }} />
            ) : (
              getDetailsCustomer?.data?.email
            )}
          </Typography>
        </Box>
      </div>
      <Accordion title="Address" defaultExpanded>
        <Table
          loading={getDetailsCustomer?.isLoading}
          nbrRowLoading={5}
          columns={columnsAddress}
          data={renderTableRowsAddress()}
          emptyMessage="No data available"
        />
        <Box p="8px 16px">
          {/* <Button
            color="secondary"
            onClick={() =>
              setOpenFormDialog({
                open: true,
                title: "Add Address",
                action: "add",
              })
            }
          >
            <AddCircleOutlineIcon fontSize="small" style={{ marginRight: 8 }} />
            Add Address
          </Button> */}
        </Box>
      </Accordion>
      <Accordion title="Orders" defaultExpanded>
        <Table
          loading={getDetailsCustomer?.isLoading}
          nbrRowLoading={5}
          columns={columnsOrders}
          data={renderTableRowsOrders()}
          emptyMessage="No data available"
          tablePaginationProps={{
            rowsPerPageOptions: [5, 10, 25],
            count: getDetailsCustomer?.data?.orders?.length || 0,
            page: page,
            onPageChange: (_, newPage) => setPage(newPage),
            rowsPerPage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
        />
      </Accordion>
      <AddressFormDialog
        open={openFormDialog.open}
        title={openFormDialog.title}
        action={openFormDialog.action}
        onClose={() =>
          setOpenFormDialog({
            open: false,
            title: "",
            action: undefined,
          })
        }
      />
      <Dialog
        open={openDelete.open}
        title={openDelete.title}
        action="delete"
        handleContent={() => {}}
        onClose={() =>
          setOpenDelete({
            open: false,
            title: "",
            action: "delete",
          })
        }
      >
        <Typography>Are you sure to delete this address?</Typography>
      </Dialog>
    </Page>
  );
};
