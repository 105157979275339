import React from "react";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { ReactSortable } from "react-sortablejs";
import Skeleton from "@mui/material/Skeleton";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { LoadingButton, Dialog } from "../../../../commons";
import { FaqCateringFormDialog, QuestionItem } from "../../../../components";

import { SnackbarContext } from "../../../../App";
import {
  successfulOperation,
  serviceUnavailable,
} from "../../../../utils/message";

import { CateringFaqService } from "../../../../services/openApi";

import { Page } from "./Catering-style";

type openFormState = {
  open: boolean;
  title: string;
  action?: "delete" | "add" | "update";
};
export const Catering = () => {
  const { getCateringFaq, deleteCateringFaq } = CateringFaqService;
  const { setSnack } = React.useContext(SnackbarContext);
  const queryClient = useQueryClient();

  const [openDelete, setOpenDelete] = useState<openFormState>({
    open: false,
    title: "",
  });
  const [openForm, setOpenForm] = useState<openFormState>({
    open: false,
    title: "",
  });

  const [state, setState] = useState<any[]>([{}, {}, {}, {}]);
  const [idFaq, setIdFaq] = useState<number | undefined>();
  const [faqDetails, setFaqDetails] = useState<any>();

  const getFaqs = useQuery("getCateringFaq", getCateringFaq, {
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  React.useEffect(() => {
    if (getFaqs.data) {
      const arrayList: any[] = [];
      getFaqs.data.forEach((item: any) => {
        arrayList.push({
          id: item.id,
          qstEn: item?.question_en,
          qstAr: item?.question_ar,
          resEn: item?.answer_en,
          resAr: item?.answer_ar,
        });
      });
      setState(arrayList);
    }
  }, [getFaqs.data]);

  const deleteFaq = useMutation(() => deleteCateringFaq(idFaq!), {
    onSuccess: () => {
      queryClient.refetchQueries("getCateringFaq");
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
      setOpenDelete({
        open: false,
        title: "",
      });
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const handleClick = (id: number, action: string) => {
    setIdFaq(id);
    if (action === "edit") {
      setOpenForm({
        open: true,
        title: "Edit Question & Response",
        action: "add",
      });
      setFaqDetails(state?.find((item) => item?.id === id));
    } else if (action === "delete") {
      setOpenDelete({
        open: true,
        title: "Delete",
        action: "delete",
      });
    }
  };

  return (
    <Page>
      <Box display="flex" justifyContent="flex-end">
        <LoadingButton
          variant="contained"
          color="secondary"
          onClick={() =>
            setOpenForm({
              open: true,
              title: "Add Question & Responses",
              action: "add",
            })
          }
          style={{ width: "13em" }}
        >
          Add Question
        </LoadingButton>
      </Box>
      <Box
        display="column"
        mt={2}
        sx={{
          border: "1px solid #dddddd",
          borderRadius: "20px",
          padding: "20px",
        }}
      >
        <Box display="grid" gridTemplateColumns="1fr 1fr 40px" gap={2}>
          {getFaqs?.isLoading ? (
            <Skeleton variant="text" width={150} sx={{ marginLeft: 2 }} />
          ) : (
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 600, marginLeft: 2 }}
            >
              In English
            </Typography>
          )}
          {getFaqs?.isLoading ? (
            <Skeleton variant="text" width={150} sx={{ marginLeft: 2 }} />
          ) : (
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 600, marginLeft: 2 }}
            >
              In Arabic
            </Typography>
          )}
        </Box>

        <ReactSortable
          list={state}
          setList={setState}
          animation={200}
          delay={2}
          style={{ width: "100%" }}
        >
          {state?.map((item, index) => (
            <QuestionItem
              id={item.id!}
              key={index}
              onAction={handleClick}
              question={item.qstEn}
              response={item.resEn}
              questionAr={item.qstAr}
              responseAr={item.resAr}
              loading={getFaqs?.isLoading}
            />
          ))}
        </ReactSortable>
      </Box>

      {openForm.open && (
        <FaqCateringFormDialog
          open={openForm.open}
          title={openForm.title}
          action={openForm.action}
          faqData={faqDetails}
          onClose={() => {
            setOpenForm({
              open: false,
              action: undefined,
              title: "",
            });
          }}
        />
      )}

      <Dialog
        open={openDelete.open}
        title={openDelete.title}
        action="delete"
        handleContent={() => idFaq && deleteFaq.mutate()}
        loading={deleteFaq.isLoading}
        onClose={() =>
          setOpenDelete({
            open: false,
            title: "",
          })
        }
      >
        <Typography>Are you sure to delete this question?</Typography>
      </Dialog>
    </Page>
  );
};
