import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import Skeleton from "@mui/material/Skeleton";
import { useQuery, useQueryClient, useMutation } from "react-query";

import { Button, Dialog } from "../../commons";
import { AddonsContainer } from "..";
import { AddonsFormDialog } from "../";
import { SnackbarContext } from "../../App";

import { RestaurantItemAddonsService } from "../../services/openApi";
import { serviceUnavailable, successfulOperation } from "../../utils/message";

import { TrashIcon } from "../../img/icon";

import { PageAddons } from "./Addons-styles";

type ModalProps = {
  open: boolean;
  title: string;
  action?: "delete" | "add" | "update";
};

const arrayListAddonsloading = new Array(9).fill("");

type AddonsProps = {
  idMenu?: any;
  style?: React.CSSProperties;
};

export const Addons = ({ idMenu, style }: AddonsProps): JSX.Element => {
  const { getItemAddon, deleteItemAddon } = RestaurantItemAddonsService;
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);

  const [idAddon, setIdAddon] = React.useState<number | undefined>();
  const [openForm, setOpenForm] = React.useState<ModalProps>({
    open: false,
    title: "",
  });
  const [addNewAddonOption, setAddNewAddonOption] =
    React.useState<boolean>(false);
  const [enableBtnAddOption, setEnableBtnAddOption] =
    React.useState<boolean>(false);

  const getListAddons = useQuery(
    ["getItemAddon", idMenu],
    () => getItemAddon(+idMenu!),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
      enabled: !!idMenu,
    }
  );

  const deleteAddon = useMutation(() => deleteItemAddon(idAddon!), {
    onSuccess: () => {
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
      queryClient.refetchQueries(["getItemAddon", idMenu]);
      setOpenForm({
        open: false,
        title: "",
      });
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const HandleNewChoiceOption = () => {
    setAddNewAddonOption(true);
  };

  const handleDisabledAddOption = (value: boolean) => {
    setAddNewAddonOption(false);
    setEnableBtnAddOption(value);
  };

  return (
    <PageAddons>
      <Box
        bgcolor="#fff"
        border="1px solid #dddddd"
        borderRadius="0 0 0 20px"
        style={style}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          p={2}
          borderBottom="1px solid #dddddd"
        >
          <Typography
            variant="h3"
            sx={{ color: "secondary.main", fontWeight: 600 }}
          >
            Addons
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            onClick={() =>
              setOpenForm({
                open: true,
                title: "Add Addons",
                action: "add",
              })
            }
          >
            Add
          </Button>
        </Box>
        {getListAddons?.isLoading ? (
          arrayListAddonsloading?.map((_, index) => (
            <Box
              key={index}
              display="flex"
              height={45}
              alignItems="center"
              gap={2}
              padding="8px 16px 0 16px"
            >
              <Skeleton variant="text" width="100%" />
              <Skeleton
                component={IconButton}
                variant="circular"
                width={26}
                height={26}
              />
            </Box>
          ))
        ) : (
          <MenuList>
            {getListAddons?.data?.map((item: any) => (
              <MenuItem
                key={item?.id}
                selected={item?.id === idAddon}
                onClick={() => setIdAddon(item?.id)}
              >
                <ListItemText>{item?.item_addon_name}</ListItemText>
                <IconButton
                  onClick={() =>
                    setOpenForm({
                      open: true,
                      title: "Delete",
                      action: "delete",
                    })
                  }
                >
                  <TrashIcon />
                </IconButton>
              </MenuItem>
            ))}
          </MenuList>
        )}

        {!getListAddons?.isLoading && getListAddons?.data?.length === 0 && (
          <Typography
            variant="body2"
            align="center"
            alignItems="center"
            sx={{ margin: "100% 0" }}
          >
            No list addons are available
          </Typography>
        )}
      </Box>
      <Box
        bgcolor="#fff"
        border="1px solid #dddddd"
        borderRadius="0 20px 20px 0"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          p={2}
          borderBottom="1px solid #dddddd"
        >
          <Typography
            variant="h3"
            sx={{ color: "secondary.main", fontWeight: 600 }}
          >
            Options
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            disabled={enableBtnAddOption || getListAddons?.data?.length === 0}
            onClick={HandleNewChoiceOption}
          >
            Add Options
          </Button>
        </Box>
        <AddonsContainer
          idAddon={idAddon}
          addNewAddonOption={addNewAddonOption}
          onEnableBtnAddOption={handleDisabledAddOption}
        />
      </Box>
      {(openForm?.action === "update" || openForm?.action === "add") && (
        <AddonsFormDialog
          idMenu={idMenu}
          open={openForm.open}
          title={openForm.title}
          action={openForm.action}
          onClose={() =>
            setOpenForm({
              open: false,
              title: "",
            })
          }
        />
      )}
      {openForm?.action === "delete" && (
        <Dialog
          open={openForm.open}
          title={openForm.title}
          action="delete"
          handleContent={() => idAddon && deleteAddon.mutate()}
          loading={deleteAddon.isLoading}
          onClose={() =>
            setOpenForm({
              open: false,
              title: "",
            })
          }
        >
          <Typography>Are you sure to delete this addon?</Typography>
        </Dialog>
      )}
    </PageAddons>
  );
};
