import isEmpty from "validator/lib/isEmpty";
import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";
import isByteLength from "validator/lib/isByteLength";

import { RestaurantsFormDialogType } from "./restaurantsFormDialog-Types";
import { message } from "../../utils/message";

export type Error = {
  name?: string;
  phone?: string;
  logofile?: string;
  password?: string;
  email?: string;
};

export const Validation = (
  values: RestaurantsFormDialogType,
  edit?: boolean
): Error => {
  const errors: Error = {};

  Object.keys(values).forEach((key: string) => {
    switch (key) {
      case "logofile":
        if (!edit) {
          if (isEmpty((values.logofile || "").toString())) {
            errors.logofile = message.restaurantsForm.logofile;
          }
        }
        break;
      case "name":
        if (isEmpty(values.name || "")) {
          errors.name = message.restaurantsForm.nameRequiered;
        }
        break;
      case "phone":
        if (isEmpty(values.phone?.toString() || "")) {
          errors.phone = message.restaurantsForm.phoneRequiered;
        } else if (
          !isMobilePhone(values.phone?.toString() || "") ||
          !isByteLength(values.phone?.toString() || "", { min: 8, max: 8 })
        ) {
          errors.phone = message.restaurantsForm.phoneInvalid;
        }
        break;
      case "email":
        if (isEmpty(values.email?.toString() || "")) {
          errors.email = message.restaurantsForm.emailRequiered;
        } else if (!isEmail(values.email?.toString() || "")) {
          errors.email = message.restaurantsForm.emailInvalid;
        }
        break;
      case "password":
        if (!edit) {
          if (isEmpty(values.password?.toString() || "")) {
            errors.password = message.restaurantsForm.passwordRequiered;
          }
        }
        break;
      default:
        break;
    }
  });

  return errors;
};
