/* eslint-disable no-useless-concat */
import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { Dialog } from "../../../../commons";

import { CategoriesStore, CardCartingMenu } from "../../../../components";
import { SnackbarContext } from "../../../../App";
import {
  serviceUnavailable,
  successfulOperation,
} from "../../../../utils/message";
import { StoreUserStoreItemsService } from "../../../../services/openApi";

import { Page, StyledReactSortable } from "./CartingMenu-styles";

interface ItemType {
  id: number;
  name: string;
  picture: string;
  price: string;
  cash_on_delivery: boolean;
  status: boolean;
  show_on_restaurant_manager: boolean;
}

type openUpdateState = {
  open: boolean;
  title: string;
  action: "delete" | "add" | "update" | undefined;
  type: "show" | "showManager" | "accept" | "";
};

export const CartingMenuStore = (props: RouteComponentProps): JSX.Element => {
  const { get, updateShowMenu, updateShowItem, updateCashOnDelivery } =
    StoreUserStoreItemsService;
  const { setSnack } = React.useContext(SnackbarContext);
  const queryClient = useQueryClient();

  const [state, setState] = React.useState<ItemType[]>([]);

  const [idSubCategory, setIdSubCategory] = React.useState<number>(0);
  const [idItemMenu, setIdItemMenu] = React.useState<number | undefined>(
    undefined
  );

  const [openStatus, setOpenStatus] = React.useState<openUpdateState>({
    open: false,
    title: "",
    action: undefined,
    type: "",
  });
  const [show, setShow] = React.useState<boolean | undefined>(undefined);
  const [status, setStatus] = React.useState<boolean | undefined>(undefined);
  // const [cash, setCash] = React.useState<boolean | undefined>(undefined);

  const listMenu = useQuery(["get", idSubCategory], () => get(idSubCategory), {
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
    enabled: Boolean(idSubCategory),
  });

  React.useEffect(() => {
    if (listMenu?.data) {
      const data = listMenu?.data;
      const arrayState: ItemType[] = [];
      data?.forEach((item: any) => {
        arrayState.push({
          id: item.id,
          name: item?.name || "",
          picture: item?.images?.[0]?.uri || "",
          price:
            item?.promoted_price?.toString() + " " + "QR" || 0 + " " + "QR",
          cash_on_delivery: item?.cash_on_delivery,
          show_on_restaurant_manager: item?.show_on_restaurant_manager,
          status: item?.show,
        });
      });
      setState(arrayState);
    }
  }, [listMenu?.data]);

  const editShowItem = useMutation(
    () =>
      updateShowItem({
        item_id: idItemMenu,
        // show_on_restaurant_manager: cash,
      }),
    {
      onSuccess: () => {
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        setOpenStatus({
          open: false,
          title: "",
          action: "update",
          type: "",
        });
        queryClient.fetchQuery(["get", idSubCategory]);
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );
  const editShowMenu = useMutation(
    () => updateShowMenu({ item_id: idItemMenu, status: status }),
    {
      onSuccess: () => {
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        setOpenStatus({
          open: false,
          title: "",
          action: "update",
          type: "",
        });
        queryClient.refetchQueries(["get", idSubCategory]);
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );
  const editCashOnDelivery = useMutation(
    () =>
      updateCashOnDelivery({
        item_id: idItemMenu,
        cash_on_delivery: show,
      }),
    {
      onSuccess: () => {
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        setOpenStatus({
          open: false,
          title: "",
          action: "update",
          type: "",
        });
        queryClient.refetchQueries(["get", idSubCategory]);
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const handleContent = () => {
    if (openStatus.type === "show") editShowMenu.mutate();
    if (openStatus.type === "showManager") editShowItem.mutate();
    if (openStatus.type === "accept") editCashOnDelivery.mutate();
  };

  return (
    <Page>
      <CategoriesStore onSelect={setIdSubCategory} />
      <div className="container-menu">
        <div className="header">
          <Typography variant="h2" className="title">
            Catring Menu
          </Typography>
        </div>

        {state?.length === 0 ? (
          <Typography variant="body2" className="msg-empty">
            No data availble
          </Typography>
        ) : (
          <StyledReactSortable
            list={state}
            setList={setState}
            animation={600}
            delay={6}
            easing="cubic-bezier(0, 0.55, 0.45, 1)"
            className="scrolbar"
            scroll
          >
            {state?.map((item) => (
              <CardCartingMenu
                key={item.id}
                picture={item.picture}
                name={item.name}
                price={item.price}
                cashOnDelivery={item.cash_on_delivery}
                status={item.status}
                // showOnRestaurantManager={item.show_on_restaurant_manager}
                onCashActive={(event) => {
                  setOpenStatus({
                    open: true,
                    title: "Update Accept cash on delivry",
                    action: "update",
                    type: "accept",
                  });
                  setShow(event.target.checked);
                  setIdItemMenu(item?.id);
                }}
                onStatusActive={(event) => {
                  setOpenStatus({
                    open: true,
                    title: "Update Show",
                    action: "update",
                    type: "show",
                  });
                  setStatus(event.target.checked);
                  setIdItemMenu(item?.id);
                }}
                // onShowActive={(event) => {
                // setOpenStatus({
                //   open: true,
                //   title: "Update Show on restaurent manager",
                //   action: "update",
                //   type: "showManager",
                // });
                // setCash(event.target.checked);
                // setIdItemMenu(item?.id);
                // }}
              />
            ))}
          </StyledReactSortable>
        )}
      </div>

      <Dialog
        open={openStatus.open}
        title={openStatus.title}
        action="update"
        handleContent={handleContent}
        loading={
          editShowMenu.isLoading ||
          editShowItem.isLoading ||
          editCashOnDelivery.isLoading
        }
        onClose={() =>
          setOpenStatus({
            open: false,
            title: "",
            action: "update",
            type: "",
          })
        }
      >
        <Typography>Are you sure to change status this menu?</Typography>
      </Dialog>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={listMenu?.isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Page>
  );
};
