import * as React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Box from "@mui/material/Box";
import { Wrapper } from "./AuthRoute-styles";

import { Login, ForgetPassword } from "../../modules";

import { logoMeizWhite } from "../../img";

export const AuthRouter = (): JSX.Element => {
  const token = localStorage.getItem("token");

  if (token) {
    return <Redirect to={{ pathname: "/main/restaurants" }} />;
  }

  return (
    <Wrapper>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        className="background-meiz"
      >
        <img src={logoMeizWhite} className="logo-meiz-white" alt="logo meiz" />
        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </Box>
      <Switch>
        <Route path="/" component={Login} exact />
        <Route path="/forget-password" component={ForgetPassword} exact />
        <Route
          path="/forget-password/:token"
          component={ForgetPassword}
          exact
        />
      </Switch>
    </Wrapper>
  );
};
