/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class RestaurantFiltersService {

    /**
     * List of filters
     * @param requestBody search value
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getFilters(
        requestBody?: {
            search?: string;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/restaurant/filter/index`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * List of filters
     * @param id ID of filter
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getFilterById(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/admins/restaurant/filter/${id}`,
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * save a filter
     * @param formData
     * @returns any Successful operation
     * @throws ApiError
     */
    public static saveFilter(
        formData: {
            name_en?: string;
            name_ar?: string;
            slug?: string;
            imagefile?: Blob;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/restaurant/filter/store`,
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * update a filter
     * @param id ID of filter
     * @param formData
     * @returns any Successful operation
     * @throws ApiError
     */
    public static updateFilterById(
        id: number,
        formData: {
            name_en?: string;
            name_ar?: string;
            slug?: string;
            imagefile?: Blob;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/restaurant/filter/update/${id}`,
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * update a filter status
     * @param id ID of filter
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static updateCategoryStatus(
        id: number,
        requestBody: {
            status?: boolean;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/restaurant/filter/updateStatus/${id}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * delete filter
     * @param id ID of filter
     * @returns any Successful operation
     * @throws ApiError
     */
    public static deleteFilter(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/api/admins/restaurant/filter/delete/${id}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

}