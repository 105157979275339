import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { useQuery } from "react-query";

import { TextField } from "../../commons";

import { SnackbarContext } from "../../App";
import { serviceUnavailable } from "../../utils/message";

import {
  RestaurantService,
  RestaurantStoresService,
  FilterCollectionsService,
} from "../../services/openApi";

import { ChooseMenuFormStyled } from "./ChooseMenuForm-style";

type ChooseMenuFormProps = {
  onChooseMenu: (id: number) => void;
};

export const ChooseMenuForm = ({
  onChooseMenu,
}: ChooseMenuFormProps): JSX.Element => {
  const { getAllReastaurants } = RestaurantService;
  const { getAllStores } = RestaurantStoresService;
  const { getItemListByStoresId } = FilterCollectionsService;

  const { setSnack } = React.useContext(SnackbarContext);

  const [idRestaurant, setIdRestaurant] = React.useState<number | undefined>(
    undefined
  );
  const [idStore, setIdStore] = React.useState<number | undefined>(undefined);

  const listReastaurants = useQuery(
    "getAllReastaurants",
    () => getAllReastaurants(),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const listStoresOfRestaurant = useQuery(
    ["getAllStores", idRestaurant],
    () => getAllStores(idRestaurant!),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
      enabled: !!idRestaurant,
    }
  );

  const listMenu = useQuery(
    ["getItemListByStoresId", idStore],
    () => getItemListByStoresId({ stores_id: [idStore!] }),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
      enabled: !!idStore,
    }
  );

  const handleChangeIdRestaurant = (id: number) => {
    setIdRestaurant(id);
  };

  const handleChangeIdStore = (id: number) => {
    setIdStore(id);
  };

  return (
    <ChooseMenuFormStyled>
      <Autocomplete
        options={listReastaurants.data || []}
        getOptionLabel={(option: any) => option?.name || ""}
        filterSelectedOptions
        onChange={(_, value) => value?.id && handleChangeIdRestaurant(value.id)}
        renderInput={(params) => (
          <TextField {...params} label="Select Restaurant" color="secondary" />
        )}
      />
      <Autocomplete
        options={listStoresOfRestaurant.data || []}
        getOptionLabel={(option: any) => option?.name || ""}
        filterSelectedOptions
        onChange={(_, value) => value?.id && handleChangeIdStore(value.id)}
        renderInput={(params) => (
          <TextField {...params} label="Select Store" color="secondary" />
        )}
        loading={listStoresOfRestaurant.isLoading}
      />
      <Autocomplete
        options={listMenu.data || []}
        getOptionLabel={(option: any) => option?.name || ""}
        filterSelectedOptions
        onChange={(_, value) => {
          value?.id && onChooseMenu(value.id);
        }}
        renderInput={(params) => (
          <TextField {...params} label="Select Menu" color="secondary" />
        )}
        loading={listMenu.isLoading}
      />
    </ChooseMenuFormStyled>
  );
};
