import styled from "styled-components";

export const PageAddons = styled.div`
  display: grid;
  width: 100%;
  height: calc(100vh - 170px);
  grid-template-columns: 300px minmax(600px, 1fr);
  gap: 15px;
  margin: 20px;
  animation: 500ms ease-out 0ms 1 show;
  @keyframes show {
    0% {
      display: none;
      opacity: 0;
    }
    100% {
      display: grid;
      opacity: 1;
    }
  }
`;
