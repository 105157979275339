/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import { useQuery, useMutation, useQueryClient } from "react-query";
import moment from "moment";

import { Input, Button, Dialog } from "../../commons";
import Table, { ColumnsProps } from "../../commons/Table";
import { RestaurantsFormDialog } from "../../components";
import { EditIcon, TrashIcon } from "../../img/icon";
import { SnackbarContext, NameRestaurantContext } from "../../App";
import { serviceUnavailable, successfulOperation } from "../../utils/message";

import { RestaurantService } from "../../services/openApi";

import { Page } from "./Restaurants-styles";

type openFormState = {
  open: boolean;
  title: string;
  action?: "delete" | "add" | "update";
};

export const Restaurants = (props: RouteComponentProps): JSX.Element => {
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);
  const { setNameRestaurant } = React.useContext(NameRestaurantContext);
  const { getAllReastaurants, deleteRestaurant } = RestaurantService;
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [openForm, setOpenForm] = React.useState<openFormState>({
    open: false,
    title: "",
  });
  const [openDelete, setOpenDelete] = React.useState<openFormState>({
    open: false,
    title: "",
  });
  const [idRestaurant, setIdRestaurant] = React.useState<number | undefined>(
    undefined
  );
  const [search, setSearch] = React.useState<string>("");

  React.useEffect(() => {
    setNameRestaurant("");
  }, []);

  const listReastaurants = useQuery(
    "getAllReastaurants",
    () => getAllReastaurants({ search }),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const removeReastaurant = useMutation(deleteRestaurant, {
    onSuccess: () => {
      queryClient.fetchQuery("getAllReastaurants");
      queryClient.fetchQuery(["getRestaurantById", idRestaurant]);
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
      setOpenDelete({
        open: false,
        title: "",
      });
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const columns: ColumnsProps = [
    {
      header: "ID",
      accessor: "id",
    },
    {
      header: "Name",
      accessor: "name",
    },
    {
      header: "Email",
      accessor: "email",
    },
    {
      header: "Mobile",
      accessor: "mobile",
    },
    {
      header: "Created",
      accessor: "created",
    },
    {
      header: "Actions",
      accessor: "actions",
      headerCellProps: { align: "center" },
      cellProps: { align: "center", width: "150px" },
    },
  ];

  const renderTableRows = () => {
    return (
      listReastaurants?.data
        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        ?.map((item) => ({
          id: item?.id,
          name: item?.name,
          email: item?.email,
          mobile: item?.phone,
          created: item.created_at
            ? moment(item.created_at).format("DD MMM YYYY")
            : "",
          actions: (
            <Box display="flex" justifyContent="center" gap={1}>
              <Button
                variant="contained"
                className="btn-edit"
                onClick={(e) => {
                  e.stopPropagation();
                  setIdRestaurant(item?.id);
                  setOpenForm({
                    open: true,
                    title: "Edit Restaurant",
                    action: "update",
                  });
                }}
              >
                Edit
                <EditIcon />
              </Button>
              <Button
                variant="contained"
                className="btn-delete"
                onClick={(e) => {
                  e.stopPropagation();
                  setIdRestaurant(item?.id);
                  setOpenDelete({
                    open: true,
                    title: "Delete",
                    action: "delete",
                  });
                }}
              >
                Delete <TrashIcon />
              </Button>
            </Box>
          ),
        })) || []
    );
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  return (
    <Page className="scrolbar">
      <div className="page-action">
        <Input
          value={search}
          placeholder="Search by ID, name or E-mail"
          onChange={handleChange}
          onKeyPress={(e) => e.key === "Enter" && listReastaurants.refetch()}
          startAdornment={
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          }
        />
        <Button
          variant="contained"
          color="secondary"
          className="btn-add"
          onClick={() =>
            setOpenForm({
              open: true,
              title: "Add New Restaurant",
              action: "add",
            })
          }
        >
          Add New Restaurant
        </Button>
      </div>
      <Box display="flex" flex="1" marginTop={3}>
        <Table
          columns={columns}
          loading={listReastaurants.isLoading}
          data={renderTableRows()}
          nbrRowLoading={rowsPerPage}
          onClickRow={(row) => {
            row.name && setNameRestaurant(row.name as string);
            props.history.push(`/main/restaurants/${row?.id}/stores`);
          }}
          emptyMessage="No data available"
          tablePaginationProps={{
            rowsPerPageOptions: [5, 10, 25],
            count: listReastaurants.data?.length || 0,
            page: page,
            onPageChange: (_, newPage) => setPage(newPage),
            rowsPerPage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
        />
      </Box>
      {openForm.open && (
        <RestaurantsFormDialog
          open={openForm.open}
          idRestaurant={idRestaurant}
          title={openForm.title}
          action={openForm.action}
          onClose={() => {
            setOpenForm({
              open: false,
              title: "",
              action: undefined,
            });
            setIdRestaurant(undefined);
          }}
        />
      )}
      <Dialog
        open={openDelete.open}
        title={openDelete.title}
        action="delete"
        handleContent={() =>
          idRestaurant && removeReastaurant.mutate(idRestaurant)
        }
        loading={removeReastaurant.isLoading}
        onClose={() =>
          setOpenDelete({
            open: false,
            title: "",
            action: "delete",
          })
        }
      >
        <Typography>Are you sure to delete this restaurant?</Typography>
      </Dialog>
    </Page>
  );
};
