import styled from "styled-components";

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
  .btn-add {
    height: calc(100% - 5px);
  }
  .MuiDataGrid-columnHeader {
    background: #f7f7f7;
  }
  .Mui-selected {
    background-color: none;
  }
`;
