import styled from "styled-components";

type UploadProps = {
  type?: "cover" | "normal";
  mode?: "gallery" | "basic";
  required?: boolean;
};

export const uploadDimension = (
  value?: string
): {
  height: string;
  width: string;
} => {
  switch (value) {
    case "cover":
      return {
        height: "120px",
        width: "180px",
      };
    default:
      return {
        height: "100px",
        width: "100px",
      };
  }
};

const uploadPosition = (
  value?: string
): {
  top: string;
  left: string;
} => {
  switch (value) {
    case "cover":
      return {
        top: "-60px",
        left: "-90px",
      };
    default:
      return {
        top: "-50px",
        left: "-50px",
      };
  }
};

export const Upload = styled.div<UploadProps>`
  display: grid;
  grid-template-columns: ${({ type, mode }) =>
    mode === "gallery"
      ? `repeat(auto-fill, ${uploadDimension(type).width})`
      : "none"};
  gap: 10px;
  .upload {
    position: relative;
    display: flex;
    width: ${({ type }) => uploadDimension(type).width};
    height: ${({ type }) => uploadDimension(type).height};
    align-items: center;
    justify-content: center;
    border: ${({ theme, required }) =>
      `1px solid ${
        required ? theme.palette.error.main : theme.palette.secondary.main
      }`};
    border-radius: 20px;
    cursor: pointer;
    .picture-preview {
      position: absolute;
      top: ${({ type }) => uploadPosition(type).top};
      left: ${({ type }) => uploadPosition(type).left};
      width: ${({ type }) => uploadDimension(type).width};
      height: ${({ type }) => uploadDimension(type).height};
      border-radius: 20px;
      /* object-fit: cover; */
      object-fit: contain;
      padding: 3px;
    }

    .btn-delete {
      position: absolute;
      top: 2px;
      right: 0;
      color: ${({ theme }) => theme.palette.error.main};
      z-index: 999;
    }
    .btn-upload {
      color: ${({ theme, required }) =>
        required ? theme.palette.error.main : theme.palette.secondary.main};
    }
  }
  input {
    display: none;
  }
  .disabled {
    pointer-events: none;
    opacity: 0.5;
    background: #e5edf7;
  }
  .error {
    color: ${({ theme }) => theme.palette.error.main};
    margin: 3px 0 0 15px;
  }
`;
