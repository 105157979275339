/* eslint-disable no-useless-concat */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { RouteComponentProps, Link, useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddIcon from "@mui/icons-material/Add";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { Button, Dialog } from "../../commons";

import { Categories, CardCartingMenu } from "../../components";
import { SnackbarContext } from "../../App";
import { serviceUnavailable, successfulOperation } from "../../utils/message";
import { StoreItemsService } from "../../services/openApi";

import { EditIcon, TrashIcon } from "../../img/icon";

import { Page, StyledReactSortable } from "./CartingMenu-styles";
import { debounce } from "lodash";

interface ItemType {
  id: number;
  name?: string;
  picture?: string;
  price?: string;
  cash_on_delivery?: boolean;
  status?: boolean;
  show_on_restaurant_manager?: boolean;
  required_location?: boolean;
}

type openFormState = {
  open: boolean;
  title: string;
  action: "delete" | "add" | "update" | undefined;
};
type openUpdateState = {
  open: boolean;
  title: string;
  action?: "delete" | "add" | "update" | undefined;
  type?: "show" | "showManager" | "accept" | "required" | "";
};

export const CartingMenu = (props: RouteComponentProps): JSX.Element => {
  const { id, idStore } = useParams<{ id?: string; idStore?: string }>();
  const {
    get,
    deleteItem,
    updateShowMenu,
    updateShowItem,
    updateCashOnDelivery,
    updateRequiredLocation,
    sortItems,
  } = StoreItemsService;
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElCreateMenu, setAnchorElCreateMenu] =
    React.useState<null | HTMLElement>(null);
  const [state, setState] = React.useState<ItemType[]>([]);
  const [openDelete, setOpenDelete] = React.useState<openFormState>({
    open: false,
    title: "",
    action: undefined,
  });
  const [idSubCategory, setIdSubCategory] = React.useState<number>(0);
  const [idItemMenu, setIdItemMenu] = React.useState<number | undefined>(
    undefined
  );
  const open = Boolean(anchorEl);

  const [openStatus, setOpenStatus] = React.useState<openUpdateState>({
    open: false,
    title: "",
    action: undefined,
    type: "",
  });
  const [show, setShow] = React.useState<boolean | undefined>(undefined);
  const [status, setStatus] = React.useState<boolean | undefined>(undefined);
  const [cash, setCash] = React.useState<boolean | undefined>(undefined);
  const [required, setRequired] = React.useState<boolean | undefined>(
    undefined
  );
  const [listSort, setListSort] = React.useState<Array<any>>([]);

  const listMenu = useQuery(["get", idSubCategory], () => get(idSubCategory), {
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
    enabled: Boolean(idSubCategory),
  });

  React.useEffect(() => {
    if (listMenu?.data) {
      const data = listMenu?.data;
      const arrayState: ItemType[] = [];
      data?.forEach((item: any) => {
        arrayState.push({
          id: item.id,
          name: item?.name || "",
          picture: item?.images?.[0]?.uri || "",
          price:
            item?.promoted_price?.toString() + " " + "QR" || 0 + " " + "QR",
          cash_on_delivery: item?.cash_on_delivery,
          show_on_restaurant_manager: item?.show_on_restaurant_manager,
          status: item?.show,
          required_location: item?.required_location,
        });
      });
      setState(arrayState);
    }
  }, [listMenu?.data]);

  const editShowItem = useMutation(
    () =>
      updateShowItem({
        item_id: idItemMenu,
        show_on_restaurant_manager: cash,
      }),
    {
      onSuccess: () => {
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        setOpenStatus({
          open: false,
          title: "",
          action: "update",
          type: "",
        });
        queryClient.fetchQuery(["get", idSubCategory]);
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );
  const editShowMenu = useMutation(
    () => updateShowMenu({ item_id: idItemMenu, status: status }),
    {
      onSuccess: () => {
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        setOpenStatus({
          open: false,
          title: "",
          action: "update",
          type: "",
        });
        queryClient.refetchQueries(["get", idSubCategory]);
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const editRequiredLocation = useMutation(
    () =>
      updateRequiredLocation({
        item_id: idItemMenu,
        required_location: required,
      }),
    {
      onSuccess: () => {
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        setOpenStatus({
          open: false,
          title: "",
          action: "update",
          type: "",
        });
        queryClient.refetchQueries(["get", idSubCategory]);
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const editCashOnDelivery = useMutation(
    () =>
      updateCashOnDelivery({
        item_id: idItemMenu,
        cash_on_delivery: show,
      }),
    {
      onSuccess: () => {
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        setOpenStatus({
          open: false,
          title: "",
          action: "update",
          type: "",
        });
        queryClient.refetchQueries(["get", idSubCategory]);
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const removeMenu = useMutation(() => deleteItem(idItemMenu!), {
    onSuccess: () => {
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
      setOpenDelete({
        open: false,
        title: "",
        action: "delete",
      });
      queryClient.refetchQueries(["get", idSubCategory]);
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const sortMenu = useMutation(
    () => sortItems({ sub_category_id: idSubCategory, items: listSort }),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["get", idSubCategory]);
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const handleClick =
    (id?: number) => (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
      id && setIdItemMenu(id);
    };

  const handleClickModeCreateMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElCreateMenu(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteMenu = () => {
    setOpenDelete({
      open: true,
      title: "Delete",
      action: "delete",
    });
    handleClose();
  };

  const handleContent = () => {
    if (openStatus.type === "show") editShowMenu.mutate();
    if (openStatus.type === "showManager") editShowItem.mutate();
    if (openStatus.type === "accept") editCashOnDelivery.mutate();
    if (openStatus.type === "required") editRequiredLocation.mutate();
  };

  const handleOrder = () => {
    const arraySortable: any[] = [];
    state?.forEach((item, index) => {
      arraySortable.push({
        id: item.id,
        sorting_index: index + 1,
      });
    });
    setListSort(arraySortable);
  };

  const sendOrder = React.useCallback(
    debounce(() => sortMenu.mutate(), 2000),
    []
  );
  return (
    <Page>
      <Categories
        onSelect={(id) => {
          setIdSubCategory(id);
          !state && setState([{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }]);
        }}
      />
      <div className="container-menu">
        <div className="header">
          <Typography variant="h2" className="title">
            Catring Menu
          </Typography>
          {/* <Link
            to={`/main/restaurants/${id}/stores/${idStore}/categorie-&-carting-menu/form-menu`}
          > */}
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClickModeCreateMenu}
            disableElevation
            endIcon={<KeyboardArrowDownIcon />}
          >
            Add Menu
          </Button>
          {/* </Link> */}
        </div>

        {state?.length === 0 ? (
          <Typography variant="body2" className="msg-empty">
            No data availble
          </Typography>
        ) : (
          <StyledReactSortable
            list={state}
            setList={setState}
            animation={600}
            delay={6}
            easing="cubic-bezier(0, 0.55, 0.45, 1)"
            className="scrolbar"
            scroll
            onSort={async () => {
              await handleOrder();
              sendOrder();
            }}
          >
            {state?.map((item) => (
              <CardCartingMenu
                key={item.id}
                picture={item.picture}
                name={item.name}
                price={item.price}
                loading={listMenu?.isLoading}
                cashOnDelivery={item.cash_on_delivery}
                status={item.status}
                showOnRestaurantManager={item.show_on_restaurant_manager}
                requiredLocation={item?.required_location}
                onCashActive={(event) => {
                  setOpenStatus({
                    open: true,
                    title: "Update Accept cash on delivry",
                    action: "update",
                    type: "accept",
                  });
                  setShow(event.target.checked);
                  setIdItemMenu(item?.id);
                }}
                onStatusActive={(event) => {
                  setOpenStatus({
                    open: true,
                    title: "Update Show",
                    action: "update",
                    type: "show",
                  });
                  setStatus(event.target.checked);
                  setIdItemMenu(item?.id);
                }}
                onShowActive={(event) => {
                  setOpenStatus({
                    open: true,
                    title: "Update Show on restaurent manager",
                    action: "update",
                    type: "showManager",
                  });
                  setCash(event.target.checked);
                  setIdItemMenu(item?.id);
                }}
                onRequiredLocation={(event) => {
                  setOpenStatus({
                    open: true,
                    title: "Update required location",
                    action: "update",
                    type: "required",
                  });
                  setIdItemMenu(item?.id);
                  setRequired(event.target.checked);
                }}
                onAction={handleClick(item?.id)}
              />
            ))}
          </StyledReactSortable>
        )}
      </div>

      <Dialog
        open={openStatus.open}
        title={openStatus.title}
        action="update"
        handleContent={handleContent}
        loading={
          editShowMenu.isLoading ||
          editShowItem.isLoading ||
          editCashOnDelivery.isLoading ||
          editRequiredLocation.isLoading
        }
        onClose={() =>
          setOpenStatus({
            open: false,
            title: "",
            action: "update",
            type: "",
          })
        }
      >
        <Typography>Are you sure to change status this menu?</Typography>
      </Dialog>
      <Menu
        anchorEl={anchorElCreateMenu}
        open={Boolean(anchorElCreateMenu)}
        onClose={() => setAnchorElCreateMenu(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        style={{
          marginTop: 5,
        }}
      >
        <MenuItem
          component={Link}
          to={`/main/restaurants/${id}/stores/${idStore}/categorie-&-carting-menu/form-menu`}
        >
          <ListItemIcon>
            <AddIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Add New Menu </ListItemText>
        </MenuItem>
        <MenuItem
          component={Link}
          to={`/main/restaurants/${id}/stores/${idStore}/categorie-&-carting-menu/form-menu-duplicate`}
        >
          <ListItemIcon>
            <FileCopyIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Duplicate Menu </ListItemText>
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={handleClose}
          component={Link}
          to={`/main/restaurants/${id}/stores/${idStore}/categorie-&-carting-menu/form-menu/${idItemMenu}`}
        >
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDeleteMenu}>
          <ListItemIcon>
            <TrashIcon />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
      <Dialog
        open={openDelete.open}
        title={openDelete.title}
        action="delete"
        handleContent={() => idItemMenu && removeMenu.mutate()}
        loading={removeMenu.isLoading}
        onClose={() =>
          setOpenDelete({
            open: false,
            title: "",
            action: "delete",
          })
        }
      >
        <Typography>Are you sure to delete this menu?</Typography>
      </Dialog>
    </Page>
  );
};
