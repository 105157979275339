/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class OrderCancellingReasonsService {

    /**
     * get reasons list
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getDashboardCancelReasonsList(): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/admins/settings/cancelReason/getDashboardCancelReasonsList`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * save a reason
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static saveReason(
        requestBody: {
            reason_en?: string;
            reason_ar?: string;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/settings/cancelReason/saveReason`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * update a reason
     * @param id ID of reason
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static updateReason(
        id: number,
        requestBody: {
            reason_en?: string;
            reason_ar?: string;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/settings/cancelReason/updateReason/${id}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * delete a reason
     * @param id ID of reason
     * @returns any Successful operation
     * @throws ApiError
     */
    public static deleteReason(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/api/admins/settings/cancelReason/delete/${id}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

}