/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Box from "@mui/material/Box";
import { useQuery, useQueryClient, useMutation } from "react-query";

import { Dialog, TextField } from "../../commons";

import { AreasService } from "../../services/openApi";

import { SnackbarContext } from "../../App";
import { successfulOperation, serviceUnavailable } from "../../utils/message";

import { AreaCardFormType } from "./AreaCardForm-type";

type AreaCardFormDialogProps = {
  idArea?: number;
  idRegion?: number;
  open: boolean;
  title: string;
  action?: "delete" | "add" | "update";
  onClose: () => void;
};

export const AreaCardFormDialog = ({
  idArea,
  idRegion,
  open,
  title,
  action,
  onClose,
}: AreaCardFormDialogProps) => {
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);
  const { saveArea, areaDetails, updateArea } = AreasService;

  const [formState, setFormState] = React.useState<AreaCardFormType>();

  React.useEffect(() => {
    if (idRegion) {
      setFormState({ ...formState, region_id: idRegion });
    }
  }, [idRegion]);

  const getAreaById = useQuery(
    ["areaDetails", idArea],
    () => areaDetails(idArea!),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
      enabled: !!idArea,
    }
  );

  React.useEffect(() => {
    if (getAreaById.data) {
      const data = getAreaById.data;
      setFormState({
        name_ar: data?.name_ar,
        name_en: data?.name_en,
        region_id: data?.region_id,
      });
    }
  }, [getAreaById.data]);

  const addArea = useMutation(saveArea, {
    onSuccess: () => {
      queryClient.refetchQueries(["areaList", idRegion]);
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
      onClose();
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const editArea = useMutation(() => updateArea(idArea!, { ...formState }), {
    onSuccess: () => {
      queryClient.refetchQueries(["areaList", idRegion]);
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
      onClose();
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    if (idArea) {
      editArea.mutate();
    } else {
      addArea.mutate({ ...formState });
    }
  };

  return (
    <Dialog
      open={open}
      title={title}
      maxWidth="sm"
      handleContent={handleSubmit}
      loading={addArea.isLoading || editArea.isLoading}
      loadingUseQuery={getAreaById.isLoading}
      onClose={onClose}
      action={action}
    >
      <Box display="flex" width={300} flexDirection="column" gap={2}>
        <TextField
          name="name_en"
          label="Area Name in english"
          value={formState?.name_en || ""}
          onChange={handleChange}
          color="secondary"
          fullWidth
        />
        <TextField
          name="name_ar"
          label="Area Name in Arabic"
          value={formState?.name_ar || ""}
          onChange={handleChange}
          color="secondary"
          fullWidth
        />
      </Box>
    </Dialog>
  );
};
