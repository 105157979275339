import * as React from "react";
import { useLocation } from "react-router-dom";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import Menu from "@mui/material/Menu";
import IconExpandLess from "@mui/icons-material/ExpandLess";
import IconExpandMore from "@mui/icons-material/ExpandMore";
import PropTypes from "prop-types";

import { AppMenuItemComponentStyled } from "./AppMenuItem-styled";

// TypeScript compile-time props type, infered from propTypes
// https://dev.to/busypeoples/notes-on-typescript-inferring-react-proptypes-1g88
type AppMenuItemPropTypes = PropTypes.InferProps<typeof AppMenuItemPropTypes>;
type AppMenuItemPropsWithoutItems = Omit<AppMenuItemPropTypes, "items">;

// Improve child items declaration
export type AppMenuItemProps = AppMenuItemPropsWithoutItems & {
  items?: AppMenuItemProps[];
  reduce?: boolean;
  chiled?: boolean;
};

export const AppMenuItem = ({
  name,
  link,
  Icon,
  items,
  reduce,
  chiled,
}: AppMenuItemProps): JSX.Element => {
  const location = useLocation();

  const isExpandable = items && items.length > 0;
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  React.useEffect(() => {
    setOpen(false);
  }, [reduce]);

  function handleClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    setOpen(!open);
    reduce && setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const MenuItemRoot = (
    <AppMenuItemComponentStyled
      link={link}
      onClick={handleClick}
      parentChiled={
        isExpandable &&
        location.pathname.includes(name.replaceAll(" ", "-").toLowerCase())
      }
      chiled={chiled}
    >
      {/* Display an icon if any */}
      {!!Icon && (
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
      )}
      {!reduce && <ListItemText primary={name} inset={!Icon} />}
      {/* Display the expand menu if the item has children */}
      {!reduce && (
        <>
          {isExpandable && !open && <IconExpandMore />}
          {isExpandable && open && <IconExpandLess />}
        </>
      )}
    </AppMenuItemComponentStyled>
  );

  const MenuItemChildren = isExpandable ? (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {items?.map((item, index) => (
          <div key={index} style={{ paddingLeft: 56, margin: "4px 0" }}>
            <AppMenuItem
              {...item}
              chiled={location.pathname.includes(
                item.name.replaceAll(" ", "-").toLowerCase()
              )}
            />
          </div>
        ))}
      </List>
    </Collapse>
  ) : null;

  return (
    <>
      {MenuItemRoot}
      {!reduce && MenuItemChildren}
      {reduce && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {items?.map((item, index) => (
            <AppMenuItem
              {...item}
              key={index}
              chiled={location.pathname.includes(
                item.name.replaceAll(" ", "-").toLowerCase()
              )}
            />
          ))}
        </Menu>
      )}
    </>
  );
};

// React runtime PropTypes
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppMenuItemPropTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string,
  Icon: PropTypes.elementType,
  items: PropTypes.array,
};
