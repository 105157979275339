import isEmpty from "validator/lib/isEmpty";
import equals from "validator/lib/equals";

import { passwordState } from "./index";
import { message } from "../../utils/message";

type Error = Partial<passwordState>;

export const validation = (values: passwordState): Error => {
  const errors: Error = {};

  Object.keys(values).forEach((key: string) => {
    switch (key) {
      case "password":
        if (isEmpty(values?.password || "")) {
          errors.password = message.resetPassword.newPassword;
        }
        break;
      case "confirmationPassword":
        if (isEmpty(values?.confirmationPassword || "")) {
          errors.confirmationPassword = message.resetPassword.confirmPassword;
        } else if (
          !equals(values?.password || "", values?.confirmationPassword || "")
        ) {
          errors.confirmationPassword =
            message.resetPassword.notConfirmPassword;
        }
        break;
      default:
        break;
    }
  });
  return errors;
};
