import * as React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import AnimatedNumbers from "react-animated-numbers";

import { LazyLoadingImage } from "../../commons";

import { CardStyled } from "./CradStatus-styles";

import {
  framePending,
  frameProcessing,
  frameReady,
  frameCompleted,
  frameCancelled,
} from "../../img/frame";

type CardStatusPorps = {
  status:
    | "Pending"
    | "New Order"
    | "Processing"
    | "Ready"
    | "Accepted"
    | "Completed"
    | "Cancelled";
  loading?: boolean;
  currency?: string;
  totalePrice?: string;
  count?: number;
  selected?: boolean;
  onClick?: () => void;
};

export const CardStatus = React.memo(
  ({
    status,
    loading,
    currency,
    totalePrice,
    count,
    selected,
    onClick,
  }: CardStatusPorps): JSX.Element => {
    const handleFrame = (value: string): string | undefined => {
      switch (value) {
        case "usersCount":
          return framePending;
        case "Pending":
          return framePending;
        case "New Order":
          return framePending;
        case "Processing":
          return frameProcessing;
        case "unblockedCount":
          return frameProcessing;
        case "Completed":
          return frameCompleted;
        case "activeCount":
          return frameCompleted;
        case "Ready":
          return frameReady;
        case "Accepted":
          return frameReady;
        case "Cancelled":
          return frameCancelled;
        case "blockedCount":
          return frameCancelled;
      }
    };

    return (
      <CardStyled onClick={onClick} selected={selected}>
        {loading ? (
          <Skeleton
            variant="rectangular"
            component={Box}
            display="flex"
            flex={1}
            height={100}
            borderRadius="20px"
          />
        ) : (
          <>
            <LazyLoadingImage
              alt="carting Menu"
              src={handleFrame(status)}
              width="100%"
              height={100}
              className="zoom"
            />

            <div className="details">
              <Typography variant="h2" className="title">
                {currency} &nbsp;&nbsp;
                {totalePrice}
              </Typography>

              <Typography variant="subtitle1" className="status">
                {count && (
                  <AnimatedNumbers animateToNumber={count} key={status} />
                )}
                &nbsp; &nbsp;
                {status}
              </Typography>
            </div>
          </>
        )}
      </CardStyled>
    );
  }
);
