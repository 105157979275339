import * as React from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TimePicker from "@mui/lab/TimePicker";
// import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useQuery, useMutation, useQueryClient } from "react-query";
import moment from "moment";

import {
  Button,
  Dialog,
  Select,
  LoadingButton,
  TextField,
} from "../../../commons";
import Table, { ColumnsProps } from "../../../commons/Table";
import { WorkingHoursDialog } from "../../../components";
import { SnackbarContext } from "../../../App";
import {
  serviceUnavailable,
  successfulOperation,
} from "../../../utils/message";

import { StoreWorkingHoursService } from "../../../services/openApi";

import { EditIcon, TrashIcon, SaveIcon } from "../../../img/icon";

import { WorkingHoursType } from "./ManagementWorkingHours-type";
import { Page } from "./ManagementWorkingHours-style";

type DialogState = {
  open: boolean;
  title: string;
  action?: "delete" | "add" | "update";
};

const listDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const ManagementWorkingHours = (): JSX.Element => {
  const { idStore } = useParams<{ idStore?: string }>();
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);
  const {
    getHoursByStoreId,
    saveWorkingHours,
    updateWorkingHours,
    deleteWorkingHours,
  } = StoreWorkingHoursService;

  const [listState, setListState] = React.useState<WorkingHoursType[]>([]);
  const [openDelete, setOpenDelete] = React.useState<DialogState>({
    open: false,
    title: "",
  });
  const [id, setId] = React.useState<number | undefined>(undefined);
  const [position, setPosition] = React.useState<number>(0);
  const [openModalApplyAll, setOpenModalApplyAll] = React.useState<DialogState>(
    {
      open: false,
      title: "",
    }
  );

  const getLisyHours = useQuery(
    ["getHoursByStoreId", idStore],
    () => getHoursByStoreId(+idStore!),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
      enabled: !!idStore,
    }
  );

  React.useEffect(() => {
    const data = getLisyHours?.data;
    if (data) {
      const arrayHours: WorkingHoursType[] = [];
      data?.storeHours?.forEach((item: any) =>
        arrayHours.push({
          id: item?.id,
          week_day: item?.week_day,
          start_time: item?.start_time.substr(0, 5),
          end_time: item?.end_time.substr(0, 5),
          change: false,
        })
      );
      setListState(arrayHours);
    }
  }, [getLisyHours?.data]);

  const addHoure = useMutation(
    "getHoursByStoreId",
    () =>
      saveWorkingHours(+idStore!, {
        week_day: listState[position].week_day,
        start_time: listState[position].start_time || "",
        end_time: listState[position].end_time || "",
      }),
    {
      onSuccess: () => {
        queryClient.fetchQuery(["getHoursByStoreId", idStore]);
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        setOpenDelete({
          open: false,
          title: "",
          action: "delete",
        });
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const editHoure = useMutation(
    "updateWorkingHours",
    () =>
      updateWorkingHours(+id!, {
        week_day: listState[position].week_day || "",
        start_time: listState[position].start_time || "",
        end_time: listState[position].end_time || "",
      }),
    {
      onSuccess: () => {
        queryClient.fetchQuery(["getHoursByStoreId", idStore]);
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        setId(undefined);
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const removeHoure = useMutation(deleteWorkingHours, {
    onSuccess: () => {
      queryClient.fetchQuery(["getHoursByStoreId", idStore]);
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
      setOpenDelete({
        open: false,
        title: "",
        action: "delete",
      });
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const columns: ColumnsProps = [
    {
      header: "Sr No",
      accessor: "srNo",
      cellProps: { width: "80px" },
    },
    {
      header: "Day",
      accessor: "day",
      cellProps: { width: "20%" },
    },
    {
      header: "Shift Start",
      accessor: "shiftStart",
      cellProps: { width: "20%" },
    },
    {
      header: "Shift End",
      accessor: "shiftEnd",
      cellProps: { width: "20%" },
    },
    {
      header: "Actions",
      accessor: "actions",
      headerCellProps: { align: "center" },
      cellProps: { align: "center", width: "150px" },
    },
  ];

  const handleChange =
    (item: WorkingHoursType, prop: "start_time" | "end_time" | "week_day") =>
    (event: any) => {
      const newListState: Array<WorkingHoursType> = JSON.parse(
        JSON.stringify(listState)
      );
      const target = newListState.find((o) => o.id === item.id);
      if (target) {
        if (prop === "week_day") {
          target[prop] = event?.target?.value as string;
        } else {
          target[prop] = moment(event).format("HH:mm") as string;
        }
      }
      setListState(newListState);
    };

  const renderTableRows = () => {
    return (
      listState?.map((item, index) => ({
        srNo: item.id,
        day: item?.change ? (
          <Select
            name="week_day"
            value={item?.week_day || ""}
            color="secondary"
            onChange={handleChange(item, "week_day")}
          >
            {listDays?.map((item, index) => (
              <MenuItem value={item} key={index}>
                {item}
              </MenuItem>
            ))}
          </Select>
        ) : (
          item?.week_day
        ),
        shiftStart: item?.change ? (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              value={
                item?.start_time
                  ? new Date(`December 17, 1995 ${item?.start_time}`)
                  : null
              }
              onChange={() => {}}
              renderInput={(params) => (
                <TextField {...params} color="secondary" />
              )}
              ampm={false}
              onAccept={handleChange(item, "start_time")}
              mask="__:__"
              inputFormat="HH:mm"
            />
          </LocalizationProvider>
        ) : (
          item?.start_time
        ),
        shiftEnd: item?.change ? (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              value={
                item?.end_time
                  ? new Date(`December 17, 1995 ${item?.end_time}`)
                  : null
              }
              onChange={() => {}}
              renderInput={(params) => (
                <TextField {...params} color="secondary" />
              )}
              ampm={false}
              onAccept={handleChange(item, "end_time")}
            />
          </LocalizationProvider>
        ) : (
          item?.end_time
        ),
        actions: (
          <Box display="flex" justifyContent="center" gap={1}>
            {item.change ? (
              <LoadingButton
                variant="contained"
                className="btn-edit change"
                onClick={(e) => {
                  e.stopPropagation();
                  handleSave(index);
                }}
                loading={addHoure?.isLoading || editHoure?.isLoading}
                disabled={!item.week_day || !item.start_time || !item.end_time}
              >
                Save
                <SaveIcon fontSize="small" />
              </LoadingButton>
            ) : (
              <Button
                variant="contained"
                className="btn-edit"
                onClick={(e) => {
                  e.stopPropagation();
                  handleEdit(index);
                  setId(item?.id);
                  setPosition(index);
                }}
                disabled={listState.find((o) => o.change)?.change}
              >
                Edit
                <EditIcon />
              </Button>
            )}
            {item?.change ? (
              <Button
                variant="contained"
                className="btn-delete change"
                onClick={(e) => {
                  e.stopPropagation();
                  handleCancel(index);
                }}
              >
                Cancel <CloseIcon fontSize="small" />
              </Button>
            ) : (
              <Button
                variant="contained"
                className="btn-delete"
                onClick={(e) => {
                  e.stopPropagation();
                  setId(item.id);
                  setOpenDelete({
                    open: true,
                    title: "Delete",
                    action: "delete",
                  });
                }}
              >
                Delete <TrashIcon />
              </Button>
            )}
          </Box>
        ),
      })) || []
    );
  };

  // const handleAddItem = () => {
  //   setListState((prevListState) => {
  //     const listArray = [...(prevListState || [])];
  //     listArray.push({
  //       id: listArray.length + 1,
  //       week_day: undefined,
  //       start_time: undefined,
  //       end_time: undefined,
  //       change: true,
  //       new: true,
  //     });

  //     return listArray;
  //   });
  //   setPosition(listState.length);
  // };

  const handleCancel = (index: number) => {
    const data = getLisyHours?.data?.storeHours;
    setListState((prevListState) => {
      const listArray = [...(prevListState || [])];
      if (listArray[index]?.new) {
        listArray.splice(index, 1);
      } else {
        listArray[index] = {
          ...listArray[index],
          week_day: data?.[index]?.week_day,
          start_time: data?.[index]?.start_time?.substr(0, 5),
          end_time: data?.[index]?.end_time?.substr(0, 5),
          change: false,
        };
      }
      return listArray;
    });
  };

  const handleEdit = (index: number) => {
    setListState((prevListState) => {
      const listArray = [...(prevListState || [])];
      listArray[index].change = true;
      return listArray;
    });
  };

  const handleSave = async (index: number) => {
    await setListState((preListState) => {
      const arrayState = [...(preListState || [])];
      arrayState[index] = {
        ...arrayState[index],
        change: false,
        new: false,
      };
      return arrayState;
    });
    if (id) {
      editHoure.mutate();
    } else {
      addHoure?.mutate();
    }
  };

  const handleOpenModalWorkingHours = () => {
    setOpenModalApplyAll({
      open: true,
      title: "Apply All",
      action: "update",
    });
  };

  const handleCloseModalWorkingHours = () => {
    setOpenModalApplyAll({
      open: false,
      title: "",
    });
  };

  return (
    <Page>
      <div className="page-action">
        <Button
          variant="contained"
          color="secondary"
          onClick={handleOpenModalWorkingHours}
          disabled={
            listState?.find((o) => o.change)?.change || getLisyHours?.isLoading
          }
        >
          Apply all
        </Button>
      </div>
      <Box display="flex" flexDirection="column" flex="1" marginTop={3} gap={1}>
        <Table
          columns={columns}
          loading={getLisyHours?.isLoading}
          nbrRowLoading={5}
          data={renderTableRows()}
          emptyMessage="No data available"
        />
        {/* <Button
          onClick={handleAddItem}
          color="secondary"
          disabled={
            listState?.find((o) => o.change)?.change || getLisyHours?.isLoading
          }
          style={{ width: 120 }}
        >
          <AddCircleOutlineIcon sx={{ marginRight: 1 }} /> Add New
        </Button> */}
      </Box>
      {openModalApplyAll.open && (
        <WorkingHoursDialog
          idStore={idStore}
          open={openModalApplyAll.open}
          title={openModalApplyAll.title}
          action={openModalApplyAll.action}
          onClose={handleCloseModalWorkingHours}
        />
      )}
      <Dialog
        open={openDelete.open}
        title={openDelete.title}
        action="delete"
        handleContent={() => id && removeHoure.mutate(id)}
        loading={removeHoure.isLoading}
        onClose={() => {
          setOpenDelete({
            open: false,
            title: "",
            action: "delete",
          });
          setId(undefined);
        }}
      >
        <Typography>Are you sure to delete Sr No {id}?</Typography>
      </Dialog>
    </Page>
  );
};
