import styled from "styled-components";

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 24px;
  .basic-details {
    display: flex;
    min-width: 800px;
    flex-direction: column;
    background: #fff;
    padding: 20px;
    border: 1px solid #dddddd;
    border-radius: 20px;
  }
  th:first-child {
    border-left: 0;
    border-top-left-radius: 0;
  }
  th:last-child {
    border-right: 0;
    border-top-right-radius: 0;
  }
  .last-linge {
    &:first-child {
      border-left: 0;
      border-bottom-left-radius: 0;
    }
    &:last-child {
      border-right: 0;
      border-bottom-right-radius: 0;
    }
  }
  .btn-view {
    height: 35px;
    background-color: #fcead4;
    color: #ec8000;
    svg {
      margin-left: 10px;
    }
    box-shadow: none;
  }
  .btn-edit {
    height: 35px;
    background-color: #ecf4df;
    color: #75bd00;
    svg {
      margin-left: 10px;
    }
    box-shadow: none;
  }
  .btn-delete {
    height: 35px;
    background-color: #fdf4f7;
    color: #b93e5c;
    svg {
      margin-left: 10px;
    }
    box-shadow: none;
  }
`;
