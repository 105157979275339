/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class RoleRestaurantStoreUsersService {

    /**
     * Save store user
     * @param formData
     * @returns any Successful operation
     * @throws ApiError
     */
    public static saveUser(
        formData?: {
            name?: string;
            email?: string;
            mobile?: string;
            imagefile?: Blob;
            password?: string;
            role?: string;
            store_id?: number;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/restaurant/storeUser/save`,
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * update store user
     * @param id ID of store user
     * @param formData
     * @returns any Successful operation
     * @throws ApiError
     */
    public static updateUser(
        id: number,
        formData?: {
            name?: string;
            email?: string;
            mobile?: string;
            imagefile?: Blob;
            password?: string;
            role?: string;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/restaurant/storeUser/updateStoreUser/${id}`,
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * update store user status
     * @param id ID of store user
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static updateUserStatus(
        id: number,
        requestBody: {
            status?: boolean;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/restaurant/storeUser/updateStoreUserStatus/${id}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * get store user list
     * @param id ID of store
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getStoreUsers(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/restaurant/storeUser/getUsersByStoreId/${id}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * get store user details
     * @param id ID of store user
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getUserDetails(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/restaurant/storeUser/getUserDetails/${id}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * get data to create store user
     * @returns any Successful operation
     * @throws ApiError
     */
    public static createStoreUser(): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/restaurant/storeUser/createStoreUser`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * delete store user list
     * @param id ID of store
     * @returns any Successful operation
     * @throws ApiError
     */
    public static deleteStoreUsers(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/api/restaurant/storeUser/delete/${id}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

}