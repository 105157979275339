import styled from "styled-components";

export const Page = styled.div`
  display: grid;
  grid-template-rows: 100px auto 1fr;
  gap: 30px;
  padding: 20px;
  .title-box-1 {
    font-weight: 600;
    color: ${({ theme }) => theme.palette.secondary.main};
  }
  .title-box-2 {
    font-weight: 600;
    color: #75bd00;
  }
  .title-box-3 {
    font-weight: 600;
    color: #b93e5c;
  }
  .btn-edit {
    height: 35px;
    background-color: #ecf4df;
    color: #75bd00;
    svg {
      margin-left: 10px;
    }
    box-shadow: none;
  }
  .btn-delete {
    height: 35px;
    background-color: #fdf4f7;
    color: #b93e5c;
    svg {
      margin-left: 10px;
    }
    box-shadow: none;
  }
`;
