/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Skeleton from "@mui/material/Skeleton";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { TextField, Dialog } from "../../commons";

import { SnackbarContext } from "../../App";
import { serviceUnavailable, successfulOperation } from "../../utils/message";
import { FilterCollectionsService } from "../../services/openApi";

import { CollectionFormType } from "./CollectionFormDialog";

type CollectionFormDialogProps = {
  idCollection?: number;
  open: boolean;
  title: string;
  action?: "delete" | "add" | "update";
  onClose: () => void;
};

export const CollectionFormDialog = ({
  idCollection,
  open,
  title,
  action,
  onClose,
}: CollectionFormDialogProps): JSX.Element => {
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);
  const {
    getFilterCollectionDetails,
    saveFilterCollection,
    updateFilterCollection,
    getAllStoresList,
    getItemListByStoresId,
  } = FilterCollectionsService;

  const [formState, setFormState] = React.useState<CollectionFormType>({
    type: "Store",
  });

  const listStores = useQuery(
    ["getAllStoresList", idCollection],
    () => getAllStoresList(),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const listMenu = useQuery(
    ["getItemListByStoresId", idCollection],
    () => getItemListByStoresId({ stores_id: formState.stores }),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
      enabled:
        ((formState?.stores || [])?.length || 0) > 0 &&
        formState.type === "Item",
    }
  );

  const collectionDetails = useQuery(
    ["getFilterCollectionDetails", idCollection],
    () => getFilterCollectionDetails({ filter_collection_id: idCollection }),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
      enabled: !!idCollection,
    }
  );

  const listStoreFilter =
    formState?.stores?.map((id) =>
      listStores?.data?.find((item: any) => item?.id === id)
    ) || [];

  const listItemFilter =
    formState?.items?.map((id) =>
      listMenu?.data?.find((item: any) => item?.id === id)
    ) || [];

  React.useEffect(() => {
    if (collectionDetails?.data) {
      const storesId =
        collectionDetails?.data?.[0]?.collection_stores?.map(
          (item: any) => item?.id
        ) || [];
      const menusId =
        collectionDetails?.data?.[0]?.collection_items?.map(
          (item: any) => item?.id
        ) || [];
      setFormState({
        ...formState,
        filter_collection_id: idCollection,
        name_en:
          collectionDetails?.data?.[0]?.collection_translations?.[0]?.name,
        name_ar:
          collectionDetails?.data?.[0]?.collection_translations?.[1]?.name,
        type: collectionDetails?.data?.[0]?.type,
        stores: storesId,
        items: menusId,
      });
    }
  }, [collectionDetails?.data, idCollection]);

  const saveCollection = useMutation(() => saveFilterCollection(formState), {
    onSuccess: () => {
      queryClient.refetchQueries("getFilterCollections");
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
      onClose();
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const updateCollection = useMutation(
    () => updateFilterCollection(formState),
    {
      onSuccess: () => {
        queryClient.refetchQueries("getFilterCollections");
        queryClient.refetchQueries([
          "getFilterCollectionDetails",
          idCollection,
        ]);
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        onClose();
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleChangeOption = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({
      ...formState,
      type: (event.target as HTMLInputElement)?.value,
    });
  };

  const handleChangeAutoCompleteStore = (value: any) => {
    let arrayIds = [...(formState?.stores || [])];

    if (formState?.type === "Store") {
      arrayIds = value?.map((item: any) => item?.id);
    } else {
      arrayIds.push(value?.id);
    }
    setFormState({
      ...formState,
      stores: arrayIds,
    });
  };

  const handleChangeAutoCompleteItem = (value: any) => {
    let arrayIds = [...(formState?.items || [])];
    arrayIds = value?.map((item: any) => item?.id);
    setFormState({
      ...formState,
      items: arrayIds,
    });
  };

  const handleSave = () => {
    if (idCollection) {
      updateCollection.mutate();
    } else {
      saveCollection.mutate();
    }
  };

  return (
    <Dialog
      open={open}
      title={title}
      maxWidth="sm"
      handleContent={handleSave}
      loading={saveCollection.isLoading || updateCollection?.isLoading}
      loadingUseQuery={listStores?.isLoading || listMenu?.isLoading}
      onClose={onClose}
      action={action}
    >
      <Box display="flex" width={450} flexDirection="column">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {collectionDetails?.isLoading ||
            listStores?.isLoading ||
            listMenu?.isLoading ? (
              <Skeleton
                variant="rectangular"
                width="100%"
                height={46}
                sx={{ borderRadius: "30px" }}
              />
            ) : (
              <TextField
                name="name_en"
                label="Name Collection (En)"
                value={formState?.name_en || ""}
                onChange={handleChange}
                color="secondary"
                fullWidth
              />
            )}
          </Grid>
          <Grid item xs={6}>
            {collectionDetails?.isLoading ||
            listStores?.isLoading ||
            listMenu?.isLoading ? (
              <Skeleton
                variant="rectangular"
                width="100%"
                height={46}
                sx={{ borderRadius: "30px" }}
              />
            ) : (
              <TextField
                name="name_ar"
                label="Name Collection (Ar)"
                value={formState?.name_ar || ""}
                onChange={handleChange}
                color="secondary"
                fullWidth
              />
            )}
          </Grid>
        </Grid>

        {collectionDetails?.isLoading ||
        listStores?.isLoading ||
        listMenu?.isLoading ? (
          <Box
            display="grid"
            gridTemplateColumns="22px 45px 22px 45px"
            gap={1}
            mt={2}
            mb={1}
          >
            <Skeleton variant="circular" width={22} height={22} />
            <Skeleton variant="text" />
            <Skeleton variant="circular" width={22} height={22} />
            <Skeleton variant="text" />
          </Box>
        ) : (
          <RadioGroup
            row
            aria-label="gender"
            name="row-radio-buttons-group"
            value={formState.type}
            onChange={handleChangeOption}
            style={{ marginTop: 10 }}
          >
            <FormControlLabel
              value="Item"
              control={<Radio color="secondary" />}
              label="Menu"
            />
            <FormControlLabel
              value="Store"
              control={<Radio color="secondary" />}
              label="Store"
            />
          </RadioGroup>
        )}

        {collectionDetails?.isLoading ||
        listStores?.isLoading ||
        listMenu?.isLoading ? (
          <Skeleton
            variant="rectangular"
            width="100%"
            height={46}
            sx={{ borderRadius: "30px", marginTop: "10px" }}
          />
        ) : (
          <>
            {formState?.type === "Store" && (
              <Autocomplete
                multiple
                options={listStores.data || []}
                getOptionLabel={(option: any) => option?.name || ""}
                value={listStoreFilter}
                isOptionEqualToValue={(option, value) => option === value}
                onChange={(_, value) =>
                  value && handleChangeAutoCompleteStore(value)
                }
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="List of stores"
                    color="secondary"
                  />
                )}
                style={{ marginTop: 10 }}
              />
            )}
            {formState?.type === "Item" && (
              <Autocomplete
                options={listStores.data || []}
                getOptionLabel={(option: any) => option?.name || ""}
                value={listStoreFilter?.[0]}
                isOptionEqualToValue={(option, value) => option === value}
                onChange={(_, value) =>
                  value && handleChangeAutoCompleteStore(value)
                }
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="List of stores"
                    color="secondary"
                  />
                )}
                style={{ marginTop: 10 }}
              />
            )}
          </>
        )}

        {collectionDetails?.isLoading ||
        listStores?.isLoading ||
        listMenu?.isLoading ? (
          <Skeleton
            variant="rectangular"
            width="100%"
            height={46}
            sx={{ borderRadius: "30px", marginTop: "20px" }}
          />
        ) : (
          <>
            {formState.type === "Item" && (
              <Autocomplete
                multiple
                options={listMenu?.data || []}
                getOptionLabel={(option: any) => option?.name || ""}
                value={listItemFilter}
                onChange={(_, value) =>
                  value && handleChangeAutoCompleteItem(value)
                }
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    label="List of items"
                    {...params}
                    color="secondary"
                  />
                )}
                style={{ marginTop: 20 }}
              />
            )}
          </>
        )}
      </Box>
    </Dialog>
  );
};
