import TablePaginationMUI from "@mui/material/TablePagination";
import { withStyles, createStyles } from "@mui/styles";

export const TablePagination = withStyles(() =>
  createStyles({
    root: {
      padding: 0,
      border: "none",
    },
    toolbar: {
      padding: 0,
    },
  })
)(TablePaginationMUI);
