/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class RegionsService {

    /**
     * get region list
     * @returns any Successful operation
     * @throws ApiError
     */
    public static regionList(): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/admins/restaurant/region/RegionList`,
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * get region details
     * @param id ID of region
     * @returns any Successful operation
     * @throws ApiError
     */
    public static regiondetails(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/admins/restaurant/region/Regiondetails/${id}`,
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * list of regions for creation
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getRegionsWithAreas(): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/admins/restaurant/region/getRegionsWithAreas`,
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * save a region
     * @param formData
     * @returns any Successful operation
     * @throws ApiError
     */
    public static saveRegion(
        formData: {
            name_en?: string;
            name_ar?: string;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/restaurant/region/saveRegion`,
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * update a region
     * @param id ID of region
     * @param formData
     * @returns any Successful operation
     * @throws ApiError
     */
    public static updateRegion(
        id: number,
        formData: {
            name_en?: string;
            name_ar?: string;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/restaurant/region/updateRegion/${id}`,
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * delete a region
     * @param id ID of region
     * @returns any Successful operation
     * @throws ApiError
     */
    public static deleteRegion(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/api/admins/restaurant/region/deleteRegion/${id}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

}