import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";

import { TextField, Dialog, Select } from "../../commons";

type RolesPermissionFormDialogProps = {
  open: boolean;
  title: string;
  action: "delete" | "add" | "update" | undefined;
  onClose: () => void;
};

const handleSubmit = () => {};

export const RolesPermissionFormDialog = ({
  open,
  title,
  action,
  onClose,
}: RolesPermissionFormDialogProps): JSX.Element => {
  return (
    <Dialog
      open={open}
      title={title}
      maxWidth="sm"
      onClose={onClose}
      handleContent={handleSubmit}
      action={action}
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <>
          <TextField name="name" label="User Name" color="secondary" />
          <TextField name="email" label="E-Mail" color="secondary" />
          <TextField
            name="phone"
            type="number"
            label="Phone Number"
            color="secondary"
          />
          <TextField
            name="password"
            type="password"
            label="Password"
            color="secondary"
          />
          <Select
            name="user_role"
            color="secondary"
            label="Please select user role"
          >
            <MenuItem value={"Super Admin"} key={0}>
              Super Admin
            </MenuItem>
            <MenuItem value={"Moderator"} key={1}>
              Moderator
            </MenuItem>
          </Select>
        </>
      </Box>
    </Dialog>
  );
};
