import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import { useQuery, useQueryClient, useMutation } from "react-query";

import { Button, Dialog } from "../../commons";
import { TableRow, AreaCardFormDialog } from "..";

import { AreasService } from "../../services/openApi";
import { successfulOperation, serviceUnavailable } from "../../utils/message";
import { SnackbarContext } from "../../App";

import { Container } from "./AreaCard-style";

type AreaCardProps = {
  idRegion?: number;
};

interface ItemType {
  id: number;
  idItem: number;
  nameEn: string;
  nameAr: string;
}

type openFormState = {
  open: boolean;
  title: string;
  action?: "delete" | "add" | "update";
};

export const AreaCard = ({ idRegion }: AreaCardProps): JSX.Element => {
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);
  const { getAreaByRegionId, deleteArea } = AreasService;

  const [listState, setListState] = React.useState<ItemType[]>([]);
  const [openModal, setOpenModal] = React.useState<openFormState>({
    open: false,
    title: "",
  });
  const [idArea, setIdArea] = React.useState<number | undefined>();

  const getListArea = useQuery(
    ["areaList", idRegion],
    () => getAreaByRegionId(idRegion!),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
      enabled: !!idRegion,
    }
  );

  const removeArea = useMutation(deleteArea, {
    onSuccess: () => {
      queryClient.refetchQueries(["areaList", idRegion]);
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
      setOpenModal({
        open: false,
        title: "",
      });
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  React.useEffect(() => {
    if (getListArea?.data) {
      const data = getListArea?.data?.areas;
      const arrayList: ItemType[] = [];
      data?.forEach((item: any, index: number) =>
        arrayList.push({
          id: index + 1,
          idItem: item?.id,
          nameEn: item?.area_translations?.[0]?.name,
          nameAr: item?.area_translations?.[1]?.name,
        })
      );
      setListState(arrayList);
    }
  }, [getListArea.data]);

  return (
    <Container>
      <div className="header">
        <Typography variant="h2" className="title">
          Area
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={() =>
            setOpenModal({
              open: true,
              title: "Add Area",
              action: "add",
            })
          }
        >
          Add Area
        </Button>
      </div>
      {getListArea?.isLoading && <LinearProgress color="secondary" />}
      <Box p={2} className="list-areas scrolbar">
        <TableRow index={-1} key={0} data={listState[0]} region />
        {listState?.map((item, index) => (
          <TableRow
            index={index}
            key={item?.id}
            data={item}
            onDelete={(id, type) => {
              setIdArea(id);
              setOpenModal({
                open: true,
                title: "Delete",
                action: "delete",
              });
            }}
            onEdit={(id, type) => {
              setIdArea(id);
              setOpenModal({
                open: true,
                title: "Edit Area",
                action: "update",
              });
            }}
            region
          />
        ))}
        {listState.length === 0 && (
          <Typography
            variant="body2"
            align="center"
            sx={{ margin: "15px auto" }}
          >
            No data available
          </Typography>
        )}
      </Box>
      {(openModal.action === "add" || openModal.action === "update") && (
        <AreaCardFormDialog
          idArea={idArea}
          idRegion={idRegion}
          open={openModal.open}
          title={openModal.title}
          action={openModal.action}
          onClose={() => {
            setOpenModal({
              open: false,
              title: "",
            });
            setIdArea(undefined);
          }}
        />
      )}
      {openModal.action === "delete" && (
        <Dialog
          open={openModal.open}
          title={openModal.title}
          action="delete"
          handleContent={() => idArea && removeArea.mutate(idArea)}
          loading={removeArea.isLoading}
          onClose={() => {
            setOpenModal({
              open: false,
              title: "",
            });
            setIdArea(undefined);
          }}
        >
          <Typography>Are you sure to delete this area?</Typography>
        </Dialog>
      )}
    </Container>
  );
};
