import styled from "styled-components";

export const Page = styled.div`
  padding: 20px;
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
    .MuiAutocomplete-input {
    padding: 4px;
  }
`;

export const BoxPanel = styled.div`
  /* border-radius: 0px 20px 20px 20px; */
`;
