/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class OtherChargesService {

    /**
     * get other charges
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getOtherChargesList(
        requestBody?: {
            restaurant_store_id?: number;
            start_date?: string;
            end_date?: string;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/settings/otherCharges/getOtherChargesList`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * save other charges
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static saveOtherCharges(
        requestBody?: {
            restaurant_store_id?: number;
            description?: string;
            reference_date?: string;
            order_id?: number;
            balance?: number;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/settings/otherCharges/saveOtherCharges`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Order ids by store
     * @param id ID of store
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getOtherChargesOrdersByStoreId(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/admins/settings/otherCharges/getOtherChargesOrdersByStoreId/${id}`,
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

}