import * as React from "react";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { useQuery } from "react-query";
import moment from "moment";

import { Input, Status } from "../../../../commons";

import Table, { ColumnsProps } from "../../../../commons/Table";

import { SnackbarContext } from "../../../../App";
import { serviceUnavailable } from "../../../../utils/message";

import { RoleRestaurantOrdersService } from "../../../../services/openApi";

import { Page } from "./ReportStore-style";

export const ReportRestaurant = (): JSX.Element => {
  const { setSnack } = React.useContext(SnackbarContext);
  const { getOrdersList } = RoleRestaurantOrdersService;

  const [search, setSearch] = React.useState<string>("");
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);

  const OrdersList = useQuery(
    ["getOrdersListRestaurant", page, rowsPerPage],
    () => getOrdersList(rowsPerPage, page, { search }),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const orders = OrdersList?.data?.orders?.data;

  const columns: ColumnsProps = [
    {
      header: "Order ID",
      accessor: "orderID",
    },
    {
      header: "Store Name",
      accessor: "storeName",
    },
    {
      header: "Delivery Date",
      accessor: "deliveryDate",
    },
    {
      header: "Delivery Area",
      accessor: "deliveryArea",
    },
    {
      header: "Order Total",
      accessor: "orderTotal",
    },
    {
      header: "Payment Method",
      accessor: "paymentMethod",
    },
    {
      header: "Ammount Received",
      accessor: "ammountReceived",
    },
    {
      header: "Order Status",
      accessor: "orderStatus",
    },
  ];

  const renderTableRows = () => {
    return (
      orders?.map((item: any) => ({
        orderID: item?.id,
        storeName: item?.store?.store_translation?.name,
        deliveryDate:
          item?.picked_time &&
          moment(item?.picked_time).format("DD MMM YYYY hh:mm a"),
        deliveryArea: item?.delivery_area,
        orderTotal:
          (item?.order_total_price?.toLocaleString("en-US") || 0) + " QAR",
        paymentMethod: item?.payment_mode,
        ammountReceived:
          (item?.payed_amount?.toLocaleString("en-US") || 0) + " QAR",
        orderStatus: (
          <Status
            status={item?.order_current_status?.order_status_translation?.display_status?.toUpperCase()}
          />
        ),
      })) || []
    );
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  return (
    <Page className="scrolbar">
      <Input
        value={search}
        placeholder="Search"
        onChange={handleSearch}
        onKeyPress={(e) => e.key === "Enter" && OrdersList.refetch()}
        startAdornment={
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        }
      />
      <Box sx={{ width: "100%" }}>
        <Table
          columns={columns}
          loading={OrdersList?.isLoading || OrdersList.isRefetching}
          nbrRowLoading={rowsPerPage}
          data={renderTableRows()}
          emptyMessage="No data available"
          tablePaginationProps={{
            rowsPerPageOptions: [5, 10, 25],
            count: OrdersList?.data?.orders?.total || 0,
            page: (OrdersList?.data?.orders?.total || 0) <= 0 ? 0 : page - 1,
            onPageChange: (_, newPage) => setPage(newPage + 1),
            rowsPerPage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
        />
      </Box>
    </Page>
  );
};
