import * as React from "react";
import Box from "@mui/material/Box";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { Dialog, TextField } from "../../commons";
import { RegionsService } from "../../services/openApi";

import { SnackbarContext } from "../../App";
import { successfulOperation, serviceUnavailable } from "../../utils/message";
import { RegionsCardFormType } from "./RegionsCardFormDialog-type";

type RegionsCardFormDialogProps = {
  idRegion?: number;
  open: boolean;
  title: string;
  action?: "delete" | "add" | "update";
  onClose: () => void;
};

export const RegionsCardFormDialog = ({
  idRegion,
  open,
  title,
  action,
  onClose,
}: RegionsCardFormDialogProps): JSX.Element => {
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);
  const { saveRegion, regiondetails, updateRegion } = RegionsService;

  const [formState, setFormState] = React.useState<RegionsCardFormType>();

  const getReginDetailsById = useQuery(
    ["getRegionById", idRegion],
    () => regiondetails(idRegion!),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
      enabled: !!idRegion,
    }
  );

  React.useEffect(() => {
    if (getReginDetailsById.data) {
      const data = getReginDetailsById.data;
      setFormState({
        name_ar: data?.namear,
        name_en: data?.nameen,
      });
    }
  }, [getReginDetailsById.data]);

  const addRegion = useMutation(saveRegion, {
    onSuccess: () => {
      queryClient.refetchQueries("getRegionsList");
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
      onClose();
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const editRegion = useMutation(
    () => updateRegion(idRegion!, { ...formState }),
    {
      onSuccess: () => {
        queryClient.refetchQueries("getRegionsList");
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        onClose();
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    if (idRegion) {
      editRegion.mutate();
    } else {
      addRegion.mutate({ ...formState });
    }
  };

  return (
    <Dialog
      open={open}
      title={title}
      maxWidth="sm"
      handleContent={handleSubmit}
      loadingUseQuery={getReginDetailsById.isLoading}
      loading={addRegion.isLoading || editRegion.isLoading}
      onClose={onClose}
      action={action}
    >
      <Box display="flex" width={300} flexDirection="column" gap={2}>
        <TextField
          name="name_en"
          label="Region Name in english"
          value={formState?.name_en || ""}
          onChange={handleChange}
          color="secondary"
          fullWidth
        />
        <TextField
          name="name_ar"
          label="Region Name in Arabic"
          value={formState?.name_ar || ""}
          onChange={handleChange}
          color="secondary"
          fullWidth
        />
      </Box>
    </Dialog>
  );
};
