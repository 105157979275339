import styled from "styled-components";

export const MainLayout = styled.div<{ reduce: boolean }>`
  display: grid;
  grid-template-columns: ${({ reduce }) => (reduce ? "80px 1fr" : "280px 1fr")};
  .meiz-Log {
    /* width: 170px; */
    width: 190px;
    height: 90px;
    object-fit: contain;
    animation: 300ms ease-out 0ms 1 show-meiz-Log;
  }

  .meiz-Log2 {
    width: 70px;
    height: 70px;
    object-fit: contain;
    animation: 300ms ease-out 0ms 1 show-meiz-Log2;
  }

  .divider {
    width: 55%;
    margin: 0 auto;
    border-color: #f4f4f4;
    margin-bottom: 15px;
  }
  .container {
    display: grid;
    height: 100vh;
    grid-template-rows: 90px 1fr;
    background-color: #f6f3f3;
    overflow-x: auto;
  }
  @keyframes show-meiz-Log {
    0% {
      width: 0;
    }
    100% {
      width: 190px;
    }
  }
  @keyframes show-meiz-Log2 {
    0% {
      width: 0;
    }
    100% {
      width: 70px;
    }
  }
`;
