import * as React from "react";
import { Grid } from "@mui/material";
import { useMutation, useQueryClient } from "react-query";

import { TextField, Dialog } from "../../../commons";

import { SnackbarContext } from "../../../App";
import {
  successfulOperation,
  serviceUnavailable,
} from "../../../utils/message";

import { CateringFaqService } from "../../../services/openApi";

type FaqCateringFormDialogProps = {
  open: boolean;
  title: string;
  action: "delete" | "add" | "update" | undefined;
  faqData?: any;
  onClose: () => void;
};

type faqState = {
  questionEn?: string;
  questionAr?: string;
  answerEn?: string;
  answerAr?: string;
};

export const FaqCateringFormDialog = ({
  open,
  title,
  action,
  faqData,
  onClose,
}: FaqCateringFormDialogProps): JSX.Element => {
  const { saveCateringFaq, updateDiscount } = CateringFaqService;
  const { setSnack } = React.useContext(SnackbarContext);
  const queryClient = useQueryClient();

  const [formState, setFormState] = React.useState<faqState>();

  React.useEffect(() => {
    if (faqData) {
      setFormState({
        questionEn: faqData?.qstEn,
        questionAr: faqData?.qstAr,
        answerEn: faqData?.resEn,
        answerAr: faqData?.resAr,
      });
    }
  }, [faqData]);

  const createFaq = useMutation(saveCateringFaq, {
    onSuccess: () => {
      queryClient.refetchQueries("getCateringFaq");
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
      onClose();
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const updateFaq = useMutation(
    () =>
      updateDiscount(+faqData?.id!, {
        answer_en: formState?.answerEn,
        answer_ar: formState?.answerAr,
        question_en: formState?.questionEn,
        question_ar: formState?.questionAr,
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries("getCateringFaq");
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        onClose();
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    if (faqData?.id) {
      updateFaq.mutate();
    } else {
      createFaq.mutate({
        answer_en: formState?.answerEn,
        answer_ar: formState?.answerAr,
        question_en: formState?.questionEn,
        question_ar: formState?.questionAr,
      });
    }
  };

  return (
    <Dialog
      open={open}
      title={title}
      maxWidth="sm"
      handleContent={handleSubmit}
      onClose={() => {
        onClose();
      }}
      loading={createFaq.isLoading || updateFaq?.isLoading}
      action={action}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            name="questionEn"
            label="Question in english"
            value={formState?.questionEn}
            onChange={handleChange}
            color="secondary"
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="questionAr"
            label="Question in arabic"
            value={formState?.questionAr}
            onChange={handleChange}
            color="secondary"
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="answerEn"
            label="Response in english"
            multiline
            rows={3}
            value={formState?.answerEn}
            onChange={handleChange}
            color="secondary"
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="answerAr"
            label="Response in arabic"
            multiline
            rows={3}
            value={formState?.answerAr}
            onChange={handleChange}
            color="secondary"
            style={{ width: "100%" }}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};
