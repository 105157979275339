import styled from "styled-components";

export const AssignCollectionStyled = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 20px;
  .header {
    display: flex;
    height: 70px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dddddd;
    padding: 20px;
    .title {
      font-weight: 600;
      color: ${({ theme }) => theme.palette.secondary.main};
    }
  }
  .table-collection {
    display: grid;
    grid-template-columns: 50px 1fr 1fr 120px;
    max-height: calc(100vh - 310px);
    overflow-y: auto;
    margin-top: 30px;
    .header-element {
      background: #f7f7f7;
      padding: 16px;
      font-weight: 600;
    }
    .value-element {
      padding: 10px;
    }
    .btn-delete {
      background-color: #fdf4f7;
      color: #b93e5c;
      svg {
        margin-left: 10px;
      }
      box-shadow: none;
    }
  }
`;
