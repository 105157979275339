import styled from "styled-components";

export const Page = styled.div`
  padding: 20px;
  .page-action {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
  }
  .MuiInputAdornment-root {
    margin-right: 10px;
  }
  .btn-edit {
    height: 35px;
    background: #ecf4df;
    color: #75bd00;
    svg {
      margin-left: 10px;
      font-size: 18px;
    }
    box-shadow: none;
  }
  .btn-delete {
    height: 35px;
    background: #fdf4f7;
    color: #b93e5c;
    svg {
      margin-left: 10px;
      font-size: 18px;
    }
    box-shadow: none;
  }
  .Mui-disabled {
    opacity: 0.5;
  }
  .MuiSelect {
    padding: 10px 13px;
  }
  .MuiOutlinedInput-input {
    padding: 9px 12px;
  }
  .change {
    svg {
      margin-left: 4px;
    }
  }
`;
