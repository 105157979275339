/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import InputAdornment from "@mui/material/InputAdornment";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { TextField, Dialog } from "../../commons";
import { SnackbarContext } from "../../App";

import {
  RestaurantItemChoiceOptionsService,
  RestaurantItemChoicesService,
} from "../../services/openApi";
import { serviceUnavailable, successfulOperation } from "../../utils/message";

import { EditIcon, TrashIcon, SaveIcon } from "../../img/icon";

import { ChoiceDetailsType, ChoiceOptionType } from "./ChoicesContainer-type";
import { Container } from "./ChoicesContainer-styles";

type ChoicesContainerProps = {
  idChoice?: number;
  onEnableBtnAddOption: (value: boolean) => void;
  addNewChoiceOption: boolean;
};

type ModalProps = {
  open: boolean;
  title: string;
  action?: "delete" | "add" | "update";
};

const headerChoiceOptions = [
  "Name(En)",
  "Name(Ar)",
  "Price",
  "Min option",
  "Max option",
  "Actions",
];

export const ChoicesContainer = ({
  idChoice,
  onEnableBtnAddOption,
  addNewChoiceOption,
}: ChoicesContainerProps): JSX.Element => {
  const { getChoiceById, updateItemChoices } = RestaurantItemChoicesService;

  const {
    saveItemChoiceOption,
    updateItemChoiceOption,
    deleteItemChoiceOption,
  } = RestaurantItemChoiceOptionsService;

  const queryClient = useQueryClient();

  const { setSnack } = React.useContext(SnackbarContext);

  const [choiceDetailsState, setChoiceDetailsState] =
    React.useState<ChoiceDetailsType>({ readonly: true });

  const [listChoiceOptionState, setListChoiceOptionState] = React.useState<
    ChoiceOptionType[]
  >([]);

  const [choiceOptionState, setChoiceOptionState] =
    React.useState<ChoiceOptionType>();

  const [openForm, setOpenForm] = React.useState<ModalProps>({
    open: false,
    title: "",
  });

  const [idOption, setIdOption] = React.useState<number | undefined>();

  const getDetailsChoice = useQuery(
    ["getChoiceById", idChoice],
    () => getChoiceById(idChoice!),
    {
      onSuccess: (data) => {
        const arrayChoicesOption: ChoiceOptionType[] = [];
        const {
          item_choice_name_en,
          item_choice_name_ar,
          min_selection_needed,
          max_selection_needed,
          max_quantity,
          show_onmenu,
          item_id,
          item_choice_options,
        } = data;

        item_choice_options?.forEach((item: any) => {
          arrayChoicesOption.push({
            id: item?.id,
            name_en:
              item?.item_choice_option_translations?.[0]
                ?.item_choice_option_name,
            name_ar:
              item?.item_choice_option_translations?.[1]
                ?.item_choice_option_name,
            item_choice_price: item?.item_choice_price,
            max_selection_option: item?.max_selection_option,
            min_selection_option: item?.min_selection_option,
            readonly: true,
          });
        });

        setChoiceDetailsState({
          name_en: item_choice_name_en,
          name_ar: item_choice_name_ar,
          min_selection_needed: min_selection_needed,
          max_selection_needed: max_selection_needed,
          max_quantity,
          show_onmenu: show_onmenu,
          item_id: item_id,
          readonly: true,
        });
        setListChoiceOptionState(arrayChoicesOption);
        setChoiceOptionState(undefined);
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
      enabled: !!idChoice,
    }
  );

  const updateChoice = useMutation(
    () => updateItemChoices(idChoice!, { ...choiceDetailsState }),
    {
      onSuccess: () => {
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        queryClient.refetchQueries(["getChoiceById", idChoice]);
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const addOption = useMutation(
    () =>
      saveItemChoiceOption({ ...choiceOptionState, item_choice_id: idChoice }),
    {
      onSuccess: () => {
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        queryClient.refetchQueries(["getChoiceById", idChoice]);
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const updateOption = useMutation(
    () =>
      updateItemChoiceOption(choiceOptionState?.id!, {
        ...choiceOptionState,
      }),
    {
      onSuccess: () => {
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        queryClient.refetchQueries(["getChoiceById", idChoice]);
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const deleteOption = useMutation(deleteItemChoiceOption, {
    onSuccess: () => {
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
      queryClient.refetchQueries(["getChoiceById", idChoice]);
      setOpenForm({
        open: false,
        title: "",
      });
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  React.useEffect(() => {
    if (addNewChoiceOption) {
      setListChoiceOptionState((prevListChoiceOptionState) => {
        const arrayListChoiceOptionState = [
          ...(prevListChoiceOptionState || []),
        ];
        arrayListChoiceOptionState.push({
          name_en: undefined,
          name_ar: undefined,
          item_choice_id: idChoice,
          item_choice_price: undefined,
          readonly: false,
          new: true,
        });
        return arrayListChoiceOptionState;
      });
    }
  }, [addNewChoiceOption]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === "max_selection_needed") {
      if ((choiceDetailsState.min_selection_needed || 0) <= parseInt(value)) {
        setChoiceDetailsState({
          ...choiceDetailsState,
          max_selection_needed: parseInt(value),
          max_quantity: parseInt(value),
        });
      }
    } else if (name === "max_quantity") {
      if ((choiceDetailsState.max_selection_needed || 0) <= parseInt(value)) {
        setChoiceDetailsState({
          ...choiceDetailsState,
          max_quantity: parseInt(value),
        });
      }
    } else {
      setChoiceDetailsState({
        ...choiceDetailsState,
        [name]: value,
      });
    }
  };

  const handleChangeOption = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setChoiceOptionState({
      ...choiceOptionState,
      [name]: value,
    });
  };

  const handleEnableEditChoice = () => {
    setChoiceDetailsState({
      ...choiceDetailsState,
      readonly: false,
    });
  };

  const handleEnableEditOption = (index: number) => {
    setListChoiceOptionState((prevChoiceOptionState) => {
      const arrayChoiceOptionState = [...(prevChoiceOptionState || [])];
      arrayChoiceOptionState[index] = {
        ...arrayChoiceOptionState[index],
        readonly: false,
      };
      setChoiceOptionState(arrayChoiceOptionState[index]);
      return arrayChoiceOptionState;
    });
  };

  const handleCancelEditChoice = () => {
    if (getDetailsChoice.data) {
      const {
        item_choice_name_en,
        item_choice_name_ar,
        min_selection_needed,
        max_selection_needed,
        max_quantity,
        show_onmenu,
        item_id,
      } = getDetailsChoice.data;
      setChoiceDetailsState({
        name_en: item_choice_name_en,
        name_ar: item_choice_name_ar,
        min_selection_needed: min_selection_needed,
        max_selection_needed: max_selection_needed,
        max_quantity,
        show_onmenu: show_onmenu,
        item_id: item_id,
        readonly: true,
      });
    }
  };

  const handleCancelEditOption = (index: number) => {
    setListChoiceOptionState((prevChoiceOptionState) => {
      const arrayChoiceOptionState = [...(prevChoiceOptionState || [])];
      if (arrayChoiceOptionState[index].new) {
        arrayChoiceOptionState.splice(index, 1);
      } else {
        arrayChoiceOptionState[index] = {
          ...arrayChoiceOptionState[index],
          readonly: true,
          new: false,
        };
      }
      return arrayChoiceOptionState;
    });
    setChoiceOptionState(undefined);
  };

  const btnOptionDisabled = listChoiceOptionState?.findIndex(
    (o) => o.readonly === false
  );

  React.useEffect(() => {
    onEnableBtnAddOption(btnOptionDisabled !== -1);
  }, [btnOptionDisabled]);

  const handleSaveChoiceDetails = () => {
    if (idChoice) updateChoice.mutate();
  };

  const handleSaveChoiceOption = () => {
    if (choiceOptionState?.id) {
      updateOption.mutate();
    } else {
      addOption.mutate();
    }
  };

  return (
    <Container>
      <Typography sx={{ fontWeight: 600 }}>Choice details</Typography>
      <div className="choice-details">
        <TextField
          name="name_en"
          label="Option name (en)"
          value={choiceDetailsState?.name_en || ""}
          onChange={handleChange}
          color="secondary"
          disabled={choiceDetailsState?.readonly}
        />
        <TextField
          name="name_ar"
          label="Option name (ar)"
          value={choiceDetailsState?.name_ar || ""}
          onChange={handleChange}
          color="secondary"
          disabled={choiceDetailsState?.readonly}
        />
        <TextField
          type="number"
          name="min_selection_needed"
          label="Min selec"
          value={choiceDetailsState?.min_selection_needed || ""}
          onChange={handleChange}
          color="secondary"
          disabled={choiceDetailsState?.readonly}
        />
        <TextField
          type="number"
          name="max_selection_needed"
          label="Max selec"
          value={choiceDetailsState?.max_selection_needed || ""}
          onChange={handleChange}
          color="secondary"
          disabled={choiceDetailsState?.readonly}
        />
        <TextField
          type="number"
          name="max_quantity"
          label="Max quantity"
          value={choiceDetailsState?.max_quantity || ""}
          onChange={handleChange}
          color="secondary"
          disabled={choiceDetailsState?.readonly}
        />
        <Box display="flex" alignItems="center" gap={1}>
          {choiceDetailsState?.readonly ? (
            <IconButton
              className={`btn-save ${!idChoice && "disabled"}`}
              onClick={handleEnableEditChoice}
              disabled={!idChoice}
            >
              <EditIcon />
            </IconButton>
          ) : (
            <IconButton
              className={`btn-save ${updateChoice.isLoading && "disabled"}`}
              onClick={handleSaveChoiceDetails}
              disabled={updateChoice.isLoading}
            >
              <SaveIcon />
            </IconButton>
          )}
          <IconButton className="btn-cancel" onClick={handleCancelEditChoice}>
            <CloseIcon />
          </IconButton>
        </Box>
      </div>
      <Typography sx={{ fontWeight: 600 }}>Choice Options</Typography>
      <div className="choice-option">
        {headerChoiceOptions?.map((element, index) => (
          <Typography
            key={index}
            variant="body2"
            align="center"
            className="header-element"
          >
            {element}
          </Typography>
        ))}
        {listChoiceOptionState?.map((item, index) => (
          <React.Fragment key={index}>
            <Stack sx={{ padding: 1 }}>
              {item?.readonly ? (
                <Typography
                  variant="body2"
                  align="center"
                  className="value-element"
                >
                  {item?.name_en}
                </Typography>
              ) : (
                <TextField
                  name="name_en"
                  value={choiceOptionState?.name_en || ""}
                  onChange={handleChangeOption}
                  color="secondary"
                />
              )}
            </Stack>
            <Stack sx={{ padding: 1 }}>
              {item?.readonly ? (
                <Typography
                  variant="body2"
                  align="center"
                  className="value-element"
                >
                  {item?.name_ar}
                </Typography>
              ) : (
                <TextField
                  name="name_ar"
                  value={choiceOptionState?.name_ar || ""}
                  onChange={handleChangeOption}
                  color="secondary"
                />
              )}
            </Stack>
            <Stack sx={{ padding: 1 }} className="price">
              {item?.readonly ? (
                <Typography
                  variant="body2"
                  align="center"
                  className="value-element"
                >
                  {item?.item_choice_price && `${item.item_choice_price} QAR`}
                </Typography>
              ) : (
                <TextField
                  type="number"
                  name="item_choice_price"
                  value={choiceOptionState?.item_choice_price || ""}
                  onChange={handleChangeOption}
                  color="secondary"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">QAR</InputAdornment>
                    ),
                  }}
                />
              )}
            </Stack>
            <Stack sx={{ padding: 1 }}>
              {item?.readonly ? (
                <Typography
                  variant="body2"
                  align="center"
                  className="value-element"
                >
                  {item?.min_selection_option}
                </Typography>
              ) : (
                <TextField
                  type="number"
                  name="min_selection_option"
                  value={choiceOptionState?.min_selection_option || ""}
                  onChange={handleChangeOption}
                  color="secondary"
                />
              )}
            </Stack>
            <Stack sx={{ padding: 1 }}>
              {item?.readonly ? (
                <Typography
                  variant="body2"
                  align="center"
                  className="value-element"
                >
                  {item?.max_selection_option}
                </Typography>
              ) : (
                <TextField
                  type="number"
                  name="max_selection_option"
                  value={choiceOptionState?.max_selection_option || ""}
                  onChange={handleChangeOption}
                  color="secondary"
                />
              )}
            </Stack>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap={1}
            >
              {item?.readonly ? (
                <IconButton
                  className="btn-save"
                  onClick={() => handleEnableEditOption(index)}
                  disabled={btnOptionDisabled !== -1}
                >
                  <EditIcon />
                </IconButton>
              ) : (
                <IconButton
                  className="btn-save"
                  onClick={handleSaveChoiceOption}
                  disabled={addOption.isLoading || updateOption.isLoading}
                >
                  <SaveIcon />
                </IconButton>
              )}
              {item?.readonly ? (
                <IconButton
                  className="btn-cancel"
                  onClick={() => {
                    setOpenForm({
                      open: true,
                      title: "Delete",
                      action: "delete",
                    });
                    setIdOption(item?.id);
                  }}
                  disabled={btnOptionDisabled !== -1}
                >
                  <TrashIcon />
                </IconButton>
              ) : (
                <IconButton
                  className="btn-cancel"
                  onClick={() => handleCancelEditOption(index)}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </Box>
          </React.Fragment>
        ))}
      </div>
      {listChoiceOptionState?.length === 0 && (
        <Typography variant="body2" align="center" sx={{ margin: "15px auto" }}>
          No data available
        </Typography>
      )}
      <Dialog
        open={openForm.open}
        title={openForm.title}
        action="delete"
        handleContent={() => idOption && deleteOption.mutate(idOption)}
        loading={deleteOption.isLoading}
        onClose={() =>
          setOpenForm({
            open: false,
            title: "",
          })
        }
      >
        <Typography>Are you sure to delete this choice option?</Typography>
      </Dialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={getDetailsChoice?.isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};
