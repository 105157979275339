import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import AnimatedNumbers from "react-animated-numbers";

import { LazyLoadingImage } from "../../commons";

import { CardStyled } from "./CradStatus-styles";

import {
  framePending,
  frameCompleted,
  frameCancelled,
  frameReady,
} from "../../img/frame";

type CardStatusCouponsPorps = {
  status: "All" | "Active" | "Deactivated" | "Deactivated" | "Expitred";
  loading?: boolean;
  count?: number;
  selected?: boolean;
  onClick?: () => void;
};

export const CardStatusCoupons = ({
  status,
  loading,
  count,
  selected,
  onClick,
}: CardStatusCouponsPorps): JSX.Element => {
  const handleFrame = (value: string): string | undefined => {
    switch (value) {
      case "All":
        return framePending;
      case "Active":
        return frameCompleted;
      case "Deactivated":
        return frameReady;
      case "Expitred":
        return frameCancelled;
    }
  };

  const showStatus = (value: string) => {
    switch (value) {
      case "All":
        return "All Coupons";
      case "Active":
        return "Active Coupons";
      case "Deactivated":
        return "Deactivated coupons";
      case "Expitred":
        return "Expired Coupons";
      default:
        return value;
    }
  };

  return (
    <CardStyled onClick={onClick} selected={selected}>
      {loading ? (
        <Skeleton
          variant="rectangular"
          component={Box}
          display="flex"
          flex={1}
          height={100}
          borderRadius="20px"
        />
      ) : (
        <>
          <LazyLoadingImage
            alt="carting Menu"
            src={handleFrame(status)}
            width="100%"
            height={100}
          />
          <div className="details">
            <Box
              display="flex"
              width="100%"
              flexDirection="column"
              alignItems="center"
              justifyContent="space-around"
            >
              <Typography variant="h1" align="center" className="count">
                {count && <AnimatedNumbers animateToNumber={count} />}
              </Typography>
              <Typography variant="body1" align="center" className="status">
                {showStatus(status)}
              </Typography>
            </Box>
          </div>
        </>
      )}
    </CardStyled>
  );
};
