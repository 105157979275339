import * as React from "react";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import find from "lodash/find";
import isNumeric from "validator/lib/isNumeric";

import { NameRestaurantContext } from "../../App";

import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";

import { Link } from "..";

import {
  appMenuItems,
  appMenuItemsRestaurant,
  appMenuType,
  appMenuItemsStore,
} from "../../utils";

export const Breadcrumbs = (): JSX.Element => {
  const history = useHistory();
  const pathname = history.location.pathname;
  const kind = localStorage.getItem("kind") || "";

  const { nameRestaurant } = React.useContext(NameRestaurantContext);

  const handleMainRoute = (kind: string): string => {
    switch (kind) {
      case "admin":
        return "/main/";
      case "restaurant":
        return "/restaurant/";
      case "store_user":
        return "/store/";
      default:
        return "";
    }
  };

  const handleListRoutes = (kind: string): appMenuType[] => {
    switch (kind) {
      case "admin":
        return appMenuItems;
      case "restaurant":
        return appMenuItemsRestaurant;
      case "store_user":
        return appMenuItemsStore;
      default:
        return [];
    }
  };

  const getTitlePage = find(handleListRoutes(kind), (o) =>
    pathname.includes(o.name.replaceAll(" ", "-").toLocaleLowerCase())
  )?.name;

  const getBreadcrumbs = (): React.ReactNode => {
    let str = pathname.replace(handleMainRoute(kind), "") + "/";
    const arrayRoutes: string[] = [];

    let prevPosition = 0;
    for (var i = 0; i < str.length; i++) {
      if (str[i] === "/" && i > 0) {
        arrayRoutes.push(str.substring(prevPosition, i));
        prevPosition = i + 1;
      }
    }

    const handleRedirection = (name: string) => {
      const position = arrayRoutes.findIndex((item) => item === name);
      history.push(
        `${handleMainRoute(kind)}${arrayRoutes
          .slice(0, position + 1)
          .toString()
          .replaceAll(",", "/")}`
      );
    };

    const arrayRoutesFilter = arrayRoutes.filter((item) => !isNumeric(item));

    return (
      <Box display="flex" alignItems="center">
        {arrayRoutes.length > 1 &&
          arrayRoutesFilter.map((route, index) => (
            <React.Fragment key={index}>
              <Link
                underline="hover"
                variant="subtitle2"
                onClick={() => handleRedirection(route)}
              >
                {route.replaceAll("-", " ")}
              </Link>
              {index < arrayRoutesFilter.length - 1 && (
                <ArrowRightOutlinedIcon style={{ color: "#757575" }} />
              )}
            </React.Fragment>
          ))}
      </Box>
    );
  };

  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <Typography
        variant="h2"
        style={{ fontWeight: 600, textTransform: "capitalize" }}
      >
        {nameRestaurant ||
          getTitlePage ||
          pathname.replace(handleMainRoute(kind), "")}
      </Typography>

      {getBreadcrumbs()}
    </Box>
  );
};
