/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RestaurantStore } from '../models/RestaurantStore';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class RoleRestaurantStoresService {

    /**
     * List of restaurant store
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getAllStores(
        requestBody?: {
            search?: string;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/restaurant/store/index`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Save store
     * @param formData
     * @returns any Successful operation
     * @throws ApiError
     */
    public static saveStore(
        formData?: {
            email?: string;
            phone?: string;
            logofile?: Blob;
            thumbfile?: Blob;
            imagefile?: Blob;
            address_en?: string;
            address_ar?: string;
            name_ar?: string;
            name_en?: string;
            description_ar?: string;
            description_en?: string;
            store_policy_ar?: string;
            store_policy_en?: string;
            catering_status?: boolean;
            required_location?: boolean;
            hide_from_mobile?: boolean;
            catering_comission?: number;
            max_catering_per_day?: number;
            value_added_tax?: number;
            catering_advance?: number;
            longitude?: number;
            latitude?: number;
            drop_off_time?: string;
            short_description_en?: string;
            short_description_ar?: string;
            credit_card_fee?: number;
            debit_card_fee?: number;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/restaurant/store/save`,
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Get store details
     * @param id ID of store
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getStoreDetails(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/restaurant/store/show/${id}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Get store policies
     * @param id ID of store
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getStorePolicy(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/restaurant/store/getStorePolicy/${id}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Update store policy
     * @param id ID of store
     * @param formData
     * @returns any Successful operation
     * @throws ApiError
     */
    public static updateStorePolicy(
        id: number,
        formData?: {
            store_policy_ar?: string;
            store_policy_en?: string;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/restaurant/store/updateStorePolicy/${id}`,
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Update store
     * @param id ID of store
     * @param formData
     * @returns any Successful operation
     * @throws ApiError
     */
    public static updateStore(
        id: number,
        formData?: {
            email?: string;
            phone?: string;
            logofile?: Blob;
            thumbfile?: Blob;
            imagefile?: Blob;
            address_en?: string;
            address_ar?: string;
            name_ar?: string;
            name_en?: string;
            description_ar?: string;
            description_en?: string;
            store_policy_ar?: string;
            store_policy_en?: string;
            catering_status?: boolean;
            required_location?: boolean;
            hide_from_mobile?: boolean;
            catering_comission?: number;
            max_catering_per_day?: number;
            value_added_tax?: number;
            catering_advance?: number;
            longitude?: number;
            latitude?: number;
            drop_off_time?: string;
            short_description_en?: string;
            short_description_ar?: string;
            credit_card_fee?: number;
            debit_card_fee?: number;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/restaurant/store/update/${id}`,
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Update store status
     * @param id ID of store
     * @param requestBody
     * @returns RestaurantStore Successful operation
     * @throws ApiError
     */
    public static updateCateringStatus(
        id: number,
        requestBody: {
            catering_status?: boolean;
        },
    ): CancelablePromise<RestaurantStore> {
        return __request({
            method: 'POST',
            path: `/api/restaurant/store/updateCateringStatus/${id}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * sort store subcategory
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static sortItemSubCategories(
        requestBody?: {
            item_id?: number;
            /**
             * subCategories ids
             */
            subCategories?: Array<any>;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/restaurant/store/sortItemSubCategories`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * delete restaurant
     * @param id ID of store
     * @returns any Successful operation
     * @throws ApiError
     */
    public static deleteStore(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/api/restaurant/store/delete/${id}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

}