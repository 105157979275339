import * as React from "react";
import Box from "@mui/system/Box";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import Switch from "@mui/material/Switch";

import { Button, LazyLoadingImage } from "../../commons";

import { EditIcon, TrashIcon, DefualtIcon } from "../../img/icon";
import DefaultImage from "../../img/Default_Image_Thumbnail.png";

type GlobalCategoryTableRowType = {
  data: any;
  index?: number;
  onEdit?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onDelete?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onActive?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

type RowType = {
  rows: string;
  index?: number;
};

export const GlobalCategoryTableRow = ({
  data,
  index,
  onDelete,
  onEdit,
  onActive,
}: GlobalCategoryTableRowType): JSX.Element => {
  const getcolumns = (fields: number) => {
    let template: string = "1fr";
    for (let i = 1; i < fields; i++) {
      template = template.concat(" 1fr");
    }
    return template;
  };
  return (
    <Wrapper index={index} rows={getcolumns(5)}>
      {index === -1 ? (
        <div className="header-element" />
      ) : (
        <Stack className="box-element" sx={{ padding: 2 }}>
          <DragIndicatorIcon fontSize="small" />
        </Stack>
      )}
      {index === -1 ? (
        <Typography variant="body2" align="left" className="header-element">
          Registration code
        </Typography>
      ) : (
        <Typography variant="body2" align="left" className="value-element">
          {data.slug}
        </Typography>
      )}
      {index === -1 ? (
        <Typography variant="body2" align="left" className="header-element">
          Name
        </Typography>
      ) : (
        <Typography variant="body2" align="left" className="value-element">
          {data.name}
        </Typography>
      )}
      {index === -1 ? (
        <Typography
          variant="body2"
          align="left"
          className="header-element"
          style={{ padding: "10px 0" }}
        >
          Icon
        </Typography>
      ) : data?.image ? (
        <Box display="flex" className="box-element" alignItems="center">
          <LazyLoadingImage
            alt="file preview"
            src={data?.image}
            placeholderSrc={DefaultImage}
            height={40}
            width={40}
            className="icon-image"
          />
        </Box>
      ) : (
        <Box display="flex" className="box-element" justifyContent="center">
          <DefualtIcon />
        </Box>
      )}
      {index === -1 ? (
        <Typography variant="body2" align="center" className="header-element">
          Active
        </Typography>
      ) : (
        onActive && (
          <Box
            display="flex"
            className="box-element"
            alignItems="center"
            justifyContent="center"
          >
            <Switch
              checked={data.status}
              onChange={(event) => onActive(event)}
              color="secondary"
            />
          </Box>
        )
      )}
      {index === -1 ? (
        <Typography variant="body2" align="center" className="header-element">
          Actions
        </Typography>
      ) : (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          className="box-element"
          sx={{ padding: 1 }}
        >
          <Box display="flex" justifyContent="center" gap={1}>
            {onEdit && (
              <Button
                variant="contained"
                className="btn-edit"
                onClick={(event) => onEdit(event)}
              >
                Edit
                <EditIcon />
              </Button>
            )}
            {onDelete && (
              <Button
                variant="contained"
                className="btn-delete"
                onClick={(event) => onDelete(event)}
              >
                Delete <TrashIcon />
              </Button>
            )}
          </Box>
        </Stack>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<RowType>`
  display: grid;
  grid-template-columns: 50px ${(props) => props.rows};
  overflow-y: auto;
  :hover {
    background-color: ${(props) => (props.index !== -1 ? "#e0e0e0" : "")};
    :last-child {
      border-bottom-left-radius: 19px;
      border-bottom-right-radius: 19px;
      background-color: #e0e0e0;
    }
  }
  .header-element {
    background: #b3dbe9;
    padding: 10px 16px;
    font-weight: 600;
  }
  .header-element:first-child {
    border-top-left-radius: 19px;
  }
  .header-element:last-child {
    border-top-right-radius: 19px;
  }
  .value-element {
    /* background-color: #fff; */
    padding: 16px;
    border-top: 1px solid #dddddd;
  }
  .box-element {
    /* background-color: #fff; */
    border-top: 1px solid #dddddd;
  }
  .box-element:first-child {
    border-bottom-left-radius: 19px;
    :hover {
      background-color: #f4f6ff;
    }
  }
  .box-element:last-child {
    border-bottom-right-radius: 19px;
  }
  .btn-edit {
    background-color: #ecf4df;
    color: #75bd00;
  }
  .btn-delete {
    background-color: #fdf4f7;
    color: #b93e5c;
  }
  .icon-image {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    object-fit: cover;
  }
`;
