import styled from "styled-components";
import TimePicker from "react-time-picker";

export const InputTime = styled(TimePicker)`
  .react-time-picker__wrapper {
    background: #fff;
    border: 1px solid #d2d1d1;
    border-radius: 30px;
    padding: 5px;
    &:hover {
      border: 1px solid #444;
    }
    &:focus-within {
      border: 1px solid ${(props) => props.theme.palette.secondary.main};
    }
  }
`;
