/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useQuery, useMutation, useQueryClient } from "react-query";
import moment from "moment";

import { TextField, Dialog, UploadPicture } from "../../commons";
import { SnackbarContext } from "../../App";
import { serviceUnavailable, successfulOperation } from "../../utils/message";

import {
  RestaurantCateringOffersService,
  FilterCollectionsService,
} from "../../services/openApi";

import { OffreFormDialogType } from "./OffreFormDialog-type";

type OffreFormDialogProps = {
  idOffre?: number;
  open: boolean;
  title: string;
  action?: "delete" | "add" | "update";
  onClose: () => void;
};

export const OffreFormDialog = ({
  idOffre,
  open,
  title,
  action,
  onClose,
}: OffreFormDialogProps): JSX.Element => {
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);

  const [formState, setFormState] = React.useState<OffreFormDialogType>({
    type: "Store",
  });

  const { getAllStoresList, getItemListByStoresId } = FilterCollectionsService;
  const { saveOffer, getOfferById, updateOffer } =
    RestaurantCateringOffersService;

  const listStores = useQuery("getAllStoresList", () => getAllStoresList(), {
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const listItems = useQuery(
    ["getItemListByStoresId", formState.store_id],
    () =>
      getItemListByStoresId({
        stores_id: formState?.store_id ? [formState?.store_id] : [],
      }),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
      enabled: !!formState.store_id,
    }
  );

  const offerDetailsById = useQuery(
    ["getOfferById", idOffre],
    () => getOfferById(idOffre!),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
      enabled: !!idOffre,
    }
  );

  React.useEffect(() => {
    if (offerDetailsById.data && action === "update") {
      const data = offerDetailsById.data;
      setFormState({
        ...formState,
        title_en: data.title_en,
        title_ar: data?.title_ar,
        short_description_en: data?.short_description_en,
        short_description_ar: data?.short_description_ar,
        item_id: data?.item_id,
        store_id: data?.store_id,
        discount: data?.discount,
        start_date: data?.start_date,
        end_date: data?.end_date,
        status: +data?.status,
        type: data?.type,
      });
    }
  }, [offerDetailsById.data]);

  const findStore =
    listStores?.data?.find((o: any) => o.id === formState?.store_id) || {};

  const findItem =
    listItems?.data?.find((o: any) => o.id === formState?.item_id) || {};

  const addOffer = useMutation(saveOffer, {
    onSuccess: () => {
      queryClient.fetchQuery("getOffersList");
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
      onClose();
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const offerUpdate = useMutation(
    () => updateOffer(idOffre!, { ...formState }),
    {
      onSuccess: () => {
        queryClient.fetchQuery("getOffersList");
        queryClient.fetchQuery(["getOfferById", idOffre]);
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        onClose();
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const handlePicture = (imagefile: Blob) => {
    setFormState({
      ...formState,
      imagefile,
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleChangeOption = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({
      ...formState,
      type: (event.target as HTMLInputElement).value,
    });
  };

  const handleChangeOption2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({
      ...formState,
      status: parseInt((event.target as HTMLInputElement).value),
    });
  };

  const handleDate = (
    name: "start_date" | "end_date",
    value: string | null
  ) => {
    setFormState({
      ...formState,
      [name]: moment(value).format("YYYY-MM-DD"),
    });
  };

  const handleSave = () => {
    if (idOffre) {
      offerUpdate.mutate();
    } else {
      addOffer.mutate(formState);
    }
  };

  return (
    <Dialog
      open={open}
      title={title}
      handleContent={handleSave}
      loading={addOffer.isLoading || offerUpdate.isLoading}
      loadingUseQuery={
        offerDetailsById.isLoading ||
        listStores?.isLoading ||
        listItems.isLoading
      }
      maxWidth="xl"
      onClose={onClose}
      action={action}
    >
      <Box display="flex" width={650} flexDirection="column" gap={2}>
        <Box display="flex" alignContent="center">
          <UploadPicture
            loading={offerDetailsById?.isLoading}
            mode="basic"
            uriImage={offerDetailsById?.data?.image?.uri}
            onLoad={handlePicture}
          />
          <Typography
            variant="subtitle2"
            style={{ margin: "auto 30px", color: "#dad8d8" }}
          >
            The picture must have a size 400px * 400px
          </Typography>
        </Box>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={6}>
            <TextField
              name="title_en"
              label="Title (en)"
              value={formState?.title_en || ""}
              onChange={handleChange}
              fullWidth
              color="secondary"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="title_ar"
              label="Title (Ar)"
              value={formState?.title_ar || ""}
              onChange={handleChange}
              fullWidth
              color="secondary"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="short_description_en"
              label="Short Description(en)"
              value={formState?.short_description_en || ""}
              onChange={handleChange}
              fullWidth
              color="secondary"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="short_description_ar"
              label="Short Description(Ar)"
              value={formState?.short_description_ar || ""}
              onChange={handleChange}
              fullWidth
              color="secondary"
            />
          </Grid>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid item xs={6}>
              <DatePicker
                label="Start Date"
                value={formState.start_date || null}
                onChange={(newValue) => handleDate("start_date", newValue)}
                renderInput={(params) => (
                  <TextField {...params} color="secondary" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                label="End Date"
                value={formState.end_date || null}
                onChange={(newValue) => handleDate("end_date", newValue)}
                renderInput={(params) => (
                  <TextField {...params} color="secondary" fullWidth />
                )}
              />
            </Grid>
          </LocalizationProvider>
          <Grid item xs={6}>
            <TextField
              name="discount"
              type="number"
              label="Pourcentage Discount %"
              value={formState.discount || ""}
              onChange={handleChange}
              fullWidth
              color="secondary"
            />
          </Grid>
          <Grid item xs={6} />

          <Grid item xs={2}>
            <Typography sx={{ color: "secondary.main", fontWeight: 500 }}>
              Redirection
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <RadioGroup
              row
              value={formState.type}
              onChange={handleChangeOption}
            >
              <FormControlLabel
                value="Item"
                control={<Radio color="secondary" />}
                label="Menu"
              />
              <FormControlLabel
                value="Store"
                control={<Radio color="secondary" />}
                label="Store"
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={2}>
            <Typography sx={{ color: "secondary.main", fontWeight: 500 }}>
              Status
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <RadioGroup
              row
              value={formState.status || 0}
              onChange={handleChangeOption2}
            >
              <FormControlLabel
                value={1}
                control={<Radio color="secondary" />}
                label="Active"
              />
              <FormControlLabel
                value={0}
                control={<Radio color="secondary" />}
                label="Inactive"
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              options={listStores.data || []}
              getOptionLabel={(option: any) => option?.name || ""}
              filterSelectedOptions
              value={findStore}
              onChange={(_, value) =>
                setFormState({ ...formState, store_id: value?.id })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="List of stores"
                  color="secondary"
                />
              )}
            />
          </Grid>
          {formState?.type === "Item" && (
            <Grid item xs={6}>
              <Autocomplete
                options={listItems?.data || []}
                getOptionLabel={(option: any) => option?.name || ""}
                filterSelectedOptions
                value={findItem}
                onChange={(_, value) =>
                  setFormState({ ...formState, item_id: value?.id })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="List of Menus"
                    color="secondary"
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </Dialog>
  );
};
