import * as React from "react";
import { useParams, RouteComponentProps } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { LoadingButton } from "../../../../../commons";
import { SnackbarContext } from "../../../../../App";
import { RoleRestaurantStoresService } from "../../../../../services/openApi";
import {
  serviceUnavailable,
  successfulOperation,
} from "../../../../../utils/message";

import { Page } from "./StorePolicyRestaurant";

export const StorePolicyRestaurant = (
  props: RouteComponentProps
): JSX.Element => {
  const { id: idStore } = useParams<{ id?: string }>();
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);
  const { getStorePolicy, updateStorePolicy } = RoleRestaurantStoresService;

  const [formState, setFormState] =
    React.useState<{ store_policy_ar?: string; store_policy_en?: string }>();

  const [storePolicyEn, setStorePolicyEn] = React.useState<EditorState>(() =>
    EditorState.createEmpty()
  );
  const [storePolicyAr, setStorePolicyAr] = React.useState<EditorState>(() =>
    EditorState.createEmpty()
  );

  const getPolicy = useQuery(
    "getStorePolicy",
    () => getStorePolicy(+idStore!),
    {
      onSuccess: (data) => {
        const store = data?.store;
        setFormState({
          store_policy_en: store?.store_policy_en,
          store_policy_ar: store?.store_policy_ar,
        });
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
      enabled: !!idStore,
    }
  );

  React.useEffect(() => {
    if (getPolicy?.data?.store) {
      const store = getPolicy?.data?.store;
      const blocksFromHtmlEn = htmlToDraft(store?.store_policy_en);
      const blocksFromHtmlAr = htmlToDraft(store?.store_policy_ar);
      const contentStateEn = ContentState.createFromBlockArray(
        blocksFromHtmlEn.contentBlocks,
        blocksFromHtmlEn.entityMap
      );
      const contentStateAr = ContentState.createFromBlockArray(
        blocksFromHtmlAr.contentBlocks,
        blocksFromHtmlAr.entityMap
      );
      setStorePolicyEn(EditorState.createWithContent(contentStateEn));
      setStorePolicyAr(EditorState.createWithContent(contentStateAr));
    }
  }, [getPolicy?.data?.store]);

  const editPolicy = useMutation(
    () => updateStorePolicy(getPolicy.data.store.id!, { ...formState }),
    {
      onSuccess: () => {
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        queryClient.fetchQuery("getStorePolicy");
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const handleStorePolicyEn = (value: EditorState) => {
    if (value) setStorePolicyEn(value);
  };

  const handleStorePolicyAr = (value: EditorState) => {
    if (value) setStorePolicyAr(value);
  };

  const handleStorePolicyConvertToStringEn = () => {
    const content = draftToHtml(
      convertToRaw(storePolicyEn.getCurrentContent())
    );
    setFormState({
      ...formState,
      store_policy_en: content,
    });
  };

  const handleStorePolicyConvertToStringAr = () => {
    const content = draftToHtml(
      convertToRaw(storePolicyAr.getCurrentContent())
    );
    setFormState({
      ...formState,
      store_policy_ar: content,
    });
  };

  const handleSubmit = () => {
    if (getPolicy?.data?.store?.id) {
      editPolicy.mutate();
    }
  };

  return (
    <Page>
      <div className="form">
        <Box display="flex" flexDirection="column" flex={1} gap={1}>
          <Typography variant="subtitle2" style={{ fontWeight: 500 }}>
            Store Policy in English
          </Typography>

          <Editor
            editorState={storePolicyEn}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            onEditorStateChange={handleStorePolicyEn}
            placeholder="Please insert store policy in english ... "
            onBlur={handleStorePolicyConvertToStringEn}
          />
        </Box>

        <Box display="flex" flexDirection="column" flex={1} gap={1}>
          <Typography variant="subtitle2" style={{ fontWeight: 500 }}>
            Store Policy in Arabic
          </Typography>
          <Editor
            editorState={storePolicyAr}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            onEditorStateChange={handleStorePolicyAr}
            placeholder="Please insert store policy in arabic ... "
            onBlur={handleStorePolicyConvertToStringAr}
          />
        </Box>
      </div>
      <LoadingButton
        variant="contained"
        color="secondary"
        className="btn-save"
        onClick={handleSubmit}
        disabled={editPolicy?.isLoading || getPolicy?.isLoading}
        loading={editPolicy?.isLoading}
      >
        Save
      </LoadingButton>
    </Page>
  );
};
