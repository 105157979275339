import styled from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  height: 100vh;
  grid-template-columns: 2fr minmax(600px, 1fr);

  .background-meiz {
    position: relative;
    background: linear-gradient(
      -45deg,
      #00c8cb 22.85%,
      #6d6aff 71.63%,
      #a37eff 94.9%
    );

    .logo-meiz-white {
      /* width: 335px; */
      width: 300px;
      height: auto;
      animation: slide-in-blurred-top 1s cubic-bezier(0.23, 1, 0.32, 1) both;
    }
    /* .word-meiz {
      width: 290px;
      height: auto;
      margin-top: 30px;
      animation: text-focus-in 1.4s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
    } */

    .circles {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      padding: 0;
      margin: 0;
    }

    .circles li {
      position: absolute;
      display: block;
      list-style: none;
      width: 20px;
      height: 20px;
      background: rgba(255, 255, 255, 0.2);
      animation: animate 25s linear infinite;
      bottom: -150px;
    }

    .circles li:nth-child(1) {
      left: 45%;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      animation-delay: 0s;
    }

    .circles li:nth-child(2) {
      left: 30%;
      width: 20px;
      height: 20px;
      animation-delay: 2s;
      animation-duration: 12s;
    }

    .circles li:nth-child(3) {
      left: 70%;
      width: 20px;
      height: 20px;
      animation-delay: 4s;
    }

    .circles li:nth-child(4) {
      left: 35%;
      width: 60px;
      height: 60px;
      animation-delay: 0s;
      animation-duration: 18s;
    }

    .circles li:nth-child(5) {
      left: 65%;
      width: 20px;
      height: 20px;
      animation-delay: 0s;
    }

    .circles li:nth-child(6) {
      left: 13%;
      width: 110px;
      height: 110px;
      animation-delay: 3s;
    }

    .circles li:nth-child(7) {
      left: 2%;
      width: 150px;
      height: 150px;
      animation-delay: 7s;
    }

    .circles li:nth-child(8) {
      left: 50%;
      width: 25px;
      height: 25px;
      animation-delay: 15s;
      animation-duration: 45s;
    }

    .circles li:nth-child(9) {
      left: 20%;
      width: 15px;
      height: 15px;
      animation-delay: 2s;
      animation-duration: 35s;
    }

    .circles li:nth-child(10) {
      left: 85%;
      width: 150px;
      height: 150px;
      animation-delay: 0s;
      animation-duration: 11s;
    }
  }

  @media only screen and (max-width: 1200px) {
    grid-template-columns: 1fr 500px;
  }

  @media only screen and (max-width: 800px) {
    grid-template-columns: 0 minmax(500px, 1fr);
    .background-meiz {
      overflow: hidden;
    }
  }

  @keyframes animate {
    0% {
      transform: translateY(-1000px) rotate(720deg);
      opacity: 0;
      border-radius: 50%;
    }

    100% {
      transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 50%;
    }
  }

  @keyframes slide-in-blurred-top {
    0% {
      -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
      transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
      -webkit-transform-origin: 50% 0%;
      transform-origin: 50% 0%;
      -webkit-filter: blur(40px);
      filter: blur(40px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0) scaleY(1) scaleX(1);
      transform: translateY(0) scaleY(1) scaleX(1);
      -webkit-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
      -webkit-filter: drop-shadow(5px 5px 5px #444);
      filter: drop-shadow(5px 5px 5px #444);
      opacity: 1;
    }
  }

  /* @keyframes text-focus-in {
    0% {
      -webkit-filter: blur(12px);
      filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
      -webkit-filter: drop-shadow(5px 5px 5px #444);
      filter: drop-shadow(5px 5px 5px #444);
      opacity: 1;
    }
  } */
`;
