/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class RestaurantCateringOffersService {

    /**
     * List of offers
     * @param requestBody search value
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getOffersList(
        requestBody?: {
            search?: string;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/restaurant/offer/index`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * offer details
     * @param id ID of offer
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getOfferById(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/admins/restaurant/offer/show/${id}`,
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * save offer
     * @param formData
     * @returns any Successful operation
     * @throws ApiError
     */
    public static saveOffer(
        formData?: {
            store_id?: number;
            item_id?: number;
            discount?: number;
            start_date?: string;
            end_date?: string;
            imagefile?: Blob;
            status?: number;
            title_en?: string;
            title_ar?: string;
            short_description_en?: string;
            short_description_ar?: string;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/restaurant/offer/store`,
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * update offer
     * @param id ID of offer
     * @param formData
     * @returns any Successful operation
     * @throws ApiError
     */
    public static updateOffer(
        id: number,
        formData?: {
            store_id?: number;
            item_id?: number;
            discount?: number;
            status?: number;
            start_date?: string;
            end_date?: string;
            imagefile?: Blob;
            type?: string;
            title_en?: string;
            title_ar?: string;
            short_description_en?: string;
            short_description_ar?: string;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/restaurant/offer/update/${id}`,
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * update offer
     * @param id ID of offer
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static updateOffer1(
        id: number,
        requestBody: {
            status?: boolean;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/restaurant/offer/updateStatus/${id}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * delete offer
     * @param id ID of offer
     * @returns any Successful operation
     * @throws ApiError
     */
    public static deleteOffer(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/api/admins/restaurant/offer/delete/${id}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

}