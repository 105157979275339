import styled from "styled-components";

export const ForgetPasswordForm = styled.div`
  display: grid;
  grid-template-rows: 200px 1fr;
  background-color: #f9f8f8;
  .meiz-Log {
    width: 270px;
    margin: 65px auto 0 auto;
  }
  .title-welcome-1 {
    color: ${({ theme }) => theme.palette.text.primary};
    font-weight: 700;
    margin-bottom: -15px;
  }
  .title-welcome-2 {
    color: ${({ theme }) => theme.palette.text.primary};
    margin-bottom: 20px;
  }
  .mainInput {
    padding: 17px 12px 17px;
    margin-left: 7px;
  }
  .btn-reset {
    height: 52px;
    background: linear-gradient(
      90deg,
      #00c8cb 4.34%,
      #6d6aff 79.44%,
      #a37eff 115.54%
    );
  }
  .Mui-disabled {
    background: rgba(0, 0, 0, 0.12);
  }
  .loading {
    margin-right: 10px;
  }
`;
