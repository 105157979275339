import * as React from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import Skeleton from "@mui/material/Skeleton";
import SearchIcon from "@mui/icons-material/Search";

import { ReactSortable } from "react-sortablejs";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { Input, TreeItem, Dialog } from "../../commons";
import { SubCategoryFormDialog } from "..";
import { RoleRestaurantStoreSubcategoriesService } from "../../services/openApi";
import { serviceUnavailable, successfulOperation } from "../../utils/message";
import { SnackbarContext } from "../../App";

import { Container } from "./categories-styles";

interface ItemType {
  id: number;
  name: string;
  status: number;
}

type openFormState = {
  open: boolean;
  title: string;
  action?: "delete" | "add" | "update";
};

type CategoriesRestaurantProps = {
  onSelect: (id: number) => void;
};

export const CategoriesRestaurant = ({
  onSelect,
}: CategoriesRestaurantProps): JSX.Element => {
  const { id: idStore } = useParams<{ id?: string }>();
  const queryClient = useQueryClient();
  const { getSubcategories, updateSubCategoryStatus } =
    RoleRestaurantStoreSubcategoriesService;
  const { setSnack } = React.useContext(SnackbarContext);

  const [state, setState] = React.useState<ItemType[]>([]);
  const [openForm, setOpenForm] = React.useState<openFormState>({
    open: false,
    title: "",
    action: undefined,
  });

  const [openStatus, setOpenStatus] = React.useState<openFormState>({
    open: false,
    title: "",
    action: undefined,
  });
  const [idSubCategory, setIdSubCategory] = React.useState<number | undefined>(
    undefined
  );
  const [status, setStatus] = React.useState<boolean | undefined>(undefined);

  const listSubcategories = useQuery(
    ["getSubcategories", idStore],
    () => getSubcategories(+idStore!),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
      enabled: !!idStore,
    }
  );

  React.useEffect(() => {
    if (listSubcategories?.data) {
      const data = listSubcategories?.data?.subcategories;
      const arraySubCategories: ItemType[] = [];
      data?.forEach((item: any) => {
        arraySubCategories.push({
          id: item.id,
          name: item?.name,
          status: item?.status,
        });
      });
      setState(arraySubCategories);
    }
  }, [listSubcategories?.data]);

  const editSubCategoryStatus = useMutation(
    () => updateSubCategoryStatus(idSubCategory!, { status }),
    {
      onSuccess: () => {
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        queryClient.refetchQueries(["getSubcategories", idStore]);
        setOpenStatus({
          open: false,
          title: "",
          action: "update",
        });
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const arrayListSubCategoriesloading = new Array(9).fill("");

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const arraySubCategories: ItemType[] = [];
    listSubcategories?.data?.subcategories?.forEach((item: any) => {
      arraySubCategories.push({
        id: item.id,
        name: item?.name,
        status: item?.status,
      });
    });
    const result = arraySubCategories.filter((o) =>
      o.name.toLowerCase().includes(event.target.value)
    );
    setState(result);
  };

  return (
    <Container>
      <div className="header">
        <Typography variant="h2" className="title">
          Categories
        </Typography>
      </div>
      <div className="list-categories scrolbar">
        <Input
          placeholder="Categories"
          onChange={handleSearch}
          startAdornment={
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          }
          className="input-search"
        />

        {listSubcategories?.isLoading ? (
          <>
            {arrayListSubCategoriesloading?.map((_, index) => (
              <Box
                key={index}
                display="flex"
                height={45}
                alignItems="center"
                gap={2}
                padding="0 22px"
              >
                <Skeleton variant="rectangular" height={20} width={15} />
                <Skeleton variant="text" width="100%" />
                <Skeleton
                  variant="rectangular"
                  height={15}
                  width={55}
                  sx={{ borderRadius: 3 }}
                />
              </Box>
            ))}
          </>
        ) : (
          <ReactSortable
            list={state}
            setList={setState}
            animation={200}
            delay={2}
          >
            {state?.map((item, index) => (
              <TreeItem
                id={item.id!}
                key={item.id}
                name={item?.name}
                status={item?.status}
                onActive={(event) => {
                  setOpenStatus({
                    open: true,
                    title: "Update Status",
                    action: "update",
                  });
                  setStatus(event.target.checked);
                  setIdSubCategory(item?.id);
                }}
                onSelect={(id) => onSelect(id)}
              />
            ))}
          </ReactSortable>
        )}
      </div>
      {openForm.open && (
        <SubCategoryFormDialog
          id={idStore}
          idSubCategory={idSubCategory}
          open={openForm.open}
          title={openForm.title}
          action={openForm.action}
          onClose={() => {
            setOpenForm({
              open: false,
              action: undefined,
              title: "",
            });
            setIdSubCategory(undefined);
          }}
        />
      )}
      <Dialog
        open={openStatus.open}
        title={openStatus.title}
        action="update"
        handleContent={() => editSubCategoryStatus.mutate()}
        loading={editSubCategoryStatus.isLoading}
        onClose={() =>
          setOpenStatus({
            open: false,
            title: "",
            action: "update",
          })
        }
      >
        <Typography>Are you sure to change status this category?</Typography>
      </Dialog>
    </Container>
  );
};
