import * as React from "react";
import { RouteComponentProps, Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockIcon from "@mui/icons-material/Lock";
import { useMutation } from "react-query";

import { Input, Button } from "../../commons";

import { SnackbarContext } from "../../App";
import { message, serviceUnavailable } from "../../utils/message";

import { AuthenticationService } from "../../services/openApi";

import { meizLog } from "../../img";

import { LoginForm } from "./Login-styles";
import { loginValidation } from "./Login-validation";

export type LoginState = {
  email: string;
  password: string;
};

const initialState: LoginState = {
  password: "",
  email: "",
};

export const Login = (props: RouteComponentProps): JSX.Element => {
  const { signIn } = AuthenticationService;
  const { setSnack } = React.useContext(SnackbarContext);
  const [loginInfo, setLoginInfo] = React.useState<LoginState>(initialState);
  const [loginError, setLoginError] = React.useState<LoginState>(initialState);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setLoginInfo({
      ...loginInfo,
      [name]: value,
    });
    setLoginError({
      ...loginError,
      [name]: "",
    });
  };

  function getTypeAdmin(type?: string) {
    switch (type) {
      case "Marketing":
        props.history.push("/main/send-notifications");
        break;
      default:
        props.history.push("/main/orders");
        break;
    }
  }

  const mutationLogin = useMutation(signIn, {
    onSuccess: (data) => {
      if (data?.success) {
        localStorage.setItem("token", data.token);
        const kind = data?.loggedInUser?.role?.[0]?.guard_name;
        const nameRole = data?.loggedInUser?.role?.[0]?.name;
        localStorage.setItem("kind", kind);
        localStorage.setItem("nameRole", nameRole);
        if (kind === "restaurant") {
          props.history.push("/restaurant/orders");
        } else if (kind === "admin") {
          getTypeAdmin(nameRole);
        } else if (kind === "store_user") {
          props.history.push("/store/orders");
        }
      }
    },
    onError: (error: any) => {
      if (error.body.message === "Authentication error") {
        setSnack({
          message: message.login.authenticationError,
          severity: "error",
          open: true,
        });
      } else {
        setSnack({
          message: serviceUnavailable,
          severity: "error",
          open: true,
        });
      }
    },
  });

  const handleLogin = () => {
    const validation = loginValidation(loginInfo);
    if (Object.entries(validation).length === 0) {
      mutationLogin.mutate(loginInfo);
    } else {
      setLoginError({ ...loginError, ...validation });
    }
  };

  return (
    <LoginForm>
      <img src={meizLog} className="meiz-Log" alt="meiz-Log" />
      <Box
        display="flex"
        flexDirection="column"
        gap={3}
        marginTop={8}
        paddingLeft={8}
        paddingRight={8}
      >
        <Typography variant="h1" color="text" className="title-welcome-1">
          Hello Again!
        </Typography>
        <Typography variant="h3" color="text" className="title-welcome-2">
          Welcome Back
        </Typography>
        <Input
          name="email"
          value={loginInfo.email}
          placeholder="Email Address"
          startAdornment={
            <InputAdornment position="end">
              <MailOutlineIcon />
            </InputAdornment>
          }
          onChange={handleChange}
          msgError={loginError.email}
        />
        <Input
          name="password"
          type="password"
          value={loginInfo.password}
          placeholder="Password"
          startAdornment={
            <InputAdornment position="end">
              <LockIcon />
            </InputAdornment>
          }
          onChange={handleChange}
          msgError={loginError.password}
        />
        <Button
          variant="contained"
          className="btn-login"
          onClick={handleLogin}
          disabled={mutationLogin.isLoading}
        >
          {mutationLogin.isLoading && (
            <CircularProgress size={25} color="secondary" className="loading" />
          )}
          Login
        </Button>
        <Box display="flex" justifyContent="flex-end">
          <Link to="/forget-password" className="forget-password">
            <Typography sx={{ fontWeight: 500 }}>Forget password ?</Typography>
          </Link>
        </Box>
      </Box>
    </LoginForm>
  );
};
