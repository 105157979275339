/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TimePicker from "@mui/lab/TimePicker";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useQuery } from "react-query";
import moment from "moment";
import NumberFormat, { NumberFormatProps } from "react-number-format";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { UploadPicture, TextField, Select } from "../../commons";
import { SnackbarContext } from "../../App";
import { serviceUnavailable } from "../../utils/message";
import { onKeyPress } from "../../utils";
import {
  RestaurantUnitsService,
  RestaurantRequirementsService,
  RestaurantGlobalCategoriesService,
  RestaurantFiltersService,
  StoreSubcategoriesService,
} from "../../services/openApi";

import { GeneralInfoType } from "./GeneralInfoForm-type";
import { Page } from "./GeneralInfoForm-style";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

type GeneralInfoFormProps<T> = {
  inputData?: GeneralInfoType;
  formValidation?: T;
  onChange: (values: GeneralInfoType) => void;
  onError: (attribute: string) => void;
  urlThumbfile?: string;
  urlsGallery?: string[];
  onHandleDropOffTimeFrom?: (value?: string) => void;
  onHandleDropOffTimeTo?: (value?: string) => void;
  onHandleCleanDropOffTime?: () => void;
};

const options = [
  { label: "Catering", kind: "catering" },
  { label: "Special Fees", kind: "specialFees" },
];

const NumberFormatCustom = React.forwardRef<NumberFormatProps, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        format="##"
      />
    );
  }
);

export const GeneralInfoForm = <T extends { [key: string]: any }>({
  inputData,
  formValidation,
  onChange,
  onError,
  urlThumbfile,
  urlsGallery,
  onHandleDropOffTimeFrom,
  onHandleDropOffTimeTo,
  onHandleCleanDropOffTime,
}: GeneralInfoFormProps<T>): JSX.Element => {
  const { idStore, idMenu } =
    useParams<{ idStore?: string; idMenu?: string }>();
  const { setSnack } = React.useContext(SnackbarContext);

  const { getGlobalCategories } = RestaurantGlobalCategoriesService;
  const { getFilters } = RestaurantFiltersService;
  const { getSubcategories } = StoreSubcategoriesService;
  const { getUnits } = RestaurantUnitsService;
  const { getRequirements } = RestaurantRequirementsService;

  const [descriptionEn, setDescriptionEn] = React.useState<EditorState>(() =>
    EditorState.createEmpty()
  );

  const [descriptionAr, setDescriptionAr] = React.useState<EditorState>(() =>
    EditorState.createEmpty()
  );

  const [dropOffTime, setDropOffTime] = React.useState<boolean>(false);

  // React.useEffect(() => {
  //   if (idStore) {
  //     onChange({
  //       ...inputData,
  //       store_id: idStore,
  //     });
  //   }
  // }, [idStore]);

  React.useEffect(() => {
    if (inputData?.description_en && inputData?.description_ar) {
      const blocksFromHtmlEn = htmlToDraft(inputData?.description_en);
      const blocksFromHtmlAr = htmlToDraft(inputData?.description_ar);
      const contentStateEn = ContentState.createFromBlockArray(
        blocksFromHtmlEn.contentBlocks,
        blocksFromHtmlEn.entityMap
      );
      const contentStateAr = ContentState.createFromBlockArray(
        blocksFromHtmlAr.contentBlocks,
        blocksFromHtmlAr.entityMap
      );
      setDescriptionEn(EditorState.createWithContent(contentStateEn));
      setDescriptionAr(EditorState.createWithContent(contentStateAr));
    }
  }, [inputData?.description_en, inputData?.description_ar]);

  const listGlobalCategories = useQuery(
    "getGlobalCategories",
    () => getGlobalCategories(),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const listFilters = useQuery("getFilters", () => getFilters(), {
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const listSubcategories = useQuery(
    "getSubcategories",
    () => getSubcategories(+idStore!),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
      enabled: !!idStore,
    }
  );

  const getListUnit = useQuery("getUnits", () => getUnits(), {
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const listRequirements = useQuery(
    "getRequirements",
    () => getRequirements(),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const handleUploadthumbfile = (thumbfile: number) => {
    onChange({
      ...inputData,
      thumbfile,
    });
    onError("thumbfile");
  };

  const handleUploadGallery = (imagefile: number[]) => {
    onChange({
      ...inputData,
      images: imagefile,
    });
    onError("imagefile");
  };

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    onChange({
      ...inputData,
      [name]: value,
    });
    onError(name);
  };

  function handleChangeAutoCompleteMultiple<T>(
    name: "global_categories" | "filters" | "item_requirements",
    args: T[]
  ) {
    const arrayIds: number[] = [];
    args?.forEach((item: any) => {
      item?.id && arrayIds.push(item.id);
    });
    onChange({
      ...inputData,
      [name]: arrayIds,
    });
    onError(name);
  }

  function handleChangeAutoComplete(name: "sub_category_id", arg: number) {
    onChange({
      ...inputData,
      [name]: arg,
      store_id: idStore,
      copy_to_store_id: idStore ? +idStore : undefined,
    });
    onError(name);
  }

  const handleChangeSelect = (value: unknown) => {
    onChange({
      ...inputData,
      item_unit_id: value as number,
    });
  };

  const handleChangeTime = (
    name: "available_from_time" | "available_to_time",
    value: Date | null
  ) => {
    onChange({
      ...inputData,
      [name]: value ? (moment(value).format("HH:mm:ss") as string) : null,
    });
    onError(name);
  };

  const GlobalCategoriesFilter =
    inputData?.global_categories?.map((id) =>
      listGlobalCategories?.data?.find((item: any) => item?.id === id)
    ) || [];

  const listValuesFilters =
    inputData?.filters?.map((id) =>
      listFilters?.data?.filters?.find((item: any) => item.id === id)
    ) || [];

  const subCategoryFind =
    listSubcategories?.data?.subcategories?.find(
      (item: any) => item.id === inputData?.sub_category_id
    ) || {};

  const requirementsFilter =
    inputData?.item_requirements?.map((id) =>
      listRequirements?.data?.find((item: any) => item.id === id)
    ) || [];

  const handleDescriptionEn = (value: EditorState) => {
    if (value) setDescriptionEn(value);
  };

  const handleDescriptionAr = (value: EditorState) => {
    if (value) setDescriptionAr(value);
  };

  const handleDescriptionConvertToStringEn = () => {
    const content = draftToHtml(
      convertToRaw(descriptionEn.getCurrentContent())
    );
    onChange({
      ...inputData,
      description_en: content,
    });
  };

  const handleDescriptionConvertToStringAr = () => {
    const content = draftToHtml(
      convertToRaw(descriptionAr.getCurrentContent())
    );
    onChange({
      ...inputData,
      description_ar: content,
    });
  };

  return (
    <Page>
      <Box
        display="flex"
        bgcolor="#fff"
        margin="20px"
        p="16px 16px 16px 24px"
        border="1px solid #DDDDDD"
        borderRadius="20px"
      >
        <Box display="flex" flexDirection="column" marginRight={2}>
          <Box display="flex" gap={5}>
            <Typography
              style={{ fontWeight: 600, textTransform: "capitalize" }}
            >
              photo
            </Typography>
          </Box>
          <Box display="flex" flex={1} alignItems="center" gap={2}>
            {idMenu ? (
              urlThumbfile && (
                <UploadPicture
                  mode="basic"
                  uriImage={urlThumbfile}
                  onLoadId={handleUploadthumbfile}
                  msgError={formValidation?.thumbfile}
                />
              )
            ) : (
              <UploadPicture
                mode="basic"
                uriImage={urlThumbfile}
                onLoadId={handleUploadthumbfile}
                msgError={formValidation?.thumbfile}
              />
            )}
            <Typography align="center" style={{ color: "#DAD8D8", width: 170 }}>
              The picture must have a size 400px * 400px
            </Typography>
          </Box>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box marginLeft={2} flex={3}>
          <Box display="flex" gap={5} marginBottom={2}>
            <Typography
              style={{ fontWeight: 600, textTransform: "capitalize" }}
            >
              gallery
            </Typography>
            <Typography style={{ color: "#DAD8D8" }}>
              Maximum 5 images (The picture must have a size 700px * 300px)
            </Typography>
          </Box>
          {idMenu ? (
            urlsGallery &&
            inputData?.images && (
              <UploadPicture
                onLoadGallery={handleUploadGallery}
                mode="gallery"
                type="cover"
                maxImages={5}
                urlsGallery={urlsGallery}
                inputIds={inputData?.images}
              />
            )
          ) : (
            <UploadPicture
              onLoadGallery={handleUploadGallery}
              mode="gallery"
              type="cover"
              maxImages={5}
              urlsGallery={urlsGallery}
              inputIds={inputData?.images}
            />
          )}
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        bgcolor="#fff"
        margin="0 20px 20px 20px"
        p={2}
        border="1px solid #DDDDDD"
        borderRadius="20px"
      >
        <Typography
          style={{
            fontWeight: 600,
            textTransform: "capitalize",
            marginBottom: 16,
          }}
        >
          Menu Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              name="name_en"
              label="Title in English"
              value={inputData?.name_en || ""}
              onChange={handleChangeInput}
              fullWidth
              color="secondary"
              error={!!formValidation?.name_en}
              helperText={formValidation?.name_en}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="name_ar"
              label="Title in Arabic"
              value={inputData?.name_ar || ""}
              onChange={handleChangeInput}
              fullWidth
              color="secondary"
              error={!!formValidation?.name_ar}
              helperText={formValidation?.name_ar}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="short_description_en"
              label="Short description in English"
              value={inputData?.short_description_en || ""}
              onChange={handleChangeInput}
              fullWidth
              color="secondary"
              error={!!formValidation?.short_description_en}
              helperText={formValidation?.short_description_en}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="short_description_ar"
              label="Short description in Arabic"
              value={inputData?.short_description_ar || ""}
              onChange={handleChangeInput}
              fullWidth
              color="secondary"
              error={!!formValidation?.short_description_ar}
              helperText={formValidation?.short_description_ar}
            />
          </Grid>
          <Grid item xs={6}>
            <Editor
              editorState={descriptionEn}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              toolbarClassName="toolbar-class"
              onEditorStateChange={handleDescriptionEn}
              placeholder="Please insert descriptions in english ... "
              onBlur={handleDescriptionConvertToStringEn}
            />
          </Grid>
          <Grid item xs={6}>
            <Editor
              editorState={descriptionAr}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              toolbarClassName="toolbar-class"
              onEditorStateChange={handleDescriptionAr}
              placeholder="Please insert descriptions in arabic ... "
              onBlur={handleDescriptionConvertToStringAr}
            />
          </Grid>
        </Grid>

        {listGlobalCategories?.data && (
          <Autocomplete
            multiple
            options={listGlobalCategories?.data || []}
            getOptionLabel={(option: any) => option.name}
            value={GlobalCategoriesFilter}
            onChange={(_, value) =>
              handleChangeAutoCompleteMultiple("global_categories", value)
            }
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                name="event"
                label="Global Categories"
                color="secondary"
                error={!!formValidation?.global_categories}
                helperText={formValidation?.global_categories}
              />
            )}
            style={{ marginTop: 20 }}
          />
        )}
        {listFilters?.data?.filters && (
          <Autocomplete
            multiple
            options={listFilters?.data?.filters || []}
            getOptionLabel={(option: any) => option.name}
            value={listValuesFilters}
            onChange={(_, value) =>
              handleChangeAutoCompleteMultiple("filters", value)
            }
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                label="Filters"
                color="secondary"
                error={!!formValidation?.filters}
                helperText={formValidation?.filters}
              />
            )}
            style={{ marginTop: 20 }}
          />
        )}

        <Box display="flex" gap={2} marginTop="20px">
          {listSubcategories?.data && (
            <Autocomplete
              disablePortal
              options={listSubcategories?.data?.subcategories || []}
              getOptionLabel={(option: any) => option.name || ""}
              value={subCategoryFind}
              onChange={(_, value) =>
                handleChangeAutoComplete("sub_category_id", value?.id)
              }
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Stores caterogiers"
                  color="secondary"
                  error={!!formValidation?.sub_category_id}
                  helperText={formValidation?.sub_category_id}
                />
              )}
              fullWidth
            />
          )}
          <Autocomplete
            disablePortal
            options={options}
            getOptionLabel={(option) => option.label}
            value={options.find((o) => o.kind === inputData?.delivery_type)}
            onChange={(_, value) =>
              onChange({ ...inputData, delivery_type: value?.kind })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Delivery fees type"
                color="secondary"
              />
            )}
            fullWidth
            // disabled
          />
          {inputData?.delivery_type === "specialFees" && (
            <TextField
              label="Fees"
              type="number"
              value={inputData?.special_fees_amount}
              onChange={(event) =>
                onChange({
                  ...inputData,
                  special_fees_amount: +event.target.value,
                })
              }
              color="secondary"
              fullWidth
            />
          )}
          <ClickAwayListener onClickAway={() => setDropOffTime(false)}>
            <Box
              position="relative"
              width="100%"
              display="flex"
              flexDirection="column"
            >
              <TextField
                name="drop_off_time"
                label="Drop off Time"
                value={inputData?.drop_off_time || ""}
                color="secondary"
                autoComplete="off"
                onClick={() => setDropOffTime((v) => !v)}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          onHandleCleanDropOffTime &&
                            onHandleCleanDropOffTime();
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />

              {dropOffTime && (
                <Box className="drop_top_time">
                  <TextField
                    color="secondary"
                    value={inputData?.drop_off_time?.substring(0, 2) || ""}
                    InputProps={{
                      inputComponent: NumberFormatCustom as any,
                    }}
                    onBlur={(e) =>
                      onHandleDropOffTimeFrom &&
                      onHandleDropOffTimeFrom(e.target.value)
                    }
                    autoFocus
                  />
                  -
                  <TextField
                    color="secondary"
                    value={
                      inputData?.drop_off_time?.substring(
                        inputData?.drop_off_time?.indexOf("-") === -1
                          ? 3
                          : inputData?.drop_off_time?.indexOf("-"),
                        inputData?.drop_off_time?.length
                      ) || ""
                    }
                    InputProps={{
                      inputComponent: NumberFormatCustom as any,
                    }}
                    onBlur={(e) =>
                      onHandleDropOffTimeTo &&
                      onHandleDropOffTimeTo(e.target.value)
                    }
                    disabled={!inputData?.drop_off_time}
                  />
                </Box>
              )}
            </Box>
          </ClickAwayListener>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        bgcolor="#fff"
        margin="0 20px 20px 20px"
        p={2}
        border="1px solid #DDDDDD"
        borderRadius="20px"
      >
        <Typography
          style={{
            fontWeight: 600,
            textTransform: "capitalize",
            marginBottom: 16,
          }}
        >
          Additional details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <TextField
              name="quantity_per_day"
              label="Max Catering per Day"
              value={inputData?.quantity_per_day || ""}
              onChange={handleChangeInput}
              onKeyPress={onKeyPress}
              fullWidth
              color="secondary"
              error={!!formValidation?.quantity_per_day}
              helperText={formValidation?.quantity_per_day}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              name="max_quantity_per_day"
              label="Max add per Order"
              value={inputData?.max_quantity_per_day || ""}
              onChange={handleChangeInput}
              onKeyPress={onKeyPress}
              fullWidth
              color="secondary"
              error={!!formValidation?.max_quantity_per_day}
              helperText={formValidation?.max_quantity_per_day}
            />
          </Grid>
          <Grid item xs={2}>
            <Select
              name="item_unit_id"
              label="Unit"
              value={inputData?.item_unit_id || ""}
              onChange={(event) => handleChangeSelect(event.target.value)}
              color="secondary"
              disabled
            >
              {getListUnit?.data?.map((item: any) => (
                <MenuItem value={item?.id || ""} key={item?.id}>
                  {item?.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={2}>
            <TextField
              type="number"
              name="original_price"
              label="Original Price"
              value={inputData?.original_price || ""}
              onChange={handleChangeInput}
              color="secondary"
              className="price-per-unit"
              error={!!formValidation?.original_price}
              helperText={formValidation?.original_price}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">QAR</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              type="number"
              name="promoted_price"
              label="Promoted Price"
              value={inputData?.promoted_price || ""}
              onChange={handleChangeInput}
              color="secondary"
              className="price-per-unit"
              error={!!formValidation?.promoted_price}
              helperText={formValidation?.promoted_price}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">QAR</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              type="number"
              name="price_per_unit"
              label="Price per unit"
              value={inputData?.price_per_unit || ""}
              onChange={handleChangeInput}
              color="secondary"
              className="price-per-unit"
              error={!!formValidation?.price_per_unit}
              helperText={formValidation?.price_per_unit}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">QAR</InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Box display="flex" gap={2} marginTop={2}>
          <TextField
            name="enough_for"
            label="Enough for"
            value={inputData?.enough_for || ""}
            onChange={handleChangeInput}
            color="secondary"
            error={!!formValidation?.enough_for}
            helperText={formValidation?.enough_for}
          />
          <TextField
            name="lead_time"
            label="Lead time(hours)"
            value={inputData?.lead_time || ""}
            onChange={handleChangeInput}
            color="secondary"
            error={!!formValidation?.lead_time}
            helperText={formValidation?.lead_time}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              label="Availibility from time"
              value={
                inputData?.available_from_time
                  ? new Date(
                      `December 17, 1995 ${inputData?.available_from_time}`
                    )
                  : null
              }
              onChange={(valueTime) =>
                handleChangeTime("available_from_time", valueTime)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  color="secondary"
                  error={!!formValidation?.available_from_time}
                  helperText={formValidation?.available_from_time}
                />
              )}
              ampm={false}
              mask="__:__"
              inputFormat="HH:mm"
            />
            <TimePicker
              label="available_to_time"
              value={
                inputData?.available_to_time
                  ? new Date(
                      `December 17, 1995 ${inputData?.available_to_time}`
                    )
                  : null
              }
              onChange={(valueTime) =>
                handleChangeTime("available_to_time", valueTime)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  color="secondary"
                  error={!!formValidation?.available_to_time}
                  helperText={formValidation?.available_to_time}
                />
              )}
              ampm={false}
              mask="__:__"
              inputFormat="HH:mm"
            />
          </LocalizationProvider>
          <TextField
            type="number"
            name="setup_time"
            label="setup time(hours)"
            value={inputData?.setup_time || ""}
            onChange={handleChangeInput}
            onKeyPress={onKeyPress}
            color="secondary"
            error={!!formValidation?.setup_time}
            helperText={formValidation?.setup_time}
          />
          <TextField
            type="number"
            name="max_time"
            label="Maximum time(hours)"
            value={inputData?.max_time || ""}
            onChange={handleChangeInput}
            onKeyPress={onKeyPress}
            color="secondary"
            error={!!formValidation?.max_time}
            helperText={formValidation?.max_time}
          />
        </Box>
        {listRequirements?.data && (
          <Autocomplete
            multiple
            options={listRequirements?.data || []}
            getOptionLabel={(option: any) =>
              option.requiremenets_translations?.[0]?.name
            }
            value={requirementsFilter}
            onChange={(_, value) =>
              handleChangeAutoCompleteMultiple("item_requirements", value)
            }
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                label="Requirement(English)"
                color="secondary"
                error={!!formValidation?.item_requirements}
                helperText={formValidation?.item_requirements}
              />
            )}
            style={{ marginTop: 20 }}
          />
        )}
      </Box>
    </Page>
  );
};
