import * as React from "react";
import { useParams, useHistory } from "react-router-dom";
import Fab from "@mui/material/Fab";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PrintIcon from "@mui/icons-material/Print";
import { useReactToPrint } from "react-to-print";

import { PrintViewOrder } from "./PrintViewOrder";

import { OpenAPI } from "../../services/openApi";

import { Page, pageStyle } from "./ViewOrderResponsive-style";

export const ViewOrder = (): JSX.Element => {
  const { token } = useParams<{
    token?: string;
  }>();

  const history = useHistory();

  const componentRef = React.useRef<HTMLInputElement | null>(null);

  const [loading, setLoading] = React.useState<boolean>(true);
  const [isVisible, setIsVisible] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (token) {
      OpenAPI.TOKEN = token;
    }
  }, [token]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle,
    copyStyles: true,
    documentTitle: "",
    onAfterPrint: () => {},
  });

  return (
    <Page>
      {isVisible && (
        <>
          {!token && (
            <Fab
              color="secondary"
              className="btn-return"
              onClick={() => history.goBack()}
            >
              <ArrowBackIcon />
            </Fab>
          )}
          <Fab
            color="primary"
            className="btn-print"
            onClick={() => {
              setIsVisible(false);
              handlePrint();
              setTimeout(() => {
                setIsVisible(true);
              }, 1500);
            }}
          >
            <PrintIcon />
          </Fab>
        </>
      )}
      <PrintViewOrder
        ref={componentRef}
        onLoading={setLoading}
        kind={!token ? "admin" : undefined}
      />
      <Backdrop
        sx={{
          background: "#fff",
          color: "secondary.main",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Page>
  );
};
