/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class SocialMediasService {

    /**
     * get social media list
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getSocialMediaList(): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/admins/settings/socialMedia/getSocialMediaList`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * save social media
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static saveSocialMedia(
        requestBody?: {
            name_en?: string;
            photo?: Blob;
            url?: string;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/settings/socialMedia/saveSocialMedia`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * update a social media
     * @param id ID of social media
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static updateSocialMedia(
        id: number,
        requestBody: {
            name_en?: string;
            photo?: Blob;
            url?: string;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/settings/socialMedia/updateSocialMedia/${id}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * delete a social media
     * @param id ID of social media
     * @returns any Successful operation
     * @throws ApiError
     */
    public static deleteSocialMedia(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/api/admins/settings/socialMedia/deleteSocialMedia/${id}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

}