import styled from "styled-components";

export const ChooseMenuFormStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  background-color: #fff;
  border: 1px solid #dddddd;
  border-radius: 20px;
  gap: 16px;
  padding: 20px;
  margin: 20px;
  animation: 500ms ease-out 0ms 1 show;
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
    .MuiAutocomplete-input {
    padding: 5px;
  }
  @keyframes show {
    0% {
      display: none;
      opacity: 0;
    }
    100% {
      display: grid;
      opacity: 1;
    }
  }
`;
