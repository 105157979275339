/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RestaurantDetail } from '../models/RestaurantDetail';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class RestaurantService {

    /**
     * List of restaurants
     * @param requestBody
     * @returns RestaurantDetail Successful operation
     * @throws ApiError
     */
    public static getAllReastaurants(
        requestBody?: {
            search?: string;
        },
    ): CancelablePromise<Array<RestaurantDetail>> {
        return __request({
            method: 'POST',
            path: `/api/admins/restaurant/index`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * get stores  by restaurants ids
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getStoreListByRestaurantsId(
        requestBody?: {
            search?: string;
            /**
             * store ids array
             */
            restaurants_id?: Array<number>;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/restaurant/getStoreListByRestaurantsId`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Create restaurant
     * @param formData
     * @returns RestaurantDetail Successful operation
     * @throws ApiError
     */
    public static createRestaurant(
        formData?: {
            name?: string;
            phone?: number;
            logofile?: Blob;
            password?: string;
            email?: string;
            address?: string;
            website?: string;
        },
    ): CancelablePromise<RestaurantDetail> {
        return __request({
            method: 'POST',
            path: `/api/admins/restaurant/store`,
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Get Restaurant details
     * @param id ID of restaurant
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getRestaurantById(
        id: number,
    ): CancelablePromise<{
        id?: number;
        /**
         * Admin name
         */
        name?: string;
        /**
         * Restaurant unique email address
         */
        email?: string;
        password?: string;
        logofid?: string;
        phone?: string;
        status?: boolean;
        address?: string;
        website?: string;
        is_verified?: boolean;
        created_at?: string;
        updated_at?: string;
        deleted_at?: string;
        uri?: string;
    }> {
        return __request({
            method: 'GET',
            path: `/api/admins/restaurant/${id}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * updateRestaurant
     * @param id ID of restaurant
     * @param formData
     * @returns RestaurantDetail Successful operation
     * @throws ApiError
     */
    public static updateRestaurant(
        id: number,
        formData?: {
            name?: string;
            phone?: number;
            logofile?: Blob;
            password?: string;
            email?: string;
            address?: string;
            website?: string;
        },
    ): CancelablePromise<RestaurantDetail> {
        return __request({
            method: 'POST',
            path: `/api/admins/restaurant/update/${id}`,
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * delete restaurant
     * @param id ID of restaurant
     * @returns any Successful operation
     * @throws ApiError
     */
    public static deleteRestaurant(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/api/admins/restaurant/delete/${id}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

}