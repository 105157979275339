/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useQueryClient, useMutation, useQuery } from "react-query";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import moment from "moment";

import { TextField, Status, Button, LoadingButton } from "../../../commons";
import Table, { ColumnsProps } from "../../../commons/Table";
import { PaymentRequestDialog } from "../../../components";

import { SnackbarContext } from "../../../App";
import {
  PaymentRequestService,
  FilterCollectionsService,
  PaymentCustomRequestService,
} from "../../../services/openApi";
import { serviceUnavailable } from "../../../utils/message";

import { Page } from "./PaymentRequests-style";

type PaymentsRequestsProps = {};

type openFormState = {
  open: boolean;
  title: string;
  action?: "update";
};

export const PaymentsRequests = (props: PaymentsRequestsProps) => {
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);
  const { getAllStoresList } = FilterCollectionsService;
  const { getStorePaymentRequestList } = PaymentRequestService;
  const { getOrdersByStoreId, savePaymentCustomRequest } =
    PaymentCustomRequestService;
  const [selectedStore, setSelectedStore] = React.useState<
    number | undefined
  >();
  const [dateFrom, setDateFrom] = React.useState<Date | null>(null);
  const [dateTo, setDateTo] = React.useState<Date | null>(null);

  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(5);

  const [openForm, setOpenForm] = React.useState<openFormState>({
    open: false,
    title: "",
  });
  const [idRequest, setIdRequest] = React.useState<number | undefined>();
  const [checked, setChecked] = React.useState(false);
  const [ordersBalance, setOrdersBalance] = React.useState<number>(0);
  const [orders, setOrders] = React.useState<any[]>([]);
  const [paymentDate, setPaymentDate] = React.useState<Date | null>(new Date());
  const [paymentDescrption, setPaymentDescrption] = React.useState<string>("");
  const [custom, setCutom] = React.useState(false);

  const listStores = useQuery("getAllStoresList", () => getAllStoresList(), {
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const listStoreFilter =
    listStores?.data?.find((item: any) => item?.id === selectedStore) || "";

  const getListOrdersByStoreId = useQuery(
    "getOrdersByStoreId",
    () => getOrdersByStoreId(selectedStore!),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
      enabled: !!selectedStore,
    }
  );

  const columns: ColumnsProps = [
    {
      header: "ID",
      accessor: "id",
    },
    {
      header: "Notes",
      accessor: "notes",
    },
    {
      header: "Sender",
      accessor: "sender",
    },
    {
      header: "Status",
      accessor: "status",
    },
    {
      header: "Description",
      accessor: "description",
    },
    {
      header: "Created",
      accessor: "created",
    },
    {
      header: "Actions",
      accessor: "actions",
      headerCellProps: { align: "center" },
      cellProps: { align: "center", width: "90px" },
    },
  ];

  const getListStorePaymentRequest = useQuery(
    [
      "getStorePaymentRequestList",
      selectedStore,
      dateFrom ? moment(dateFrom).format("YYYY-MM-DD") : undefined,
      dateTo ? moment(dateTo).format("YYYY-MM-DD") : undefined,
    ],
    () =>
      getStorePaymentRequestList({
        restaurant_store_id: selectedStore,
        start_date: dateFrom
          ? moment(dateFrom).format("YYYY-MM-DD")
          : undefined,
        end_date: dateTo ? moment(dateTo).format("YYYY-MM-DD") : undefined,
      })
  );

  const handleOrdreIdsWithBalance = (value: any) => {
    setOrders(
      value.map((el: any) => ({ id: el.id, amount: el.remaining_amount }))
    );
    const sum = value.reduce((accumulator: number, object: any) => {
      return accumulator + object.remaining_amount;
    }, 0);
    setOrdersBalance(sum);
  };

  const handleCancelation = () => {
    setOrders([]);
    setPaymentDescrption("");
    setPaymentDate(new Date());
    setOrdersBalance(0);
  };

  const saveRequest = useMutation(savePaymentCustomRequest, {
    onSuccess: () => {
      queryClient.refetchQueries([
        "getAdminPaymentRequestList",
        selectedStore,
        moment(dateFrom).format("YYYY-MM-DD"),
        moment(dateTo).format("YYYY-MM-DD"),
      ]);
      handleCancelation();
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const renderTableRows = () => {
    return (
      getListStorePaymentRequest.data?.paymentRequests
        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        ?.map((item: any) => ({
          id: item?.id,
          notes: item?.note || "--",
          sender: item?.store?.store_translation?.name || "--",
          status: <Status status={item?.status?.toUpperCase()} />,
          description: item?.description || "--",
          created: item?.created_at,
          actions: (
            <Button
              variant="contained"
              className="btn-action"
              onClick={() => {
                setOpenForm({
                  open: true,
                  title: "Accept or Reject payement request",
                  action: "update",
                });
                setIdRequest(item?.id);
                item?.custom && setCutom(true);
              }}
              disabled={item?.status?.toUpperCase() !== "PENDING"}
            >
              Action
            </Button>
          ),
        })) || []
    );
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSubmit = () => {
    saveRequest.mutate({
      restaurant_store_id: selectedStore,
      description: paymentDescrption,
      reference_date: moment(paymentDate).format("YYYY-MM-DD"),
      orders: orders,
    });
  };

  React.useEffect(() => {
    getListOrdersByStoreId.refetch();
  }, [selectedStore]);

  return (
    <Page>
      <div className="header-action">
        <Autocomplete
          options={listStores.data || []}
          getOptionLabel={(option: any) => option?.name || ""}
          value={listStoreFilter}
          filterSelectedOptions
          onChange={async (_, value: any) => {
            await setSelectedStore(value?.id);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Please select a store"
              color="secondary"
            />
          )}
          fullWidth
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Typography variant="body2" align="center" sx={{ color: "#c4c3c3" }}>
            From
          </Typography>
          <DatePicker
            value={dateFrom}
            inputFormat="dd/MM/yyyy"
            onChange={(newValue) => {
              setDateFrom(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} color="secondary" fullWidth />
            )}
            maxDate={dateTo}
          />
          <Typography variant="body2" align="center" sx={{ color: "#c4c3c3" }}>
            To
          </Typography>
          <DatePicker
            value={dateTo}
            inputFormat="dd/MM/yyyy"
            onChange={(newValue) => {
              setDateTo(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} color="secondary" fullWidth />
            )}
            minDate={dateFrom}
          />
        </LocalizationProvider>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="containerCollapse">
            <Collapse
              in={checked}
              collapsedSize={50}
              sx={{ width: "100%", padding: "5px 16px 16px 16px" }}
            >
              <Box
                mb={1}
                display="flex"
                sx={{
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="subtitle1"
                  align="center"
                  sx={{
                    fontWeight: 600,
                  }}
                >
                  Add New Payment
                </Typography>
                <IconButton onClick={() => setChecked(!checked)}>
                  <ArrowDropDownIcon />
                </IconButton>
              </Box>
              <Box
                display="grid"
                gap={2}
                mb={2}
                sx={{
                  gridTemplateColumns: "1fr 250px",
                  alignItems: "center",
                }}
              >
                <Autocomplete
                  options={getListOrdersByStoreId.data || []}
                  getOptionLabel={(option: any) => option?.id || ""}
                  filterSelectedOptions
                  multiple
                  value={
                    orders?.map((item: any) =>
                      getListOrdersByStoreId?.data?.find(
                        (el: any) => item.id === el.id
                      )
                    ) || []
                  }
                  onChange={(_, value: any) => handleOrdreIdsWithBalance(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Enter Reference Number"
                      color="secondary"
                    />
                  )}
                  disabled={!listStoreFilter}
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={paymentDate}
                    inputFormat="dd/MM/yyyy"
                    onChange={(newValue) => {
                      setPaymentDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} color="secondary" fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </Box>
              <Box
                display="grid"
                gap={2}
                mb={2}
                sx={{
                  gridTemplateColumns: "1fr 250px",
                  alignItems: "center",
                }}
              >
                <TextField
                  name="description"
                  placeholder="Enter Description"
                  multiline
                  rows={2}
                  color="secondary"
                  fullWidth
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setPaymentDescrption(e.target.value)
                  }
                />
                <Typography align="center" sx={{ fontWeight: 600 }}>
                  Orders balance : QAR {ordersBalance.toLocaleString("en-US")}
                </Typography>
              </Box>
              <Box display="flex" height={45} justifyContent="flex-end" gap={2}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleCancelation}
                >
                  Cancel
                </Button>
                <LoadingButton
                  loading={saveRequest.isLoading}
                  variant="contained"
                  color="secondary"
                  onClick={handleSubmit}
                  disabled={!orders.length}
                  style={{ width: "13em" }}
                >
                  Send
                </LoadingButton>
              </Box>
            </Collapse>
          </div>
        </Grid>
      </Grid>
      <Table
        columns={columns}
        loading={getListStorePaymentRequest?.isLoading}
        nbrRowLoading={10}
        data={renderTableRows()}
        emptyMessage="No data available"
        onClickRow={() => {}}
        tablePaginationProps={{
          rowsPerPageOptions: [5, 10, 25],
          count: getListStorePaymentRequest.data?.paymentRequests?.length || 0,
          page: page,
          onPageChange: (_, newPage) => setPage(newPage),
          rowsPerPage,
          onRowsPerPageChange: handleChangeRowsPerPage,
        }}
      />
      {openForm.open && (
        <PaymentRequestDialog
          idRequest={idRequest}
          idStore={selectedStore}
          open={openForm.open}
          title={openForm.title}
          action={openForm.action}
          startDate={moment(dateFrom).format("YYYY-MM-DD")}
          endDate={moment(dateTo).format("YYYY-MM-DD")}
          onClose={() => {
            getListStorePaymentRequest.refetch();
            setOpenForm({
              open: false,
              title: "",
            });
            setIdRequest(undefined);
            setCutom(false);
          }}
          custom={custom}
        />
      )}
    </Page>
  );
};
