import * as React from "react";
import Box from "@mui/material/Box";
import { useMutation, useQueryClient } from "react-query";

import { TextField, Dialog } from "../../commons";
import { SnackbarContext } from "../../App";

import { serviceUnavailable, successfulOperation } from "../../utils/message";

import { RestaurantItemChoicesService } from "../../services/openApi";

import { ChoiceType } from "../ChoicesFormDialog/Choices-type";

type ChoicesFormDialogProps = {
  idMenu?: string;
  open: boolean;
  title: string;
  action?: "delete" | "add" | "update";
  onClose: () => void;
};

export const ChoicesFormDialog = ({
  idMenu,
  open,
  title,
  action,
  onClose,
}: ChoicesFormDialogProps): JSX.Element => {
  const queryClient = useQueryClient();
  const { saveItemChoices } = RestaurantItemChoicesService;
  const { setSnack } = React.useContext(SnackbarContext);

  const [formState, setFormState] = React.useState<ChoiceType>({
    show_onmenu: true,
  });

  const addChoice = useMutation(
    () => saveItemChoices({ ...formState, item_id: +idMenu! }),
    {
      onSuccess: () => {
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        queryClient.refetchQueries(["getItemChoices", idMenu]);
        onClose();
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === "max_selection_needed") {
      if ((formState.min_selection_needed || 0) <= parseInt(value)) {
        setFormState({
          ...formState,
          max_selection_needed: parseInt(value),
          max_quantity: parseInt(value),
        });
      }
    } else if (name === "max_quantity") {
      if ((formState.max_selection_needed || 0) <= parseInt(value)) {
        setFormState({
          ...formState,
          max_quantity: parseInt(value),
        });
      }
    } else {
      setFormState({
        ...formState,
        [name]: value,
      });
    }
  };

  const handleSubmit = () => {
    if (idMenu) addChoice.mutate();
  };

  return (
    <Dialog
      open={open}
      title={title}
      maxWidth="sm"
      handleContent={handleSubmit}
      loading={addChoice.isLoading}
      loadingUseQuery={
        !formState?.max_selection_needed || !formState?.max_quantity
      }
      onClose={onClose}
      action={action}
    >
      <Box display="flex" width={350} flexDirection="column" gap={2}>
        <TextField
          name="name_en"
          label="Choice name (En)"
          value={formState?.name_en || ""}
          onChange={handleChange}
          color="secondary"
        />
        <TextField
          name="name_ar"
          label="Choice name (Ar)"
          value={formState?.name_ar || ""}
          onChange={handleChange}
          color="secondary"
        />
        <TextField
          type="number"
          name="min_selection_needed"
          label="Minimum selection"
          value={formState?.min_selection_needed || ""}
          onChange={handleChange}
          color="secondary"
        />
        <TextField
          type="number"
          name="max_selection_needed"
          label="Maximum selection"
          value={formState?.max_selection_needed || ""}
          onChange={handleChange}
          color="secondary"
          disabled={!formState?.min_selection_needed}
        />
        <TextField
          type="number"
          name="max_quantity"
          label="Maximum quantity"
          value={formState?.max_quantity || ""}
          onChange={handleChange}
          color="secondary"
          disabled={!formState?.max_selection_needed}
        />
      </Box>
    </Dialog>
  );
};
