/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class StoreSubcategoriesService {

    /**
     * List of SubCategories
     * @param id ID of store
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getSubcategories(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/admins/restaurant/subcategory/index/${id}`,
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Subcategory by iD
     * @param id ID of subcategory
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getSubcategoryById(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/admins/restaurant/subcategory/${id}`,
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * save a subcategory
     * @param formData
     * @returns any Successful operation
     * @throws ApiError
     */
    public static saveSubCategory(
        formData: {
            name_en?: string;
            name_ar?: string;
            slug?: string;
            store_id?: string;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/restaurant/subcategory/store`,
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * update a subcategory
     * @param id ID of subcategory
     * @param formData
     * @returns any Successful operation
     * @throws ApiError
     */
    public static updateSubCategoryById(
        id: number,
        formData: {
            name_en?: string;
            name_ar?: string;
            slug?: string;
            store_id?: string;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/restaurant/subcategory/update/${id}`,
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * update a subcategory status
     * @param id ID of subcategory
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static updateSubCategoryStatus(
        id: number,
        requestBody: {
            status?: boolean;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/restaurant/subcategory/updateStatus/${id}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * delete subcategory
     * @param id ID of subcategory
     * @returns any Successful operation
     * @throws ApiError
     */
    public static deleteSubCategory(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/api/admins/restaurant/subcategory/delete/${id}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Item by subcategory iD
     * @param id ID of subcategory
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getItemsBySubCategoryId(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/admins/restaurant/subcategory/getItemsBySubCategoryId/${id}`,
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * sort an item(menu)
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static sortSubcategory(
        requestBody?: {
            /**
             * subcategories ids
             */
            subcategories?: Array<any>;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/restaurant/subcategory/sortSubcategory`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

}