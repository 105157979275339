/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class PaymentRequestService {

    /**
     * Payment Request
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getAdminPaymentRequestList(
        requestBody?: {
            restaurant_store_id?: number;
            start_date?: string;
            end_date?: string;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/settings/paymentRequest/getAdminPaymentRequestList`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Payment Request
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getStorePaymentRequestList(
        requestBody?: {
            restaurant_store_id?: number;
            start_date?: string;
            end_date?: string;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/settings/paymentRequest/getStorePaymentRequestList`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Payment Request
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static savePaymentRequest(
        requestBody?: {
            restaurant_store_id?: number;
            description?: string;
            reference_date?: string;
            /**
             * order objects
             */
            orders?: Array<any>;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/settings/paymentRequest/savePaymentRequest`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Payment Request
     * @param id ID of store payment request
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static updateStorePaymentRequest(
        id: number,
        requestBody?: {
            note?: string;
            status?: string;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/settings/paymentRequest/updateStorePaymentRequest/${id}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

}