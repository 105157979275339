/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class RoleStoreUserStatementOfAccountService {

    /**
     * Statement Of Account
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getStatementOfAccount(
        requestBody?: {
            restaurant_store_id?: number;
            start_date?: string;
            end_date?: string;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/storeUser/settings/statementOfAccount/getStatementOfAccount`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

}