import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  min-width: 185px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #dbd8d8;
  border-radius: 30px;
  padding: 3px;
  .MuiAvatar-root {
    border: ${({ theme }) => `2px solid ${theme.palette.secondary.main}`};
  }
  .profile-name {
    font-weight: 600;
    margin-left: 5px;
  }
`;
