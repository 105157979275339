import * as React from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import PersonIcon from "@mui/icons-material/Person";
import PaymentIcon from "@mui/icons-material/Payment";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ArticleIcon from "@mui/icons-material/Article";
import { useQuery } from "react-query";
import moment from "moment";

import { SnackbarContext } from "../../App";
import { serviceUnavailable } from "../../utils/message";
import { RoleStoreUserOrdersService } from "../../services/openApi";

import { meizLog } from "../../img";

import { ComponentToPrint } from "./OrderToPrint-style";

export const OrderToPrintStore = React.forwardRef<HTMLDivElement>(
  (props, ref) => {
    const { id } = useParams<{ id?: string }>();
    const { setSnack } = React.useContext(SnackbarContext);
    const { getOrderDetails } = RoleStoreUserOrdersService;

    const orderDetails = useQuery(
      ["getOrderDetails", id],
      () => getOrderDetails(+id!),
      {
        onError: () => {
          setSnack({
            open: true,
            severity: "error",
            message: serviceUnavailable,
          });
        },
        enabled: !!id,
      }
    );

    const idStatus = orderDetails?.data?.order_current_status?.id || "";

    return (
      <ComponentToPrint ref={ref}>
        {!orderDetails?.isLoading ? (
          <>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={2}
            >
              <img src={meizLog} className="meiz-Log-print" alt="meiz-Log" />
            </Box>
            <Typography variant="h2" className="title">
              {orderDetails?.data?.store?.store_translation?.name}
            </Typography>
            <Grid container className="div-show">
              <Grid item xs={7}>
                <Typography className="customer-infos">
                  <PersonIcon fontSize="small" /> Customer Infos
                </Typography>
                <Box display="flex" gap={2} padding="5px 0 0 8px">
                  <Typography variant="body1">Customer Name : </Typography>
                  <Typography variant="body1">
                    {idStatus?.toString() !== "1" &&
                    idStatus?.toString() !== "7"
                      ? orderDetails?.data?.customer?.fullname
                      : "xxxxxxxxxxxxxxxxxxxx"}
                  </Typography>
                </Box>
                <Box display="flex" gap={2} padding="5px 0 0 8px">
                  <Typography variant="body1">Mobile Number : </Typography>
                  <Typography variant="body1">
                    {idStatus?.toString() !== "1" &&
                    idStatus?.toString() !== "7"
                      ? orderDetails?.data?.customer?.mobile
                      : "xxxxxxxxxxxxxxxxxxxx"}
                  </Typography>
                </Box>
                <Box display="flex" gap={2} padding="5px 0 0 8px">
                  <Typography variant="body1">Email :</Typography>
                  <Typography variant="body1">
                    {idStatus?.toString() !== "1" &&
                    idStatus?.toString() !== "7"
                      ? orderDetails?.data?.customer?.email
                      : "xxxxxxxxxxxxxxxxxxxx"}
                  </Typography>
                </Box>
                {orderDetails?.data?.address_name && (
                  <Box display="flex" gap={2} padding="5px 0 0 8px">
                    <Typography variant="body1">Address :</Typography>
                    <Typography variant="body1">
                      {orderDetails?.data?.address_name}
                    </Typography>
                  </Box>
                )}
              </Grid>
              <Grid item xs={5}>
                <Typography className="customer-infos">
                  <ArticleIcon fontSize="small" /> Order Details
                </Typography>
                <Box display="flex" gap={2} padding="0 0 0 8px">
                  <Typography variant="body1">Order status :</Typography>
                  <Typography variant="body1">
                    {
                      orderDetails?.data?.order_current_status
                        ?.order_status_translation?.display_status
                    }
                    {idStatus === 7
                      ? ` by ${
                          orderDetails?.data?.cancelled_by_guard_name ===
                          "store_user"
                            ? "store"
                            : orderDetails?.data?.cancelled_by_guard_name
                        }`
                      : ""}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  minWidth="max-content"
                  gap="2px"
                  padding="5px 0 0 8px"
                >
                  <Typography variant="body1">
                    Date :{" "}
                    {orderDetails?.data?.iso_date &&
                      moment(orderDetails?.data?.iso_date).format(
                        "DD MMM YYYY"
                      )}
                  </Typography>
                  <Typography variant="body1">
                    Time :{" "}
                    {orderDetails?.data?.iso_date &&
                      moment(orderDetails?.data?.iso_date).format("hh:mm a")}
                  </Typography>
                  <Typography variant="body1">
                    Order Reference : {orderDetails?.data?.id}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={7} marginTop={1}>
                <Typography className="customer-infos">
                  <LocalShippingIcon fontSize="small" /> Delivery Details
                </Typography>
                <Box display="flex" gap={2} padding="5px 0 0 8px">
                  <Typography variant="body1">Delivery Data : </Typography>
                  <Typography variant="body1">
                    {orderDetails?.data?.details?.date}
                  </Typography>
                </Box>
                <Box display="flex" gap={2} padding="5px 0 0 8px">
                  <Typography variant="body1">Delivery Area : </Typography>
                  <Typography variant="body1">
                    {orderDetails?.data?.details?.formatted_address}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={5} marginTop={1}>
                <Typography className="customer-infos">
                  <PaymentIcon fontSize="small" /> Payment Method
                </Typography>
                <Box display="flex" gap={2} padding="5px 0 0 8px">
                  <Typography
                    variant="body1"
                    sx={{ textTransform: "capitalize" }}
                  >
                    {orderDetails?.data?.payment_method?.replaceAll("_", " ")}
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <div className="order-summary">
              <div className="order-summary-header">
                <Typography style={{ fontWeight: 600 }}>
                  Order summary
                </Typography>
              </div>
              <div className="rows">
                <Typography style={{ fontWeight: 600, padding: 8 }}>
                  Item
                </Typography>
                <Typography
                  align="center"
                  style={{ fontWeight: 600, padding: 8 }}
                >
                  Quantity
                </Typography>
                <Typography
                  align="center"
                  style={{ fontWeight: 600, padding: 8 }}
                >
                  Unit Price
                </Typography>
                <Typography
                  align="center"
                  style={{ fontWeight: 600, padding: 8 }}
                >
                  Price
                </Typography>
                {orderDetails?.data?.details?.OrderSummary?.map(
                  (item: any, index: number) => (
                    <React.Fragment key={item?.id}>
                      <Typography style={{ padding: 8, fontWeight: 600 }}>
                        {item?.name_en} / {item?.name_ar}
                      </Typography>
                      <Typography align="center" style={{ padding: 8 }}>
                        {item?.enough_for}
                      </Typography>
                      <Typography align="center" style={{ padding: 8 }}>
                        ---------{" "}
                      </Typography>
                      <Typography align="center" style={{ padding: 8 }}>
                        {`QAR ${item?.promoted_price?.toLocaleString("en-US")}`}
                      </Typography>

                      {/* Choices */}
                      {item?.choices?.map((choice: any) => (
                        <React.Fragment key={choice?.id}>
                          <Typography
                            style={{
                              padding: 8,
                              gridColumn: "1/5",
                              fontWeight: 600,
                              marginLeft: 16,
                            }}
                          >
                            {choice?.name_en} / {choice?.name_ar}
                          </Typography>
                          {choice?.item_choice_options?.map(
                            (itemChoice: any) => (
                              <React.Fragment key={itemChoice?.id}>
                                <Typography
                                  style={{
                                    padding: 8,
                                    marginLeft: 24,
                                  }}
                                >
                                  {itemChoice?.name_en} / {itemChoice?.name_ar}
                                </Typography>
                                <Typography
                                  align="center"
                                  style={{
                                    padding: 8,
                                  }}
                                >
                                  {itemChoice?.quantity}
                                </Typography>
                                <Typography
                                  align="center"
                                  style={{
                                    padding: 8,
                                  }}
                                >
                                  {`QAR ${itemChoice?.item_choice_price?.toLocaleString(
                                    "en-US"
                                  )}`}
                                </Typography>
                                <Typography
                                  align="center"
                                  style={{
                                    padding: 8,
                                  }}
                                >
                                  {`QAR ${(
                                    itemChoice?.item_choice_price *
                                    itemChoice?.quantity
                                  )?.toLocaleString("en-US")}`}
                                </Typography>
                              </React.Fragment>
                            )
                          )}
                        </React.Fragment>
                      ))}
                      {/* Addons */}
                      {item?.addons?.map((addon: any) => (
                        <React.Fragment key={addon?.id}>
                          <Typography
                            style={{
                              padding: 8,
                              gridColumn: "1/5",
                              fontWeight: 600,
                              marginLeft: 16,
                            }}
                          >
                            {addon?.name_en} / {addon?.name_ar}
                          </Typography>
                          {addon?.item_addon_options?.map((itemAddon: any) => (
                            <React.Fragment key={itemAddon?.id}>
                              <Typography
                                style={{
                                  padding: 8,
                                  marginLeft: 24,
                                }}
                              >
                                {itemAddon?.name_en} / {itemAddon?.name_ar}
                              </Typography>
                              <Typography
                                align="center"
                                style={{
                                  padding: 8,
                                }}
                              >
                                {itemAddon?.quantity}
                              </Typography>
                              <Typography
                                align="center"
                                style={{
                                  padding: 8,
                                }}
                              >
                                {`QAR ${itemAddon?.item_addon_option_price?.toLocaleString(
                                  "en-US"
                                )}`}
                              </Typography>
                              <Typography
                                align="center"
                                style={{
                                  padding: 8,
                                }}
                              >
                                {`QAR ${(
                                  itemAddon?.item_addon_option_price *
                                  itemAddon?.quantity
                                )?.toLocaleString("en-US")}`}
                              </Typography>
                            </React.Fragment>
                          ))}
                        </React.Fragment>
                      ))}
                      {item?.quantity - item?.enough_for > 0 && (
                        <>
                          <Typography style={{ padding: 8, fontWeight: 600 }}>
                            Additional person for {item?.name_en}
                          </Typography>
                          <Typography align="center" style={{ padding: 8 }}>
                            {item?.quantity - item?.enough_for}
                          </Typography>
                          <Typography align="center" style={{ padding: 8 }}>
                            {`QAR ${item?.price_per_unit?.toLocaleString(
                              "en-US"
                            )}`}
                          </Typography>
                          <Typography align="center" style={{ padding: 8 }}>
                            {`QAR ${(
                              (item?.quantity - item?.enough_for) *
                              item?.price_per_unit
                            )?.toLocaleString("en-US")}`}
                          </Typography>
                        </>
                      )}
                    </React.Fragment>
                  )
                )}
              </div>
            </div>
            <Box display="flex" gap={2}>
              <div className="specil-request">
                <Typography
                  variant="subtitle2"
                  style={{
                    gridColumn: "1/3",
                    fontWeight: 500,
                  }}
                >
                  Specil Request
                </Typography>
                {orderDetails?.data?.details?.OrderSummary?.map((item: any) => (
                  <React.Fragment key={item?.id}>
                    <Typography variant="subtitle2" className="field">
                      {item?.name_en} / {item?.name_ar}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className="field"
                      align="center"
                    >
                      {item?.additionalDemandsText}
                    </Typography>
                  </React.Fragment>
                ))}
              </div>
              <Box display="flex" flexDirection="column" flex={1}>
                <Box display="flex" marginTop={1}>
                  <div className="total-details">
                    <Typography sx={{ fontWeight: 500 }}>
                      Order Sub Total
                    </Typography>
                    <Typography align="right" sx={{ fontWeight: 500 }}>
                      QAR{" "}
                      {(
                        orderDetails?.data?.order_total_price +
                        orderDetails?.data?.discount_value -
                        orderDetails?.data?.details?.catering_delivery_fees
                      )?.toLocaleString("en-US")}
                    </Typography>
                    <Typography>Delivery Charges </Typography>
                    <Typography align="right">
                      {`QAR ${orderDetails?.data?.details?.catering_delivery_fees?.toLocaleString(
                        "en-US"
                      )}`}
                    </Typography>
                    <Typography>VAT</Typography>{" "}
                    <Typography align="right">
                      {` QAR ${orderDetails?.data?.details?.tax?.toLocaleString(
                        "en-US"
                      )}`}
                    </Typography>
                    {orderDetails?.data?.discount_value > 0 && (
                      <>
                        <Typography sx={{ fontWeight: 500 }}>
                          Meiz Welcome Discount ({orderDetails?.data?.discount}
                          %)
                        </Typography>
                        <Typography align="right" sx={{ fontWeight: 500 }}>
                          QAR{" "}
                          {orderDetails?.data?.discount_value?.toLocaleString(
                            "en-US"
                          )}
                        </Typography>
                      </>
                    )}
                    {orderDetails?.data?.coupon_discount_value > 0 && (
                      <>
                        <Typography className="item">
                          Coupon Discount (
                          {orderDetails?.data?.details?.coupon_discount}
                          %)
                        </Typography>
                        <Typography className="item">
                          QAR &nbsp;&nbsp;&nbsp;{" "}
                          {orderDetails?.data?.coupon_discount_value?.toLocaleString(
                            "en-US"
                          )}
                        </Typography>
                      </>
                    )}
                    {orderDetails?.data?.payment_method !==
                      "cashondelivery" && (
                      <>
                        <Typography>
                          Advance Paid{" "}
                          {`  ${orderDetails?.data?.store?.catering_advance}%`}
                        </Typography>
                        <Typography align="right">
                          QAR{" "}
                          {orderDetails?.data?.payed_amount?.toLocaleString(
                            "en-US"
                          )}
                        </Typography>
                      </>
                    )}
                    <Typography sx={{ fontWeight: 600 }}>Total</Typography>
                    <Typography align="right" sx={{ fontWeight: 600 }}>
                      {` QAR ${orderDetails?.data?.order_total_price?.toLocaleString(
                        "en-US"
                      )}`}
                    </Typography>
                  </div>
                </Box>
                <Box display="flex" marginTop={1}>
                  <div className="total-advance">
                    <Typography>Balance Amount </Typography>
                    <Typography align="right">
                      {`QAR ${orderDetails?.data?.remaining_amount?.toLocaleString(
                        "en-US"
                      )}`}
                    </Typography>
                  </div>
                </Box>
              </Box>
            </Box>
          </>
        ) : (
          <CircularProgress color="secondary" style={{ margin: "auto" }} />
        )}
      </ComponentToPrint>
    );
  }
);
