import styled from "styled-components";

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

export const BoxPanel = styled.div`
  border: 1px solid #dddddd;
  border-radius: 0px 20px 20px 20px;
`;
