import styled from "styled-components";

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 15px;
  .mainInput {
    padding: 12px 20px 12px;
  }
  .drop_top_time {
    position: absolute;
    display: flex;
    top: 47px;
    width: 200px;
    align-items: center;
    background: #eeeeee;
    margin-top: 3px;
    padding: 6px;
    border-radius: 10px;
    box-sizing: border-box;
    gap: 8px;
  }
`;
