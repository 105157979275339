import styled from "styled-components";

export const Page = styled.div`
  /* padding: 20px; */
`;

export const BoxPanel = styled.div`
  border-top: 1px solid #dddddd;

  /* border-radius: 0px 20px 20px 20px; */
`;
