import isEmpty from "validator/lib/isEmpty";

import { FormCouponModalType } from "./FormCouponModal-type";
import { message } from "../../utils/message";

export type Error = {
  title_en?: string;
  short_description_en?: string;
  title_ar?: string;
  short_description_ar?: string;
  discount?: string;
  meiz_contribution?: string;
  start_date?: any;
  end_date?: any;
  code?: string;
};

export const Validation = (
  values: FormCouponModalType,
  edit?: boolean
): Error => {
  const errors: Error = {};

  Object.keys(values).forEach((key: string) => {
    switch (key) {
      case "title_en":
        if (isEmpty(values.title_en || "")) {
          errors.title_en = message.couponsForm.title_en;
        }
        break;
      case "short_description_en":
        if (isEmpty(values.short_description_en || "")) {
          errors.short_description_en =
            message.couponsForm.short_description_en;
        }
        break;
      case "title_ar":
        if (isEmpty(values.title_ar || "")) {
          errors.title_ar = message.couponsForm.title_ar;
        }
        break;
      case "short_description_ar":
        if (isEmpty(values.short_description_ar || "")) {
          errors.short_description_ar =
            message.couponsForm.short_description_ar;
        }
        break;
      case "discount":
        if (isEmpty(values.short_description_ar || "")) {
          errors.discount = message.couponsForm.discount;
        }
        break;
      case "meiz_contribution":
        if (!edit) {
          if (isEmpty(values.short_description_ar || "")) {
            errors.meiz_contribution = message.couponsForm.meiz_contribution;
          }
        }

        break;
      case "start_date":
        if (!edit) {
          if (isEmpty(values.start_date || "")) {
            errors.start_date = message.couponsForm.start_date;
          }
        }
        break;
      case "end_date":
        if (!edit) {
          if (isEmpty(values.end_date || "")) {
            errors.end_date = message.couponsForm.end_date;
          }
        }
        break;
      case "code":
        if (!edit) {
          if (isEmpty(values.code || "")) {
            errors.code = message.couponsForm.code;
          }
        }
        break;

      default:
        break;
    }
  });

  return errors;
};
