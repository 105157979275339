/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class CateringFaqService {

    /**
     * get catering FAQ
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getCateringFaq(): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/admins/settings/faqs/getCateringFAQ`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * save catering FAQ
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static saveCateringFaq(
        requestBody?: {
            question_en?: string;
            question_ar?: string;
            answer_en?: string;
            answer_ar?: string;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/settings/faqs/saveCateringFAQ`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * update catering FAQ
     * @param id ID of FAQ
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static updateDiscount(
        id: number,
        requestBody?: {
            question_en?: string;
            question_ar?: string;
            answer_en?: string;
            answer_ar?: string;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/settings/faqs/updateCateringFAQ/${id}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * delete an FAQ
     * @param id ID of FAQ
     * @returns any Successful operation
     * @throws ApiError
     */
    public static deleteCateringFaq(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/api/admins/settings/faqs/deleteCateringFAQ/${id}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

}