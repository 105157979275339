export * from "./AppMenuItems";
export * from "./GenerateKey";

export const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);
  const regex = /[0-9]/;
  if (!regex.test(keyValue)) {
    event.preventDefault();
  }
};

export const handleNamePaymentMethod = (value?: string): string => {
  switch (value) {
    case "cashondelivery":
      return "Cash on delivery";
    case "creditcard":
      return "Credit card";
    default:
      return "";
  }
};
