import * as React from "react";
import { useParams, RouteComponentProps } from "react-router-dom";
import Box from "@mui/material/Box";
import { useMutation, useQueryClient, useQuery } from "react-query";

import { Button, LoadingButton } from "../../../commons";
import { StoreForm } from "../../../components";
import {
  serviceUnavailable,
  successfulOperation,
} from "../../../utils/message";

import { SnackbarContext } from "../../../App";

import { RestaurantStoresService } from "../../../services/openApi";

import { StoreFormType } from "./FormStore-types";
import { validation, Error } from "./FormStore-validation";
import { Page } from "./FormStore-styles";

export const FormStore = (props: RouteComponentProps): JSX.Element => {
  const { id, idStore } = useParams<{ id?: string; idStore?: string }>();
  const { saveStore, getStoreDetails, updateStore } = RestaurantStoresService;
  const { setSnack } = React.useContext(SnackbarContext);
  const queryClient = useQueryClient();

  const [formState, setFormState] = React.useState<StoreFormType>({
    name_en: undefined,
    name_ar: undefined,
    catering_comission: undefined,
    value_added_tax: undefined,
    cancellation_days: undefined,
    catering_advance: undefined,
    credit_card_fee: undefined,
    debit_card_fee: undefined,
    delivery_type: "Catering",
    email: undefined,
    phone: undefined,
    description_en: undefined,
    description_ar: undefined,
    max_catering_per_day: undefined,
    logofile: undefined,
    thumbfile: undefined,
    imagefile: undefined,
  });

  const [formError, setFormError] = React.useState<Error>({});

  const getStoreDetailsById = useQuery(
    ["getStoreDetails", idStore],
    () => getStoreDetails(+idStore!),
    {
      onSuccess: async (data) => {
        const dataStore = await data?.store;
        setFormState({
          name_en: dataStore?.name_en,
          name_ar: dataStore?.name_ar,
          catering_comission: dataStore?.catering_comission,
          value_added_tax: dataStore?.value_added_tax,
          catering_advance: dataStore?.catering_advance,
          credit_card_fee: dataStore?.credit_card_fee,
          debit_card_fee: dataStore?.debit_card_fee,
          email: dataStore?.email,
          description_en: dataStore?.description_en,
          description_ar: dataStore?.description_ar,
          phone: dataStore?.phone ? dataStore.phone.substring(3) : undefined,
          address_en: dataStore?.address_en,
          address_ar: dataStore?.address_ar,
          max_catering_per_day: dataStore?.max_catering_per_day,
          drop_off_time: dataStore?.drop_off_time,
        });
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
      enabled: !!idStore,
    }
  );

  const addStore = useMutation(() => saveStore(+id!, formState), {
    onSuccess: () => {
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
      queryClient.fetchQuery("getAllStores");
      props.history.goBack();
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const editStore = useMutation(() => updateStore(+idStore!, formState), {
    onSuccess: () => {
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
      queryClient.fetchQuery("getAllStores");
      props.history.goBack();
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormState({
      ...formState,
      [name]: value,
    });
    setFormError({
      ...formError,
      [name]: "",
    });
  };

  const handlePicture = (name: string, picture?: Blob) => {
    setFormState({
      ...formState,
      [name]: picture,
    });
    setFormError({
      ...formError,
      [name]: "",
    });
  };

  const handleDropOffTimeFrom = (value?: string) => {
    let dropOffTime: string | undefined = formState.drop_off_time || "";
    const position = dropOffTime.indexOf("-");
    if (value) {
      if (position === -1) {
        // dropOffTime = value?.concat("-");
        dropOffTime = value;
      } else {
        const str = dropOffTime?.substring(position, dropOffTime?.length);
        dropOffTime = value?.concat(str);
      }
      setFormState({
        ...formState,
        drop_off_time: dropOffTime,
      });
    }
  };

  const handleDropOffTimeTo = (value?: string) => {
    let dropOffTime: string | undefined = formState.drop_off_time || "";
    const position = dropOffTime.indexOf("-");
    if (value) {
      if (position === -1) {
        // dropOffTime = "-"?.concat(value);
        dropOffTime = dropOffTime + "-"?.concat(value);
      } else {
        const str = dropOffTime?.substring(0, position + 1);
        dropOffTime = str?.concat(value);
      }
      setFormState({
        ...formState,
        drop_off_time: dropOffTime,
      });
    }
  };

  const handleSubmit = () => {
    const resultValidation = validation(formState);
    if (Object.entries(resultValidation).length === 0) {
      if (idStore) {
        editStore.mutate();
      } else {
        addStore.mutate();
      }
    } else {
      setFormError({ ...resultValidation });
    }
  };

  return (
    <Page className="scrolbar">
      <StoreForm
        data={formState}
        formValidation={formError}
        loading={getStoreDetailsById?.isLoading}
        uriLogo={getStoreDetailsById?.data?.store?.logo?.uri}
        uriThumb={getStoreDetailsById?.data?.store?.thumb?.uri}
        uriImage={getStoreDetailsById?.data?.store?.image?.uri}
        onHandleChange={handleChange}
        onHandlePicture={handlePicture}
        onHandleDropOffTimeFrom={handleDropOffTimeFrom}
        onHandleDropOffTimeTo={handleDropOffTimeTo}
        onHandleCleanDropOffTime={() =>
          setFormState({
            ...formState,
            drop_off_time: undefined,
          })
        }
      />

      <Box display="flex" height={45} justifyContent="flex-end" gap={2}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => props.history.goBack()}
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="secondary"
          onClick={handleSubmit}
          loading={
            addStore.isLoading ||
            getStoreDetailsById.isLoading ||
            editStore.isLoading
          }
        >
          Save Store
        </LoadingButton>
      </Box>
    </Page>
  );
};
