import * as React from "react";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
// import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import Logout from "@mui/icons-material/Logout";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { Dialog, TextField } from "../../commons";

import { SnackbarContext } from "../../App";
import { serviceUnavailable, successfulOperation } from "../../utils/message";

import {
  AuthenticationService,
  RoleStoreUserAuthenticationService,
  RoleRestaurantAuthenticationService,
} from "../../services/openApi";

import { Container } from "./DropDownProfile-styles";
import { validation, Error } from "./FormChangePassword-validation";

type modalState = {
  open: boolean;
  title: string;
  action?: "update";
};

export type formState = {
  old_password?: string;
  new_password?: string;
  confirmation_password?: string;
};

export const DropDownProfile = (): JSX.Element => {
  const history = useHistory();
  const kind = localStorage.getItem("kind");
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);

  const { logout, getAuthenticatedUserInfo, changePassword } =
    AuthenticationService;
  const {
    getAuthenticatedUserInfo: getAuthenticatedStoreUserInfo,
    changePassword: changePasswordStoreUser,
  } = RoleStoreUserAuthenticationService;
  const {
    getAuthenticatedUserInfo: getAuthenticatedRestaurantUserInfo,
    changePassword: changePasswordRestaurantUser,
  } = RoleRestaurantAuthenticationService;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [openModal, setOpenModal] = React.useState<modalState>({
    open: false,
    title: "",
  });

  const [formState, setFormState] = React.useState<formState>({
    old_password: "",
    new_password: "",
    confirmation_password: "",
  });
  const [formError, setFormError] = React.useState<Error>({});

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getUserInfo = useQuery(
    "getAuthenticatedUserInfo",
    () => getAuthenticatedUserInfo(),
    {
      enabled: kind === "admin",
    }
  );

  const getStoreUserInfo = useQuery(
    "getAuthenticatedStoreUserInfo",
    () => getAuthenticatedStoreUserInfo(),
    {
      enabled: kind === "store_user",
    }
  );

  const getRestaurantUserInfo = useQuery(
    "getAuthenticatedStoreUserInfo",
    () => getAuthenticatedRestaurantUserInfo(),
    {
      enabled: kind === "restaurant",
    }
  );

  const fullName =
    getUserInfo?.data?.userInfo?.name ||
    getStoreUserInfo?.data?.userInfo?.name ||
    getRestaurantUserInfo?.data?.userInfo?.name ||
    "";

  const { mutate, isLoading } = useMutation(logout, {
    onError: () => {
      setSnack({
        message: serviceUnavailable,
        severity: "error",
        open: true,
      });
    },
    onSuccess: () => {
      queryClient.removeQueries();
    },
  });

  const changePasswordAdmin = useMutation(() => changePassword(formState), {
    onSuccess: () => {
      setSnack({
        message: successfulOperation,
        severity: "success",
        open: true,
      });
      setOpenModal({
        open: false,
        title: "",
      });
    },
    onError: async (error: any) => {
      const messageError1 = await error?.body?.error;
      const messageError2 = await error?.body?.messsage;

      setSnack({
        message: messageError1 || messageError2 || serviceUnavailable,
        severity: "error",
        open: true,
      });
    },
  });

  const changePasswordStore = useMutation(
    () => changePasswordStoreUser(formState),
    {
      onSuccess: () => {
        setSnack({
          message: successfulOperation,
          severity: "success",
          open: true,
        });
        setOpenModal({
          open: false,
          title: "",
        });
      },
      onError: async (error: any) => {
        const messageError1 = await error?.body?.error;
        const messageError2 = await error?.body?.messsage;

        setSnack({
          message: messageError1 || messageError2 || serviceUnavailable,
          severity: "error",
          open: true,
        });
      },
    }
  );

  const changePasswordRestaurant = useMutation(
    () => changePasswordRestaurantUser(formState),
    {
      onSuccess: () => {
        setSnack({
          message: successfulOperation,
          severity: "success",
          open: true,
        });
        setOpenModal({
          open: false,
          title: "",
        });
      },
      onError: async (error: any) => {
        const messageError1 = await error?.body?.error;
        const messageError2 = await error?.body?.messsage;

        setSnack({
          message: messageError1 || messageError2 || serviceUnavailable,
          severity: "error",
          open: true,
        });
      },
    }
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormState({
      ...formState,
      [name]: value,
    });
    setFormError({
      ...formError,
      [name]: "",
    });
  };

  const handleChangePassword = () => {
    const resultValidation = validation(formState);
    if (Object.entries(resultValidation).length === 0) {
      if (kind === "admin") {
        changePasswordAdmin.mutate();
      } else if (kind === "store_user") {
        changePasswordStore.mutate();
      } else if (kind === "restaurant") {
        changePasswordRestaurant.mutate();
      }
    } else {
      setFormError({ ...resultValidation });
    }
  };

  const handleLogout = () => {
    mutate();
    localStorage.removeItem("token");
    localStorage.removeItem("kind");
    history.replace("/");
  };

  // const handleProfile = () => {
  //   history.push("/main/profile");
  // };

  return (
    <Container>
      <Avatar alt={fullName}>{fullName.charAt(0)}</Avatar>
      <Typography variant="subtitle1" className="profile-name">
        {fullName}
      </Typography>
      <IconButton
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        size="small"
      >
        <ExpandMoreIcon color="secondary" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            width: "fit-content",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {/* <MenuItem onClick={handleProfile}>
          <ListItemIcon>
            <PersonOutlineIcon fontSize="medium" />
          </ListItemIcon>
          Profile
        </MenuItem> */}
        <MenuItem
          onClick={() =>
            setOpenModal({
              open: true,
              title: "Change Password",
              action: "update",
            })
          }
        >
          <ListItemIcon>
            <ChangeCircleOutlinedIcon fontSize="medium" />
          </ListItemIcon>
          Change password
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {openModal.open && (
        <Dialog
          open={openModal.open}
          title={openModal.title}
          action={openModal.action}
          handleContent={handleChangePassword}
          loading={
            changePasswordAdmin.isLoading ||
            changePasswordStore.isLoading ||
            changePasswordRestaurant.isLoading
          }
          onClose={() =>
            setOpenModal({
              open: false,
              title: "",
            })
          }
        >
          <Box display="flex" width={350} flexDirection="column" gap={2}>
            <TextField
              name="old_password"
              type="password"
              label="Old Password"
              color="secondary"
              fullWidth
              onChange={handleChange}
              error={!!formError?.old_password}
              helperText={formError?.old_password}
            />
            <TextField
              name="new_password"
              type="password"
              label="New Password"
              color="secondary"
              fullWidth
              onChange={handleChange}
              error={!!formError?.new_password}
              helperText={formError?.new_password}
            />
            <TextField
              name="confirmation_password"
              type="password"
              label="Confirm New password"
              color="secondary"
              fullWidth
              onChange={handleChange}
              error={!!formError?.confirmation_password}
              helperText={formError?.confirmation_password}
            />
          </Box>
        </Dialog>
      )}
    </Container>
  );
};
