import styled from "styled-components";

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 20px;
  border: 1px solid #dddddd;
  border-radius: 0px 20px 20px 20px;
  .list-premission-container {
    border: 1px solid #dddddd;
    border-radius: 20px;
  }
  .value-element {
    padding: 10px;
  }
  .list-permission-element {
    border-top: 1px solid #dddddd;
    :first-child {
      border-top: none;
    }
  }
`;
