import styled from "styled-components";

export const Page = styled.div`
  display: grid;
  grid-template-rows: 50px 1fr;
  padding: 20px;
  .page-action {
    display: grid;
    grid-template-columns: minmax(400px, 770px) 250px;
    justify-content: space-between;
    gap: 20px;
    .btn-add {
      height: 44px;
    }
  }
  .btn-edit {
    height: 35px;
    background-color: #ecf4df;
    color: #75bd00;
    svg {
      margin-left: 10px;
    }
    box-shadow: none;
  }
  .btn-delete {
    height: 35px;
    background-color: #fdf4f7;
    color: #b93e5c;
    svg {
      margin-left: 10px;
    }
    box-shadow: none;
  }
`;
