/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class RoleRestaurantAreasService {

    /**
     * List of areas
     * @returns any Successful operation
     * @throws ApiError
     */
    public static areaList(): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/restaurant/restaurant/area/AreaList`,
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * get area by id
     * @param id ID of area
     * @returns any Successful operation
     * @throws ApiError
     */
    public static areaDetails(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/restaurant/restaurant/area/AreaDetails/${id}`,
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * list of areas by region id
     * @param id ID of region
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getAreaByRegionId(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/restaurant/restaurant/area/getAreaByRegionId/${id}`,
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * save an area
     * @param formData
     * @returns any Successful operation
     * @throws ApiError
     */
    public static saveArea(
        formData: {
            name_en?: string;
            name_ar?: string;
            region_id?: number;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/restaurant/restaurant/area/saveArea`,
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * update an area
     * @param id ID of area
     * @param formData
     * @returns any Successful operation
     * @throws ApiError
     */
    public static updateArea(
        id: number,
        formData: {
            name_en?: string;
            name_ar?: string;
            region_id?: number;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/restaurant/restaurant/area/updateArea/${id}`,
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * delete an area
     * @param id ID of area
     * @returns any Successful operation
     * @throws ApiError
     */
    public static deleteArea(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/api/restaurant/restaurant/area/deleteArea/${id}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

}