import * as React from "react";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

type TreeItemProps = {
  id?: number;
  name: string;
  status?: number;
  onActive?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onAction?: (event: React.MouseEvent<HTMLElement>) => void;
  onSelect: (id: number) => void;
};

const useStyles = makeStyles((theme) => ({
  selectRoot: {
    "&:focus": {
      backgroundColor: "#EDFAFF",
    },
  },
}));

export const TreeItem = ({
  id,
  name,
  status,
  onActive,
  onAction,
  onSelect,
}: TreeItemProps): JSX.Element => {
  const classes = useStyles();
  return (
    <MenuItem
      dense
      classes={{ root: classes.selectRoot }}
      onClick={() => id && onSelect(id)}
    >
      <ListItemIcon>
        <DragIndicatorIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText style={{ fontWeight: 600, textTransform: "capitalize" }}>
        {name}
      </ListItemText>
      <Box display="flex">
        {status !== undefined && (
          <Switch
            checked={Boolean(status)}
            onChange={onActive}
            color="secondary"
          />
        )}
        {onAction && (
          <IconButton onClick={onAction}>
            <MoreVertIcon />
          </IconButton>
        )}
      </Box>
    </MenuItem>
  );
};
