import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 20px;
  .header {
    display: flex;
    height: 70px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dddddd;
    padding: 20px;
    .title {
      font-weight: 600;
      color: ${({ theme }) => theme.palette.secondary.main};
    }
  }
  .list-regions {
    max-height: calc(100vh - 210px);
    margin-top: 5px;
    overflow-x: auto;
  }
`;
