import styled from "styled-components";
import Accordion from "@mui/material/Accordion";

export const AccordionStyled = styled(Accordion)`
  border: 1px solid #dddddd;
  border-radius: 20px;
  box-shadow: none;
  &::before {
    display: none;
    margin: 0;
  }
  .Mui-expanded {
    margin: 0;
  }
  .MuiAccordionDetails-root {
    padding: 0;
  }
`;
