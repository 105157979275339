import * as React from "react";
import {
  LazyLoadImage,
  LazyLoadImageProps,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export const LazyLoadingImage = React.memo(
  (props: LazyLoadImageProps): JSX.Element => {
    return <LazyLoadImage {...props} effect="blur" />;
  }
);
