import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import SearchIcon from "@mui/icons-material/Search";
import { useQuery, useMutation, useQueryClient } from "react-query";
import moment from "moment";

import { Input, Button, Dialog, LazyLoadingImage } from "../../../../commons";
import Table, { ColumnsProps } from "../../../../commons/Table";
import { CalendarIcon } from "../../../../img/icon";
import { SnackbarContext } from "../../../../App";
import {
  serviceUnavailable,
  successfulOperation,
} from "../../../../utils/message";

import { RoleStoreUserStoresService } from "../../../../services/openApi";

import { Page } from "./StoreRestaurant-style";

type openFormState = {
  open: boolean;
  title: string;
  action?: "delete" | "add" | "update";
};

export const StoreStores = (props: RouteComponentProps): JSX.Element => {
  const queryClient = useQueryClient();
  const { getAllStores, updateCateringStatus } = RoleStoreUserStoresService;
  const { setSnack } = React.useContext(SnackbarContext);

  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);

  const [openStatus, setOpenStatus] = React.useState<openFormState>({
    open: false,
    title: "",
  });

  const [idStore, setIdStore] = React.useState<number | undefined>(undefined);
  const [search, setSearch] = React.useState<string>("");
  const [status, setStatus] = React.useState<boolean>(false);

  const listStoresOfRestaurant = useQuery(
    ["getAllStoresRestaurant", search],
    () => getAllStores({ search }),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const updateStatus = useMutation(
    () => updateCateringStatus(idStore!, { catering_status: status }),
    {
      onSuccess: () => {
        queryClient.fetchQuery(["getAllStoresRestaurant", search]);
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        setOpenStatus({
          open: false,
          title: "",
        });
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const columns: ColumnsProps = [
    {
      header: "ID",
      accessor: "id",
    },
    {
      header: "Title",
      accessor: "title",
    },
    {
      header: "Photo",
      accessor: "photo",
    },
    {
      header: "Carting status",
      accessor: "cartingStatus",
    },
    {
      header: "Created",
      accessor: "created",
    },
    {
      header: "View Calendar",
      accessor: "viewCalendar",
      headerCellProps: { align: "center" },
      cellProps: { align: "center", width: "150px" },
    },
  ];

  const renderTableRows = () => {
    return (
      listStoresOfRestaurant.data
        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        ?.map((item: any) => ({
          id: item?.id,
          title: item?.name,
          photo: (
            <LazyLoadingImage
              alt="store"
              src={item?.image?.uri} // use normal <img> attributes as props
              height={40}
              width={40}
              wrapperClassName="store-image"
            />
          ),
          cartingStatus: (
            <Switch
              checked={Boolean(item?.catering_status)}
              onClick={(e) => e.stopPropagation()}
              onChange={handleUpdateStatus(item?.id)}
            />
          ),
          created: item.created_at
            ? moment(item.created_at).format("DD MMM YYYY")
            : "",
          viewCalendar: (
            <Button
              variant="contained"
              className="btn-view"
              onClick={(e) => {
                e.stopPropagation();
                props.history.push(`/store/stores/${item?.id}/calendar`);
              }}
            >
              Calendar <CalendarIcon />
            </Button>
          ),
        })) || []
    );
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleUpdateStatus =
    (id?: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setStatus(event.target.checked);
      setOpenStatus({
        open: true,
        title: "Update status",
        action: "update",
      });
      id && setIdStore(id);
    };

  return (
    <Page>
      <div className="page-action">
        <Input
          value={search}
          onChange={handleChange}
          onKeyPress={(e) =>
            e.key === "Enter" && listStoresOfRestaurant.refetch()
          }
          placeholder="Search by ID, name or E-mail"
          startAdornment={
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          }
        />
      </div>
      <Box display="flex" flex="1" marginTop={3}>
        <Table
          columns={columns}
          loading={listStoresOfRestaurant.isLoading}
          nbrRowLoading={rowsPerPage}
          data={renderTableRows()}
          emptyMessage="No data available"
          onClickRow={(row) =>
            props.history.push(
              `/store/stores/${row?.id}/categorie-&-carting-menu`
            )
          }
          tablePaginationProps={{
            rowsPerPageOptions: [5, 10, 25],
            count: listStoresOfRestaurant?.data?.length || 0,
            page: page,
            onPageChange: (_, newPage) => setPage(newPage),
            rowsPerPage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
        />
      </Box>

      {openStatus.open && (
        <Dialog
          open={openStatus.open}
          title={openStatus.title}
          action="update"
          handleContent={() => idStore && updateStatus.mutate()}
          loading={updateStatus.isLoading}
          onClose={() =>
            setOpenStatus({
              open: false,
              title: "",
            })
          }
        >
          <Typography>Are you sure to change status this store?</Typography>
        </Dialog>
      )}
    </Page>
  );
};
