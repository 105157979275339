import styled from "styled-components";

export const Page = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 20px;
  overflow: hidden;
  gap: 20px;
  .header-action {
    display: grid;
    grid-template-columns: 2fr 40px 1fr 40px 1fr 150px;
    align-items: center;
    gap: 20px;
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
      .MuiAutocomplete-input {
      padding: 4px;
    }
  }
  .container-table {
    display: flex;
    flex-direction: column;
    border: 1px solid #dddddd;
    thead {
      tr {
        th {
          background: #ebf7fb;
          color: ${(props) => props.theme.palette.secondary.main};
          text-align: center;
        }
      }
    }
    tbody {
      background-color: #fff;
    }
  }
  .total {
    font-weight: 600;
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;
