import { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Tab, Tabs } from "../../commons";
import { Page, BoxPanel } from "./SendNotification-style";
import { PushMessage } from "./PushMessage";
import { Emails } from "./Emails";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <BoxPanel>{children}</BoxPanel>}
    </div>
  );
}

export const SendNotifications = (props: RouteComponentProps) => {
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Page className="scrolbar">
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        TabIndicatorProps={{
          style: {
            display: "none",
            height: "40px",
          },
        }}
      >
        <Tab
          label="E-Mails"
          index={0}
          selected={value === 0}
          {...a11yProps(0)}
        />
        {/* <Tab label="SMS" index={1} selected={value === 1} {...a11yProps(1)} /> */}
        <Tab
          label="Push message"
          index={1}
          selected={value === 1}
          {...a11yProps(1)}
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Emails />
      </TabPanel>
      {/* <TabPanel value={value} index={1}>
        <SMS />
      </TabPanel> */}
      <TabPanel value={value} index={1}>
        <PushMessage />
      </TabPanel>
    </Page>
  );
};
