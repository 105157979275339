import styled from "styled-components";

export const CardCartingMenuStyled = styled.div<{ p?: boolean }>`
  display: flex;
  width: 100%;
  border: 1px solid #dddddd;
  align-items: center;
  border-radius: 20px;
  padding: ${(props) => (props.p ? "0px" : "10px")};
  gap: 16px;
  img {
    width: 120px;
    height: 190px;
    object-fit: cover;
    border-radius: 20px;
  }
  .title {
    color: ${({ theme }) => theme.palette.secondary.main};
    font-weight: 600;
    text-transform: capitalize;
  }
  .price {
    width: max-content;
    background: #e0f3f9;
    color: ${({ theme }) => theme.palette.secondary.main};
    border-radius: 20px;
    padding: 5px 10px;
    margin: 5px 0 8px;
  }
  .MuiCheckbox-root {
    padding: 5px;
  }
`;
