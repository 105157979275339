import styled from "styled-components";

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  background: #fff;
  border: 1px solid #dddddd;
  border-radius: 20px;
  margin: 20px;
  padding: 20px;
  .switch {
    width: fit-content;
    margin-left: 10px;
    .MuiFormControlLabel-label {
      color: #302d60;
      font-weight: 500;
    }
  }
  .item1 {
    grid-area: discount;
  }
  .itemEmpty {
    grid-area: empty;
  }
  .item2 {
    grid-area: textInEnglish;
  }
  .item3 {
    grid-area: textInArabic;
  }
  .from {
    display: grid;
    grid-template-areas: "discount empty" "textInEnglish textInArabic";
    gap: 16px;
    margin: 10px 0 30px 0;
  }
  .btn-save {
    width: 120px;
    margin-left: auto;
    svg {
      margin-left: 5px;
    }
  }
`;
