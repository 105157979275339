/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class SettingsService {

    /**
     * save multi image
     * @param formData
     * @returns any success
     * @throws ApiError
     */
    public static saveMultiImages(
        formData?: {
            /**
             * images
             */
            images?: Array<Blob>;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/settings/saveMultiImages`,
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * get application logos
     * @returns any success
     * @throws ApiError
     */
    public static getApplicationLogo(): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/admins/settings/getApplicationLogo`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * save application logos
     * @param formData
     * @returns any success
     * @throws ApiError
     */
    public static changeApplicationLogo(
        formData?: {
            logo?: Blob;
            small_logo?: Blob;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/settings/changeApplicationLogo`,
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

}