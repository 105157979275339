import styled from "styled-components";

export const Page = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 20px;
  .MuiStepIcon-root.Mui-active {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
  .MuiStepIcon-root.Mui-completed {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;
