import styled from "styled-components";

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 15px;

  .form-address {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    gap: 20px;
    padding: 20px;
    border: 1px solid #dddddd;
    border-radius: 20px;
  }
`;
