/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class FilterCollectionsService {

    /**
     * List collections
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getFilterCollections(
        requestBody?: {
            search?: string;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/settings/filterCollection/getFilterCollections`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * get menus by stores ids
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getItemListByStoresId(
        requestBody?: {
            search?: string;
            /**
             * store ids array
             */
            stores_id?: Array<number>;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/settings/filterCollection/getItemListByStoresId`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * get stores
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getAllStoresList(): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/admins/settings/filterCollection/getAllStoresList`,
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * filter collection details
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getFilterCollectionDetails(
        requestBody?: {
            search?: string;
            filter_collection_id?: number;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/settings/filterCollection/getFilterCollectionDetails`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * sort filter collection
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static sortFilterCollections(
        requestBody?: {
            /**
             * collections ids
             */
            collections?: Array<any>;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/settings/filterCollection/sortFilterCollections`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * sort filter collection stores elements
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static sortFilterCollectionStores(
        requestBody?: {
            /**
             * collections store elements ids
             */
            elements?: Array<any>;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/settings/filterCollection/sortFilterCollectionStores`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * sort filter collection items elements
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static sortFilterCollectionItems(
        requestBody?: {
            /**
             * collections item elements ids
             */
            elements?: Array<any>;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/settings/filterCollection/sortFilterCollectionItems`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * save filter collection
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static saveFilterCollection(
        requestBody?: {
            name_en?: string;
            name_ar?: string;
            type?: string;
            color?: string;
            style?: string;
            /**
             * stores ids
             */
            stores?: Array<number>;
            /**
             * items ids
             */
            items?: Array<any>;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/settings/filterCollection/saveFilterCollection`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * update filter collection
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static updateFilterCollection(
        requestBody?: {
            name_en?: string;
            name_ar?: string;
            type?: string;
            color?: string;
            style?: string;
            /**
             * stores ids
             */
            stores?: Array<number>;
            /**
             * items ids
             */
            items?: Array<number>;
            filter_collection_id?: number;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/settings/filterCollection/updateFilterCollection`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * update filter collection
     * @param id ID of filter collection
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static updateStatus(
        id: number,
        requestBody: {
            status?: boolean;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/settings/filterCollection/updateStatus/${id}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * remove elements from collection
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static removeElementsFromCollection(
        requestBody?: {
            type?: string;
            element_id?: number;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/settings/filterCollection/removeElementsFromCollection`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * delete filter collection
     * @param id ID of collection
     * @returns any Successful operation
     * @throws ApiError
     */
    public static deleteFilterCollection(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/api/admins/settings/filterCollection/delete/${id}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

}