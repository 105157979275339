/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { TextField, Dialog } from "../../commons";

import {
  RestaurantItemAddonsService,
  RestaurantItemAddonOptionsService,
} from "../../services/openApi";
import { SnackbarContext } from "../../App";

import { serviceUnavailable, successfulOperation } from "../../utils/message";

import { EditIcon, TrashIcon, SaveIcon } from "../../img/icon";

import { AddonsDetailsType, AddonsOptionType } from "./AddonsContainer-type";
import { Container } from "./AddonsContainer-style";

type AddonsContainerProps = {
  idAddon?: number;
  addNewAddonOption: boolean;
  onEnableBtnAddOption: (value: boolean) => void;
};

type ModalProps = {
  open: boolean;
  title: string;
  action?: "delete" | "add" | "update";
};

const headerAddonsOptions = [
  "Name(En)",
  "Name(Ar)",
  "Price",
  "Max Qty select",
  "Show on menu",
  "Actions",
];

export const AddonsContainer = ({
  idAddon,
  addNewAddonOption,
  onEnableBtnAddOption,
}: AddonsContainerProps): JSX.Element => {
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);

  const { getAddonById, updateAddon } = RestaurantItemAddonsService;
  const { saveItemAddonOption, deleteItemAddonOption, updateItemAddonOption } =
    RestaurantItemAddonOptionsService;

  const [addonsDetailsForm, setAddonsDetailsForm] =
    React.useState<AddonsDetailsType>({ readOnly: true });

  const [listAddonsOption, setListAddonsOption] = React.useState<
    AddonsOptionType[]
  >([]);

  const [addonsOption, setAddonsOption] = React.useState<
    AddonsOptionType | undefined
  >({
    show_onmenu: true,
  });

  const [openForm, setOpenForm] = React.useState<ModalProps>({
    open: false,
    title: "",
  });

  const [idOption, setIdOption] = React.useState<number | undefined>();

  const getAddonDetails = useQuery(
    ["getAddonById", idAddon],
    () => getAddonById(idAddon!),
    {
      onSuccess: (data) => {
        const arrayAddonsOption: AddonsOptionType[] = [];
        const {
          item_addon_name_en,
          item_addon_name_ar,
          item_id,
          show_onmenu,
          item_addon_options,
        } = data;

        item_addon_options?.forEach((item: any) => {
          arrayAddonsOption.push({
            id: item?.id,
            item_addon_id: item?.item_addon_id,
            name_en:
              item?.item_addon_option_translations?.[0]?.item_addon_option_name,
            name_ar:
              item?.item_addon_option_translations?.[1]?.item_addon_option_name,
            max_selection: item?.max_selection,
            item_addon_option_price: item?.item_addon_option_price,
            show_onmenu: Boolean(item?.show_onmenu),
            readOnly: true,
          });
        });
        setAddonsDetailsForm({
          name_en: item_addon_name_en,
          name_ar: item_addon_name_ar,
          show_onmenu: Boolean(show_onmenu),
          item_id,
          readOnly: true,
        });
        setListAddonsOption(arrayAddonsOption);
        setAddonsOption(undefined);
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
      enabled: !!idAddon,
    }
  );

  const updateAddonDetails = useMutation(
    () => updateAddon(idAddon!, { ...addonsDetailsForm }),
    {
      onSuccess: () => {
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        queryClient.refetchQueries(["getAddonById", idAddon]);
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  React.useEffect(() => {
    if (addNewAddonOption) {
      setListAddonsOption((prevListAddonsOption) => {
        const arrayListAddonsOption = [...(prevListAddonsOption || [])];
        arrayListAddonsOption.push({
          name_en: undefined,
          name_ar: undefined,
          item_addon_id: idAddon,
          item_addon_option_price: undefined,
          max_selection: undefined,
          show_onmenu: true,
          readOnly: false,
          new: true,
        });
        return arrayListAddonsOption;
      });
    }
  }, [addNewAddonOption]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setAddonsDetailsForm({
      ...addonsDetailsForm,
      [name]: value,
    });
  };

  const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setAddonsDetailsForm({
      ...addonsDetailsForm,
      [name]: checked,
    });
  };

  const handleEnableEditAddons = () => {
    setAddonsDetailsForm({
      ...addonsDetailsForm,
      readOnly: false,
    });
  };

  const handleCancelEditAddons = () => {
    if (getAddonDetails?.data) {
      const { item_addon_name_en, item_addon_name_ar, item_id } =
        getAddonDetails?.data;
      setAddonsDetailsForm({
        name_en: item_addon_name_en,
        name_ar: item_addon_name_ar,
        show_onmenu: true,
        item_id,
        readOnly: true,
      });
    }
  };

  const handleSaveAddons = () => {
    if (idAddon) updateAddonDetails.mutate();
  };

  const btnOptionDisabled = listAddonsOption?.findIndex(
    (o) => o.readOnly === false
  );

  React.useEffect(() => {
    onEnableBtnAddOption(btnOptionDisabled !== -1);
  }, [btnOptionDisabled]);

  const handleEnableEditOption = (index: number) => {
    setListAddonsOption((prevListAddonsOption) => {
      const arrayAddonsOption = [...(prevListAddonsOption || [])];
      arrayAddonsOption[index] = {
        ...arrayAddonsOption[index],
        readOnly: false,
      };
      setAddonsOption(arrayAddonsOption[index]);
      return arrayAddonsOption;
    });
  };

  const handleCancelEditOption = (index: number) => {
    setListAddonsOption((prevListAddonsOption) => {
      const arrayAddonsOption = [...(prevListAddonsOption || [])];
      if (arrayAddonsOption[index].new) {
        arrayAddonsOption.splice(index, 1);
      } else {
        arrayAddonsOption[index] = {
          ...arrayAddonsOption[index],
          readOnly: true,
          new: false,
        };
      }
      return arrayAddonsOption;
    });
    setAddonsOption(undefined);
  };

  const handleChangeOption = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setAddonsOption({
      ...addonsOption,
      [name]: value,
    });
  };

  const handleChangeOptionSwitch = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked } = event.target;
    setAddonsOption({
      ...addonsOption,
      [name]: checked,
    });
  };

  const addOption = useMutation(
    () =>
      saveItemAddonOption({
        ...addonsOption,
        item_addon_id: idAddon,
      }),
    {
      onSuccess: () => {
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        queryClient.refetchQueries(["getAddonById", idAddon]);
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const updateOption = useMutation(
    () =>
      updateItemAddonOption(addonsOption?.id!, {
        ...addonsOption,
      }),
    {
      onSuccess: () => {
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        queryClient.refetchQueries(["getAddonById", idAddon]);
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const deleteOption = useMutation(() => deleteItemAddonOption(idOption!), {
    onSuccess: () => {
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
      queryClient.refetchQueries(["getAddonById", idAddon]);
      setOpenForm({
        open: false,
        title: "",
      });
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const handleAddonSave = () => {
    if (addonsOption?.id) {
      updateOption.mutate();
    } else {
      addOption.mutate();
    }
  };

  return (
    <Container>
      <Typography sx={{ fontWeight: 600 }}>Addons details</Typography>
      <div className="addons-details">
        <TextField
          name="name_en"
          label="Name(En)"
          value={addonsDetailsForm?.name_en || ""}
          onChange={handleChange}
          color="secondary"
          disabled={addonsDetailsForm?.readOnly}
        />
        <TextField
          name="name_ar"
          label="Name(Ar)"
          value={addonsDetailsForm?.name_ar || ""}
          onChange={handleChange}
          color="secondary"
          disabled={addonsDetailsForm?.readOnly}
        />
        <FormControlLabel
          value="start"
          control={
            <Switch
              name="show_onmenu"
              checked={addonsDetailsForm?.show_onmenu || false}
              onChange={handleChangeSwitch}
              color="secondary"
              disabled={addonsDetailsForm?.readOnly}
            />
          }
          label="Show on Menu"
          labelPlacement="start"
        />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap={1}
          marginLeft={1}
        >
          {addonsDetailsForm?.readOnly ? (
            <IconButton
              className={`btn-save ${!idAddon && "disabled"}`}
              onClick={handleEnableEditAddons}
              disabled={!idAddon}
            >
              <EditIcon />
            </IconButton>
          ) : (
            <IconButton
              className={`btn-save ${
                updateAddonDetails.isLoading && "disabled"
              }`}
              onClick={handleSaveAddons}
              disabled={updateAddonDetails.isLoading}
            >
              <SaveIcon />
            </IconButton>
          )}
          <IconButton className="btn-cancel" onClick={handleCancelEditAddons}>
            <CloseIcon />
          </IconButton>
        </Box>
      </div>
      <Typography sx={{ fontWeight: 600 }}>Addons Options</Typography>
      <div className="addons-option">
        {headerAddonsOptions?.map((element, index) => (
          <Typography
            key={index}
            variant="body2"
            align="center"
            className="header-element"
          >
            {element}
          </Typography>
        ))}
        {listAddonsOption?.map((item, index) => (
          <React.Fragment key={index}>
            <Stack sx={{ padding: 1 }}>
              {item?.readOnly ? (
                <Typography
                  variant="body2"
                  align="center"
                  className="value-element"
                >
                  {item?.name_en}
                </Typography>
              ) : (
                <TextField
                  name="name_en"
                  value={addonsOption?.name_en || ""}
                  onChange={handleChangeOption}
                  color="secondary"
                />
              )}
            </Stack>
            <Stack sx={{ padding: 1 }}>
              {item?.readOnly ? (
                <Typography
                  variant="body2"
                  align="center"
                  className="value-element"
                >
                  {item?.name_ar}
                </Typography>
              ) : (
                <TextField
                  name="name_ar"
                  value={addonsOption?.name_ar || ""}
                  onChange={handleChangeOption}
                  color="secondary"
                />
              )}
            </Stack>
            <Stack sx={{ padding: 1 }} className="price">
              {item?.readOnly ? (
                <Typography
                  variant="body2"
                  align="center"
                  className="value-element"
                >
                  {item?.item_addon_option_price &&
                    `${item.item_addon_option_price} QAR`}
                </Typography>
              ) : (
                <TextField
                  type="number"
                  name="item_addon_option_price"
                  value={addonsOption?.item_addon_option_price || ""}
                  onChange={handleChangeOption}
                  color="secondary"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">QAR</InputAdornment>
                    ),
                  }}
                />
              )}
            </Stack>
            <Stack sx={{ padding: 1 }}>
              {item?.readOnly ? (
                <Typography
                  variant="body2"
                  align="center"
                  className="value-element"
                >
                  {item?.max_selection}
                </Typography>
              ) : (
                <TextField
                  type="number"
                  name="max_selection"
                  value={addonsOption?.max_selection || ""}
                  onChange={handleChangeOption}
                  color="secondary"
                />
              )}
            </Stack>
            <Stack direction="row" justifyContent="center" sx={{ padding: 1 }}>
              <Switch
                name="show_onmenu"
                color="secondary"
                checked={
                  item?.readOnly ? item?.show_onmenu : addonsOption?.show_onmenu
                }
                onChange={handleChangeOptionSwitch}
              />
            </Stack>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap={1}
            >
              {item?.readOnly ? (
                <IconButton
                  className="btn-save"
                  onClick={() => handleEnableEditOption(index)}
                  disabled={btnOptionDisabled !== -1}
                >
                  <EditIcon />
                </IconButton>
              ) : (
                <IconButton
                  className="btn-save"
                  onClick={handleAddonSave}
                  disabled={addOption.isLoading}
                >
                  <SaveIcon />
                </IconButton>
              )}
              {item?.readOnly ? (
                <IconButton
                  className="btn-cancel"
                  onClick={() => {
                    setOpenForm({
                      open: true,
                      title: "Delete",
                      action: "delete",
                    });
                    setIdOption(item?.id);
                  }}
                  disabled={btnOptionDisabled !== -1}
                >
                  <TrashIcon />
                </IconButton>
              ) : (
                <IconButton
                  className="btn-cancel"
                  onClick={() => handleCancelEditOption(index)}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </Box>
          </React.Fragment>
        ))}
      </div>

      {listAddonsOption?.length === 0 && (
        <Typography variant="body2" align="center" sx={{ margin: "15px auto" }}>
          No data available
        </Typography>
      )}

      <Dialog
        open={openForm.open}
        title={openForm.title}
        action="delete"
        handleContent={() => idOption && deleteOption.mutate()}
        loading={deleteOption.isLoading}
        onClose={() =>
          setOpenForm({
            open: false,
            title: "",
          })
        }
      >
        <Typography>Are you sure to delete this addon option?</Typography>
      </Dialog>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={getAddonDetails?.isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};
