import styled from "styled-components";
import { ReactSortable, ItemInterface } from "react-sortablejs";

export const Page = styled.div`
  display: grid;
  grid-template-columns: 320px minmax(600px, 1fr);
  gap: 20px;
  padding: 20px;
  .container-menu {
    display: flex;
    height: calc(100vh - 130px);
    min-width: fit-content;
    flex-direction: column;
    background: #fff;
    border: 1px solid #dddddd;
    border-radius: 20px;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #dddddd;
      padding: 20px;
      .title {
        font-weight: 600;
        color: ${({ theme }) => theme.palette.secondary.main};
      }
      a {
        text-decoration: none;
      }
    }
    .msg-empty {
      margin: auto;
    }
  }
`;

export const StyledReactSortable = styled(ReactSortable)<ItemInterface>`
  display: grid;
  grid-template-columns: minmax(350px, 1fr) minmax(350px, 1fr);
  gap: 20px;
  padding: 20px;
  overflow-x: auto;
`;
