/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class RestaurantItemAddonsService {

    /**
     * List of Addons
     * @param id ID of item
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getItemAddon(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/admins/restaurant/addon/index/${id}`,
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * get addon by id
     * @param id ID of addon
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getAddonById(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/admins/restaurant/addon/show/${id}`,
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * save addon
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static saveAddon(
        requestBody?: {
            name_en?: string;
            name_ar?: string;
            item_id?: number;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/restaurant/addon/store`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * update addon
     * @param id ID of addon
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static updateAddon(
        id: number,
        requestBody?: {
            name_en?: string;
            name_ar?: string;
            item_id?: number;
            show_onmenu?: boolean;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/restaurant/addon/update/${id}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * delete an addon
     * @param id ID of addon
     * @returns any Successful operation
     * @throws ApiError
     */
    public static deleteItemAddon(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/api/admins/restaurant/addon/delete/${id}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

}