import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { TextField, Dialog, UploadPicture, Select } from "../../commons";
import { serviceUnavailable, successfulOperation } from "../../utils/message";
import { SnackbarContext } from "../../App";

import { StoreUsersService } from "../../services/openApi";

import { UserFormType } from "./userFormDialog-type";
import { validation, Error } from "./userFormDialog-validation";

type UserFormDialogProps = {
  open: boolean;
  title: string;
  action: "delete" | "add" | "update" | undefined;
  onClose: () => void;
  idStore?: string;
  idUser?: number;
};

export const UserFormDialog = ({
  open,
  title,
  action,
  onClose,
  idStore,
  idUser,
}: UserFormDialogProps) => {
  const queryClient = useQueryClient();
  const { createStoreUser, saveUser, updateUser, getUserDetails } =
    StoreUsersService;
  const { setSnack } = React.useContext(SnackbarContext);

  const [userFormState, setUserFormState] = React.useState<UserFormType>({
    name: undefined,
    email: undefined,
    mobile: undefined,
    imagefile: undefined,
    password: undefined,
    role: undefined,
  });
  const [userFormError, setUserFormError] = React.useState<Error>();

  const listRoles = useQuery("createStoreUser", createStoreUser, {
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const getUserDetailsById = useQuery(
    ["getUserDetails", idUser],
    () => getUserDetails(idUser!),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
      enabled: !!idUser,
    }
  );

  React.useEffect(() => {
    const data = getUserDetailsById?.data;
    if (data) {
      setUserFormState({
        name: data?.name,
        email: data?.email,
        mobile: data?.mobile?.substring(3),
        role: data?.role_name,
      });
    }
  }, [getUserDetailsById?.data]);

  const addUser = useMutation(saveUser, {
    onSuccess: () => {
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
      queryClient.fetchQuery(["getStoreUsers", idStore]);
      queryClient.fetchQuery(["getUserDetails", idUser]);
      onClose();
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const editUser = useMutation(
    () => updateUser(idUser!, { ...userFormState }),
    {
      onSuccess: () => {
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        queryClient.fetchQuery(["getStoreUsers", idStore]);
        queryClient.fetchQuery(["getUserDetails", idUser]);
        onClose();
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const handlePicture = (file: Blob) => {
    setUserFormState({
      ...userFormState,
      imagefile: file,
    });
    setUserFormError({
      ...userFormError,
      imagefile: "",
    });
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setUserFormState({
      ...userFormState,
      [name]: value,
    });
    setUserFormError({
      ...userFormError,
      [name]: "",
    });
  };
  const handleChangeSelect = (value?: unknown) => {
    setUserFormState({
      ...userFormState,
      role: value as string,
    });
    setUserFormError({
      ...userFormError,
      role: "",
    });
  };

  const handleSubmit = () => {
    const resultValidation = validation(userFormState, !!idUser);
    if (Object.entries(resultValidation).length === 0) {
      if (idStore) {
        if (idUser) {
          editUser.mutate();
        } else {
          addUser.mutate({
            ...userFormState,
            store_id: +idStore,
          });
        }
      }
    } else {
      setUserFormError({ ...resultValidation });
    }
  };

  return (
    <Dialog
      open={open}
      title={title}
      maxWidth="sm"
      handleContent={handleSubmit}
      loading={addUser.isLoading || editUser.isLoading}
      loadingUseQuery={getUserDetailsById?.isLoading}
      onClose={onClose}
      action={action}
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <Box display="flex" alignContent="center">
          <UploadPicture
            uriImage={getUserDetailsById?.data?.image?.uri}
            mode="basic"
            onLoad={handlePicture}
            msgError={userFormError?.imagefile}
          />
          <Typography
            variant="subtitle2"
            style={{ margin: "auto 30px", color: "#dad8d8" }}
          >
            The picture must have a size 400px * 400px
          </Typography>
        </Box>
        <TextField
          name="name"
          label="User Name"
          value={userFormState?.name || ""}
          onChange={handleChange}
          color="secondary"
          error={!!userFormError?.name}
          helperText={userFormError?.name}
        />
        <TextField
          name="email"
          label="E-Mail"
          value={userFormState?.email || ""}
          onChange={handleChange}
          color="secondary"
          error={!!userFormError?.email}
          helperText={userFormError?.email}
        />
        <TextField
          name="mobile"
          label="Phone number"
          type="number"
          value={userFormState?.mobile || ""}
          onChange={handleChange}
          color="secondary"
          error={!!userFormError?.mobile}
          helperText={userFormError?.mobile}
        />
        <Select
          name="role"
          label="Role User"
          value={userFormState?.role || ""}
          onChange={(e) => handleChangeSelect(e?.target?.value)}
          color="secondary"
          msgError={userFormError?.role}
        >
          {listRoles?.data?.map((item: any) => (
            <MenuItem value={item?.name} key={item?.id}>
              {item?.name}
            </MenuItem>
          ))}
        </Select>
        <TextField
          name="password"
          label="Password"
          type="password"
          value={userFormState?.password || ""}
          onChange={handleChange}
          color="secondary"
          error={!!userFormError?.password}
          helperText={userFormError?.password}
        />
      </Box>
    </Dialog>
  );
};
