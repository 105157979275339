import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import { useQuery, useQueryClient, useMutation } from "react-query";
import moment from "moment";

import { Dialog, Select } from "../../commons";

import { SnackbarContext } from "../../App";
import { serviceUnavailable, successfulOperation } from "../../utils/message";
import { RoleRestaurantOrdersService } from "../../services/openApi";

type OrderStatusRestaurantDialogProps = {
  idOrder?: number;
  open: boolean;
  title: string;
  action?: "update";
  onClose: () => void;
  page?: number;
  rowsPerPage?: number;
  filterStatus?: any;
};

export const OrderStatusRestaurantDialog = ({
  idOrder,
  open,
  title,
  action,
  onClose,
  page,
  rowsPerPage,
  filterStatus,
}: OrderStatusRestaurantDialogProps): JSX.Element => {
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);
  const { getOrderStatusList, getOrderDetails, updateOrderStatus } =
    RoleRestaurantOrdersService;

  const [idStatus, setIdStatus] = React.useState<number | undefined>();
  const [idReason, setIdReason] = React.useState<number | undefined>();

  const orderDetails = useQuery(
    ["getOrderDetails", idOrder],
    () => getOrderDetails(idOrder!),
    {
      onSuccess: (data) => {
        setIdStatus(data?.order_current_status?.id);
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
      enabled: !!idOrder,
    }
  );

  const statusList = useQuery(
    "getOrderStatusList",
    () => getOrderStatusList(),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  // const cancelReasonsList = useQuery(
  //   "getDashboardCancelReasonsList",
  //   () => getDashboardCancelReasonsList(),
  //   {
  //     onError: () => {
  //       setSnack({
  //         open: true,
  //         severity: "error",
  //         message: serviceUnavailable,
  //       });
  //     },
  //     enabled: false,
  //   }
  // );

  const updateStatus = useMutation(
    () =>
      updateOrderStatus({
        status_id: idStatus,
        order_id: idOrder ? idOrder?.toString() : undefined,
        cancel_reason_id: idReason ? idReason?.toString() : undefined,
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries([
          "getOrdersListRestaurant",
          page,
          rowsPerPage,
          filterStatus,
        ]);
        queryClient.refetchQueries(["getOrderDetails", idOrder]);
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        onClose();
      },
      onError: async (error: any) => {
        const messageError = await error?.body?.messsage;
        setSnack({
          open: true,
          severity: "error",
          message: messageError,
        });
      },
    }
  );

  return (
    <Dialog
      open={open}
      title={title}
      maxWidth="sm"
      handleContent={updateStatus.mutate}
      loadingUseQuery={orderDetails?.isLoading}
      loading={updateStatus.isLoading}
      onClose={onClose}
      action={action}
    >
      <Box display="flex" width={350} flexDirection="column">
        <Grid
          container
          spacing={1}
          style={{ marginBottom: 24, paddingLeft: 16 }}
        >
          <Grid item xs={4}>
            <Typography
              variant="subtitle2"
              style={{
                fontWeight: 500,
              }}
            >
              Order ID
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 400,
              }}
            >
              {idOrder}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography
              variant="subtitle2"
              style={{
                fontWeight: 500,
              }}
            >
              Store Name
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 400,
              }}
            >
              {orderDetails?.data?.store?.store_translation?.name}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography
              variant="subtitle2"
              style={{
                fontWeight: 500,
              }}
            >
              Created
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 400,
              }}
            >
              {orderDetails?.data?.created_at &&
                moment(orderDetails?.data?.created_at).format(
                  "DD MMM YYYY hh:mm"
                )}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography
              variant="subtitle2"
              style={{
                fontWeight: 500,
              }}
            >
              Delivery date
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 400,
              }}
            >
              {orderDetails?.data?.picked_time &&
                moment(orderDetails?.data?.picked_time).format(
                  "DD MMM YYYY hh:mm"
                )}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="subtitle2"
              style={{
                fontWeight: 500,
              }}
            >
              Order Total
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 400,
              }}
            >
              QAR &nbsp; {orderDetails?.data?.order_total_price || 0}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography
              variant="subtitle2"
              style={{
                fontWeight: 500,
              }}
            >
              Payment Status
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 400,
              }}
            >
              {
                orderDetails?.data?.order_current_payment_status
                  ?.order_payment_status_translation?.display_status
              }
            </Typography>
          </Grid>
        </Grid>
        {idStatus === 1 && (
          <Select
            value={idStatus || ""}
            onChange={(e) => {
              setIdStatus(e.target.value as number);
              setIdReason(undefined);
            }}
            label="Order Stauts"
            color="secondary"
          >
            {statusList?.data
              ?.filter(
                (item: any) =>
                  item?.id === idStatus ||
                  item?.status === "accepted" ||
                  item?.status === "cancelled"
              )
              ?.map((item: any) => (
                <MenuItem
                  value={item?.id}
                  key={item?.id}
                  style={{ display: item?.id === idStatus ? "none" : "" }}
                >
                  {item?.display_status}
                </MenuItem>
              ))}
          </Select>
        )}

        {idStatus === 6 && (
          <Select
            value={idStatus || ""}
            onChange={(e) => {
              setIdStatus(e.target.value as number);
              setIdReason(undefined);
            }}
            label="Order Stauts"
            color="secondary"
          >
            {statusList?.data
              ?.filter((item: any) => item?.id === 8 || item?.id === 6)
              ?.map((item: any) => (
                <MenuItem value={item?.id} key={item?.id}>
                  {item?.display_status}
                </MenuItem>
              ))}
          </Select>
        )}

        {idStatus?.toString() !== "1" && idStatus?.toString() !== "6" && (
          <Select
            value={idStatus || ""}
            onChange={(e) => {
              setIdStatus(e.target.value as number);
              setIdReason(undefined);
            }}
            label="Order Stauts"
            color="secondary"
            disabled
          >
            {statusList?.data?.map((item: any) => (
              <MenuItem value={item?.id} key={item?.id}>
                {item?.display_status}
              </MenuItem>
            ))}
          </Select>
        )}

        {/* {idStatus === 7 && (
          <Select
            value={idReason || ""}
            onChange={(e) => setIdReason(e.target.value as number)}
            label="Cancel Reason"
            color="secondary"
            style={{ marginTop: 24 }}
          >
            {cancelReasonsList?.data?.map((item: any) => (
              <MenuItem value={item?.id} key={item?.id}>
                {item?.reason}
              </MenuItem>
            ))}
          </Select>
        )} */}
      </Box>
    </Dialog>
  );
};
