import styled from "styled-components";

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #fff;
  margin: 20px;
  padding: 20px;
  border: 1px solid #dddddd;
  border-radius: 20px;
  .form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  .btn-save {
    width: 100px;
    margin-left: auto;
  }
  .wrapper-class {
    border: 1px solid #dddddd;
    border-radius: 20px;
  }

  .rdw-editor-main {
    height: 250px;
    overflow-x: auto;
    padding: 0 10px;
  }
  .toolbar-class {
    border: none;
    border-bottom: 1px solid #dddddd;
    border-radius: 20px 20px 0 0;
    padding: 8px;
  }
  .rdw-editor-toolbar {
    margin-bottom: 0;
  }
  .rdw-option-wrapper {
    border-radius: 8px;
  }
  .rdw-dropdown-wrapper {
    border-radius: 8px;
  }
  .rdw-option-active {
    box-shadow: none;
    background-color: #5bc0de;
    border-radius: 5px;
  }
`;
