import * as React from "react";
import Typography from "@mui/material/Typography";

import { LazyLoadingImage } from "../../commons";

import { Page } from "./NotFound-style";

import Error404 from "../../img/error.png";

export const NotFound = (): JSX.Element => {
  return (
    <Page>
      <div className="div-image">
        <LazyLoadingImage alt="store" src={Error404} width="100%" />
        <Typography align="center" className="decription404">
          We are sorry but the page you were looking for was not found!...
        </Typography>
      </div>
    </Page>
  );
};
