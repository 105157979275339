import React from "react";
import styled from "styled-components";
import TableCell, { TableCellProps } from "@mui/material/TableCell";
import {
  Table as TableMUI,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import { TablePaginationProps } from "@mui/material/TablePagination";
import Skeleton from "@mui/material/Skeleton";

import { TablePagination } from "..";
import { generateKey } from "../../utils";

export type ColumnsProps = {
  header: React.ReactNode;
  accessor: string;
  headerCellProps?: TableCellProps;
  cellProps?: TableCellProps;
}[];

type TableProps<T> = {
  data: T[];
  columns: ColumnsProps;
  tablePaginationProps?: TablePaginationProps;
  emptyMessage?: string;
  onClickRow?: (row: T) => void;
  loading?: boolean;
  nbrRowLoading?: Number;
};

function Table<T extends { [key: string]: any }>(
  tableProps: TableProps<T>
): JSX.Element {
  const {
    data,
    columns,
    tablePaginationProps,
    emptyMessage,
    onClickRow,
    loading,
    nbrRowLoading,
  } = tableProps;

  const rowsLoading = new Array(nbrRowLoading).fill(undefined);
  const rows = data?.map((item) => ({ render: item, key: generateKey() }));

  return (
    <TableContainer sx={{ overflowX: "unset" }}>
      <TableMUI
        aria-label="customized table"
        size="small"
        style={{ borderCollapse: "unset" }}
      >
        <TableHead>
          <TableRow>
            {columns?.map((column, index) => (
              <TableCellStyled
                // eslint-disable-next-line
                key={index}
                {...column.headerCellProps}
              >
                {column.header}
              </TableCellStyled>
            ))}
          </TableRow>
        </TableHead>
        {loading ? (
          <TableBody>
            {rowsLoading.map((_, indexRow) => (
              <TableRowStyled key={indexRow}>
                {columns?.map((column, index) => (
                  <TableCellStyled2
                    // eslint-disable-next-line
                    key={index}
                    {...column.cellProps}
                    className={
                      indexRow === rowsLoading.length - 1 ? "last-linge" : ""
                    }
                  >
                    <Skeleton variant="text" sx={{ margin: "5px 0" }} />
                  </TableCellStyled2>
                ))}
              </TableRowStyled>
            ))}
          </TableBody>
        ) : (
          <TableBody>
            {rows.length >= 1 ? (
              rows.map((row, indexRow) => (
                <TableRowStyled
                  key={row.key}
                  // hover={!!onClickRow}
                  onClick={() => onClickRow && onClickRow(row.render)}
                >
                  {columns?.map((column, index) => (
                    <TableCellStyled2
                      // eslint-disable-next-line
                      key={index}
                      {...column.cellProps}
                      className={
                        indexRow === rows.length - 1 ? "last-linge" : ""
                      }
                    >
                      {row.render[column?.accessor]}
                    </TableCellStyled2>
                  ))}
                </TableRowStyled>
              ))
            ) : (
              <TableRowStyled>
                <TableCellStyled2
                  size="medium"
                  colSpan={columns.length}
                  align="center"
                  className="last-linge"
                  style={{ height: 50 }}
                >
                  {emptyMessage}
                </TableCellStyled2>
              </TableRowStyled>
            )}
          </TableBody>
        )}
        <TableFooter>
          <TableRow>
            {tablePaginationProps && (
              <TablePagination {...tablePaginationProps} />
            )}
          </TableRow>
        </TableFooter>
      </TableMUI>
    </TableContainer>
  );
}

const TableCellStyled = styled(TableCell)`
  background-color: #b3dbe9;
  color: ${({ theme }) => theme.palette.text.primary};
  border-top: 1px solid rgba(224, 224, 224, 1);
  font-weight: 500;
  &:first-child {
    border-left: 1px solid rgba(224, 224, 224, 1);
    border-top-left-radius: 20px;
  }
  &:last-child {
    border-right: 1px solid rgba(224, 224, 224, 1);
    border-top-right-radius: 20px;
  }
  padding: 8px 16px;
`;

const TableCellStyled2 = styled(TableCell)`
  background: #fff;
  &:first-child {
    border-left: 1px solid rgba(224, 224, 224, 1);
  }
  &:last-child {
    border-right: 1px solid rgba(224, 224, 224, 1);
  }
  padding: 8px 16px;
`;

const TableRowStyled = styled(TableRow)`
  cursor: pointer;
  overflow: hidden;
  :hover {
    .MuiTableCell-root {
      background: ${({ theme }) => theme.palette.grey[300]};
    }
  }
  .last-linge {
    &:first-child {
      border-bottom-left-radius: 20px;
    }
    &:last-child {
      border-bottom-right-radius: 20px;
    }
  }
`;

export default Table;
