import styled from "styled-components";
import { Tabs as TabsMu } from "@mui/material";

export const Tabs = styled(TabsMu)`
  height: 38px;
  min-height: 38px;
  .MuiTab-root {
    height: 38px;
    min-height: 38px;
  }

  .Mui-selected {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;
