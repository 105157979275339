/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class DiscountService {

    /**
     * get discounts
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getDiscount(): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/admins/settings/discounts/getDiscount`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * save Discount
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static saveDiscount(
        requestBody?: {
            value?: number;
            status?: boolean;
            message_en?: string;
            message_ar?: string;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/settings/discounts/saveDiscount`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * update Discount
     * @param id ID of discount
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static updateDiscount(
        id: number,
        requestBody?: {
            value?: number;
            status?: boolean;
            message_en?: string;
            message_ar?: string;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/settings/discounts/updateDiscount/${id}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

}