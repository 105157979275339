import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import Skeleton from "@mui/material/Skeleton";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { Button, Dialog } from "../../commons";
import { ChoicesFormDialog, ChoicesContainer } from "..";

import { RestaurantItemChoicesService } from "../../services/openApi";
import { SnackbarContext } from "../../App";

import { serviceUnavailable, successfulOperation } from "../../utils/message";

import { TrashIcon } from "../../img/icon";

import { PageChoices } from "./Choices-styles";

const arrayListChoicesloading = new Array(9).fill("");

type ModalProps = {
  open: boolean;
  title: string;
  action?: "delete" | "add" | "update";
};

type ChoicesProps = {
  idMenu?: any;
  style?: React.CSSProperties;
};

export const Choices = ({ idMenu, style }: ChoicesProps): JSX.Element => {
  const { getItemChoices, deleteItemChoices } = RestaurantItemChoicesService;

  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);

  const [openForm, setOpenForm] = React.useState<ModalProps>({
    open: false,
    title: "",
  });
  const [idChoice, setIdChoice] = React.useState<number | undefined>();
  const [enableBtnAddOption, setEnableBtnAddOption] =
    React.useState<boolean>(false);
  const [addNewChoiceOption, setAddNewChoiceOption] =
    React.useState<boolean>(false);

  const listChoices = useQuery(
    ["getItemChoices", idMenu],
    () => getItemChoices(+idMenu!),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
      enabled: !!idMenu,
    }
  );

  const removeChoices = useMutation(() => deleteItemChoices(+idChoice!), {
    onSuccess: () => {
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
      queryClient.refetchQueries(["getItemChoices", idMenu]);
      handleCloseChoicesFormDialog();
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const handleOpenChoicesFormDialog = (
    title: string,
    action: "add" | "update" | "delete"
  ) => {
    setOpenForm({
      open: true,
      title,
      action,
    });
  };

  const handleCloseChoicesFormDialog = () => {
    setOpenForm({
      open: false,
      title: "",
    });
  };

  const handleNewAddOption = () => {
    setAddNewChoiceOption(true);
  };

  const handleDisabledAddOption = (value: boolean) => {
    setAddNewChoiceOption(false);
    setEnableBtnAddOption(value);
  };

  return (
    <PageChoices>
      <Box
        bgcolor="#fff"
        border="1px solid #dddddd"
        borderRadius="0 0 0 20px"
        style={style}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          p={2}
          borderBottom="1px solid #dddddd"
        >
          <Typography
            variant="h3"
            sx={{ color: "secondary.main", fontWeight: 600 }}
          >
            Choices
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleOpenChoicesFormDialog("Add Choices", "add")}
          >
            Add
          </Button>
        </Box>

        {listChoices?.isLoading ? (
          arrayListChoicesloading?.map((_, index) => (
            <Box
              key={index}
              display="flex"
              height={45}
              alignItems="center"
              gap={2}
              padding="8px 16px 0 16px"
            >
              <Skeleton variant="text" width="100%" />
              <Skeleton
                component={IconButton}
                variant="circular"
                width={26}
                height={26}
              />
            </Box>
          ))
        ) : (
          <MenuList>
            {listChoices?.data?.map((item: any) => (
              <MenuItem
                key={item?.id}
                selected={item?.id === idChoice}
                onClick={() => setIdChoice(item?.id)}
              >
                <ListItemText>{item?.item_choice_name}</ListItemText>
                <IconButton
                  onClick={() =>
                    handleOpenChoicesFormDialog("Delete", "delete")
                  }
                >
                  <TrashIcon />
                </IconButton>
              </MenuItem>
            ))}
          </MenuList>
        )}
        {!listChoices?.isLoading && listChoices?.data?.length === 0 && (
          <Typography
            variant="body2"
            align="center"
            alignItems="center"
            sx={{ margin: "100% 0" }}
          >
            No list choices are available
          </Typography>
        )}
      </Box>
      <Box
        bgcolor="#fff"
        border="1px solid #dddddd"
        borderRadius="0 20px 20px 0"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          p={2}
          borderBottom="1px solid #dddddd"
        >
          <Typography
            variant="h3"
            sx={{ color: "secondary.main", fontWeight: 600 }}
          >
            Options
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            disabled={enableBtnAddOption || listChoices?.data?.length === 0}
            onClick={handleNewAddOption}
          >
            Add Options
          </Button>
        </Box>
        <ChoicesContainer
          idChoice={idChoice}
          addNewChoiceOption={addNewChoiceOption}
          onEnableBtnAddOption={handleDisabledAddOption}
        />
      </Box>
      {(openForm?.action === "update" || openForm?.action === "add") && (
        <ChoicesFormDialog
          idMenu={idMenu}
          open={openForm.open}
          title={openForm.title}
          action={openForm?.action}
          onClose={handleCloseChoicesFormDialog}
        />
      )}
      {openForm?.action === "delete" && (
        <Dialog
          open={openForm.open}
          title={openForm.title}
          action="delete"
          handleContent={() => idChoice && removeChoices.mutate()}
          loading={removeChoices.isLoading}
          onClose={handleCloseChoicesFormDialog}
        >
          <Typography>Are you sure to delete this choice?</Typography>
        </Dialog>
      )}
    </PageChoices>
  );
};
