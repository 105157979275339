import * as React from "react";

import { Collections, AssignCollection } from "../../components";

import { Page } from "./FilterCollections-style";

export const FilterCollections = (): JSX.Element => {
  const [idCollection, setIdCollection] = React.useState<number | undefined>();

  return (
    <Page>
      <Collections onSelected={setIdCollection} />
      <AssignCollection idCollection={idCollection} />
    </Page>
  );
};
