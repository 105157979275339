/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Box from "@mui/system/Box";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import LinearProgress from "@mui/material/LinearProgress";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { ReactSortable } from "react-sortablejs";
import debounce from "lodash/debounce";

import { TablePagination } from "@mui/material";
import { Input, Button, Dialog } from "../../../commons";
import {
  CategoryFormDialog,
  GlobalCategoryTableRow,
} from "../../../components";
import { SnackbarContext } from "../../../App";
import {
  serviceUnavailable,
  successfulOperation,
} from "../../../utils/message";

import { RestaurantGlobalCategoriesService } from "../../../services/openApi";

import { Page } from "./GlobalGategory-style";

type openFormState = {
  open: boolean;
  title: string;
  action?: "delete" | "add" | "update";
};
interface ItemType {
  id: number;
  idItem: number;
  name: string;
  status: boolean;
  image: string;
  slug: string;
}

export const GlobalGategory = (): JSX.Element => {
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);
  const {
    getGlobalCategories,
    deleteCategory,
    updateFilterStatus,
    sortItemGlobalCategories,
  } = RestaurantGlobalCategoriesService;

  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [openForm, setOpenForm] = React.useState<openFormState>({
    open: false,
    title: "",
    action: undefined,
  });

  const [openDelete, setOpenDelete] = React.useState<openFormState>({
    open: false,
    title: "",
    action: undefined,
  });
  const [openStatus, setOpenStatus] = React.useState<openFormState>({
    open: false,
    title: "",
    action: undefined,
  });
  const [search, setSearch] = React.useState("");
  const [idCategory, setIdCategory] = React.useState<number | undefined>(
    undefined
  );
  const [status, setStatus] = React.useState<boolean | undefined>(undefined);
  const [listSort, setListSort] = React.useState<Array<any>>([]);
  const [listState, setListState] = React.useState<ItemType[]>([]);

  const listGlobalCategories = useQuery(
    "getGlobalCategories",
    () => getGlobalCategories({ search }),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  React.useEffect(() => {
    if (listGlobalCategories?.data) {
      const data = listGlobalCategories?.data;
      const arrayGlobalCategories: ItemType[] = [];
      data
        ?.sort((a: any, b: any) => a?.sorting_index - b?.sorting_index)
        ?.forEach((item: any) => {
          arrayGlobalCategories.push({
            id: item?.sorting_index,
            idItem: item?.id,
            name: item?.name,
            status: item?.status,
            slug: item?.slug,
            image: item?.image?.uri,
          });
        });
      setListState(arrayGlobalCategories);
    }
  }, [listGlobalCategories?.data]);

  const removeCategory = useMutation(deleteCategory, {
    onSuccess: () => {
      queryClient.fetchQuery("getGlobalCategories");
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
      setOpenDelete({
        open: false,
        title: "",
        action: "delete",
      });
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const updateCategoryStatus = useMutation(
    () => updateFilterStatus(idCategory!, { status }),
    {
      onSuccess: () => {
        queryClient.fetchQuery("getGlobalCategories");
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        setOpenStatus({
          open: false,
          title: "",
          action: "update",
        });
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );
  const sortGlobalCategoriesItems = useMutation(
    () => sortItemGlobalCategories({ globalCategories: listSort }),
    {
      onSuccess: () => {
        queryClient.refetchQueries("getGlobalCategories");
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };
  const handleOrder = () => {
    const arraySortable: any[] = [];
    listState?.forEach((item, index) => {
      arraySortable.push({
        id: item.idItem,
        sorting_index: index + 1,
      });
    });
    setListSort(arraySortable);
  };

  const sendOrder = React.useCallback(
    debounce(() => sortGlobalCategoriesItems.mutate(), 2000),
    []
  );

  return (
    <Page className="scrolbar">
      <div className="page-action">
        <Input
          value={search}
          placeholder="Search"
          onChange={handleChange}
          onKeyPress={(e) =>
            e.key === "Enter" && listGlobalCategories.refetch()
          }
          startAdornment={
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          }
        />
        <Button
          variant="contained"
          color="secondary"
          className="btn-add"
          onClick={() =>
            setOpenForm({
              open: true,
              title: "Add New Global Categorie",
              action: "add",
            })
          }
        >
          Add New Categorie
        </Button>
      </div>
      <Box
        marginTop={3}
        height="max-content"
        bgcolor="#fff"
        border="1px solid #DDDDDD"
        borderRadius="20px"
      >
        <GlobalCategoryTableRow index={-1} key={0} data={listState[0]} />
        {listGlobalCategories?.isLoading && (
          <LinearProgress color="secondary" />
        )}
        <ReactSortable
          list={listState}
          setList={setListState}
          animation={200}
          onSort={async () => {
            await handleOrder();
            sendOrder();
          }}
        >
          {listState
            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            ?.map((item: any, index: any) => (
              <GlobalCategoryTableRow
                index={index}
                key={item?.idItem}
                data={item}
                onEdit={(
                  e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  e.stopPropagation();
                  setIdCategory(item?.idItem);
                  setOpenForm({
                    open: true,
                    title: "Edit Global Categorie",
                    action: "update",
                  });
                }}
                onDelete={(e) => {
                  e.stopPropagation();
                  setIdCategory(item?.idItem);
                  setOpenDelete({
                    open: true,
                    title: "Delete",
                    action: "delete",
                  });
                }}
                onActive={(e) => {
                  e.stopPropagation();
                  setStatus(e.target.checked);
                  setIdCategory(item?.idItem);
                  setOpenStatus({
                    open: true,
                    title: "Update Status",
                    action: "update",
                  });
                }}
              />
            ))}
        </ReactSortable>
        {listState?.length === 0 && (
          <Typography
            variant="body2"
            align="center"
            sx={{ margin: "15px auto" }}
          >
            No data available
          </Typography>
        )}
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <table>
          <tfoot>
            <tr>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                count={listGlobalCategories.data?.length || 0}
                page={page}
                onPageChange={(_, newPage) => setPage(newPage)}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </tr>
          </tfoot>
        </table>
      </Box>
      {openForm.open && (
        <CategoryFormDialog
          open={openForm.open}
          title={openForm.title}
          action={openForm.action}
          idCategory={idCategory}
          onClose={() => {
            setOpenForm({
              open: false,
              title: "",
              action: undefined,
            });
            setIdCategory(undefined);
          }}
        />
      )}
      <Dialog
        open={openDelete.open}
        title={openDelete.title}
        action="delete"
        handleContent={() => idCategory && removeCategory.mutate(idCategory)}
        loading={removeCategory.isLoading}
        onClose={() =>
          setOpenDelete({
            open: false,
            title: "",
            action: "delete",
          })
        }
      >
        <Typography>Are you sure to delete this category?</Typography>
      </Dialog>
      <Dialog
        open={openStatus.open}
        title={openStatus.title}
        action="delete"
        handleContent={() => idCategory && updateCategoryStatus.mutate()}
        loading={updateCategoryStatus.isLoading}
        onClose={() =>
          setOpenStatus({
            open: false,
            title: "",
            action: "update",
          })
        }
      >
        <Typography>Are you sure to change this category status?</Typography>
      </Dialog>
    </Page>
  );
};
