/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { Input, TextField, Button, Dialog } from "../../commons";
import Table, { ColumnsProps } from "../../commons/Table";

import { NameRestaurantContext, SnackbarContext } from "../../App";
import { serviceUnavailable, successfulOperation } from "../../utils/message";

import {
  FilterCollectionsService,
  ReviewsService,
} from "../../services/openApi";

import { Page } from "./Reviews-style";

type openModalState = {
  open: boolean;
  title: string;
  action?: "delete" | "add" | "update";
};

export const Reviews = (): JSX.Element => {
  const { getAllStoresList } = FilterCollectionsService;
  const { getReviewsList, updateReviewStatus } = ReviewsService;
  const { setNameRestaurant } = React.useContext(NameRestaurantContext);
  const { setSnack } = React.useContext(SnackbarContext);
  const queryClient = useQueryClient();

  const [search, setSearch] =
    React.useState<{ storeId?: number; name?: string }>();
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [idReview, setIdReview] = React.useState<number | undefined>();
  const [openModal, setOpenModal] = React.useState<openModalState>({
    open: false,
    title: "",
  });
  const [status, setStatus] = React.useState<boolean>(false);

  const listStores = useQuery("getAllStoresList", () => getAllStoresList(), {
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const listReviews = useQuery(
    "getReviewsList",
    () =>
      getReviewsList({
        search: search?.name,
        store_id: search?.storeId,
      }),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const updateStatus = useMutation(
    () => updateReviewStatus(idReview!, { status }),
    {
      onSuccess: () => {
        queryClient.refetchQueries("getReviewsList");
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        setOpenModal({
          open: false,
          title: "",
        });
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const columns: ColumnsProps = [
    {
      header: "Costumer Name",
      accessor: "costumerName",
    },
    {
      header: "Store Name",
      accessor: "storeName",
    },
    {
      header: listReviews?.data?.reviews?.reviews?.[0]?.name || "Service",
      accessor: "key1",
    },
    {
      header: listReviews?.data?.reviews?.reviews?.[1]?.name || "Quality",
      accessor: "key2",
    },
    {
      header:
        listReviews?.data?.reviews?.reviews?.[2]?.name || "Respecting Time",
      accessor: "key3",
    },
    {
      header:
        listReviews?.data?.reviews?.reviews?.[3]?.name || "Presenting way",
      accessor: "key4",
    },
    {
      header: "Option",
      accessor: "option",
    },
    {
      header: "Date",
      accessor: "date",
    },
  ];

  React.useEffect(() => {
    setNameRestaurant("");
  }, []);

  const renderTableRows = () => {
    return (
      listReviews?.data?.reviews
        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        ?.map((item: any) => ({
          costumerName: item?.customer?.fullname,
          storeName: item?.store?.store_name,
          key1: <Rating value={item?.reviews?.[0]?.rate || 0} readOnly />,
          key2: <Rating value={item?.reviews?.[1]?.rate || 0} readOnly />,
          key3: <Rating value={item?.reviews?.[2]?.rate || 0} readOnly />,
          key4: <Rating value={item?.reviews?.[3]?.rate || 0} readOnly />,
          option: (
            <Switch
              inputProps={{ "aria-label": "Switch demo" }}
              checked={Boolean(item?.status)}
              onChange={(e) => {
                setOpenModal({
                  open: true,
                  title: "Update status",
                  action: "update",
                });
                setIdReview(item?.id);
                setStatus(e.target.checked);
              }}
            />
          ),
          date: item?.created_at,
        })) || []
    );
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchByName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch({
      ...search,
      name: event.target.value,
    });
  };

  return (
    <Page className="scrolbar">
      <div className="search">
        <Input
          placeholder="Search by customer name"
          onChange={handleSearchByName}
        />
        <Autocomplete
          options={listStores.data || []}
          getOptionLabel={(option: any) => option?.name || ""}
          filterSelectedOptions
          onChange={(_, value) =>
            setSearch({
              ...search,
              storeId: value?.id,
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select Store"
              color="secondary"
            />
          )}
        />
        <Button
          variant="contained"
          color="secondary"
          className="btn-search"
          onClick={() => listReviews?.refetch()}
        >
          <SearchIcon /> Search
        </Button>
      </div>
      <Box display="flex" flex="1" marginTop={3}>
        <Table
          columns={columns}
          loading={listReviews?.isLoading}
          nbrRowLoading={rowsPerPage}
          data={renderTableRows()}
          emptyMessage="No data available"
          tablePaginationProps={{
            rowsPerPageOptions: [5, 10, 25],
            count: listReviews?.data?.reviews?.length || 0,
            page: page,
            onPageChange: (_, newPage) => setPage(newPage),
            rowsPerPage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
        />
      </Box>
      <Dialog
        open={openModal.open}
        title={openModal.title}
        action="delete"
        handleContent={() => idReview && updateStatus.mutate()}
        loading={updateStatus?.isLoading}
        onClose={() =>
          setOpenModal({
            open: false,
            title: "",
          })
        }
      >
        <Typography>Are you sure to change status this review?</Typography>
      </Dialog>
    </Page>
  );
};
