import styled from "styled-components";

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0 20px 20px 20px;
  .header-action {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    .btn-print {
      background-color: #fff;
      svg {
        margin-right: 5px;
      }
    }
  }
`;

export const pageStyle = `
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @page {
    size: A4;
    margin: 0 1mm 7mm 1mm;
  }
`;
