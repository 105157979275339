import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useQuery } from "react-query";
import filter from "lodash/filter";

import { Input, Status } from "../../../../commons";
import { CardStatus, OrderStatusStoreDialog } from "../../../../components";
import Table, { ColumnsProps } from "../../../../commons/Table";

import { SnackbarContext } from "../../../../App";
import { serviceUnavailable } from "../../../../utils/message";

import { RoleStoreUserOrdersService } from "../../../../services/openApi";

import { Page } from "./OrderStore-style";

type openFormState = {
  open: boolean;
  title: string;
  action?: "update";
};

type statusType =
  | "newOrder"
  | "accepted"
  | "cancelled"
  | "completed"
  | undefined;

export const OrderStore = (props: RouteComponentProps): JSX.Element => {
  const { setSnack } = React.useContext(SnackbarContext);
  const { getOrdersList } = RoleStoreUserOrdersService;

  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [openForm, setOpenForm] = React.useState<openFormState>({
    open: false,
    title: "",
  });

  const [idOrder, setIdOrder] = React.useState<number | undefined>();
  const [filterStatus, setFilterStatus] = React.useState<statusType>(undefined);
  const [search, setSearch] = React.useState<string>("");

  const OrdersList = useQuery(
    ["getOrdersListStore", page, rowsPerPage, filterStatus],
    () =>
      getOrdersList(rowsPerPage, page, { search, order_status: filterStatus }),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const { totalAccepted, totalCancelled, totalCompleted, totalPending } =
    OrdersList?.data || "";

  const orders = OrdersList?.data?.orders?.data;

  const columns: ColumnsProps = [
    {
      header: "Order ID",
      accessor: "orderID",
    },
    {
      header: "Store Name",
      accessor: "storeName",
    },
    {
      header: "Picked Time",
      accessor: "pickedTime",
    },
    {
      header: "Order Amount",
      accessor: "orderTotal",
    },
    {
      header: "Payment Method",
      accessor: "paymentMethod",
    },

    {
      header: "Order Status",
      accessor: "orderStatus",
      headerCellProps: { align: "center" },
    },
    {
      header: "Actions",
      accessor: "actions",
      headerCellProps: { align: "center" },
      cellProps: { align: "center", width: "80px" },
    },
  ];

  const getListOrder = filterStatus
    ? filter(
        orders,
        (o: any) =>
          o?.order_current_status?.status === filterStatus.toLowerCase()
      )
    : orders;

  const renderTableRows = () => {
    return (
      getListOrder?.map((item: any) => ({
        orderID: item?.id,
        storeName: item?.store?.store_translation?.name,
        pickedTime: item?.picked_time,
        orderTotal:
          (item?.order_total_price?.toLocaleString("en-US") || 0) + " QAR",
        paymentMethod: item?.payment_method?.replaceAll("_", " "),
        orderStatus: (
          <Status
            status={item?.order_current_status?.order_status_translation?.display_status?.toUpperCase()}
          />
        ),
        actions: (
          <IconButton
            onClick={() =>
              props.history.push(`/store/orders/order-details/${item?.id}`)
            }
          >
            <VisibilityIcon />
          </IconButton>
        ),
      })) || []
    );
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleFilter = (filter: statusType) => {
    if (filter === filterStatus) {
      setFilterStatus(undefined);
    } else {
      setFilterStatus(filter);
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  return (
    <Page className="scrolbar">
      <Box display="flex" justifyContent="space-between" gap={3}>
        <CardStatus
          status="Pending"
          loading={OrdersList?.isLoading}
          currency="QAR"
          totalePrice={totalPending?.total_price?.toLocaleString("en-US") || 0}
          count={totalPending?.order_count || 0}
          selected={filterStatus === "newOrder"}
          onClick={() => handleFilter("newOrder")}
        />
        <CardStatus
          status="Accepted"
          loading={OrdersList?.isLoading}
          currency="QAR"
          totalePrice={totalAccepted?.total_price?.toLocaleString("en-US") || 0}
          count={totalAccepted?.order_count || 0}
          selected={filterStatus === "accepted"}
          onClick={() => handleFilter("accepted")}
        />
        <CardStatus
          status="Completed"
          loading={OrdersList?.isLoading}
          currency="QAR"
          totalePrice={
            totalCompleted?.total_price?.toLocaleString("en-US") || 0
          }
          count={totalCompleted?.order_count || 0}
          selected={filterStatus === "completed"}
          onClick={() => handleFilter("completed")}
        />
        <CardStatus
          status="Cancelled"
          loading={OrdersList?.isLoading}
          currency="QAR"
          totalePrice={
            totalCancelled?.total_price?.toLocaleString("en-US") || 0
          }
          count={totalCancelled?.order_count || 0}
          selected={filterStatus === "cancelled"}
          onClick={() => handleFilter("cancelled")}
        />
      </Box>
      <Input
        value={search}
        placeholder="Search by order id"
        onChange={handleSearch}
        onKeyPress={(e) => e.key === "Enter" && OrdersList.refetch()}
        startAdornment={
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        }
      />
      <Box sx={{ width: "100%" }}>
        <Table
          columns={columns}
          loading={OrdersList?.isLoading || OrdersList?.isRefetching}
          nbrRowLoading={rowsPerPage}
          data={renderTableRows()}
          onClickRow={(item) => {
            setIdOrder(item?.orderID);
            setOpenForm({
              open: true,
              title: "Change Status",
              action: "update",
            });
          }}
          emptyMessage="No data available"
          tablePaginationProps={{
            rowsPerPageOptions: [5, 10, 25],
            count: OrdersList?.data?.orders?.total || 0,
            page: (OrdersList?.data?.orders?.total || 0) <= 0 ? 0 : page - 1,
            onPageChange: (_, newPage) => setPage(newPage + 1),
            rowsPerPage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
        />
      </Box>
      {openForm.open && (
        <OrderStatusStoreDialog
          idOrder={idOrder}
          open={openForm.open}
          title={openForm.title}
          action={openForm.action}
          onClose={() =>
            setOpenForm({
              open: false,
              title: "",
            })
          }
          page={page}
          rowsPerPage={rowsPerPage}
          filterStatus={filterStatus}
        />
      )}
    </Page>
  );
};
