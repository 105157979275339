/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class CateringCouponService {

    /**
     * List coupons
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getCatringCouponsList(
        requestBody?: {
            search?: string;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/settings/cateringCoupon/getCatringCouponsList`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * get item list for coupon creation
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getAllItemsList(): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/admins/settings/cateringCoupon/getAllItemsList`,
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * get catering coupon details
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getCateringCouponDetails(
        requestBody?: {
            search?: string;
            catering_coupon_id?: number;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/settings/cateringCoupon/getCateringCouponDetails`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * save catering coupon
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static saveCateringCoupon(
        requestBody?: {
            title_en?: string;
            title_ar?: string;
            short_description_en?: string;
            short_description_ar?: string;
            type?: string;
            code?: string;
            discount?: number;
            meiz_contribution?: number;
            minimum_order_amount?: number;
            total_usage_limit?: number;
            limit_per_user?: number;
            status?: boolean;
            all_stores?: boolean;
            start_date?: string;
            end_date?: string;
            /**
             * coupons ids
             */
            coupons?: Array<any>;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/settings/cateringCoupon/saveCateringCoupon`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * update coupon
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static updateCateringCoupon(
        requestBody?: {
            title_en?: string;
            title_ar?: string;
            short_description_en?: string;
            short_description_ar?: string;
            status?: boolean;
            all_stores?: boolean;
            type?: string;
            catering_coupon_id?: number;
            /**
             * coupons ids
             */
            coupons?: Array<any>;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/settings/cateringCoupon/updateCateringCoupon`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * update coupon status
     * @param id ID of coupon
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static updateStatus(
        id: number,
        requestBody: {
            status?: boolean;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/settings/cateringCoupon/updateStatus/${id}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * delete catering coupon
     * @param id ID of coupon
     * @returns any Successful operation
     * @throws ApiError
     */
    public static delete(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/api/admins/settings/cateringCoupon/delete/${id}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

}