import styled from "styled-components";

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 20px;
  border: 1px solid #dddddd;
  border-radius: 20px;
  .addUrl {
    display: grid;
    grid-template-columns: 1fr 2fr 130px;
    gap: 20px;
  }
  .MuiPaper-root.MuiAccordion-root {
    margin: 0;
    .MuiAccordionDetails-root {
      padding: 0 16px 8px 16px;
    }
  }
  .MuiPaper-root.MuiAccordion-root::before {
    background-color: #fff;
  }
  .btn-delete {
    background-color: #fdf4f7;
    color: #b93e5c;
  }
`;
