import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

import { TrashIcon, EditIcon } from "../../img/icon";

type TableRowType = {
  data: any;
  index?: number;
  selected?: boolean;
  onEdit?: (data: any) => void;
  onDelete?: (id: number) => void;
};

type RowType = {
  rows: string;
  index?: number;
  selected?: boolean;
};

export const TableRowRequirement = ({
  data,
  index,
  selected,
  onDelete,
  onEdit,
}: TableRowType): JSX.Element => {
  const getcolumns = (fields: number) => {
    let template: string = "1fr";
    for (let i = 1; i < fields; i++) {
      template = template.concat(" 2fr");
    }
    return template;
  };
  return (
    <Wrapper index={index} rows={getcolumns(2)} selected={selected}>
      {index === -1 ? (
        <div className="header-element" />
      ) : (
        <Stack sx={{ padding: 2 }}>
          <DragIndicatorIcon fontSize="small" />
        </Stack>
      )}
      {index === -1 ? (
        <Typography variant="body2" align="center" className="header-element">
          Requirement (En)
        </Typography>
      ) : (
        <Typography variant="body2" align="center" className="value-element">
          {data.nameRequirementEn}
        </Typography>
      )}
      {index === -1 ? (
        <Typography variant="body2" align="center" className="header-element">
          Requirement (Ar)
        </Typography>
      ) : (
        <Typography variant="body2" align="center" className="value-element">
          {data.nameRequirementAr}
        </Typography>
      )}
      {index === -1 ? (
        <Typography variant="body2" align="center" className="header-element">
          Action
        </Typography>
      ) : (
        <Stack
          direction="row"
          justifyContent="center"
          spacing={2}
          sx={{ padding: 1 }}
        >
          {onEdit && (
            <IconButton className="btn-edit" onClick={() => onEdit(data)}>
              <EditIcon />
            </IconButton>
          )}
          {onDelete && (
            <IconButton
              className="btn-delete"
              onClick={() => onDelete(data?.id)}
            >
              <TrashIcon />
            </IconButton>
          )}
        </Stack>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<RowType>`
  display: grid;
  grid-template-columns: 55px ${(props) => props.rows} 120px;
  max-height: calc(100vh - 310px);
  overflow-y: auto;
  background-color: ${(props) => (props.selected ? "#f4f6ff" : "")};
  :hover {
    background-color: #f4f6ff;
  }
  .header-element {
    background: #f7f7f7;
    padding: 16px;
    font-weight: 600;
  }
  .value-element {
    padding: 16px;
  }
  .btn-edit {
    background-color: #ecf4df;
    color: #75bd00;
  }
  .btn-delete {
    background-color: #fdf4f7;
    color: #b93e5c;
  }
`;
