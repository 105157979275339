import * as React from "react";
import { Autocomplete, Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { useMutation, useQuery } from "react-query";

import { Button, LoadingButton, TextField } from "../../../commons";
import { Page } from "./PushMessage-style";
import {
  DashboardNotificationsService,
  FilterCollectionsService,
} from "../../../services/openApi";
import { SnackbarContext } from "../../../App";
import {
  serviceUnavailable,
  successfulOperation,
} from "../../../utils/message";

export type pushMessageState = {
  title_ar?: string;
  title_en?: string;
  message_ar?: string;
  message_en?: string;
  menu_id?: number;
  store_id?: string;
};

const initialState: pushMessageState = {
  title_ar: "",
  title_en: "",
  message_ar: "",
  message_en: "",
  menu_id: undefined,
  store_id: undefined,
};
type PushMessageProps = {};

export const PushMessage = (props: PushMessageProps) => {
  const { setSnack } = React.useContext(SnackbarContext);
  const { getAllStoresList, getItemListByStoresId } = FilterCollectionsService;
  const { sendDashPushNotificationToEveryone } = DashboardNotificationsService;

  const [pushMessage, setPushMessage] =
    React.useState<pushMessageState>(initialState);
  const [messageError, setMessageError] =
    React.useState<pushMessageState>(initialState);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPushMessage({
      ...pushMessage,
      [name]: value,
    });
    setMessageError({
      ...messageError,
      [name]: "",
    });
  };

  const listStores = useQuery("getAllStoresList", () => getAllStoresList(), {
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const listItems = useQuery(
    ["getItemListByStoresId", pushMessage.store_id],
    () =>
      getItemListByStoresId({
        stores_id: parseInt(pushMessage?.store_id || "")
          ? [parseInt(pushMessage?.store_id || "")]
          : [],
      }),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
      enabled: !!pushMessage.store_id,
    }
  );

  const findStore =
    listStores?.data?.find((o: any) => o.id === pushMessage?.store_id) || {};

  const findItem =
    listItems?.data?.find((o: any) => o.id === pushMessage?.menu_id) || {};

  const sendMessage = useMutation(sendDashPushNotificationToEveryone, {
    onSuccess: () => {
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });
  const handleSubmit = () => {
    sendMessage.mutate(pushMessage);
  };
  return (
    <Page className="scrolbar">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Autocomplete
            options={listStores.data || []}
            getOptionLabel={(option: any) => option?.name || ""}
            filterSelectedOptions
            value={findStore}
            onChange={(_, value) =>
              setPushMessage({ ...pushMessage, store_id: value?.id })
            }
            renderInput={(params) => (
              <TextField {...params} label="List of stores" color="secondary" />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            options={listItems?.data || []}
            getOptionLabel={(option: any) => option?.name || ""}
            filterSelectedOptions
            value={findItem}
            onChange={(_, value) =>
              setPushMessage({ ...pushMessage, menu_id: value?.id })
            }
            renderInput={(params) => (
              <TextField {...params} label="Menu" color="secondary" />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="title_en"
            label="Title in English"
            value={pushMessage.title_en}
            onChange={handleChange}
            color="secondary"
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="title_ar"
            label="Title in Arabic"
            value={pushMessage.title_ar}
            onChange={handleChange}
            color="secondary"
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" className="message-title">
            English Push Message Content
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" className="message-title">
            Arabic Push Message Content
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="message_en"
            // label="Push Message Content"
            multiline
            rows={4}
            value={pushMessage.message_en}
            onChange={handleChange}
            color="secondary"
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="message_ar"
            // label="Push Message Content"
            multiline
            rows={4}
            value={pushMessage.message_ar}
            onChange={handleChange}
            color="secondary"
            style={{ width: "100%" }}
          />
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" height={45} justifyContent="flex-end" gap={2}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setPushMessage(initialState)}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
              loading={sendMessage.isLoading}
              style={{ width: "13em" }}
              disabled={
                !pushMessage?.title_en ||
                !pushMessage?.title_ar ||
                !pushMessage?.message_en ||
                !pushMessage?.message_ar
              }
            >
              Send
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </Page>
  );
};
