import { Dialog, Input } from "../../commons";

type BlockFormDialogProps = {
  open: boolean;
  title: string;
  action?: "delete" | "add" | "update" | "block" | "unBlock";
  onClose: () => void;
};

export const BlockFormDialog = ({
  open,
  title,
  action,
  onClose,
}: BlockFormDialogProps): JSX.Element => {
  return (
    <Dialog
      open={open}
      title={title}
      maxWidth="sm"
      handleContent={() => {}}
      onClose={onClose}
      action={action}
    >
      <Input
        multiline
        rows={7}
        maxRows={7}
        placeholder="Type a reson to block this user"
        style={{ width: 450, marginTop: 5 }}
      />
    </Dialog>
  );
};
