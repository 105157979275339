import * as React from "react";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import { Instance } from "@popperjs/core";
import { useQuery, useMutation, useQueryClient } from "react-query";

import {
  Button,
  LoadingButton,
  Input,
  Select,
  UploadPicture,
  Dialog,
} from "../../../../commons";
import Table, { ColumnsProps } from "../../../../commons/Table";

import { SnackbarContext } from "../../../../App";
import {
  successfulOperation,
  serviceUnavailable,
} from "../../../../utils/message";
import {
  SettingsService,
  SocialMediasService,
} from "../../../../services/openApi";

import { Page } from "./MediaAppLink-style";

import {
  FkIcon,
  InsIcon,
  SpIcon,
  TwIcon,
  WaIcon,
} from "../../../../img/socialMedia";

type logosType = {
  logo?: Blob;
  small_logo?: Blob;
};

type socialMediaType = {
  name_en?: string;
  photo?: Blob;
  url?: string;
};

type openFormState = {
  open: boolean;
  title: string;
  action?: "delete";
};

export const MediaAppLink = () => {
  const { getApplicationLogo, changeApplicationLogo } = SettingsService;
  const { getSocialMediaList, deleteSocialMedia, saveSocialMedia } =
    SocialMediasService;
  const queryClient = useQueryClient();

  const { setSnack } = React.useContext(SnackbarContext);

  const positionRef = React.useRef<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const popperRef = React.useRef<Instance>(null);
  const areaRef = React.useRef<HTMLDivElement>(null);

  const [panel1, setPanel1] = React.useState<boolean>(true);
  const [panel2, setPanel2] = React.useState<boolean>(true);

  const [formLogos, setFormLogos] = React.useState<logosType>();
  const [formSocialMedia, setFormSocialMedia] =
    React.useState<socialMediaType>();

  const [openDelete, setOpenDelete] = React.useState<openFormState>({
    open: false,
    title: "",
  });
  const [id, setId] = React.useState<number | undefined>();

  const columns: ColumnsProps = [
    {
      header: "Name",
      accessor: "name",
    },
    {
      header: "Url",
      accessor: "url",
    },
    {
      header: "Type",
      accessor: "type",
    },
    {
      header: "Status",
      accessor: "status",
    },
    {
      header: "Actions",
      accessor: "actions",
    },
  ];

  const logos = useQuery("getApplicationLogo", () => getApplicationLogo(), {
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const getListSocialMedia = useQuery(
    "getSocialMediaList",
    () => getSocialMediaList(),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const updateLogos = useMutation(() => changeApplicationLogo(formLogos), {
    onSuccess: () => {
      queryClient.refetchQueries("getApplicationLogo");
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const addSocialMedia = useMutation(() => saveSocialMedia(formSocialMedia), {
    onSuccess: () => {
      queryClient.refetchQueries("getSocialMediaList");
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const removeSocialMedia = useMutation(() => deleteSocialMedia(id!), {
    onSuccess: () => {
      queryClient.refetchQueries("getSocialMediaList");
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
      setOpenDelete({
        open: false,
        title: "",
      });
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const handleChangeLog = (props: "logo" | "small_logo", logo: Blob) => {
    setFormLogos({
      ...formLogos,
      [props]: logo,
    });
  };

  const handleSaveLogos = () => {
    updateLogos.mutate();
  };

  const getLogoSocialMedia = (name?: string) => {
    switch (name?.toLocaleLowerCase()) {
      case "facebook":
        return <FkIcon />;
      case "instagram":
        return <InsIcon />;
      case "skype":
        return <SpIcon />;
      case "whatsapp":
        return <WaIcon />;
      case "twitter":
        return <TwIcon />;
      default:
        return null;
    }
  };

  const renderTableRows = () => {
    return (
      getListSocialMedia.data?.map((item: any) => ({
        name: (
          <Box display="flex" alignItems="center">
            {getLogoSocialMedia(item?.name_en)} &nbsp; &nbsp; {item?.name_en}
          </Box>
        ),
        url: item?.url,
        type: "Social Media",
        status: <Switch checked={item?.status || false} color="secondary" />,
        actions: (
          <Button
            variant="contained"
            disableElevation
            className="btn-delete"
            onClick={(e) => {
              e.stopPropagation();
              setId(item?.id);
              setOpenDelete({
                open: true,
                title: "Delete",
                action: "delete",
              });
            }}
          >
            Delete
          </Button>
        ),
      })) || []
    );
  };

  const handleMouseMove = (event: React.MouseEvent) => {
    positionRef.current = { x: event.clientX, y: event.clientY };

    if (popperRef.current != null) {
      popperRef.current.update();
    }
  };

  return (
    <Page>
      <Accordion
        expanded={panel1}
        elevation={0}
        onChange={() => setPanel1((p1) => !p1)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ fontWeight: 600 }}>System Logo</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" gap={2} alignItems="center">
            <Tooltip
              title="System  Logo 1"
              placement="bottom"
              arrow
              PopperProps={{
                popperRef,
              }}
            >
              <Box ref={areaRef} onMouseMove={handleMouseMove}>
                <UploadPicture
                  mode="basic"
                  uriImage={logos.data?.logo?.uri}
                  loading={logos.isLoading}
                  onLoad={(img) => handleChangeLog("logo", img)}
                />
              </Box>
            </Tooltip>
            <Tooltip
              title="System  Logo 2"
              placement="bottom"
              arrow
              PopperProps={{
                popperRef,
              }}
            >
              <Box ref={areaRef} onMouseMove={handleMouseMove}>
                <UploadPicture
                  mode="basic"
                  uriImage={logos.data?.small_logo?.uri}
                  loading={logos.isLoading}
                  onLoad={(img) => handleChangeLog("small_logo", img)}
                />
              </Box>
            </Tooltip>

            <LoadingButton
              variant="contained"
              color="secondary"
              onClick={handleSaveLogos}
              loading={updateLogos.isLoading}
              disableElevation
              sx={{ height: 46, marginLeft: "30px" }}
            >
              Save Change
            </LoadingButton>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={panel2}
        elevation={0}
        onChange={() => setPanel2((p2) => !p2)}
        sx={{ mb: 2 }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel2a-header"
        >
          <Typography sx={{ fontWeight: 600 }}>Media & App Link</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="addUrl">
            <Select
              label="Media and Mobile app"
              color="secondary"
              onChange={(e) =>
                setFormSocialMedia({
                  ...formSocialMedia,
                  name_en: e.target.value as string,
                })
              }
            >
              <MenuItem value="Facebook">
                <FkIcon />
                &nbsp;&nbsp; Facebook
              </MenuItem>

              <MenuItem value="Instagram">
                <InsIcon />
                &nbsp;&nbsp; Instagram
              </MenuItem>

              <MenuItem value="Twitter">
                <TwIcon />
                &nbsp;&nbsp; Twitter
              </MenuItem>

              <MenuItem value="WhatsApp">
                <WaIcon />
                &nbsp;&nbsp; WhatsApp
              </MenuItem>

              <MenuItem value="Skype">
                <SpIcon />
                &nbsp;&nbsp; Skype
              </MenuItem>
            </Select>

            <Input
              placeholder="Link"
              onChange={(e) =>
                setFormSocialMedia({
                  ...formSocialMedia,
                  url: e.target.value,
                })
              }
              startAdornment={
                <InputAdornment position="end">
                  <Typography
                    variant="subtitle2"
                    style={{ fontWeight: 500, color: "#212826" }}
                  >
                    https://
                  </Typography>
                </InputAdornment>
              }
            />
            <LoadingButton
              variant="contained"
              color="secondary"
              disableElevation
              loading={addSocialMedia.isLoading}
              onClick={() => addSocialMedia.mutate()}
              disabled={
                getListSocialMedia.data?.length < 5
                  ? !(formSocialMedia?.name_en && formSocialMedia?.url)
                  : true
              }
            >
              Add New
            </LoadingButton>
          </div>
        </AccordionDetails>
      </Accordion>
      <Box mt={3}>
        <Table
          columns={columns}
          data={renderTableRows()}
          loading={getListSocialMedia?.isLoading}
          nbrRowLoading={5}
          emptyMessage="No data available"
        />
      </Box>
      <Dialog
        open={openDelete.open}
        title={openDelete.title}
        action="delete"
        handleContent={() => id && removeSocialMedia.mutate()}
        loading={removeSocialMedia.isLoading}
        onClose={() =>
          setOpenDelete({
            open: false,
            title: "",
          })
        }
      >
        <Typography>Are you sure to delete this social media?</Typography>
      </Dialog>
    </Page>
  );
};
