import styled from "styled-components";

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 20px;
  border: 1px solid #dddddd;
  border-radius: 0px 20px 20px 20px;

  .btn-edit {
    height: 35px;
    background-color: #ecf4df;
    color: #75bd00;
    svg {
      margin-left: 10px;
    }
    box-shadow: none;
  }
  .btn-delete {
    height: 35px;
    background-color: #fdf4f7;
    color: #b93e5c;
    svg {
      margin-left: 10px;
    }
    box-shadow: none;
  }
    .value-element {
      padding: 10px;
    }
  }
`;
