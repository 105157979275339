import {
  // DashboardIcon,
  OrdersIcon,
  RestaurantsIcon,
  CostumersIcon,
  CouponsIcon,
  ReviewsIcon,
  // CustomersEnquiriesIcon,
  PaymentsIcon,
  // SubscribersIcon,
  NotificationsIcon,
  ReportsIcon,
  SettingsIcon,
  // RoleIcon,
  // ApprovalRequestsIcon,
  // HelpsIcon,
  StoreIcon,
  OffersIcon,
} from "../../img/icon";

export type appMenuType = {
  name: string;
  link: string;
  Icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  items?: {
    name: string;
    link: string;
  }[];
};

export const appMenuItems: appMenuType[] = [
  // {
  //   name: "Dashboard",
  //   link: "/main/dashboard",
  //   Icon: DashboardIcon,
  // },
  {
    name: "Orders",
    link: "/main/orders",
    Icon: OrdersIcon,
  },
  {
    name: "Restaurants",
    link: "/main/restaurants",
    Icon: RestaurantsIcon,
  },
  {
    name: "Customers",
    link: "/main/customers",
    Icon: CostumersIcon,
  },
  {
    name: "Offers",
    link: "/main/offers",
    Icon: OffersIcon,
  },
  {
    name: "Coupons",
    link: "/main/coupons",
    Icon: CouponsIcon,
  },
  {
    name: "Reviews",
    link: "",
    Icon: ReviewsIcon,
    items: [
      {
        name: "List Reviews",
        link: "/main/list-reviews",
      },
      // {
      //   name: "Setting Reviews",
      //   link: "/main/reviews/setting",
      // },
    ],
  },
  // {
  //   name: "Customers Enquiries",
  //   link: "/main/customers-enquiriesIcon",
  //   Icon: CustomersEnquiriesIcon,
  // },
  {
    name: "Payments",
    link: "",
    Icon: PaymentsIcon,
    items: [
      {
        name: "Payment Requests",
        link: "/main/payments/payment-requests",
      },
      {
        name: "Statement of accounts",
        link: "/main/payments/statement-of-accounts",
      },
      {
        name: "Stores payments",
        link: "/main/payments/stores-payments",
      },
      {
        name: "Other Charges",
        link: "/main/payments/other-charges",
      },
    ],
  },
  // {
  //   name: "Subscribers",
  //   link: "/main/subscribers",
  //   Icon: SubscribersIcon,
  // },
  {
    name: "Send Notifications",
    link: "/main/send-notifications",
    Icon: NotificationsIcon,
  },
  // {
  //   name: "Reports",
  //   link: "",
  //   Icon: ReportsIcon,
  //   items: [
  //     {
  //       name: "Catering",
  //       link: "/main/reports/catering",
  //     },
  //   ],
  // },
  {
    name: "Settings",
    link: "",
    Icon: SettingsIcon,
    items: [
      {
        name: "General Setting",
        link: "/main/settings/general-setting",
      },
      {
        name: "Welcome Discount",
        link: "/main/settings/welcome-discount",
      },
      {
        name: "Global Categories",
        link: "/main/settings/global-categories",
      },
      {
        name: "Filters",
        link: "/main/settings/filters",
      },
      // {
      //   name: "Manage Content",
      //   link: "/main/settings/manage-content",
      // },
      {
        name: "Filter Collection",
        link: "/main/settings/filter-collection",
      },
      {
        name: "Requirements",
        link: "/main/settings/requirements",
      },
      {
        name: "FAQS",
        link: "/main/settings/faqs",
      },
      {
        name: "Regions",
        link: "/main/settings/regions",
      },
    ],
  },
  // {
  //   name: "Roles & Permission",
  //   link: "/main/Roles-&-permission",
  //   Icon: RoleIcon,
  // },
  // {
  //   name: "Approval Requests",
  //   link: "/main/approval-requests",
  //   Icon: ApprovalRequestsIcon,
  // },
  // {
  //   name: "Helps",
  //   link: "/main/helps",
  //   Icon: HelpsIcon,
  // },
];

export const appMenuItemsBusiness: appMenuType[] = [
  {
    name: "Orders",
    link: "/main/orders",
    Icon: OrdersIcon,
  },
  {
    name: "Restaurants",
    link: "/main/restaurants",
    Icon: RestaurantsIcon,
  },
  {
    name: "Settings",
    link: "",
    Icon: SettingsIcon,
    items: [
      {
        name: "Global Categories",
        link: "/main/settings/global-categories",
      },
      {
        name: "Filters",
        link: "/main/settings/filters",
      },
      {
        name: "Requirements",
        link: "/main/settings/requirements",
      },
      {
        name: "Regions",
        link: "/main/settings/regions",
      },
    ],
  },
];

export const appMenuItemsMarketing: appMenuType[] = [
  {
    name: "Send Notifications",
    link: "/main/send-notifications",
    Icon: NotificationsIcon,
  },
];

export const appMenuItemsRestaurant: appMenuType[] = [
  {
    name: "Orders",
    link: "/restaurant/orders",
    Icon: OrdersIcon,
  },
  {
    name: "Store",
    link: "/restaurant/store",
    Icon: StoreIcon,
  },
  {
    name: "Reports",
    link: "/restaurant/reports",
    Icon: ReportsIcon,
  },
];

export const appMenuItemsStore: appMenuType[] = [
  {
    name: "Orders",
    link: "/store/orders",
    Icon: OrdersIcon,
  },
  {
    name: "Store",
    link: "/store/stores",
    Icon: StoreIcon,
  },
  {
    name: "Reports",
    link: "/store/reports",
    Icon: ReportsIcon,
  },
  {
    name: "Payments",
    link: "",
    Icon: PaymentsIcon,
    items: [
      {
        name: "Payment Requests",
        link: "/store/payments/payment-requests",
      },
      // {
      //   name: "Statement of accounts",
      //   link: "/store/payments/statement-of-accounts",
      // },
    ],
  },
];
