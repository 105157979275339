import styled from "styled-components";

export const Page = styled.div`
  padding: 20px;
  .header-action {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    .btn-print {
      background-color: #fff;
      svg {
        margin-right: 5px;
      }
    }
  }
`;

export const BoxPanel = styled.div``;
