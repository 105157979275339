import * as React from "react";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import { FilledInputProps } from "@mui/material/FilledInput";
import { TextField } from "../TextField";

type InputProps = FilledInputProps & {
  label?: string;
  msgError?: string;
  list?: string[];
  onTagsChanged: (value: string) => void;
  onDelete: (value: string) => void;
};
export function InputTags({
  label,
  msgError,
  style,
  list,
  onTagsChanged,
  onDelete,
}: InputProps) {
  const handleOnSubmit = (ev: any) => {
    if (ev.length <= 0) return;
    onTagsChanged(ev);
  };
  return (
    <TextField
      style={style}
      label={label}
      color="secondary"
      onKeyPress={(ev: any) => {
        if (ev.key === "Enter") {
          ev.preventDefault();
          handleOnSubmit(ev.target.value);
          ev.target.value = "";
        }
      }}
      InputProps={{
        endAdornment: (
          <>
            {list && list?.length > 0 && (
              <Box
                display="flex"
                sx={{
                  width: "100%",
                  flexWrap: "wrap",
                  padding: "8px 0",
                  gap: 1,
                }}
              >
                {list?.map((data: any, index) => (
                  <Chip
                    key={index}
                    label={data}
                    onDelete={() => onDelete(data)}
                  />
                ))}
              </Box>
            )}
          </>
        ),
      }}
    />
  );
}
