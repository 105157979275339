import * as React from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { Input, Button, Dialog } from "../../../commons";
import Table, { ColumnsProps } from "../../../commons/Table";
import { UserFormDialog } from "../../../components";
import { SnackbarContext } from "../../../App";
import {
  serviceUnavailable,
  successfulOperation,
} from "../../../utils/message";

import { StoreUsersService } from "../../../services/openApi";

import { EditIcon, TrashIcon } from "../../../img/icon";

import { Page } from "./ManagementUser-style";

type openFormState = {
  open: boolean;
  title: string;
  action?: "delete" | "add" | "update";
};

export const ManagementUser = (): JSX.Element => {
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);
  const { getStoreUsers, deleteStoreUsers, updateUserStatus } =
    StoreUsersService;
  const { idStore } = useParams<{ idStore?: string }>();
  const [listUserState, setListUserState] = React.useState<any[]>([]);
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [openForm, setOpenForm] = React.useState<openFormState>({
    open: false,
    title: "",
    action: undefined,
  });
  const [openDelete, setOpenDelete] = React.useState<openFormState>({
    open: false,
    title: "",
    action: undefined,
  });
  const [openStatus, setOpenStatus] = React.useState<openFormState>({
    open: false,
    title: "",
    action: undefined,
  });
  const [idUser, setIdUser] = React.useState<number | undefined>(undefined);
  const [status, setStatus] = React.useState<boolean>(false);

  const getListUsers = useQuery(
    ["getStoreUsers", idStore],
    () => getStoreUsers(+idStore!),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
      enabled: !!idStore,
    }
  );

  React.useEffect(() => {
    if (getListUsers?.data) {
      const data = getListUsers?.data;
      setListUserState(data);
    }
  }, [getListUsers?.data]);

  const removeUser = useMutation(deleteStoreUsers, {
    onSuccess: () => {
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
      queryClient.fetchQuery(["getStoreUsers", idStore]);
      setOpenDelete({
        open: false,
        title: "",
      });
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const editStatus = useMutation(() => updateUserStatus(idUser!, { status }), {
    onSuccess: () => {
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
      queryClient.fetchQuery("getStoreUsers");
      setOpenStatus({
        open: false,
        title: "",
      });
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const columns: ColumnsProps = [
    {
      header: "Name",
      accessor: "name",
    },
    {
      header: "Email",
      accessor: "email",
    },
    {
      header: "Phone number",
      accessor: "mobile",
    },
    {
      header: "Status",
      accessor: "status",
    },
    {
      header: "User role",
      accessor: "userRole",
    },
    {
      header: "Actions",
      accessor: "actions",
      headerCellProps: { align: "center" },
      cellProps: { align: "center", width: "150px" },
    },
  ];

  const renderTableRows = () => {
    return listUserState
      ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      ?.map(
        (item: any) =>
          ({
            name: item?.name,
            email: item?.email,
            mobile: item?.mobile,
            status: (
              <Switch
                checked={item?.status}
                onChange={(e) => {
                  e.stopPropagation();
                  setIdUser(item?.id);
                  handleChange(e);
                }}
              />
            ),
            userRole: item?.role_name,
            actions: (
              <Box display="flex" justifyContent="center" gap={1}>
                <Button
                  variant="contained"
                  className="btn-edit"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIdUser(item?.id);
                    setOpenForm({
                      open: true,
                      title: "Edit user",
                      action: "update",
                    });
                  }}
                >
                  Edit
                  <EditIcon />
                </Button>
                <Button
                  variant="contained"
                  className="btn-delete"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIdUser(item?.id);
                    setOpenDelete({
                      open: true,
                      title: "Delete",
                      action: "delete",
                    });
                  }}
                >
                  Delete <TrashIcon />
                </Button>
              </Box>
            ),
          } || [])
      );
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const results = getListUsers?.data?.filter((o: any) =>
      o.name.toLowerCase().includes(event.target.value)
    );
    setListUserState(results);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStatus(event.target.checked);
    setOpenStatus({
      open: true,
      title: "Update Status",
      action: "update",
    });
  };

  return (
    <Page className="scrolbar">
      <div className="page-action">
        <Input
          placeholder="Search"
          onChange={handleSearch}
          startAdornment={
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          }
        />
        <Button
          variant="contained"
          color="secondary"
          className="btn-add"
          onClick={() =>
            setOpenForm({
              open: true,
              title: "Add New User",
              action: "add",
            })
          }
        >
          Add New User
        </Button>
      </div>
      <Box display="flex" flex="1" marginTop={3}>
        <Table
          columns={columns}
          loading={getListUsers.isLoading}
          nbrRowLoading={rowsPerPage}
          data={renderTableRows()}
          emptyMessage="No data available"
          tablePaginationProps={{
            rowsPerPageOptions: [5, 10, 25],
            count: getListUsers?.data?.length || 0,
            page: page,
            onPageChange: (_, newPage) => setPage(newPage),
            rowsPerPage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
        />
      </Box>
      {openForm.open && (
        <UserFormDialog
          open={openForm.open}
          title={openForm.title}
          action={openForm.action}
          idStore={idStore}
          idUser={idUser}
          onClose={() => {
            setOpenForm({
              open: false,
              title: "",
            });
            setIdUser(undefined);
          }}
        />
      )}
      <Dialog
        open={openDelete.open}
        title={openDelete.title}
        action="delete"
        handleContent={() => idUser && removeUser.mutate(idUser)}
        loading={removeUser.isLoading}
        onClose={() => {
          setOpenDelete({
            open: false,
            title: "",
          });
          setIdUser(undefined);
        }}
      >
        <Typography>Are you sure to delete this user?</Typography>
      </Dialog>
      <Dialog
        open={openStatus.open}
        title={openStatus.title}
        action="delete"
        handleContent={() => idUser && editStatus.mutate()}
        loading={editStatus.isLoading}
        onClose={() => {
          setOpenStatus({
            open: false,
            title: "",
          });
          setIdUser(undefined);
        }}
      >
        <Typography>Are you sure to change this user status?</Typography>
      </Dialog>
    </Page>
  );
};
