/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class RestaurantItemChoicesService {

    /**
     * List of Choices
     * @param id ID of item
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getItemChoices(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/admins/restaurant/choice/index/${id}`,
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * get choice by id
     * @param id ID of choice
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getChoiceById(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/admins/restaurant/choice/show/${id}`,
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * save a Choices
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static saveItemChoices(
        requestBody?: {
            name_en?: string;
            name_ar?: string;
            min_selection_needed?: number;
            max_selection_needed?: number;
            max_quantity?: number;
            show_onmenu?: boolean;
            item_id?: number;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/restaurant/choice/store`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * update a Choices
     * @param id ID of choice
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static updateItemChoices(
        id: number,
        requestBody?: {
            name_en?: string;
            name_ar?: string;
            min_selection_needed?: number;
            max_selection_needed?: number;
            max_quantity?: number;
            show_onmenu?: boolean;
            item_id?: number;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/restaurant/choice/update/${id}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * update a Choices
     * @param id ID of choice
     * @returns any Successful operation
     * @throws ApiError
     */
    public static deleteItemChoices(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/api/admins/restaurant/choice/delete/${id}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

}