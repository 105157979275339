import * as React from "react";
import { RouteComponentProps, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import SearchIcon from "@mui/icons-material/Search";
import { useQuery, useMutation, useQueryClient } from "react-query";
import moment from "moment";

import { Input, Button, Dialog, LazyLoadingImage } from "../../commons";
import Table, { ColumnsProps } from "../../commons/Table";
import { RestaurantsFormDialog } from "../../components";
import {
  EditIcon,
  EditIconSecondary,
  TrashIcon,
  CalendarIcon,
} from "../../img/icon";
import { SnackbarContext } from "../../App";
import { serviceUnavailable, successfulOperation } from "../../utils/message";

import { RestaurantStoresService } from "../../services/openApi";

import { Page } from "./Stores-styles";

type openFormState = {
  open: boolean;
  title: string;
  action?: "delete" | "add" | "update";
};

export const Stores = (props: RouteComponentProps): JSX.Element => {
  const { id } = useParams<{ id?: string }>();
  const queryClient = useQueryClient();
  const { getAllStores, deleteStore, updateCateringStatus } =
    RestaurantStoresService;
  const { setSnack } = React.useContext(SnackbarContext);

  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);

  const [openForm, setOpenForm] = React.useState<openFormState>({
    open: false,
    title: "",
  });

  const [openDelete, setOpenDelete] = React.useState<openFormState>({
    open: false,
    title: "",
  });

  const [openStatus, setOpenStatus] = React.useState<openFormState>({
    open: false,
    title: "",
  });

  const [idStore, setIdStore] = React.useState<number | undefined>(undefined);
  const [search, setSearch] = React.useState<string>("");
  const [status, setStatus] = React.useState<boolean>(false);

  const listStoresOfRestaurant = useQuery(
    ["getAllStores", id],
    () => getAllStores(parseInt(id!, 10), { search }),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
      enabled: !!id,
    }
  );

  const updateStatus = useMutation(
    () => updateCateringStatus(idStore!, { catering_status: status }),
    {
      onSuccess: () => {
        queryClient.fetchQuery(["getAllStores", id]);
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        setOpenStatus({
          open: false,
          title: "",
        });
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const deleteStoreOfRestaurant = useMutation(deleteStore, {
    onSuccess: () => {
      queryClient.fetchQuery(["getAllStores", id]);
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
      setOpenDelete({
        open: false,
        title: "",
        action: "delete",
      });
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const columns: ColumnsProps = [
    {
      header: "ID",
      accessor: "id",
    },
    {
      header: "Title",
      accessor: "title",
    },
    {
      header: "Photo",
      accessor: "photo",
    },
    {
      header: "Carting status",
      accessor: "cartingStatus",
    },
    {
      header: "Busy Status",
      accessor: "busyStatus",
    },
    {
      header: "Created",
      accessor: "created",
    },
    {
      header: "View Calendar",
      accessor: "viewCalendar",
      headerCellProps: { align: "center" },
      cellProps: { align: "center", width: "150px" },
    },
    {
      header: "Actions",
      accessor: "actions",
      headerCellProps: { align: "center" },
      cellProps: { align: "center", width: "130px" },
    },
  ];

  const renderTableRows = () => {
    return (
      listStoresOfRestaurant.data
        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        ?.map((item: any) => ({
          id: item?.id,
          title: item?.name,
          photo: (
            <LazyLoadingImage
              alt="store"
              src={item?.image?.uri} // use normal <img> attributes as props
              height={40}
              width={40}
              wrapperClassName="store-image"
            />
          ),
          cartingStatus: (
            <Switch
              checked={Boolean(item?.catering_status)}
              onClick={(e) => e.stopPropagation()}
              onChange={handleUpdateStatus(item?.id)}
            />
          ),
          busyStatus: "Open",
          created: item.created_at
            ? moment(item.created_at).format("DD MMM YYYY")
            : "",
          viewCalendar: (
            <Button
              variant="contained"
              className="btn-view"
              onClick={(e) => {
                e.stopPropagation();
                props.history.push(
                  `/main/restaurants/${id}/stores/${item?.id}/calendar`
                );
              }}
            >
              Calendar <CalendarIcon />
            </Button>
          ),
          actions: (
            <Box display="flex" justifyContent="center" gap={1}>
              <Button
                variant="contained"
                className="btn-edit"
                onClick={(e) => {
                  e.stopPropagation();
                  props.history.push(
                    `/main/restaurants/${id}/stores/${item?.id}/edit-store`
                  );
                }}
              >
                Edit
                <EditIcon />
              </Button>
              <Button
                variant="contained"
                className="btn-delete"
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenDelete({
                    open: true,
                    title: "Delete",
                    action: "delete",
                  });
                  setIdStore(item?.id);
                }}
              >
                Delete <TrashIcon />
              </Button>
            </Box>
          ),
        })) || []
    );
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleUpdateStatus =
    (id?: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setStatus(event.target.checked);
      setOpenStatus({
        open: true,
        title: "Update status",
        action: "update",
      });
      id && setIdStore(id);
    };

  return (
    <Page>
      <div className="page-action">
        <Input
          value={search}
          onChange={handleChange}
          onKeyPress={(e) =>
            e.key === "Enter" && listStoresOfRestaurant.refetch()
          }
          placeholder="Search by ID, name or E-mail"
          startAdornment={
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          }
        />
        <Button
          variant="outlined"
          color="secondary"
          className="btn-edit-restaurant"
          onClick={() =>
            setOpenForm({
              open: true,
              title: "Edit Restaurant",
              action: "update",
            })
          }
        >
          <EditIconSecondary /> Edit Restaurant
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className="btn-add"
          onClick={() =>
            props.history.push(`/main/restaurants/${id}/stores/add-store`)
          }
        >
          Add Store
        </Button>
      </div>
      <Box display="flex" flex="1" marginTop={3}>
        <Table
          columns={columns}
          loading={listStoresOfRestaurant.isLoading}
          nbrRowLoading={rowsPerPage}
          data={renderTableRows()}
          emptyMessage="No data available"
          onClickRow={(row) =>
            props.history.push(
              `/main/restaurants/${id}/stores/${row?.id}/categorie-&-carting-menu`
            )
          }
          tablePaginationProps={{
            rowsPerPageOptions: [5, 10, 25],
            count: listStoresOfRestaurant?.data?.length || 0,
            page: page,
            onPageChange: (_, newPage) => setPage(newPage),
            rowsPerPage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
        />
      </Box>
      {openForm.open && (
        <RestaurantsFormDialog
          open={openForm.open}
          title={openForm.title}
          idRestaurant={id ? parseInt(id) : undefined}
          action={openForm.action}
          onClose={() =>
            setOpenForm({
              open: false,
              title: "",
              action: undefined,
            })
          }
        />
      )}
      <Dialog
        open={openDelete.open}
        title={openDelete.title}
        action="delete"
        handleContent={() => idStore && deleteStoreOfRestaurant.mutate(idStore)}
        loading={deleteStoreOfRestaurant.isLoading}
        onClose={() =>
          setOpenDelete({
            open: false,
            title: "",
            action: "delete",
          })
        }
      >
        <Typography>Are you sure to delete this store?</Typography>
      </Dialog>
      <Dialog
        open={openStatus.open}
        title={openStatus.title}
        action="update"
        handleContent={() => idStore && updateStatus.mutate()}
        loading={updateStatus.isLoading}
        onClose={() =>
          setOpenStatus({
            open: false,
            title: "",
          })
        }
      >
        <Typography>Are you sure to change status this store?</Typography>
      </Dialog>
    </Page>
  );
};
