import styled from "styled-components";

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  .btn-return {
    position: fixed;
    right: 90px;
    bottom: 20px;
  }

  .btn-print {
    position: fixed;
    right: 20px;
    bottom: 20px;
  }
`;

export const pageStyle = `
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @page {
    size: A4;
    margin: 0mm;
  }
`;
