import styled from "styled-components";
import { TextField as TextFieldMui } from "@mui/material";

export const TextField = styled(TextFieldMui)`
  background-color: #fff;
  border-radius: 30px;
  .MuiOutlinedInput-root {
    border-radius: 30px;
  }
  .MuiInputLabel-root {
    top: -3px;
    color: ${(props) => (props.error ? "#f67070" : "#c4c2c2")};
  }
  .MuiOutlinedInput-input {
    padding: 13px;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: #dad8d8;
  }
  .Mui-disabled {
    background: #f9f8f8;
    border-radius: 30px;
  }
`;
