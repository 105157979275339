export const serviceUnavailable = "Service unavailable";
export const successfulOperation = "successful operation";

export const message = {
  login: {
    emailRequiered: "This field email is required",
    emailInvalid: "Invalid email address",
    password: "This field password is requiered",
    authenticationError: "Please verify your email address or password",
  },
  changePassword: {
    oldPassword: "This field is required",
    newPassword: "This field is required",
    confirmPassword: "This field is required",
    notConfirmPassword: "The confirmation does not match the new password",
  },
  resetPassword: {
    newPassword: "This field is required",
    confirmPassword: "This field is required",
    notConfirmPassword: "The confirmation does not match the new password",
  },
  restaurantsForm: {
    logofile: "This logo is required",
    nameRequiered: "This field is required",
    phoneRequiered: "This field is required",
    phoneInvalid: "Invalid phone",
    emailRequiered: "This field is required",
    emailInvalid: "Invalid email address",
    passwordRequiered: "This field is required",
  },
  couponsForm: {
    title_en: "This field is required",
    short_description_en: "This field is required",
    title_ar: "This field is required",
    short_description_ar: "This field is required",
    discount: "This field is required",
    meiz_contribution: "This field is required",
    start_date: "This field is required",
    end_date: "This field is required",
    code: "This field is required",
  },
  storeForm: {
    nameEn: "This name English is required",
    nameAr: "This name Arabic is required",
    cateringComission: "This catering comission is required",
    valueAddedTax: "This value added tax is required",
    cancellationDays: "This field is required",
    cateringAdvance: "This field is required",
    cashOnDeliveryLimit: "This field is required",
    creditCardFee: "This field is required",
    debitCardFee: "This field is required",
    deliveryType: "This field is required",
    email: "This field is required",
    emailInvalid: "This email is invalid",
    phone: "This field is required",
    invalidPhone: "This phone is invalid",
    descriptionEn: "This field is required",
    descriptionAr: "This field is required",
    maxCateringPerDay: "This field is required",
    latitude: "This field is required",
    longitude: "This field is required",
    addressEn: "This field is required",
    addressAr: "This field is required",
    logofile: "This is required",
    thumbfile: "This is required",
    imagefile: "This is required",
  },
  userForm: {
    name: "This name is required",
    email: "This email is required",
    emailInvalid: "This email is invalid",
    mobile: "This mobile is required",
    mobileInvalid: "This phone is invalid",
    password: "This possord is required",
    role: "This role is required",
    imagefile: "This picture is required",
  },
  menu: {
    thumbfile: "This picture is required",
    nameEn: "This name English is required",
    nameAr: "This name Arabic is required",
    itemRequirements: "This field is required",
    shortDescriptionEn: "This field is required",
    shortDescriptionAr: "This field is required",
    globalCategories: "This field is required",
    filters: "This field is required",
    subCategoryId: "This field is required",
    quantityPerDay: "This field is required",
    maxQuantityPerDay: "This field is required",
    originalPrice: "This field is required",
    promotedPrice: "This field is required",
    pricePerUnit: "This field is required",
    enoughFor: "This field is required",
    leadTime: "This field is required",
    availableFromTime: "This field is required",
    availableToTime: "This field is required",
    setupTime: "This field is required",
    maxTime: "This field is required",
  },
};
