import * as React from "react";
import Box from "@mui/system/Box";
import InputAdornment from "@mui/material/InputAdornment";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { Input, Button, Dialog, LazyLoadingImage } from "../../../commons";
import Table, { ColumnsProps } from "../../../commons/Table";
import { FiltersFormDialog } from "../../../components";
import { SnackbarContext } from "../../../App";

import { RestaurantFiltersService } from "../../../services/openApi";

import {
  serviceUnavailable,
  successfulOperation,
} from "../../../utils/message";

import { EditIcon, TrashIcon, DefualtIcon } from "../../../img/icon";
import DefaultImage from "../../../img/Default_Image_Thumbnail.png";

import { Page } from "./Filters-style";

type openFormState = {
  open: boolean;
  title: string;
  action: "delete" | "add" | "update" | undefined;
};

export const Filters = (): JSX.Element => {
  const queryClient = useQueryClient();
  const { getFilters, deleteFilter, updateCategoryStatus } =
    RestaurantFiltersService;
  const { setSnack } = React.useContext(SnackbarContext);
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);

  const [openForm, setOpenForm] = React.useState<openFormState>({
    open: false,
    title: "",
    action: undefined,
  });

  const [openDelete, setOpenDelete] = React.useState<openFormState>({
    open: false,
    title: "",
    action: undefined,
  });

  const [search, setSearch] = React.useState("");
  const [idFilter, setIdFilter] = React.useState<number | undefined>(undefined);
  const [status, setStatus] = React.useState<boolean | undefined>(undefined);
  const [openStatus, setOpenStatus] = React.useState<openFormState>({
    open: false,
    title: "",
    action: undefined,
  });

  const listFilters = useQuery("getFilters", () => getFilters({ search }), {
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const removeFilter = useMutation(deleteFilter, {
    onSuccess: () => {
      queryClient.fetchQuery("getFilters");
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
      setOpenDelete({
        open: false,
        title: "",
        action: "delete",
      });
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const updateFilterStatus = useMutation(
    () => updateCategoryStatus(idFilter!, { status }),
    {
      onSuccess: () => {
        queryClient.fetchQuery("getFilters");
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        setOpenStatus({
          open: false,
          title: "",
          action: "update",
        });
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const columns: ColumnsProps = [
    {
      header: "Registration code",
      accessor: "registrationCode",
    },
    {
      header: "Name",
      accessor: "name",
    },
    {
      header: "Icon",
      accessor: "icon",
    },
    {
      header: "Active",
      accessor: "active",
    },

    {
      header: "Actions",
      accessor: "actions",
      headerCellProps: { align: "center" },
      cellProps: { align: "center", width: "150px" },
    },
  ];

  const renderTableRows = () => {
    return (
      listFilters?.data?.filters
        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        ?.map((item: any) => ({
          registrationCode: item?.slug,
          name: item?.name,
          icon: item?.image?.uri ? (
            // <img src={item.image.uri} alt="" className="icon-image" />
            <LazyLoadingImage
              alt="file preview"
              src={item?.image?.uri}
              placeholderSrc={DefaultImage}
              height={40}
              width={40}
              className="icon-image"
            />
          ) : (
            <DefualtIcon />
          ),
          active: (
            <Switch
              checked={Boolean(item?.status)}
              onChange={(e) => {
                e.stopPropagation();
                setStatus(e.target.checked);
                setIdFilter(item?.id);
                setOpenStatus({
                  open: true,
                  title: "Update Status",
                  action: "update",
                });
              }}
            />
          ),
          actions: (
            <Box display="flex" justifyContent="center" gap={1}>
              <Button
                variant="contained"
                className="btn-edit"
                onClick={(e) => {
                  e.stopPropagation();
                  setIdFilter(item?.id);
                  setOpenForm({
                    open: true,
                    title: "Edit Filter",
                    action: "update",
                  });
                }}
              >
                Edit
                <EditIcon />
              </Button>
              <Button
                variant="contained"
                className="btn-delete"
                onClick={(e) => {
                  e.stopPropagation();
                  setIdFilter(item?.id);
                  setOpenDelete({
                    open: true,
                    title: "Delete",
                    action: "delete",
                  });
                }}
              >
                Delete <TrashIcon />
              </Button>
            </Box>
          ),
        })) || []
    );
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  return (
    <Page>
      <div className="page-action">
        <Input
          value={search}
          placeholder="Search"
          onChange={handleChange}
          onKeyPress={(e) => e.key === "Enter" && listFilters.refetch()}
          startAdornment={
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          }
        />
        <Button
          variant="contained"
          color="secondary"
          className="btn-add"
          onClick={() =>
            setOpenForm({
              open: true,
              title: "Add New Filter",
              action: "add",
            })
          }
        >
          Add New Filter
        </Button>
      </div>
      <Box display="flex" flex="1" marginTop={3}>
        <Table
          columns={columns}
          loading={listFilters.isLoading || listFilters.isFetching}
          data={renderTableRows()}
          nbrRowLoading={rowsPerPage}
          emptyMessage="No data available"
          tablePaginationProps={{
            rowsPerPageOptions: [5, 10, 25],
            count: listFilters?.data?.filters?.length || 0,
            page: page,
            onPageChange: (_, newPage) => setPage(newPage),
            rowsPerPage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
        />
      </Box>
      {openForm.open && (
        <FiltersFormDialog
          open={openForm.open}
          title={openForm.title}
          action={openForm.action}
          onClose={() => {
            setOpenForm({
              open: false,
              title: "",
              action: undefined,
            });
            setIdFilter(undefined);
          }}
          idFilter={idFilter}
        />
      )}
      <Dialog
        open={openDelete.open}
        title={openDelete.title}
        action="delete"
        handleContent={() => idFilter && removeFilter.mutate(idFilter)}
        loading={removeFilter.isLoading}
        onClose={() =>
          setOpenDelete({
            open: false,
            title: "",
            action: "delete",
          })
        }
      >
        <Typography>Are you sure to delete this filter?</Typography>
      </Dialog>
      <Dialog
        open={openStatus.open}
        title={openStatus.title}
        action="delete"
        handleContent={() => idFilter && updateFilterStatus.mutate()}
        loading={updateFilterStatus.isLoading}
        onClose={() =>
          setOpenStatus({
            open: false,
            title: "",
            action: "update",
          })
        }
      >
        <Typography>Are you sure to change this filter status?</Typography>
      </Dialog>
    </Page>
  );
};
