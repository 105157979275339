import * as React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import { useMutation, useQueryClient } from "react-query";

import { Dialog, TextField, Select } from "../../commons";

import { SnackbarContext } from "../../App";
import {
  PaymentRequestService,
  PaymentCustomRequestService,
  RoleStoreUserPaymentRequestService,
} from "../../services/openApi";
import { serviceUnavailable, successfulOperation } from "../../utils/message";

type PaymentRequestDialogProps = {
  idStore?: number;
  idRequest?: number;
  open: boolean;
  title: string;
  action?: "update";
  startDate?: string;
  endDate?: string;
  custom?: boolean;
  storeCustom?: boolean;
  onClose: () => void;
};

export const PaymentRequestDialog = ({
  idStore,
  idRequest,
  open,
  title,
  action,
  startDate,
  endDate,
  custom,
  onClose,
  storeCustom,
}: PaymentRequestDialogProps) => {
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);
  const { updateStorePaymentRequest } = PaymentRequestService;
  const { updatePaymentCustomRequest } = PaymentCustomRequestService;
  const { updatePaymentRequest } = RoleStoreUserPaymentRequestService;

  const [stateForm, setStateForm] =
    React.useState<{ note?: string; status?: string }>();

  const updateRequest = useMutation(
    () => updateStorePaymentRequest(idRequest!, { ...stateForm }),
    {
      onSuccess: () => {
        queryClient.refetchQueries([
          "getStorePaymentRequestList",
          idStore,
          startDate,
          endDate,
        ]);
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        onClose();
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "success",
          message: serviceUnavailable,
        });
      },
    }
  );

  const updateRequestCustom = useMutation(
    () => updatePaymentCustomRequest(idRequest!, { ...stateForm }),
    {
      onSuccess: () => {
        queryClient.refetchQueries([
          "getStorePaymentRequestList",
          idStore,
          startDate,
          endDate,
        ]);
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        onClose();
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "success",
          message: serviceUnavailable,
        });
      },
    }
  );

  const updatePaymentRequestCustom = useMutation(
    () => updatePaymentRequest(idRequest!, { ...stateForm }),
    {
      onSuccess: () => {
        queryClient.refetchQueries([
          "getStorePaymentRequestList",
          idStore,
          startDate,
          endDate,
        ]);
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        onClose();
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "success",
          message: serviceUnavailable,
        });
      },
    }
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    console.log("value", value);
    setStateForm({
      ...stateForm,
      [name]: value,
    });
  };

  const handleChangeSelect = (value: unknown) => {
    console.log("value", value);
    setStateForm({
      ...stateForm,
      status: value as string,
    });
  };

  const handleSubmit = () => {
    if (idRequest) {
      if (storeCustom) {
        updatePaymentRequestCustom.mutate();
      } else if (custom) {
        updateRequestCustom.mutate();
      } else {
        updateRequest.mutate();
      }
    }
  };

  return (
    <Dialog
      open={open}
      title={title}
      maxWidth="sm"
      handleContent={handleSubmit}
      loading={updateRequest.isLoading || updateRequestCustom.isLoading}
      onClose={onClose}
      action={action}
    >
      <Box display="flex" width={350} flexDirection="column" gap={3}>
        <TextField
          name="note"
          value={stateForm?.note || ""}
          onChange={handleChange}
          color="secondary"
          placeholder="Enter Notes here"
          multiline
          rows={3}
        />
        <Select
          label="Select Action"
          value={stateForm?.status || ""}
          onChange={(e) => handleChangeSelect(e.target.value)}
          color="secondary"
        >
          <MenuItem value="request_accepted">Accepted</MenuItem>
          <MenuItem value="request_rejected">Rejected</MenuItem>
        </Select>
      </Box>
    </Dialog>
  );
};
