import * as React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider as ScProvider } from "styled-components";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import { PrivateRoute } from "./commons";
import { AuthRouter, MainRoute } from "./routes";
import { NotFound, ViewOrder } from "./modules";

import { OpenAPI } from "../src/services/openApi";
import { API_URL } from "../src/utils/config";

export type SnackState = {
  open: boolean;
  severity: "success" | "error" | "warning" | "info" | undefined;
  message: string;
};

const snackInitial: SnackState = {
  open: false,
  severity: undefined,
  message: "",
};

const appCtxSnackBarDefaultValue = {
  setSnack: (snack: SnackState) => {},
};

const appCtxNameRestaurantDefaultValue = {
  nameRestaurant: "",
  setNameRestaurant: (name: string) => {},
};

const muiTheme = createTheme({
  palette: {
    primary: { main: "#6D6AFF", contrastText: "#fff" },
    secondary: { main: "#5BC0DE", contrastText: "#fff" },
    text: { primary: "#333333", secondary: "#757575" },
    error: { main: "#F67070", contrastText: "#fff" },
    warning: { main: "#FCF1F1", contrastText: "#F67070" },
    success: { main: "#4CD964", contrastText: "#fff" },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    h1: {
      fontSize: "28px",
      fontWeight: 400,
    },
    h2: {
      fontSize: "20px",
      fontWeight: 400,
    },
    h3: {
      fontSize: "18px",
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: "14px",
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: "12px",
      fontWeight: 400,
    },
    body1: {
      fontSize: "14px",
    },
    body2: {
      fontSize: "12px",
    },
  },
});

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const SnackbarContext = React.createContext(appCtxSnackBarDefaultValue);
export const NameRestaurantContext = React.createContext(
  appCtxNameRestaurantDefaultValue
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const [snack, setSnack] = React.useState<SnackState>(snackInitial);
  const [nameRestaurant, setNameRestaurant] = React.useState<string>("");

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setSnack(snackInitial);
  };

  OpenAPI.BASE = API_URL as string;

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <SnackbarContext.Provider value={{ setSnack }}>
          <NameRestaurantContext.Provider
            value={{ nameRestaurant, setNameRestaurant }}
          >
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={muiTheme}>
                <ScProvider theme={muiTheme}>
                  <CssBaseline />
                  <Router>
                    <Switch>
                      <PrivateRoute path="/main" component={MainRoute} />
                      <PrivateRoute path="/restaurant" component={MainRoute} />
                      <PrivateRoute path="/store" component={MainRoute} />
                      <Route
                        path="/view-order/:orderId/:token"
                        component={ViewOrder}
                        exact
                      />
                      <Route
                        path="/view-order/:orderId"
                        component={ViewOrder}
                        exact
                      />
                      <Route path="/" component={AuthRouter} />
                      <Route path="/*" component={NotFound} />
                    </Switch>
                  </Router>
                </ScProvider>
              </ThemeProvider>
            </StyledEngineProvider>
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              open={snack.open}
              autoHideDuration={6000}
              onClose={handleClose as any}
            >
              <Alert
                onClose={handleClose}
                severity={snack.severity}
                sx={{ width: "100%" }}
              >
                {snack.message}
              </Alert>
            </Snackbar>
          </NameRestaurantContext.Provider>
        </SnackbarContext.Provider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </>
  );
}

export default App;
