import styled from "styled-components";

export const FormStyled = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 20px;
  border: 1px solid #dddddd;
  border-radius: 20px;
  .form-column {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 170px;
    gap: 15px;
    .description {
      margin-top: 8px;
      color: #dad8d8;
    }
  }
`;
