/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";
import { useQuery } from "react-query";

import { Input, Dialog, LazyLoadingImage } from "../../commons";
import Table, { ColumnsProps } from "../../commons/Table";

import { NameRestaurantContext } from "../../App";

import { CustomersService } from "../../services/openApi";

import {
  CostumerFormDialog,
  BlockFormDialog,
  CardStatusCustomer,
} from "../../components";

// import {
//   EditIcon,
//   TrashIcon,
//   UserBlockIcon,
//   UserUnBlockIcon,
// } from "../../img/icon";
import DefaultImage from "../../img/personn-default.png";

import { Page } from "./costumers-styles";

type OpenFormState = {
  open: boolean;
  title: string;
  action: "delete" | "add" | "update" | undefined;
};

type BlockFormState = {
  title: string;
  action: "block" | "unBlock" | undefined;
};

export const Costumers = (props: RouteComponentProps): JSX.Element => {
  const { getCustomersList } = CustomersService;
  const { setNameRestaurant } = React.useContext(NameRestaurantContext);

  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [search, setSearch] = React.useState<string>("");
  const [listUsers, setListUsers] = React.useState<any[]>([]);

  const [openForm, setOpenForm] = React.useState<OpenFormState>({
    open: false,
    title: "",
    action: undefined,
  });
  const [openDelete, setOpenDelete] = React.useState<OpenFormState>({
    open: false,
    title: "",
    action: undefined,
  });
  const [openFormBlock, setOpenFormBlock] = React.useState<BlockFormState>({
    title: "",
    action: undefined,
  });

  React.useEffect(() => {
    setNameRestaurant("");
  }, []);

  const listCustomers = useQuery("getCustomersList", () =>
    getCustomersList({ search })
  );

  React.useEffect(() => {
    const data = listCustomers?.data?.users;
    if (data) {
      setListUsers(data);
    }
  }, [listCustomers?.data?.users]);

  const columns: ColumnsProps = [
    {
      header: "ID",
      accessor: "id",
    },
    {
      header: "Name",
      accessor: "name",
    },
    {
      header: "Photo",
      accessor: "photo",
    },
    {
      header: "Email",
      accessor: "email",
    },
    {
      header: "Mobile",
      accessor: "mobile",
    },
    {
      header: "Created",
      accessor: "created",
    },
    // {
    //   header: "Actions",
    //   accessor: "actions",
    //   headerCellProps: { align: "center" },
    //   cellProps: { align: "center", width: "150px" },
    // },
  ];

  const renderTableRows = () => {
    return (
      // listCustomers?.data?.users
      listUsers
        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        ?.map((item: any) => ({
          id: item.id,
          name: item.fullname,
          photo: (
            <LazyLoadingImage
              alt="customer"
              src={DefaultImage}
              className="user-image"
              placeholderSrc={DefaultImage}
            />
          ),
          email: item.email,
          mobile: item.mobile,
          created: item.created_at,
          // actions: (
          //   <Box display="flex" justifyContent="center" gap={1}>
          //     <Button
          //       variant="contained"
          //       className="btn-edit"
          //       onClick={(e) => {
          //         e.stopPropagation();
          //         setOpenForm({
          //           open: true,
          //           title: "Edit Costumer",
          //           action: "add",
          //         });
          //       }}
          //     >
          //       Edit
          //       <EditIcon />
          //     </Button>
          //     <Button
          //       variant="contained"
          //       onClick={(e) => {
          //         e.stopPropagation();
          //         setOpenDelete({
          //           open: true,
          //           title: "Delete",
          //           action: "delete",
          //         });
          //       }}
          //       className="btn-delete"
          //     >
          //       Delete <TrashIcon />
          //     </Button>
          //   </Box>
          // ),
        })) || []
    );
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleFilter = (filter?: string) => {
    if (filter === "active") {
      const listFilterActive = listCustomers?.data?.users?.filter(
        (item: any) => item?.active
      );
      setListUsers(listFilterActive);
    } else if (filter === "unblocked") {
      const listFilterUnblocked = listCustomers?.data?.users?.filter(
        (item: any) => item?.status
      );
      setListUsers(listFilterUnblocked);
    } else if (filter === "blocked") {
      const listFilterblocked = listCustomers?.data?.users?.filter(
        (item: any) => !item?.status
      );
      setListUsers(listFilterblocked);
    } else {
      setListUsers(listCustomers?.data?.users);
    }
  };

  return (
    <Page className="scrolbar">
      <Box display="flex" justifyContent="space-between" gap={3}>
        <CardStatusCustomer
          status="usersCount"
          loading={listCustomers?.isLoading}
          count={listCustomers?.data?.usersCount || 0}
          onClick={() => handleFilter()}
        />
        <CardStatusCustomer
          status="activeCount"
          loading={listCustomers?.isLoading}
          count={listCustomers?.data?.activeCount || 0}
          onClick={() => handleFilter("active")}
        />
        <CardStatusCustomer
          status="unblockedCount"
          loading={listCustomers?.isLoading}
          count={listCustomers?.data?.unblockedCount || 0}
          onClick={() => handleFilter("unblocked")}
        />
        <CardStatusCustomer
          status="blockedCount"
          loading={listCustomers?.isLoading}
          count={listCustomers?.data?.blockedCount || 0}
          onClick={() => handleFilter("blocked")}
        />
      </Box>
      <div className="page-action">
        <Input
          value={search}
          placeholder="Search"
          onChange={handleSearch}
          onKeyPress={(e) => e.key === "Enter" && listCustomers.refetch()}
          startAdornment={
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          }
        />
        {/* <Button
          variant="contained"
          color="secondary"
          className="btn-add"
          onClick={() =>
            setOpenForm({
              open: true,
              title: "Add New Costumer",
              action: "add",
            })
          }
        >
          Add Costumer
        </Button> */}
      </div>
      <Box
        display="flex"
        flex="1"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Table
          columns={columns}
          data={renderTableRows()}
          loading={listCustomers?.isLoading || listCustomers?.isFetching}
          nbrRowLoading={10}
          emptyMessage="No data available"
          onClickRow={(row) =>
            props.history.push(`/main/customers/customer-profile/${row.id}`)
          }
          tablePaginationProps={{
            rowsPerPageOptions: [5, 10, 25],
            count: listUsers?.length || 0,
            page: page,
            onPageChange: (_, newPage) => setPage(newPage),
            rowsPerPage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
        />
      </Box>
      <CostumerFormDialog
        open={openForm.open}
        title={openForm.title}
        action={openForm.action}
        onClose={() =>
          setOpenForm({
            open: false,
            title: "",
            action: undefined,
          })
        }
      />
      <Dialog
        open={openDelete.open}
        title={openDelete.title}
        action="delete"
        handleContent={() => {}}
        onClose={() =>
          setOpenDelete({
            open: false,
            title: "",
            action: "delete",
          })
        }
      >
        <Typography>Are you sure to delete this user?</Typography>
      </Dialog>
      <BlockFormDialog
        open={openFormBlock.action === "block"}
        title={openFormBlock.title}
        action={openFormBlock.action}
        onClose={() =>
          setOpenFormBlock({
            title: "",
            action: undefined,
          })
        }
      />
      <Dialog
        open={openFormBlock.action === "unBlock"}
        title={openFormBlock.title}
        action={openFormBlock.action}
        handleContent={() => {}}
        onClose={() =>
          setOpenFormBlock({
            title: "",
            action: undefined,
          })
        }
      >
        <Typography>Are you sure to unBlock this user?</Typography>
      </Dialog>
    </Page>
  );
};
