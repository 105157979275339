import Box from "@mui/material/Box";
import { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";

import { Button, LoadingButton, Tab, Tabs } from "../../../commons";
import { Page, BoxPanel } from "./Catering-style";
import { Revenue } from "./Revenue";

import { PrinterIcon } from "../../../img/icon";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <BoxPanel>{children}</BoxPanel>}
    </div>
  );
}

export const CateringReports = (props: RouteComponentProps) => {
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Page className="scrolbar">
      <Box className="header-action" gap={2}>
        <Button variant="outlined" color="secondary" className="btn-print">
          <PrinterIcon />
          Print
        </Button>
        <LoadingButton
          variant="contained"
          color="secondary"
          style={{ height: 42 }}
        >
          <FeedOutlinedIcon sx={{ marginRight: "4px" }} />
          Export to Excel
        </LoadingButton>
      </Box>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        TabIndicatorProps={{
          style: {
            display: "none",
            height: "40px",
          },
        }}
      >
        <Tab
          label="Revenue restaurant"
          index={0}
          selected={value === 0}
          {...a11yProps(0)}
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Revenue />
      </TabPanel>
    </Page>
  );
};
