import styled from "styled-components";
import { Dialog as DialogMui } from "@mui/material";

export type DialogStyledProps = {
  positionbtnaction?: "center" | "start" | "end";
};

export const DialogStyled = styled(DialogMui)<DialogStyledProps>`
  .MuiPaper-root {
    border-radius: 20px;
    padding: 10px 25px 20px;
  }
  .title {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
  }
  .content {
    padding: 8px 0 16px 0;
  }
  .action {
    justify-content: ${({ positionbtnaction }) => positionbtnaction};
    button {
      height: 38px;
      box-shadow: none;
    }
  }
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
    .MuiAutocomplete-input {
    padding: 4px;
  }
`;
