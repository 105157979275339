/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useParams, RouteComponentProps } from "react-router-dom";
import Box from "@mui/material/Box";
import { useMutation, useQueryClient, useQuery } from "react-query";

import { Tab, Tabs, Button, LoadingButton } from "../../../commons";
import { GeneralInfoForm, Choices, Addons } from "../../../components";
import { SnackbarContext } from "../../../App";
import { successfulOperation } from "../../../utils/message";
import { StoreItemsService } from "../../../services/openApi";

import { Page, BoxPanel } from "./FormCartingMenu-style";

import { GeneralInfoType } from "../../../components/GeneralInfoForm/GeneralInfoForm-type";
import { validation, Error } from "./GeneralInfoForm-validation";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <BoxPanel>{children}</BoxPanel>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const FormCartingMenu = (props: RouteComponentProps): JSX.Element => {
  const { setSnack } = React.useContext(SnackbarContext);
  const { id, idStore, idMenu } =
    useParams<{ id?: string; idStore?: string; idMenu?: string }>();
  const queryClient = useQueryClient();
  const { saveItem, getItemDetails, updateItem } = StoreItemsService;

  const [value, setValue] = React.useState(0);

  const [formMenu, setFormMenu] = React.useState<GeneralInfoType>({
    thumbfile: undefined,
    name_en: undefined,
    name_ar: undefined,
    short_description_en: undefined,
    short_description_ar: undefined,
    global_categories: undefined,
    filters: undefined,
    sub_category_id: undefined,
    quantity_per_day: undefined,
    max_quantity_per_day: undefined,
    item_unit_id: 1,
    original_price: undefined,
    promoted_price: undefined,
    price_per_unit: undefined,
    enough_for: undefined,
    lead_time: undefined,
    available_from_time: undefined,
    available_to_time: undefined,
    setup_time: undefined,
    max_time: undefined,
    item_requirements: undefined,
    delivery_type: "catering",
  });

  const [fromMenuError, setFormMenuError] = React.useState<Error>();

  const getMenuDetails = useQuery(
    ["getItemDetails", idMenu],
    () => getItemDetails(+idMenu!),
    {
      onError: (error: any) => {
        setSnack({
          open: true,
          severity: "error",
          message: JSON.stringify(error?.body?.message),
        });
      },
      enabled: !!idMenu,
    }
  );

  React.useEffect(() => {
    if (getMenuDetails?.data) {
      const data = getMenuDetails?.data;
      const arrayIdsGlabalGategory: number[] = [];
      const arrayIdsFilters: number[] = [];
      const arrayIdsRequirement: number[] = [];
      const idsGallery: number[] = [];
      const {
        item_translations,
        sub_category_id,
        quantity_per_day,
        max_quantity_per_day,
        item_units_id,
        original_price,
        promoted_price,
        enough_for,
        lead_time,
        available_from_time,
        available_to_time,
        setup_time,
        max_time,
        thumb,
        price_per_unit,
        drop_off_time,
        delivery_type,
        special_fees_amount,
      } = data?.item;

      data?.item?.global_categories?.forEach((item: any) =>
        arrayIdsGlabalGategory.push(item?.category_id)
      );

      data?.item?.filters?.forEach((item: any) =>
        arrayIdsFilters.push(item?.id)
      );

      data?.item?.requirements?.forEach((item: any) =>
        arrayIdsRequirement.push(item?.id)
      );

      data?.item?.gallery?.forEach((item: any) =>
        idsGallery.push(item?.imagefid)
      );

      setFormMenu({
        ...formMenu,
        thumbfile: thumb?.id,
        images: idsGallery,
        name_en: item_translations?.[0]?.name,
        name_ar: item_translations?.[1]?.name,
        dish_en: item_translations?.[0]?.dish,
        dish_ar: item_translations?.[1]?.dish,
        short_description_en: item_translations?.[0]?.short_description,
        short_description_ar: item_translations?.[1]?.short_description,
        description_en: item_translations?.[0]?.description,
        description_ar: item_translations?.[1]?.description,
        global_categories: arrayIdsGlabalGategory,
        sub_category_id: sub_category_id,
        filters: arrayIdsFilters,
        quantity_per_day,
        max_quantity_per_day,
        item_unit_id: item_units_id,
        original_price,
        promoted_price,
        enough_for,
        lead_time,
        available_from_time,
        available_to_time,
        setup_time,
        max_time,
        item_requirements: arrayIdsRequirement,
        price_per_unit,
        drop_off_time,
        delivery_type,
        special_fees_amount,
      });
    }
  }, [getMenuDetails?.data]);

  const urlsGallery = getMenuDetails?.data?.item?.gallery?.map(
    (item: any) => item?.image?.uri
  );

  const addMenu = useMutation(() => saveItem(formMenu), {
    onSuccess: (data) => {
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
      queryClient.fetchQuery(["getItemDetails", idMenu]);
      props.history.push(
        `/main/restaurants/${id}/stores/${idStore}/categorie-&-carting-menu/form-menu/${data?.item_id}`
      );
    },
    onError: (error: any) => {
      setSnack({
        open: true,
        severity: "error",
        message: JSON.stringify(error?.body?.message),
      });
    },
  });

  const updateMenu = useMutation(() => updateItem(+idMenu!, formMenu), {
    onSuccess: () => {
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
      queryClient.fetchQuery(["getItemDetails", idMenu]);
    },
    onError: async (error: any) => {
      const messageError = await JSON.stringify(error?.body?.message);
      setSnack({
        open: true,
        severity: "error",
        message: messageError,
      });
    },
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleError = (attribute: string) => {
    setFormMenuError({
      ...fromMenuError,
      [attribute]: "",
    });
  };

  const handleDropOffTimeFrom = (value?: string) => {
    let dropOffTime: string | undefined = formMenu.drop_off_time || "";
    const position = dropOffTime.indexOf("-");
    if (value) {
      if (position === -1) {
        // dropOffTime = value?.concat("-");
        dropOffTime = value;
      } else {
        const str = dropOffTime?.substring(position, dropOffTime?.length);
        dropOffTime = value?.concat(str);
      }
      setFormMenu({
        ...formMenu,
        drop_off_time: dropOffTime,
      });
    }
  };

  const handleDropOffTimeTo = (value?: string) => {
    let dropOffTime: string | undefined = formMenu.drop_off_time || "";
    const position = dropOffTime.indexOf("-");
    if (value) {
      if (position === -1) {
        // dropOffTime = "-"?.concat(value);
        dropOffTime = dropOffTime + "-"?.concat(value);
      } else {
        const str = dropOffTime?.substring(0, position + 1);
        dropOffTime = str?.concat(value);
      }
      setFormMenu({
        ...formMenu,
        drop_off_time: dropOffTime,
      });
    }
  };

  const handleSave = () => {
    const resultValidation = validation(formMenu);
    if (Object.entries(resultValidation).length === 0) {
      if (idMenu) {
        updateMenu.mutate();
      } else {
        addMenu.mutate();
      }
    } else {
      setFormMenuError({ ...resultValidation });
    }
  };

  return (
    <Page className="scrolbar">
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          TabIndicatorProps={{
            style: {
              display: "none",
              height: "40px",
            },
          }}
        >
          <Tab
            label="General  Info"
            index={0}
            selected={value === 0}
            {...a11yProps(0)}
          />
          <Tab
            label="Choices"
            index={1}
            selected={value === 1}
            {...a11yProps(0)}
            disabled={!idMenu}
          />
          <Tab
            label="Addons"
            index={2}
            selected={value === 2}
            {...a11yProps(0)}
            disabled={!idMenu}
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          <GeneralInfoForm
            inputData={formMenu}
            formValidation={fromMenuError}
            onChange={setFormMenu}
            onError={handleError}
            urlThumbfile={getMenuDetails?.data?.item?.thumb?.uri}
            urlsGallery={urlsGallery}
            onHandleDropOffTimeFrom={handleDropOffTimeFrom}
            onHandleDropOffTimeTo={handleDropOffTimeTo}
            onHandleCleanDropOffTime={() =>
              setFormMenu({
                ...formMenu,
                drop_off_time: undefined,
              })
            }
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Choices idMenu={idMenu} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Addons idMenu={idMenu} />
        </TabPanel>
      </Box>
      {value === 0 && (
        <Box
          display="flex"
          minHeight={45}
          justifyContent="flex-end"
          marginTop="20px"
          gap={2}
        >
          <Button variant="outlined" color="secondary">
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="secondary"
            onClick={handleSave}
            loading={addMenu.isLoading || updateMenu.isLoading}
          >
            Save Menu
          </LoadingButton>
        </Box>
      )}
    </Page>
  );
};
