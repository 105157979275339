import styled from "styled-components";
import { Drawer as DrawerMui } from "@mui/material";

export const Drawer = styled(DrawerMui)<{ reduce: string }>`
  .MuiPaper-root {
    width: ${({ reduce }) => (reduce === "true" ? "80px" : "280px")};
    border-right: none;
    box-shadow: 0px 0px 20px #ebebeb;
    transition: width 300ms ease-in-out;
  }
`;
