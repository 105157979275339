import styled from "styled-components";

export const Page = styled.div`
  display: grid;
  grid-template-rows: 100px auto 1fr;
  gap: 30px;
  padding: 20px;
  /* th:first-child {
    border-top-left-radius: 0;
  } */
`;
