import * as React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { useMutation, useQueryClient, useQuery } from "react-query";

import { TextField, Dialog } from "../../commons";
import { serviceUnavailable, successfulOperation } from "../../utils/message";
import { SnackbarContext } from "../../App";

import { SaveSubCategoryType } from "./SubCategoryFormDialog-type";
import { StoreSubcategoriesService } from "../../services/openApi";

type SubCategoryFormDialogProps = {
  id?: string;
  idSubCategory?: number;
  open: boolean;
  title: string;
  action: "delete" | "add" | "update" | undefined;
  onClose: () => void;
};

export const SubCategoryFormDialog = ({
  id,
  idSubCategory,
  open,
  title,
  action,
  onClose,
}: SubCategoryFormDialogProps): JSX.Element => {
  const queryClient = useQueryClient();
  const { saveSubCategory, getSubcategoryById, updateSubCategoryById } =
    StoreSubcategoriesService;
  const { setSnack } = React.useContext(SnackbarContext);

  const [formSubCategory, setFormSubCategory] =
    React.useState<SaveSubCategoryType>();

  const getSubCategoryDetails = useQuery(
    "getSubcategoryById",
    () => getSubcategoryById(idSubCategory!),
    {
      onSuccess: (data) => {
        setFormSubCategory({
          store_id: data?.category?.restaurant_store_id,
          slug: data?.category?.slug,
          name_en: data?.category?.name_en,
          name_ar: data?.category?.name_ar,
        });
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
      enabled: !!idSubCategory,
    }
  );

  const addSubCategory = useMutation(saveSubCategory, {
    onSuccess: () => {
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
      queryClient.refetchQueries("getSubcategories");
      onClose();
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const editSubCategroy = useMutation(
    () =>
      updateSubCategoryById(idSubCategory!, {
        ...formSubCategory,
        store_id: id,
      }),
    {
      onSuccess: () => {
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        queryClient.refetchQueries("getSubcategories");
        onClose();
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormSubCategory({
      ...formSubCategory,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    if (id) {
      if (idSubCategory) {
        editSubCategroy.mutate();
      } else {
        addSubCategory.mutate({ ...formSubCategory, store_id: id });
      }
    }
  };

  return (
    <Dialog
      open={open}
      title={title}
      maxWidth="sm"
      handleContent={handleSubmit}
      loading={addSubCategory.isLoading || editSubCategroy.isLoading}
      onClose={async () => {
        await getSubCategoryDetails.remove();
        onClose();
      }}
      loadingUseQuery={getSubCategoryDetails.isLoading}
      action={action}
    >
      <Box display="flex" flexDirection="column" gap={2}>
        {getSubCategoryDetails.isLoading ? (
          <>
            <Skeleton
              variant="rectangular"
              height={46}
              width={400}
              sx={{ borderRadius: "30px" }}
            />
            <Skeleton
              variant="rectangular"
              height={46}
              width={400}
              sx={{ borderRadius: "30px" }}
            />
            <Skeleton
              variant="rectangular"
              height={46}
              width={400}
              sx={{ borderRadius: "30px" }}
            />
          </>
        ) : (
          <>
            <TextField
              name="slug"
              label="Slug"
              value={formSubCategory?.slug || ""}
              onChange={handleChange}
              color="secondary"
              style={{ width: 400 }}
            />
            <TextField
              name="name_en"
              label="Title in (En)"
              value={formSubCategory?.name_en || ""}
              onChange={handleChange}
              color="secondary"
              style={{ width: 400 }}
            />
            <TextField
              name="name_ar"
              label="Title in (Ar)"
              value={formSubCategory?.name_ar || ""}
              onChange={handleChange}
              color="secondary"
              style={{ width: 400 }}
            />
          </>
        )}
      </Box>
    </Dialog>
  );
};
