import * as React from "react";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import LinearProgress from "@mui/material/LinearProgress";
import { useQuery } from "react-query";
import moment from "moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import { TextField, Button } from "../../../commons";

import { SnackbarContext } from "../../../App";
import { serviceUnavailable } from "../../../utils/message";

import {
  FilterCollectionsService,
  StatementOfAccountService,
} from "../../../services/openApi";

import { SheetIcon } from "../../../img/icon";

import { Page } from "./StatementOfAccounts-style";

type StatementOfAccountsProps = {};

interface Column {
  id:
    | "store"
    | "date"
    | "type"
    | "orderStatus"
    | "description"
    | "reference"
    | "totalOrder"
    | "pgwAmountRecived"
    | "pgwRate"
    | "meizCommission"
    | "pgwCharges"
    | "balance";
  label: string;
  minWidth?: number;
  align?: "center";
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: "store", label: "Store", minWidth: 170, align: "center" },
  { id: "date", label: "Date", minWidth: 170, align: "center" },
  { id: "type", label: "Type", minWidth: 170, align: "center" },
  {
    id: "orderStatus",
    label: "Order Status",
    minWidth: 150,
    align: "center",
  },
  {
    id: "description",
    label: "Description",
    minWidth: 150,
    align: "center",
  },
  {
    id: "reference",
    label: "Reference #",
    minWidth: 150,
    align: "center",
  },
  {
    id: "totalOrder",
    label: "Total Order (QAR)",
    minWidth: 150,
    align: "center",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "pgwAmountRecived",
    label: "PGW Amount Recived (QAR)",
    minWidth: 210,
    align: "center",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "pgwRate",
    label: "PGW Rate",
    minWidth: 150,
    align: "center",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "meizCommission",
    label: "Meiz Commission",
    minWidth: 150,
    align: "center",
    format: (value: number) => value.toLocaleString("en-US"),
  },

  {
    id: "pgwCharges",
    label: "PGW Charges",
    minWidth: 150,
    align: "center",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "balance",
    label: "Balance",
    minWidth: 150,
    align: "center",
    format: (value: number) => value.toLocaleString("en-US"),
  },
];

interface Data {
  store: string;
  date: string;
  type: string;
  orderStatus: string;
  description: string;
  reference: string;
  totalOrder: number;
  pgwAmountRecived: number;
  pgwRate: number;
  meizCommission: number;
  pgwCharges: number;
  balance: number;
}

function createData(
  store: string,
  date: string,
  type: string,
  orderStatus: string,
  description: any,
  reference: any,
  totalOrder: number,
  pgwAmountRecived: number,
  pgwRate: number,
  meizCommission: number,
  pgwCharges: number,
  balance: number
): Data {
  return {
    store,
    date,
    type,
    orderStatus,
    description,
    reference,
    totalOrder,
    pgwAmountRecived,
    pgwRate,
    meizCommission,
    pgwCharges,
    balance,
  };
}

const ExportToExcel = ({ apiData, fileName }: any) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData: any, fileName: any) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Button
      variant="outlined"
      color="secondary"
      onClick={(e) => exportToCSV(apiData, fileName)}
      sx={{ height: 42 }}
    >
      <SheetIcon /> &nbsp; Export a XLS
    </Button>
  );
};

export const StatementOfAccounts = (props: StatementOfAccountsProps) => {
  const { setSnack } = React.useContext(SnackbarContext);
  const { getAllStoresList } = FilterCollectionsService;
  const { getStatementOfAccount } = StatementOfAccountService;

  const [selectedStore, setSelectedStore] = React.useState<
    number | undefined
  >();
  const [dateFrom, setDateFrom] = React.useState<Date | null>(null);
  const [dateTo, setDateTo] = React.useState<Date | null>(null);

  const listStores = useQuery("getAllStoresList", () => getAllStoresList(), {
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const listStoreFilter =
    listStores?.data?.find((item: any) => item?.id === selectedStore) || "";

  const getListStatementOfAccount = useQuery(
    [
      "getStatementOfAccount",
      selectedStore,
      dateFrom ? moment(dateFrom).format("YYYY-MM-DD") : undefined,
      dateTo ? moment(dateTo).format("YYYY-MM-DD") : undefined,
    ],
    () =>
      getStatementOfAccount({
        restaurant_store_id: selectedStore,
        start_date: dateFrom
          ? moment(dateFrom).format("YYYY-MM-DD")
          : undefined,
        end_date: dateTo ? moment(dateTo).format("YYYY-MM-DD") : undefined,
      }),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
      // enabled: !!selectedStore,
    }
  );

  const rows = getListStatementOfAccount?.data?.statements
    ?.map((item: any) =>
      createData(
        item?.store?.store_translation?.name,
        moment(item?.iso_date).format("DD MMM YYYY hh:mm a"),
        item?.type,
        item?.order_current_status?.order_status_translation?.display_status ||
          "_",
        item?.payment_method?.replaceAll("_", " ") ||
          item?.description?.replaceAll("_", " "),
        item?.id || item?.order_id,
        item?.order_total_price,
        item?.payed_amount,
        item?.credit_card_fee,
        item?.meiz_commission_value,
        item?.pgw_charges_value,
        item?.balance || item?.amount
      )
    )
    .concat({
      store: "",
      date: "",
      type: "",
      orderStatus: "",
      description: "",
      reference: "Total (QAR)",
      totalOrder: getListStatementOfAccount?.data?.totalOrders,
      pgwAmountRecived:
        getListStatementOfAccount?.data?.totalOrderAmountReceived,
      pgwRate: getListStatementOfAccount?.data?.totalOrderMeizComissions,
      meizCommission: getListStatementOfAccount?.data?.totalOrderMeizComissions,
      pgwCharges: getListStatementOfAccount?.data?.totalOrderPGWCharges,
      balance: getListStatementOfAccount?.data?.totalOrderBalance,
    });

  return (
    <Page className="scrolbar">
      <div className="header-action">
        <Autocomplete
          options={listStores.data || []}
          getOptionLabel={(option: any) => option?.name || ""}
          filterSelectedOptions
          value={listStoreFilter}
          onChange={(_, value: any) => setSelectedStore(value?.id)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Please select a store"
              color="secondary"
            />
          )}
          fullWidth
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Typography variant="body2" align="center" sx={{ color: "#c4c3c3" }}>
            From
          </Typography>
          <DatePicker
            value={dateFrom}
            inputFormat="dd/MM/yyyy"
            onChange={(newValue) => {
              setDateFrom(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} color="secondary" fullWidth />
            )}
            maxDate={dateTo}
          />
          <Typography variant="body2" align="center" sx={{ color: "#c4c3c3" }}>
            To
          </Typography>
          <DatePicker
            value={dateTo}
            inputFormat="dd/MM/yyyy"
            onChange={(newValue) => {
              setDateTo(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} color="secondary" fullWidth />
            )}
            minDate={dateFrom}
          />
        </LocalizationProvider>
        <ExportToExcel apiData={rows} fileName={new Date()} />
      </div>
      <div className="container-table">
        <Box
          display="grid"
          gap={1}
          p={3}
          bgcolor="#fff"
          sx={{
            gridTemplateColumns: "1fr 1fr 1fr",
            alignItems: "center",
          }}
        >
          <Typography variant="h3" align="left" sx={{ fontWeight: 600 }}>
            Statement of accounts
          </Typography>
          <Typography variant="h3" align="center">
            {listStoreFilter?.name && <> Store : {listStoreFilter?.name} </>}
          </Typography>
          <Typography variant="body1" align="right">
            {dateFrom && (
              <>
                From : {dateFrom?.getDate()}/
                {dateFrom && dateFrom?.getMonth() + 1}/{dateFrom?.getFullYear()}
              </>
            )}{" "}
            {dateTo && (
              <>
                to : {dateTo?.getDate()}/{dateTo && dateTo?.getMonth() + 1}/
                {dateTo?.getFullYear()}
              </>
            )}
          </Typography>
        </Box>
        <TableContainer
          sx={{ maxHeight: "calc(100vh - 288px)", minHeight: 127 }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {rows?.map((row: any, index: number) => {
                return (
                  <TableRow hover role="checkbox" key={index} tabIndex={-1}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          className={
                            rows?.length === index + 1 &&
                            column.id === "reference"
                              ? "total"
                              : ""
                          }
                        >
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>

          {!rows && (
            <Box position="absolute" width="calc(100vw - 322px)" bgcolor="#fff">
              {getListStatementOfAccount?.isLoading && (
                <LinearProgress color="secondary" />
              )}
              <Typography
                align="center"
                sx={{ position: "relative", width: "100%", p: 2 }}
              >
                No data availble
              </Typography>
            </Box>
          )}
        </TableContainer>
      </div>
    </Page>
  );
};
