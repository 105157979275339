/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useLocation, useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { isMobile } from "react-device-detect";
import { useMutation, useQuery } from "react-query";

import { Drawer, Breadcrumbs } from "../../commons";
import { AppMenu, DropDownProfile } from "../../components";

import {
  getKindContentAdmin,
  getKindContentMarketing,
  getKindContentRestaurant,
  getKindContentStore,
} from "../../utils/Roles";
import {
  OpenAPI,
  AuthenticationService,
  SettingsService,
} from "../../services/openApi";

import { MainLayout } from "./MainRoute-styles";

import { meizLog, meizLog2 } from "../../img";

export const MainRoute = (): JSX.Element => {
  const history = useHistory();
  const pathname = useLocation().pathname;
  const { verifyToken } = AuthenticationService;

  OpenAPI.TOKEN = localStorage.getItem("token") || "";
  const kind = localStorage.getItem("kind") || "";
  const nameRole = localStorage.getItem("nameRole") || "";

  const [reduce, setReduce] = React.useState(true);

  function getKindContent(kind?: string) {
    switch (kind) {
      case "restaurant":
        return getKindContentRestaurant();
      case "admin":
        return getTypeAdmin(nameRole);
      case "store_user":
        return getKindContentStore();
      default:
        return null;
    }
  }

  function getTypeAdmin(type?: string) {
    switch (type) {
      case "Marketing":
        return getKindContentMarketing();
      default:
        return getKindContentAdmin();
    }
  }

  const tokenVerify = useMutation(() => verifyToken(), {
    onSuccess: (result) => {},
    onError: () => {
      localStorage.removeItem("token");
      localStorage.removeItem("kind");
      history.replace("/");
    },
  });

  const logos = useQuery(
    "getApplicationLogo",
    () => SettingsService.getApplicationLogo(),
    {}
  );

  React.useEffect(() => {
    tokenVerify.mutate();
  }, [pathname]);
  React.useEffect(() => {
    if (isMobile) {
      setReduce(true);
    }
  }, [isMobile]);

  const handleReduce = () => {
    setReduce(!reduce);
  };

  return (
    <MainLayout reduce={reduce}>
      <Drawer variant="permanent" reduce={reduce.toString()}>
        <Box
          display="flex"
          height={97}
          alignItems="center"
          justifyContent="center"
          // padding={1}
        >
          {!reduce ? (
            <img
              src={logos.data?.logo?.uri || meizLog}
              className="meiz-Log"
              alt="meiz-Log"
            />
          ) : (
            <img
              src={logos.data?.small_logo?.uri || meizLog2}
              className="meiz-Log2"
              alt="meiz-Log"
            />
          )}
        </Box>
        <Divider className="divider" />
        <AppMenu reduce={reduce} />
      </Drawer>
      <Box className="container">
        <Box display="flex" justifyContent="space-between" padding="20px">
          <Box display="flex" alignItems="center" gap={2}>
            <IconButton onClick={handleReduce}>
              <MenuIcon />
            </IconButton>
            <Breadcrumbs />
          </Box>
          <DropDownProfile />
        </Box>
        {getKindContent(kind)}
      </Box>
    </MainLayout>
  );
};
