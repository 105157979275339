/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class RestaurantRequirementsService {

    /**
     * List of requirements
     * @param requestBody search value
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getRequirements(
        requestBody?: {
            search?: string;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/restaurant/requirement/index`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * List of requirements in main language
     * @param id ID of requirement
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getRequirementsList(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/admins/restaurant/requirement/${id}`,
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * save a requirement
     * @param formData
     * @returns any Successful operation
     * @throws ApiError
     */
    public static saveRequirement(
        formData: {
            name_en?: string;
            name_ar?: string;
            status?: boolean;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/restaurant/requirement/store`,
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * update a requirement
     * @param id ID of requirement
     * @param formData
     * @returns any Successful operation
     * @throws ApiError
     */
    public static updateRequirement(
        id: number,
        formData: {
            name_en?: string;
            name_ar?: string;
            status?: boolean;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/restaurant/requirement/update/${id}`,
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * sort Requirements
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static sortItemFilters(
        requestBody?: {
            /**
             * requirements ids
             */
            requirements?: Array<any>;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/restaurant/requirement/sortItemRequirements`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * delete a requirement
     * @param id ID of requirement
     * @returns any Successful operation
     * @throws ApiError
     */
    public static deleteRequirement(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/api/admins/restaurant/requirement/delete/${id}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

}