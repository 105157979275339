import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import { TextField, Dialog, UploadPicture } from "../../commons";

type CostumerFormDialogProps = {
  open: boolean;
  title: string;
  action: "delete" | "add" | "update" | undefined;
  onClose: () => void;
};

export const CostumerFormDialog = ({
  open,
  title,
  action,
  onClose,
}: CostumerFormDialogProps): JSX.Element => {
  return (
    <Dialog
      open={open}
      title={title}
      maxWidth="sm"
      handleContent={() => {}}
      onClose={onClose}
      action={action}
    >
      <Box display="flex" width={450} flexDirection="column" gap={2}>
        <Box display="flex" height="100px" alignContent="center">
          <UploadPicture mode="basic" onLoad={() => {}} />
          <Typography
            variant="subtitle2"
            style={{ width: 200, margin: "auto 30px", color: "#dad8d8" }}
          >
            The picture must have a size 400px * 400px
          </Typography>
        </Box>
        <TextField label="First Name" color="secondary" />
        <TextField label="Last Name" color="secondary" />
        <TextField label="Phone number" color="secondary" />
        <TextField type="E-Mail" label="E-mail" color="secondary" />
        <TextField label="Password" color="secondary" />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            disableFuture
            label="Date of birth"
            openTo="year"
            value={null}
            onChange={(newValue) => {}}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ fontWeight: 500 }}
        >
          <Typography sx={{ color: "secondary.main" }}>Gender</Typography>
          <FormControl component="fieldset">
            <RadioGroup row aria-label="gender" name="row-radio-buttons-group">
              <FormControlLabel
                value="male"
                control={<Radio color="secondary" />}
                label="Male"
              />
              <FormControlLabel
                value="female"
                control={<Radio color="secondary" />}
                label="Female"
              />
            </RadioGroup>
          </FormControl>
        </Box>
      </Box>
    </Dialog>
  );
};
