import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";

import { Page } from "./AdminUser-style";
import { LoadingButton, Select, TextField } from "../../../commons";
import Table, { ColumnsProps } from "../../../commons/Table";
import { EditIcon, TrashIcon, SaveIcon } from "../../../img/icon";
import { RolesPermissionFormDialog } from "../../../components";

type AdminUserProps = {};

export type UersType = {
  id?: number;
  name?: string;
  email?: string;
  mobile?: string;
  status?: boolean;
  readonly?: boolean;
  user_role?: string;
};
type openFormState = {
  open: boolean;
  title: string;
  action?: "delete" | "add" | "update";
};
export const AdminUser = (props: AdminUserProps) => {
  const [openForm, setOpenForm] = React.useState<openFormState>({
    open: false,
    title: "",
    action: undefined,
  });
  const [listUsersState, setListUsersState] = React.useState<UersType[]>([
    {
      id: 1,
      name: "USERA",
      email: "user@example.com",
      mobile: "55555",
      user_role: "Moderator",
      status: true,
      readonly: true,
    },
    {
      id: 2,
      name: "USERB",
      email: "user@example.com",
      mobile: "666",
      user_role: "Moderator",
      status: false,
      readonly: true,
    },
    {
      id: 3,
      name: "USERC",
      email: "user@example.com",
      mobile: "777",
      user_role: "Moderator",
      status: false,
      readonly: true,
    },
    {
      id: 4,
      name: "USERD",
      email: "user@example.com",
      mobile: "888",
      user_role: "Moderator",
      status: true,
      readonly: true,
    },
  ]);
  const [userState, setUserState] = React.useState<UersType>();

  const columns: ColumnsProps = [
    {
      header: "User Name",
      accessor: "name",
    },
    {
      header: "Email",
      accessor: "email",
    },
    {
      header: "Mobile",
      accessor: "mobile",
    },
    {
      header: "Status",
      accessor: "status",
    },
    {
      header: "User role",
      accessor: "role",
    },
    {
      header: "Actions",
      accessor: "actions",
      headerCellProps: { align: "center" },
      cellProps: { align: "center", width: "200px" },
    },
  ];
  const btnOptionDisabled = listUsersState?.findIndex(
    (o) => o.readonly === false
  );

  const handleChangeUser = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setUserState({
      ...userState,
      [name]: value,
    });
  };
  const handleCancelEdit = (index: number) => {
    setListUsersState((prevRoleState) => {
      const arrayUserState = [...(prevRoleState || [])];
      arrayUserState[index] = {
        ...arrayUserState[index],
        readonly: true,
      };
      return arrayUserState;
    });
    setUserState(undefined);
  };
  const handleEnableEdit = (index: number) => {
    setListUsersState((prevUserState) => {
      const arrayUserState = [...(prevUserState || [])];
      arrayUserState[index] = {
        ...arrayUserState[index],
        readonly: false,
      };
      setUserState(arrayUserState[index]);
      return arrayUserState;
    });
  };

  const handleChangeSelect = (value: unknown) => {
    setUserState({
      ...userState,
      user_role: value as string,
    });
  };

  const renderTableRows = () => {
    return (
      listUsersState.map((item, index) => ({
        id: item?.id,
        name: item?.readonly ? (
          <Typography variant="body2" align="center" className="value-element">
            {item?.name}
          </Typography>
        ) : (
          <TextField
            name="name"
            value={userState?.name || ""}
            onChange={handleChangeUser}
            color="secondary"
            fullWidth
          />
        ),
        email: item?.readonly ? (
          <Typography variant="body2" align="center" className="value-element">
            {item?.email}
          </Typography>
        ) : (
          <TextField
            name="email"
            value={userState?.email || ""}
            onChange={handleChangeUser}
            color="secondary"
            fullWidth
          />
        ),
        mobile: item?.readonly ? (
          <Typography variant="body2" align="center" className="value-element">
            {item?.mobile}
          </Typography>
        ) : (
          <TextField
            name="mobile"
            value={userState?.mobile || ""}
            onChange={handleChangeUser}
            color="secondary"
            fullWidth
          />
        ),
        status: <Switch checked={item.status} color="secondary" />,
        role: item?.readonly ? (
          <Typography variant="body2" align="center" className="value-element">
            {item?.user_role}
          </Typography>
        ) : (
          <Select
            name="user_role"
            value={userState?.user_role || ""}
            onChange={(event) => handleChangeSelect(event.target.value)}
            color="secondary"
          >
            <MenuItem value={"Super Admin"} key={0}>
              Super Admin
            </MenuItem>
            <MenuItem value={"Moderator"} key={1}>
              Moderator
            </MenuItem>
          </Select>
        ),
        actions: (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={1}
          >
            {item?.readonly ? (
              <IconButton
                className="btn-save"
                onClick={() => handleEnableEdit(index)}
                disabled={btnOptionDisabled !== -1}
              >
                <EditIcon />
              </IconButton>
            ) : (
              <IconButton className="btn-save">
                <SaveIcon />
              </IconButton>
            )}
            {item?.readonly ? (
              <IconButton
                className="btn-cancel"
                disabled={btnOptionDisabled !== -1}
              >
                <TrashIcon />
              </IconButton>
            ) : (
              <IconButton
                className="btn-cancel"
                onClick={() => handleCancelEdit(index)}
              >
                <CloseIcon />
              </IconButton>
            )}
          </Box>
        ),
      })) || []
    );
  };

  return (
    <Page>
      <Box display="flex" height={45} justifyContent="center" gap={2}>
        <TextField
          type="text"
          name="enter_role_name"
          placeholder="Search by user name or mobile"
          color="secondary"
          fullWidth
        />
        <LoadingButton
          variant="contained"
          color="secondary"
          style={{ width: "16em" }}
          onClick={() =>
            setOpenForm({
              open: true,
              title: "Add New User",
              action: "add",
            })
          }
        >
          Add New User
        </LoadingButton>
      </Box>
      <Box display="flex" flex="1" marginTop={3}>
        <Table
          columns={columns}
          data={renderTableRows()}
          emptyMessage="No data available"
        />
      </Box>
      {openForm.open && (
        <RolesPermissionFormDialog
          open={openForm.open}
          title={openForm.title}
          action={openForm.action}
          onClose={() => {
            setOpenForm({
              open: false,
              title: "",
              action: undefined,
            });
          }}
        />
      )}
    </Page>
  );
};
