/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class DashboardOrdersService {

    /**
     * Orders
     * @param count pagination count
     * @param page pagination page number
     * @param requestBody search value
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getOrdersList(
        count?: any,
        page?: any,
        requestBody?: {
            search?: string;
            order_status?: string;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/restaurant/order/getOrdersList`,
            query: {
                'count': count,
                'page': page,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Order details
     * @param id ID of order
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getOrderDetails(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/admins/restaurant/order/getOrderDetails/${id}`,
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Order ids by store
     * @param id ID of store
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getOrderByStoreId(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/admins/restaurant/order/getOrderByStoreId/${id}`,
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Canceled order ids by store
     * @param id ID of store
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getCanceledOrderByStoreId(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/admins/restaurant/order/getCanceledOrderByStoreId/${id}`,
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * get order status list
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getOrderStatusList(): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/admins/settings/orderStatus/getOrderStatusList`,
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * update Order status
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static updateOrderStatus(
        requestBody?: {
            status_id?: number;
            order_id?: string;
            cancel_reason_id?: string;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/settings/orderStatus/updateOrderStatus`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

}