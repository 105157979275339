import * as React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";

import SearchIcon from "@mui/icons-material/Search";

import { Button, Input, LoadingButton } from "../../../../commons";
import { Page } from "./AdministratorPersmission-style";
import { RoleType } from "..";
// import Table, { ColumnsProps } from "../../../../commons/Table";

type AdministratorPersmissionProps = {
  item?: RoleType;
  cancel: () => void;
};
type PermissionType = {
  status?: boolean;
  type?: string;
};
export const AdministratorPersmission = ({
  item,
  cancel,
}: AdministratorPersmissionProps) => {
  const listPermission: PermissionType[] = [
    { status: true, type: "View Dashboard Total Sales" },
    { status: false, type: "View Dashboard Total Orders" },
    { status: false, type: "View Dashboard Total Carporates" },
    { status: true, type: "View Dashboard Total Subscriped users" },
    { status: true, type: "View Dashboard Last Week Sales" },
    { status: true, type: "View Dashboard Top Selling Menu" },
    { status: false, type: "View Dashboard Top Selling Restaurants" },
    { status: true, type: "View Dashboard Year Sales Chart" },
    { status: false, type: "View Reviews Menu link" },
    { status: false, type: "Cancel Order" },
    { status: true, type: "Add New Corporate" },
    { status: true, type: "View Corporate Restaurants" },
  ];

  return (
    <Page>
      <Input
        style={{ marginBottom: "10px" }}
        placeholder="Search"
        onKeyPress={(e) => e.key === "Enter"}
        startAdornment={
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        }
      />
      <Box className="list-premission-container">
        {listPermission.map((item: any, index: number) => (
          <Stack
            className="list-permission-element"
            sx={{
              padding: 1,
              gridTemplateColumns: "1fr 6fr",
            }}
            direction="row"
            display="grid"
            spacing={2}
          >
            <Switch checked={item.status} color="secondary" />
            <Typography
              variant="body2"
              justifyContent="center"
              className="value-element"
            >
              {item.type}
            </Typography>
          </Stack>
        ))}
      </Box>
      <Box display="flex" height={45} justifyContent="flex-end" gap={2} mt={1}>
        <Button variant="outlined" color="secondary" onClick={cancel}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="secondary"
          style={{ width: "13em" }}
        >
          Save
        </LoadingButton>
      </Box>
    </Page>
  );
};
