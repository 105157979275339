import * as React from "react";
import { useParams, RouteComponentProps } from "react-router-dom";
import Box from "@mui/material/Box";
import { useMutation, useQueryClient, useQuery } from "react-query";

import { Button, TextField, LoadingButton } from "../../../../../commons";
import { StoreForm } from "../../../../../components";
import {
  serviceUnavailable,
  successfulOperation,
} from "../../../../../utils/message";

import { SnackbarContext } from "../../../../../App";

import { RoleRestaurantStoresService } from "../../../../../services/openApi";

import { Page } from "./FormStoreRestaurant-style";
import { StoreFormType } from "./FormStore-types";
import { validation, Error } from "./FormStore-validation";

export const FormStoreRestaurant = (
  props: RouteComponentProps
): JSX.Element => {
  const { id } = useParams<{ id?: string }>();
  const { saveStore, getStoreDetails, updateStore } =
    RoleRestaurantStoresService;
  const { setSnack } = React.useContext(SnackbarContext);
  const queryClient = useQueryClient();

  const [formState, setFormState] = React.useState<StoreFormType>({
    name_en: undefined,
    name_ar: undefined,
    catering_comission: undefined,
    value_added_tax: undefined,
    cancellation_days: undefined,
    catering_advance: undefined,
    cash_on_delivery_limit: undefined,
    credit_card_fee: undefined,
    debit_card_fee: undefined,
    delivery_type: undefined,
    email: undefined,
    phone: undefined,
    description_en: undefined,
    description_ar: undefined,
    max_catering_per_day: undefined,
    required_location: true,
    latitude: undefined,
    longitude: undefined,
    address_en: undefined,
    address_ar: undefined,
    logofile: undefined,
    thumbfile: undefined,
    imagefile: undefined,
  });

  const [formError, setFormError] = React.useState<Error>({});

  const getStoreDetailsById = useQuery(
    ["getStoreDetails", id],
    () => getStoreDetails(+id!),
    {
      onSuccess: async (data) => {
        const dataStore = await data?.store;
        setFormState({
          name_en: dataStore?.name_en,
          name_ar: dataStore?.name_ar,
          catering_comission: dataStore?.catering_comission,
          value_added_tax: dataStore?.value_added_tax,
          cancellation_days: dataStore?.cancellation_days,
          catering_advance: dataStore?.catering_advance,
          cash_on_delivery_limit: dataStore?.cash_on_delivery_limit,
          credit_card_fee: dataStore?.credit_card_fee,
          debit_card_fee: dataStore?.debit_card_fee,
          delivery_type: dataStore?.delivery_type,
          email: dataStore?.email,
          description_en: dataStore?.description_en,
          description_ar: dataStore?.description_ar,
          phone: dataStore?.phone ? dataStore.phone.substring(3) : undefined,
          required_location: dataStore?.required_location,
          longitude: dataStore?.longitude,
          latitude: dataStore?.latitude,
          address_en: dataStore?.address_en,
          address_ar: dataStore?.address_ar,
          max_catering_per_day: dataStore?.max_catering_per_day,
        });
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
      enabled: !!id,
    }
  );

  const addStore = useMutation(() => saveStore(formState), {
    onSuccess: () => {
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
      queryClient.fetchQuery(["getAllStoresRestaurant", ""]);
      props.history.goBack();
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const editStore = useMutation(() => updateStore(+id!, formState), {
    onSuccess: () => {
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
      queryClient.fetchQuery(["getAllStoresRestaurant", ""]);
      props.history.goBack();
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormState({
      ...formState,
      [name]: value,
    });
    setFormError({
      ...formError,
      [name]: "",
    });
  };

  const handlePicture = (name: string, picture?: Blob) => {
    setFormState({
      ...formState,
      [name]: picture,
    });
    setFormError({
      ...formError,
      [name]: "",
    });
  };

  const handleSubmit = () => {
    const resultValidation = validation(formState);
    if (Object.entries(resultValidation).length === 0) {
      if (id) {
        editStore.mutate();
      } else {
        addStore.mutate();
      }
    } else {
      setFormError({ ...resultValidation });
    }
  };

  return (
    <Page className="scrolbar">
      <StoreForm
        data={formState}
        formValidation={formError}
        uriLogo={getStoreDetailsById?.data?.store?.logo?.uri}
        uriThumb={getStoreDetailsById?.data?.store?.thumb?.uri}
        uriImage={getStoreDetailsById?.data?.store?.image?.uri}
        onHandleChange={handleChange}
        onHandlePicture={handlePicture}
      />
      <div className="form-address">
        <TextField
          name="latitude"
          label="Latitude"
          value={formState?.latitude || ""}
          type="number"
          onChange={handleChange}
          color="secondary"
          error={!!formError?.latitude}
          helperText={formError?.latitude}
        />
        <TextField
          name="longitude"
          label="Longitude"
          value={formState?.longitude || ""}
          type="number"
          onChange={handleChange}
          color="secondary"
          error={!!formError?.longitude}
          helperText={formError?.longitude}
        />
        <TextField
          name="address_en"
          label="Address in English"
          value={formState?.address_en || ""}
          onChange={handleChange}
          color="secondary"
          error={!!formError?.address_en}
          helperText={formError?.address_en}
        />
        <TextField
          name="address_ar"
          label="Address in Arabic"
          value={formState?.address_ar || ""}
          onChange={handleChange}
          color="secondary"
          error={!!formError?.address_ar}
          helperText={formError?.address_ar}
        />
      </div>
      <Box display="flex" height={45} justifyContent="flex-end" gap={2}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => props.history.goBack()}
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="secondary"
          onClick={handleSubmit}
          loading={
            addStore.isLoading ||
            getStoreDetailsById.isLoading ||
            editStore.isLoading
          }
        >
          Save Store
        </LoadingButton>
      </Box>
    </Page>
  );
};
