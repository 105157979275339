/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class PaymentCustomRequestService {

    /**
     * Order ids by store
     * @param id ID of store
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getOrdersByStoreId(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/admins/settings/paymentCustomRequest/getOrdersByStoreId/${id}`,
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Payment Custom Request
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static savePaymentCustomRequest(
        requestBody?: {
            restaurant_store_id?: number;
            description?: string;
            reference_date?: string;
            /**
             * order objects
             */
            orders?: Array<any>;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/settings/paymentCustomRequest/savePaymentCustomRequest`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * update Payment Custom Request
     * @param id ID of store payment request
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static updatePaymentCustomRequest(
        id: number,
        requestBody?: {
            description?: string;
            status?: string;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/settings/paymentCustomRequest/updatePaymentCustomRequest/${id}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

}