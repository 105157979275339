import * as React from "react";
import { DialogProps as DialogPropsMui } from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import { Button, LoadingButton } from "../../commons";

import { DialogStyled, DialogStyledProps } from "./Dialog-styles";

type DialogProps = {
  open: boolean;
  title: string;
  children?: React.ReactNode;
  maxWidth?: DialogPropsMui["maxWidth"];
  action:
    | "delete"
    | "add"
    | "update"
    | "view"
    | "block"
    | "unBlock"
    | "assign"
    | undefined;
  handleContent: () => void;
  loadingUseQuery?: boolean;
  loading?: boolean;
  onClose: () => void;
  sx?: DialogPropsMui["sx"];
  className?: string;
} & DialogStyledProps;

export const Dialog = ({
  open,
  title,
  children,
  maxWidth,
  action,
  handleContent,
  loadingUseQuery,
  loading,
  onClose,
  positionbtnaction,
  sx,
  className,
}: DialogProps): JSX.Element => {
  const getTitleAction = () => {
    if (action === "add") {
      return "Save";
    } else if (action === "update") {
      return "Save Changes";
    } else if (action === "delete") {
      return "Confirm";
    } else if (action === "block") {
      return "Block";
    } else if (action === "unBlock") {
      return "UnBlock";
    } else if (action === "assign") {
      return "Assign";
    }
  };

  return (
    <DialogStyled
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      positionbtnaction={positionbtnaction ? positionbtnaction : "center"}
      sx={sx}
      className={className}
    >
      <DialogTitle className="title">{title}</DialogTitle>
      <DialogContent className="content scrolbar">{children}</DialogContent>
      <DialogActions className="action">
        <Button variant="outlined" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        {action !== "view" && (
          <LoadingButton
            variant="contained"
            color="secondary"
            onClick={handleContent}
            loading={loading}
            disabled={loadingUseQuery}
          >
            {getTitleAction()}
          </LoadingButton>
        )}
      </DialogActions>
    </DialogStyled>
  );
};
