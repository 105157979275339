/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { Input, Button, Dialog, LazyLoadingImage } from "../../commons";
import Table, { ColumnsProps } from "../../commons/Table";
import { OffreFormDialog } from "../../components";

import { NameRestaurantContext, SnackbarContext } from "../../App";
import { serviceUnavailable, successfulOperation } from "../../utils/message";

import { RestaurantCateringOffersService } from "../../services/openApi";

import { EditIcon, TrashIcon } from "../../img/icon";
import DefaultImage from "../../img/Default_Image_Thumbnail.png";

import { Page } from "./Offers-style";
import { Typography } from "@mui/material";

type openFormState = {
  open: boolean;
  title: string;
  action?: "delete" | "add" | "update";
};

export const Offers = (): JSX.Element => {
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);
  const { setNameRestaurant } = React.useContext(NameRestaurantContext);
  const { getOffersList, deleteOffer, updateOffer1 } =
    RestaurantCateringOffersService;

  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [search, setSearch] = React.useState<string>("");
  const [openDialog, setOpenDialog] = React.useState<openFormState>({
    open: false,
    title: "",
  });
  const [idOffre, setIdOffre] = React.useState<number | undefined>();
  const [status, setStatus] = React.useState<boolean>(false);
  const [openStatus, setOpenStatus] = React.useState<openFormState>({
    open: false,
    title: "",
  });

  const GetListOffers = useQuery(
    "getOffersList",
    () => getOffersList({ search }),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const updateStatus = useMutation(() => updateOffer1(idOffre!, { status }), {
    onSuccess: () => {
      queryClient.fetchQuery("getOffersList");
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
      setOpenStatus({
        open: false,
        title: "",
      });
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const removeOffre = useMutation(deleteOffer, {
    onSuccess: () => {
      queryClient.fetchQuery("getOffersList");
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
      setOpenDialog({
        open: false,
        title: "",
      });
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  React.useEffect(() => {
    setNameRestaurant("");
  }, []);

  const columns: ColumnsProps = [
    {
      header: "Title",
      accessor: "title",
    },
    {
      header: "Short Description",
      accessor: "shortDescription",
    },
    {
      header: "Photo",
      accessor: "photo",
    },
    {
      header: "Redirection",
      accessor: "redirection",
    },
    {
      header: "% Discount",
      accessor: "discount",
    },
    {
      header: "Validation Date",
      accessor: "validationDate",
    },
    {
      header: "Status",
      accessor: "status",
    },
    {
      header: "Actions",
      accessor: "actions",
      headerCellProps: { align: "center" },
      cellProps: { align: "center", width: "150px" },
    },
  ];

  const renderTableRows = () => {
    return (
      GetListOffers?.data
        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        ?.map((item: any) => ({
          title: item?.title,
          shortDescription: item?.short_description || "--",
          photo: (
            <LazyLoadingImage
              key={item?.id}
              alt="store"
              src={item?.image?.uri} // use normal <img> attributes as props
              height={40}
              width={40}
              placeholderSrc={DefaultImage}
            />
          ),
          redirection:
            item?.type === "Store"
              ? item?.store?.redirection
              : item?.item?.redirection,
          discount: item?.discount,
          validationDate: (
            <Box>
              <Typography variant="body2"> From : {item.start_date}</Typography>
              <Typography variant="body2"> To : {item.end_date}</Typography>
            </Box>
          ),
          status: (
            <Switch checked={item?.status} onChange={handleChange(item?.id)} />
          ),
          actions: (
            <Box display="flex" justifyContent="center" gap={1}>
              <Button
                variant="contained"
                className="btn-edit"
                onClick={(e) => {
                  e.stopPropagation();
                  setIdOffre(item?.id);
                  setOpenDialog({
                    open: true,
                    title: "Edit Offer",
                    action: "update",
                  });
                }}
              >
                Edit
                <EditIcon />
              </Button>
              <Button
                variant="contained"
                className="btn-delete"
                onClick={(e) => {
                  e.stopPropagation();
                  setIdOffre(item?.id);
                  setOpenDialog({
                    open: true,
                    title: "Delete",
                    action: "delete",
                  });
                }}
              >
                Delete <TrashIcon />
              </Button>
            </Box>
          ),
        })) || []
    );
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleChange =
    (id?: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      event.stopPropagation();
      setIdOffre(id);
      setStatus(event.target.checked);
      setOpenStatus({
        open: true,
        title: "Update Status",
        action: "update",
      });
    };

  return (
    <Page>
      <div className="page-action">
        <Input
          value={search}
          placeholder="Search"
          onChange={handleSearch}
          onKeyPress={(e) => e.key === "Enter" && GetListOffers.refetch()}
          startAdornment={
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          }
        />
        <Button
          variant="contained"
          color="secondary"
          className="btn-add"
          onClick={() =>
            setOpenDialog({
              open: true,
              title: "Add Offer",
              action: "add",
            })
          }
        >
          Add Offer
        </Button>
      </div>
      <Box display="flex" flex="1" marginTop={3}>
        <Table
          columns={columns}
          nbrRowLoading={10}
          loading={GetListOffers?.isLoading}
          data={renderTableRows()}
          emptyMessage="No data available"
          tablePaginationProps={{
            rowsPerPageOptions: [5, 10, 25],
            count: GetListOffers.data?.length || 0,
            page: page,
            onPageChange: (_, newPage) => setPage(newPage),
            rowsPerPage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
        />
      </Box>
      {openDialog.action === "delete" && (
        <Dialog
          open={openDialog.open}
          title={openDialog.title}
          action="delete"
          handleContent={() => idOffre && removeOffre.mutate(idOffre)}
          loading={removeOffre.isLoading}
          onClose={() =>
            setOpenDialog({
              open: false,
              title: "",
            })
          }
        >
          <Typography>Are you sure to delete this Offre?</Typography>
        </Dialog>
      )}
      {(openDialog.action === "add" || openDialog.action === "update") && (
        <OffreFormDialog
          idOffre={idOffre}
          open={openDialog.open}
          title={openDialog.title}
          action={openDialog.action}
          onClose={() => {
            setOpenDialog({
              open: true,
              title: "",
            });
            setIdOffre(undefined);
          }}
        />
      )}
      {openStatus.open && (
        <Dialog
          open={openStatus.open}
          title={openStatus.title}
          action="delete"
          handleContent={() => idOffre && updateStatus.mutate()}
          loading={updateStatus.isLoading}
          onClose={() =>
            setOpenStatus({
              open: false,
              title: "",
            })
          }
        >
          <Typography>Are you sure to change this status?</Typography>
        </Dialog>
      )}
    </Page>
  );
};
