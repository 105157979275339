import styled from "styled-components";

export const Page = styled.div`
  flex-direction: column;
  animation: 500ms ease-out 0ms 1 show;
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
    .MuiAutocomplete-input {
    padding: 4px;
  }
  .MuiChip-root {
    background: #302d60;
    color: #fff;
    .MuiChip-deleteIcon {
      color: rgb(237 235 235 / 26%);
    }
  }
  .price-per-unit {
    .MuiOutlinedInput-input {
      border-right: 1px solid #e5e5e5;
    }
    .MuiTypography-root {
      color: #dad8d8;
    }
  }
  .wrapper-class {
    border: 1px solid #dddddd;
    border-radius: 20px;
  }

  .rdw-editor-main {
    height: 250px;
    overflow-x: auto;
    padding: 0 10px;
  }
  .toolbar-class {
    border: none;
    border-bottom: 1px solid #dddddd;
    border-radius: 20px 20px 0 0;
    padding: 8px;
  }
  .rdw-editor-toolbar {
    margin-bottom: 0;
  }
  .rdw-option-wrapper {
    border-radius: 8px;
  }
  .rdw-dropdown-wrapper {
    border-radius: 8px;
  }
  .rdw-option-active {
    box-shadow: none;
    background-color: #5bc0de;
    border-radius: 5px;
  }
  .Mui-disabled {
    background: #f9f8f8;
  }

  .drop_top_time {
    position: absolute;
    display: flex;
    top: 47px;
    width: 200px;
    align-items: center;
    background: #eeeeee;
    margin-top: 3px;
    padding: 6px;
    border-radius: 10px;
    box-sizing: border-box;
    gap: 8px;
  }

  @keyframes show {
    0% {
      display: none;
      opacity: 0;
    }
    100% {
      display: flex;
      opacity: 1;
    }
  }
`;
