import * as React from "react";
import { useParams } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Calendar,
  momentLocalizer,
  stringOrDate,
  SlotInfo,
} from "react-big-calendar";
import moment from "moment";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { Dialog } from "../../../commons";
import { SnackbarContext } from "../../../App";
import {
  serviceUnavailable,
  successfulOperation,
} from "../../../utils/message";
import { StoreCalendarService } from "../../../services/openApi";

import { CalendarType } from "./calendar-type";

import { Page } from "./calendar-style";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);

type eventState = {
  id: number;
  start: stringOrDate;
  end: stringOrDate;
  title: string;
};

type openFormState = {
  open: boolean;
  title: string;
  action?: "delete" | "update";
};

const minTime = new Date();

export const CalendarEvent = (): JSX.Element => {
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);
  const { getFbDaysByStoreId, addFullyBooked, deleteFullyBooked } =
    StoreCalendarService;
  const { idStore } = useParams<{ idStore?: string }>();
  const [myEventsList, setMyEventsList] = React.useState<eventState[]>([]);

  const [openForm, setOpenForm] = React.useState<openFormState>({
    open: false,
    title: "",
  });

  const [selectDate, setSelectDate] = React.useState<string>(
    moment(minTime).format("YYYY-MM-DD")
  );
  const [idEvent, setIdEvent] = React.useState<number | undefined>();

  const getListFbDay = useQuery(
    ["getFbDaysByStoreId", selectDate],
    () =>
      getFbDaysByStoreId({ restaurant_store_id: +idStore!, date: selectDate }),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
      enabled: !!idStore,
    }
  );

  React.useEffect(() => {
    if (getListFbDay.data) {
      const listEvents: eventState[] = [];
      getListFbDay.data?.map((item: any) =>
        listEvents.push({
          id: item?.id,
          start: item?.start_date,
          end: item?.end_date,
          title: item?.title,
        })
      );
      setMyEventsList(listEvents);
    }
  }, [getListFbDay.data]);

  const addEvent = useMutation(addFullyBooked, {
    onSuccess: () => {
      queryClient.refetchQueries(["getFbDaysByStoreId", selectDate]);
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const deleteEvent = useMutation(() => deleteFullyBooked(idEvent!), {
    onSuccess: () => {
      queryClient.refetchQueries(["getFbDaysByStoreId", selectDate]);
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
      setOpenForm({
        open: false,
        title: "",
      });
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const handleEvent = (slotInfo: SlotInfo) => {
    if (slotInfo.start.valueOf() >= minTime.setHours(0, 0, 0, 0)) {
      if (
        myEventsList.findIndex(
          (o) =>
            Date.parse(o.start.toString()) <=
              Date.parse(moment(slotInfo.start).format("YYYY-MM-DD")) &&
            Date.parse(o.end.toString()) >=
              Date.parse(moment(slotInfo.end).format("YYYY-MM-DD"))
        ) === -1
      ) {
        const newEvent: CalendarType = {
          restaurant_store_id: +idStore!,
          start_date: moment(slotInfo.start).format("YYYY-MM-DD"),
          end_date: moment(slotInfo.end).format("YYYY-MM-DD"),
        };
        addEvent.mutate({ ...newEvent });
      }
    }
  };

  const handleModal = (title: string, action?: "delete" | "update") => {
    setOpenForm({
      open: true,
      title,
      action,
    });
  };

  return (
    <Page>
      <Paper elevation={0} sx={{ padding: "20px", borderRadius: "20px" }}>
        <Calendar
          min={minTime}
          selectable
          localizer={localizer}
          events={myEventsList}
          startAccessor="start"
          endAccessor="end"
          onSelectSlot={handleEvent}
          view="month"
          views={["month"]}
          onDoubleClickEvent={(value) => {
            if (value.id) {
              if (
                Date.parse(value.start.toString()) >=
                minTime.setHours(0, 0, 0, 0)
              ) {
                setIdEvent(value?.id);
                handleModal("Delete", "delete");
              }
            }
          }}
          style={{ height: "calc(100vh - 170px)" }}
          onNavigate={(dateNvigate) =>
            setSelectDate(moment(dateNvigate).format("YYYY-MM-DD"))
          }
        />
      </Paper>

      {openForm.action === "delete" && (
        <Dialog
          open={openForm.open}
          title={openForm.title}
          action="delete"
          handleContent={() => deleteEvent.mutate()}
          loading={deleteEvent.isLoading}
          onClose={() =>
            setOpenForm({
              open: false,
              title: "",
            })
          }
        >
          <Typography>Are you sure to delete this event?</Typography>
        </Dialog>
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={
          addEvent.isLoading || getListFbDay.isLoading || deleteEvent.isLoading
        }
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Page>
  );
};
