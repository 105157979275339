import * as React from "react";
import { useParams, RouteComponentProps } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TimePicker from "@mui/lab/TimePicker";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useMutation, useQuery, useQueryClient } from "react-query";
import moment from "moment";

import { Dialog, Button, Select, TextField } from "../../../../../commons";
import { RegionFormRestaurantDialog } from "../../../../../components";
import { SnackbarContext } from "../../../../../App";
import {
  serviceUnavailable,
  successfulOperation,
} from "../../../../../utils/message";

import {
  RoleRestaurantStoreDeliveryRegionsService,
  RoleRestaurantRegionsService,
  RoleRestaurantAreasService,
} from "../../../../../services/openApi";

import { EditIcon, TrashIcon, SaveIcon } from "../../../../../img/icon";

import {
  DeliveryRegionType,
  DeliveryRegionAreaType,
} from "./ManagementDeliveryRegions-type";

import { Page } from "./ManagementDeliveryRegionsRestaurant-style";

type openFormState = {
  open: boolean;
  title: string;
  action?: "delete" | "add" | "update";
};

const headerAreaDetails = [
  "Area",
  "Start time",
  "End time",
  "Catering Delivery Fees",
  "Action",
];

export const ManagementDeliveryRegionsRestaurant = (
  props: RouteComponentProps
): JSX.Element => {
  const queryClient = useQueryClient();
  const { id: idStore } = useParams<{ id?: string }>();
  const { setSnack } = React.useContext(SnackbarContext);
  const { regionList } = RoleRestaurantRegionsService;
  const {
    getStoreRegionList,
    saveStoreRegion,
    deleteStoreRegion,
    updateStoreRegion,
    saveStoreRegionArea,
    getStoreRegionDetails1,
    updateStoreRegionArea,
    deleteStoreRegionArea,
  } = RoleRestaurantStoreDeliveryRegionsService;
  const { getAreaByRegionId } = RoleRestaurantAreasService;

  const [openForm, setOpenForm] = React.useState<openFormState>({
    open: false,
    title: "",
    action: undefined,
  });
  const [openDelete, setOpenDelete] = React.useState<openFormState>({
    open: false,
    title: "",
    action: undefined,
  });

  const [regionsDetailsForm, setRegionsDetailsForm] =
    React.useState<DeliveryRegionType>();
  const [idRegion, setStoreRegion] = React.useState<number | undefined>();

  const [listState, setListState] = React.useState<DeliveryRegionAreaType[]>(
    []
  );

  const [position, setPosition] = React.useState<number>(0);
  const [idArea, setIdArea] = React.useState<number | undefined>(undefined);

  const [openDeleteArea, setOpenDeleteArea] = React.useState<openFormState>({
    open: false,
    title: "",
    action: undefined,
  });

  const [idsAreas, setIdsAreas] = React.useState<(number | undefined)[]>([]);

  const getAllRegionList = useQuery("getRegionsList", regionList, {
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const listRegion = useQuery(
    "getStoreRegionList",
    () => getStoreRegionList(+idStore!),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const saveRegion = useMutation(
    "saveStoreRegion",
    () =>
      saveStoreRegion(+regionsDetailsForm?.region_id!, {
        ...regionsDetailsForm,
        restaurant_store_id: +idStore!,
      }),
    {
      onSuccess: () => {
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        queryClient.fetchQuery("getStoreRegionList");
        const lastItem = listRegion?.data?.[listRegion?.data?.length - 1];
        setStoreRegion(lastItem?.region_id);
        setRegionsDetailsForm({
          ...regionsDetailsForm,
          id: lastItem?.id,
          change: false,
        });
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const updateRegion = useMutation(
    "updateStoreRegion",
    () =>
      updateStoreRegion(+regionsDetailsForm?.id!, { ...regionsDetailsForm }),
    {
      onSuccess: () => {
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        queryClient.fetchQuery("getStoreRegionList");
        setRegionsDetailsForm({
          ...regionsDetailsForm,
          change: false,
        });
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const removeRegion = useMutation("deleteStoreRegion", deleteStoreRegion, {
    onSuccess: () => {
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
      queryClient.fetchQuery("getStoreRegionList");
      handleCloseDeleteRegion();
      setRegionsDetailsForm(undefined);
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const getAllAreas = useQuery(
    "getAreaByRegionId",
    () => getAreaByRegionId(idRegion!), // id region
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
      enabled: !!idRegion,
    }
  );

  const getAreaDetailsbyRegionId = useQuery(
    "getStoreRegionDetails1",
    () => getStoreRegionDetails1(regionsDetailsForm?.id!), // id store region
    {
      onSuccess: (data) => {
        const arrayState: DeliveryRegionAreaType[] = [];
        const arrayIdsAreas: number[] = [];
        data?.forEach((item: any) => {
          arrayState.push({
            id: item?.id,
            area_id: item?.area_id,
            start_time: item?.start_time?.substr(0, 5),
            end_time: item?.end_time?.substr(0, 5),
            catering_delivery_fees: item.catering_delivery_fees,
            action: "edit",
            change: false,
          });
          if (item?.area_id) {
            arrayIdsAreas.push(item.area_id);
          }
        });
        setListState(arrayState);
        setIdsAreas(arrayIdsAreas);
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
      enabled: !!regionsDetailsForm?.id, // id store region
      suspense: getAllAreas?.data?.length > 0,
    }
  );

  const addArea = useMutation(
    () =>
      saveStoreRegionArea(regionsDetailsForm?.id!, { ...listState[position] }),
    {
      onSuccess: () => {
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        queryClient.fetchQuery("getStoreRegionDetails1");
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const updateArea = useMutation(
    () =>
      updateStoreRegionArea(listState[position]?.id!, {
        ...listState[position],
      }),
    {
      onSuccess: () => {
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        queryClient.fetchQuery("getStoreRegionDetails1");
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const removeArea = useMutation(deleteStoreRegionArea, {
    onSuccess: () => {
      setSnack({
        open: true,
        severity: "success",
        message: successfulOperation,
      });
      queryClient.fetchQuery("getStoreRegionDetails1");
      handleCloseDeleteArea();
    },
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  // Functions for Region Form

  const handleChangeReaginDetails =
    (prop: "start_time" | "end_time" | "catering_delivery_fees") =>
    (event: any) => {
      const value =
        prop === "catering_delivery_fees"
          ? (event?.target?.value as string)
          : (moment(event).format("hh:mm a") as string);

      setRegionsDetailsForm({
        ...regionsDetailsForm,
        [prop]: value,
      });
    };

  const handleSelectedRegin = async (value: any) => {
    await setStoreRegion(value?.region_id);
    await setRegionsDetailsForm({
      id: value?.id,
      region_id: value?.region_id,
      start_time: value?.start_time?.substr(0, 5),
      end_time: value?.end_time?.substr(0, 5),
      catering_delivery_fees: value?.catering_delivery_fees,
      restaurant_store_id: value?.restaurant_store_id,
      action: "edit",
    });
    await getAllAreas.refetch();
    getAreaDetailsbyRegionId.refetch();
  };

  const handleEditRegion = () => {
    if (idRegion) {
      setRegionsDetailsForm((regionsDetailsFormPrev) => {
        return { ...regionsDetailsFormPrev, change: true, action: "edit" };
      });
    }
  };

  const handleCancelReagionDetails = () => {
    const findRegion = listRegion?.data?.find((o: any) => o.id === idRegion);
    setRegionsDetailsForm({
      region_id: findRegion?.region_id,
      start_time: findRegion?.start_time,
      end_time: findRegion?.end_time,
      catering_delivery_fees: findRegion?.catering_delivery_fees,
      restaurant_store_id: findRegion?.restaurant_store_id,
      change: false,
    });
  };

  const handleSaveStoreRegion = () => {
    if (idStore && regionsDetailsForm?.region_id) {
      if (regionsDetailsForm?.action === "add") {
        saveRegion.mutate();
      } else {
        updateRegion.mutate();
      }
    }
  };

  const handleDelete =
    (id: number) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      id && setStoreRegion(id);
      setOpenDelete({
        open: true,
        title: "Delete",
        action: "delete",
      });
    };

  const handleCloseDeleteRegion = () => {
    setOpenDelete({
      open: false,
      title: "Delete",
    });
  };

  // functions for areas

  const handleAddListArea = () => {
    setListState((prevListState) => {
      const arrayState = [...(prevListState || [])];
      arrayState.push({
        change: true,
        action: "add",
      });
      setPosition(arrayState.length - 1);
      return arrayState;
    });
  };

  const handleEditArea = (index: number) => {
    setListState((prevListState) => {
      const arrayState = [...(prevListState || [])];
      const position = arrayState.findIndex((o: any) => o?.change);
      if (position === -1) {
        arrayState[index] = {
          ...arrayState[index],
          change: true,
        };
      }
      return arrayState;
    });
    setPosition(index);
  };

  const handleCancelArea = (index: number, item: DeliveryRegionAreaType) => {
    const prevItem = getAreaDetailsbyRegionId?.data?.find(
      (o: any) => o?.id === item?.id
    );
    setListState((prevListState) => {
      const arrayState = [...(prevListState || [])];
      if (arrayState?.[index]?.action === "add") {
        arrayState?.splice(index, 1);
      } else {
        arrayState[index] = {
          ...prevItem,
          change: false,
        };
      }

      return arrayState;
    });
  };

  const handleSaveArea = (index: number) => {
    setListState((prevListState) => {
      const arrayState = [...(prevListState || [])];
      //for ids areas
      const arrayIdsAreas = [...idsAreas];
      arrayState[index] = {
        ...arrayState[index],
        change: false,
      };
      //for find id id ares if exist or not
      const position = arrayIdsAreas.find(
        (item) => item === arrayState?.[index]?.area_id
      );
      if (arrayState?.[index]?.area_id !== undefined) {
        if (position && position !== -1) {
          arrayIdsAreas[position] = arrayState[index].area_id;
        } else {
          arrayIdsAreas.push(arrayState[index].area_id);
        }
      }
      setIdsAreas(arrayIdsAreas);
      if (arrayState[index].action === "add") {
        addArea.mutate();
      } else {
        updateArea.mutate();
      }
      return arrayState;
    });
  };

  const handleChangeAreaDetails =
    (
      item: DeliveryRegionAreaType,
      prop: "start_time" | "end_time" | "catering_delivery_fees" | "area_id"
    ) =>
    (event: any) => {
      const newListState: Array<DeliveryRegionAreaType> = JSON.parse(
        JSON.stringify(listState)
      );
      const target = newListState.find((o) => o.id === item.id);
      if (target) {
        if (prop === "catering_delivery_fees" || prop === "area_id") {
          target[prop] = event?.target?.value as number;
        } else {
          target[prop] = moment(event).format("hh:mm a") as string;
        }
      }
      setListState(newListState);
    };

  const handleDeleteArea = (id: number) => {
    setIdArea(id);
    setOpenDeleteArea({
      open: true,
      title: "Delete Area",
      action: "delete",
    });
  };

  const handleCloseDeleteArea = () => {
    setOpenDeleteArea({
      open: false,
      title: "",
    });
  };

  return (
    <Page className="scrolbar">
      <Box
        display="flex"
        flexDirection="column"
        bgcolor="#fff"
        border="1px solid #DDDDDD"
        borderRadius="20px"
      >
        <Box
          display="flex"
          height={90}
          alignItems="center"
          justifyContent="space-between"
          borderBottom="1px solid #DDDDDD"
          padding="6px 16px"
        >
          <Typography
            variant="h3"
            sx={{ color: "secondary.main", fontWeight: 600 }}
          >
            Region
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            onClick={() =>
              setOpenForm({
                open: true,
                title: "Add Region",
                action: "add",
              })
            }
          >
            Add
          </Button>
        </Box>

        {listRegion?.data?.length === 0 ? (
          <Typography variant="body2" style={{ margin: "auto" }}>
            You need to add region
          </Typography>
        ) : (
          <MenuList>
            {listRegion?.data?.map((item: any) => (
              <MenuItem
                key={item?.id}
                onClick={() => handleSelectedRegin(item)}
                selected={item?.id === regionsDetailsForm?.id}
              >
                <ListItemText>{item?.name}</ListItemText>
                <IconButton onClick={handleDelete(item?.id)}>
                  <TrashIcon />
                </IconButton>
              </MenuItem>
            ))}
          </MenuList>
        )}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        bgcolor="#fff"
        border="1px solid #DDDDDD"
        borderRadius="20px"
      >
        <Box
          display="flex"
          flexDirection="column"
          height={90}
          borderBottom="1px solid #DDDDDD"
          padding="6px 16px"
        >
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: 600, margin: "5px 0" }}
          >
            Region details
          </Typography>
          <div className="form-region">
            <TextField
              name="name"
              value={
                getAllRegionList?.data?.find(
                  (o: any) => o?.id === regionsDetailsForm?.region_id
                )?.name || ""
              }
              placeholder="Region name"
              color="secondary"
              InputProps={{
                readOnly: true,
              }}
            />

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                label="Start time"
                value={
                  regionsDetailsForm?.start_time
                    ? new Date(
                        `December 17, 1995 ${regionsDetailsForm?.start_time}`
                      )
                    : null
                }
                onChange={() => {}}
                renderInput={(params) => (
                  <TextField {...params} color="secondary" />
                )}
                ampm={false}
                onAccept={handleChangeReaginDetails("start_time")}
                mask="__:__"
                inputFormat="HH:mm"
                readOnly={!regionsDetailsForm?.change}
              />
              <TimePicker
                label="End time"
                value={
                  regionsDetailsForm?.end_time
                    ? new Date(
                        `December 17, 1995 ${regionsDetailsForm?.end_time}`
                      )
                    : null
                }
                onChange={() => {}}
                renderInput={(params) => (
                  <TextField {...params} color="secondary" />
                )}
                ampm={false}
                onAccept={handleChangeReaginDetails("end_time")}
                mask="__:__"
                inputFormat="HH:mm"
                readOnly={!regionsDetailsForm?.change}
              />
            </LocalizationProvider>
            <TextField
              label="Catering Delivery Fees"
              name="catering_delivery_fees"
              type="number"
              value={regionsDetailsForm?.catering_delivery_fees || ""}
              onChange={handleChangeReaginDetails("catering_delivery_fees")}
              color="secondary"
              InputProps={{
                readOnly: !regionsDetailsForm?.change,
              }}
            />
            <Box display="flex" gap={1}>
              {!regionsDetailsForm?.change ? (
                <IconButton className="btn-save" onClick={handleEditRegion}>
                  <EditIcon />
                </IconButton>
              ) : (
                <>
                  <IconButton
                    className="btn-save"
                    onClick={handleSaveStoreRegion}
                    disabled={saveRegion.isLoading}
                  >
                    <SaveIcon />
                  </IconButton>
                  <IconButton
                    className="btn-cancel"
                    onClick={handleCancelReagionDetails}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </>
              )}
            </Box>
          </div>
        </Box>
        <Box p={2}>
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: 600, marginBottom: "5px" }}
          >
            Specific area details
          </Typography>
          <div className="area-form">
            {headerAreaDetails?.map((nameItem) => (
              <Typography
                variant="subtitle2"
                align="center"
                style={{ fontWeight: 600 }}
              >
                {nameItem}
              </Typography>
            ))}
            {listState?.map((item, index) => (
              <>
                <Select
                  value={item?.area_id || ""}
                  onChange={handleChangeAreaDetails(item, "area_id")}
                  color="secondary"
                  readOnly={!item?.change}
                >
                  {getAllAreas?.data?.areas?.map((item: any) => (
                    <MenuItem
                      value={item?.id || ""}
                      key={item?.id}
                      disabled={idsAreas.includes(item?.id)}
                    >
                      {item?.area_translation?.name}
                    </MenuItem>
                  ))}
                </Select>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                    value={
                      item?.start_time
                        ? new Date(`December 17, 1995 ${item?.start_time}`)
                        : null
                    }
                    onChange={() => {}}
                    renderInput={(params) => (
                      <TextField {...params} color="secondary" />
                    )}
                    ampm={false}
                    onAccept={handleChangeAreaDetails(item, "start_time")}
                    mask="__:__"
                    inputFormat="HH:mm"
                    readOnly={!item?.change}
                  />
                  <TimePicker
                    value={
                      item?.end_time
                        ? new Date(`December 17, 1995 ${item?.end_time}`)
                        : null
                    }
                    onChange={() => {}}
                    renderInput={(params) => (
                      <TextField {...params} color="secondary" />
                    )}
                    ampm={false}
                    onAccept={handleChangeAreaDetails(item, "end_time")}
                    mask="__:__"
                    inputFormat="HH:mm"
                    readOnly={!item?.change}
                  />
                </LocalizationProvider>
                {/* <TextField name="Normal Delivery" /> */}
                <TextField
                  value={item.catering_delivery_fees || ""}
                  onChange={handleChangeAreaDetails(
                    item,
                    "catering_delivery_fees"
                  )}
                  color="secondary"
                  InputProps={{
                    readOnly: !item?.change,
                  }}
                />
                <Box display="flex" gap={1}>
                  {item?.change ? (
                    <>
                      <IconButton
                        className="btn-save"
                        onClick={() => handleSaveArea(index)}
                        disabled={addArea.isLoading || updateArea.isLoading}
                      >
                        <SaveIcon />
                      </IconButton>
                      <IconButton
                        className="btn-cancel"
                        onClick={() => handleCancelArea(index, item)}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <IconButton
                        className="btn-save"
                        onClick={() => handleEditArea(index)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        className="btn-cancel"
                        onClick={() => item?.id && handleDeleteArea(item.id)}
                        disabled={removeArea?.isLoading}
                      >
                        <TrashIcon />
                      </IconButton>
                    </>
                  )}
                </Box>
              </>
            ))}
            {listState?.length === 0 && (
              <Typography variant="body2" align="center">
                No data available
              </Typography>
            )}
          </div>
          <Box borderBottom="1px solid #dddddd" p="5px 0">
            <Button
              className="btn-add"
              onClick={handleAddListArea}
              disabled={listState.find((o) => o.change)?.change}
            >
              <AddCircleOutlineIcon fontSize="small" /> Add specific Area
            </Button>
          </Box>
        </Box>
      </Box>

      <RegionFormRestaurantDialog
        open={openForm.open}
        title={openForm.title}
        action={openForm.action}
        onClose={() =>
          setOpenForm({
            open: false,
            title: "",
            action: undefined,
          })
        }
        onReturn={(region) =>
          setRegionsDetailsForm({
            region_id: parseInt(region),
            action: "add",
            change: true,
          })
        }
      />
      <Dialog
        open={openDelete.open}
        title={openDelete.title}
        action="delete"
        handleContent={() => idRegion && removeRegion.mutate(idRegion)}
        loading={removeRegion?.isLoading}
        onClose={handleCloseDeleteRegion}
      >
        <Typography>Are you sure to delete this region?</Typography>
      </Dialog>
      <Dialog
        open={openDeleteArea.open}
        title={openDeleteArea.title}
        action="delete"
        handleContent={() => idArea && removeArea.mutate(idArea)}
        loading={removeArea?.isLoading}
        onClose={handleCloseDeleteArea}
      >
        <Typography>Are you sure to delete this area?</Typography>
      </Dialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={
          getAllAreas?.isLoading ||
          getAllAreas?.isRefetching ||
          getAreaDetailsbyRegionId?.isLoading ||
          getAreaDetailsbyRegionId?.isRefetching
        }
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Page>
  );
};
