import styled from "styled-components";

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  .header-action {
    display: grid;
    grid-template-columns: 2fr 40px 1fr 40px 1fr;
    align-items: center;
    gap: 20px;
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
      .MuiAutocomplete-input {
      padding: 4px;
    }
  }
  .MuiChip-root {
    background: #302d60;
    color: #fff;
    .MuiChip-deleteIcon {
      color: rgb(237 235 235 / 26%);
    }
  }
  .containerCollapse {
    display: flex;
    border: 1px solid #dddddd;
    background-color: #fff;
    height: auto;
    border-radius: 20px;
  }
  .btn-action {
    background-color: #fcead4;
    color: #ec8000;
    box-shadow: none;
  }
  .Mui-disabled {
    opacity: 0.3;
  }
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
    .MuiAutocomplete-input {
    padding: 4px;
  }
`;
