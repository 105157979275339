import isEmpty from "validator/lib/isEmpty";
import equals from "validator/lib/equals";

import { formState } from "./index";
import { message } from "../../utils/message";

export type Error = formState;

export const validation = (values: formState): Error => {
  const errors: Error = {};

  Object.keys(values).forEach((key: string) => {
    switch (key) {
      case "old_password":
        if (isEmpty(values?.old_password || "")) {
          errors.old_password = message.changePassword.oldPassword;
        }
        break;
      case "new_password":
        if (isEmpty(values?.new_password || "")) {
          errors.new_password = message.changePassword.newPassword;
        }
        break;
      case "confirmation_password":
        if (isEmpty(values?.confirmation_password || "")) {
          errors.confirmation_password = message.changePassword.confirmPassword;
        } else if (
          !equals(
            values?.confirmation_password || "",
            values?.new_password || ""
          )
        ) {
          errors.confirmation_password =
            message.changePassword.notConfirmPassword;
        }
        break;
      default:
        break;
    }
  });

  return errors;
};
