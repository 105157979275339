import { GeneralInfoType } from "../../../components/GeneralInfoForm/GeneralInfoForm-type";
import { message } from "../../../utils/message";

export type Error = {
  thumbfile?: string;
  name_en?: string;
  name_ar?: string;
  short_description_en?: string;
  short_description_ar?: string;
  global_categories?: string;
  filters?: string;
  sub_category_id?: string;
  quantity_per_day?: string;
  max_quantity_per_day?: string;
  original_price?: string;
  promoted_price?: string;
  price_per_unit?: string;
  enough_for?: string;
  lead_time?: string;
  available_from_time?: string;
  available_to_time?: string;
  setup_time?: string;
  max_time?: string;
  item_requirements?: string;
};

export const validation = (values: GeneralInfoType): Error => {
  const errors: Error = {};

  Object.keys(values).forEach((key: string) => {
    switch (key) {
      case "thumbfile":
        if (!values?.thumbfile) {
          errors.thumbfile = message.menu.thumbfile;
        }
        break;
      case "name_en":
        if (!values?.name_en) {
          errors.name_en = message.menu.nameEn;
        }
        break;
      case "name_ar":
        if (!values?.name_ar) {
          errors.name_ar = message.menu.nameAr;
        }
        break;

      case "short_description_en":
        if (!values?.short_description_en) {
          errors.short_description_en = message.menu.shortDescriptionEn;
        }
        break;

      case "short_description_ar":
        if (!values?.short_description_ar) {
          errors.short_description_ar = message.menu.shortDescriptionAr;
        }
        break;

      case "global_categories":
        if (
          !values?.global_categories ||
          values?.global_categories?.length === 0
        ) {
          errors.global_categories = message.menu.globalCategories;
        }
        break;

      case "filters":
        if (!values?.filters || values?.filters?.length === 0) {
          errors.filters = message.menu.filters;
        }
        break;

      case "sub_category_id":
        if (!values?.sub_category_id) {
          errors.sub_category_id = message.menu.subCategoryId;
        }
        break;

      case "quantity_per_day":
        if (!values?.quantity_per_day) {
          errors.quantity_per_day = message.menu.quantityPerDay;
        }
        break;

      case "max_quantity_per_day":
        if (!values?.max_quantity_per_day) {
          errors.max_quantity_per_day = message.menu.maxQuantityPerDay;
        }
        break;

      case "original_price":
        if (!values?.original_price) {
          errors.original_price = message.menu.originalPrice;
        }
        break;
      case "promoted_price":
        if (!values?.promoted_price) {
          errors.promoted_price = message.menu.promotedPrice;
        }
        break;
      case "price_per_unit":
        if (!values?.price_per_unit) {
          errors.price_per_unit = message.menu.pricePerUnit;
        }
        break;

      case "enough_for":
        if (!values?.enough_for) {
          errors.enough_for = message.menu.enoughFor;
        }
        break;
      case "lead_time":
        if (!values?.lead_time) {
          errors.lead_time = message.menu.leadTime;
        }
        break;
      case "available_from_time":
        if (!values?.available_from_time) {
          errors.available_from_time = message.menu.availableFromTime;
        }
        break;

      case "available_to_time":
        if (!values?.available_to_time) {
          errors.available_to_time = message.menu.availableToTime;
        }
        break;
      case "setup_time":
        if (!values?.setup_time) {
          errors.setup_time = message.menu.setupTime;
        }
        break;
      case "max_time":
        if (!values?.max_time) {
          errors.max_time = message.menu.maxTime;
        }
        break;

      case "item_requirements":
        if (
          !values?.item_requirements ||
          values?.item_requirements?.length === 0
        ) {
          errors.item_requirements = message.menu.itemRequirements;
        }
        break;
      default:
        break;
    }
  });
  return errors;
};
