/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class StoreUserStoreItemsService {

    /**
     * List of store items
     * @param id ID of subcategory
     * @returns any Successful operation
     * @throws ApiError
     */
    public static get(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/storeUser/restaurant/item/getItemBySubCategoryId/${id}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * List of items
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getAllItems(): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/storeUser/restaurant/item/getAllItems`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * save image
     * @param formData
     * @returns any success
     * @throws ApiError
     */
    public static saveImage(
        formData?: {
            imagefile?: Blob;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/storeUser/restaurant/item/saveImage`,
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * save an item(menu)
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static saveItem(
        requestBody?: {
            thumbfile?: number;
            /**
             * Item Global categories
             */
            images?: Array<number>;
            name_en?: string;
            name_ar?: string;
            description_en?: string;
            description_ar?: string;
            dish_en?: string;
            dish_ar?: string;
            short_description_en?: string;
            short_description_ar?: string;
            store_id?: string;
            promoted_price?: number;
            original_price?: number;
            quantity_per_day?: number;
            max_quantity_per_day?: number;
            lead_time?: number;
            enough_for?: number;
            price_per_unit?: number;
            available_from_time?: string;
            available_to_time?: string;
            setup_time?: number;
            max_time?: number;
            sub_category_id?: number;
            item_unit_id?: number;
            /**
             * Item Global categories
             */
            global_categories?: Array<number>;
            /**
             * filters
             */
            filters?: Array<number>;
            /**
             * item_requirements
             */
            item_requirements?: Array<number>;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/storeUser/restaurant/item/store`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * update an item(menu)
     * @param id ID of requirement
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static updateItem(
        id: number,
        requestBody?: {
            name_en?: string;
            name_ar?: string;
            description_en?: string;
            description_ar?: string;
            dish_en?: string;
            dish_ar?: string;
            short_description_en?: string;
            short_description_ar?: string;
            promoted_price?: number;
            original_price?: number;
            quantity_per_day?: number;
            max_quantity_per_day?: number;
            lead_time?: number;
            enough_for?: number;
            price_per_unit?: number;
            available_from_time?: string;
            available_to_time?: string;
            setup_time?: number;
            max_time?: number;
            sub_category_id?: number;
            item_unit_id?: number;
            thumbfile?: number;
            /**
             * images
             */
            images?: Array<number>;
            /**
             * Item Global categories
             */
            global_categories?: Array<number>;
            /**
             * filters
             */
            filters?: Array<number>;
            /**
             * item_requirements
             */
            item_requirements?: Array<number>;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/storeUser/restaurant/item/update/${id}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Update Cash On Delivery
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static updateCashOnDelivery(
        requestBody: {
            item_id?: number;
            cash_on_delivery?: boolean;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/storeUser/restaurant/item/updateCashOnDelivery`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Update Show Item
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static updateShowMenu(
        requestBody: {
            item_id?: number;
            status?: boolean;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/storeUser/restaurant/item/updateShowMenu`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Update Show On Restaurant Manager
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static updateShowItem(
        requestBody: {
            item_id?: number;
            show_on_restaurant_manager?: boolean;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/storeUser/restaurant/item/updateShowOnRestaurantManager`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Update requiremenent location
     * @param requestBody
     * @returns any success
     * @throws ApiError
     */
    public static updateRequiredLocation(
        requestBody: {
            item_id?: number;
            required_location?: boolean;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/storeUser/restaurant/item/updateRequiredLocation`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * setdefault item image
     * @param id ID of item
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static setDefaultItemImage(
        id: number,
        requestBody: {
            image_id?: number;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/storeUser/restaurant/item/setDefaultImage/${id}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * sort an item(menu)
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static sortItems(
        requestBody?: {
            sub_category_id?: number;
            /**
             * items ids
             */
            items?: Array<any>;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/storeUser/restaurant/item/sortItems`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * delete an item(menu)
     * @param id Id of image
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static deleteImage(
        id: number,
        requestBody: {
            image_id?: number;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/api/storeUser/restaurant/item/deleteImage/${id}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Item Detail
     * @param id ID of subcategory
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getItemDetails(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/storeUser/restaurant/item/getItemDetails/${id}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * delete an item(menu)
     * @param id ID of item
     * @returns any Successful operation
     * @throws ApiError
     */
    public static deleteItem(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/api/storeUser/restaurant/item/delete/${id}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * update an item status
     * @param id ID of item
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static updateItemStatus(
        id: number,
        requestBody: {
            status?: boolean;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/storeUser/restaurant/item/updateStatus/${id}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

}