import * as React from "react";
import Box from "@mui/material/Box";
import { useMutation, useQueryClient } from "react-query";

import { TextField, Dialog } from "../../commons";
import { SnackbarContext } from "../../App";

import { RestaurantItemAddonsService } from "../../services/openApi";
import { serviceUnavailable, successfulOperation } from "../../utils/message";

import { AddonsType } from "./Addons-type";

type AddonsFormDialogProps = {
  idMenu?: string;
  open: boolean;
  title: string;
  action?: "delete" | "add" | "update";
  onClose: () => void;
};

export const AddonsFormDialog = ({
  idMenu,
  open,
  title,
  action,
  onClose,
}: AddonsFormDialogProps): JSX.Element => {
  const queryClient = useQueryClient();
  const { saveAddon } = RestaurantItemAddonsService;
  const { setSnack } = React.useContext(SnackbarContext);

  const [addonsForm, setAddonsForm] = React.useState<AddonsType>();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setAddonsForm({
      ...addonsForm,
      [name]: value,
    });
  };

  const addAddons = useMutation(
    () => saveAddon({ ...addonsForm, item_id: +idMenu! }),
    {
      onSuccess: () => {
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        queryClient.refetchQueries(["getItemAddon", idMenu]);
        onClose();
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const handleSubmit = () => {
    if (idMenu) addAddons.mutate();
  };

  return (
    <Dialog
      open={open}
      title={title}
      maxWidth="sm"
      handleContent={handleSubmit}
      loading={addAddons.isLoading}
      onClose={onClose}
      action={action}
    >
      <Box display="flex" width={350} flexDirection="column" gap={2}>
        <TextField
          name="name_en"
          label="Addon name (En)"
          value={addonsForm?.name_en}
          onChange={handleChange}
          color="secondary"
        />
        <TextField
          name="name_ar"
          label="Addon name (Ar)"
          value={addonsForm?.name_ar}
          onChange={handleChange}
          color="secondary"
        />
      </Box>
    </Dialog>
  );
};
