import * as React from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { AccordionStyled } from "./Accordion-styles";

type AccordionProps = {
  title?: string;
  children?: React.ReactNode;
  defaultExpanded?: boolean;
};

export const Accordion = ({
  title,
  children,
  defaultExpanded,
}: AccordionProps): JSX.Element => {
  return (
    <AccordionStyled square disableGutters defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </AccordionStyled>
  );
};
