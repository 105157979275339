import * as React from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ArticleIcon from "@mui/icons-material/Article";
import PaymentIcon from "@mui/icons-material/Payment";
import PersonIcon from "@mui/icons-material/Person";
import StoreIcon from "@mui/icons-material/Store";
import InfoIcon from "@mui/icons-material/Info";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { useQuery } from "react-query";
import moment from "moment";

import { SnackbarContext } from "../../App";
import { serviceUnavailable } from "../../utils/message";
import { DashboardOrdersService } from "../../services/openApi";

import { meizLog } from "../../img";

import { ComponentToPrint } from "./OrderToPrint-style";

export const OrderToPrint = React.forwardRef<HTMLDivElement>((props, ref) => {
  const { id } = useParams<{ id?: string }>();
  const { setSnack } = React.useContext(SnackbarContext);
  const { getOrderDetails } = DashboardOrdersService;

  const orderDetails = useQuery(
    ["getOrderDetails", id],
    () => getOrderDetails(+id!),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
      enabled: !!id,
    }
  );

  const idStatus = orderDetails?.data?.order_current_status?.id || "";

  return (
    <ComponentToPrint ref={ref}>
      {orderDetails?.isLoading ? (
        <div className="loader-container">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : (
        <>
          <Box display="flex" justifyContent="center" mb={1}>
            <img src={meizLog} className="meiz-Log-print" alt="meiz-Log" />
          </Box>

          <Box
            display="grid"
            gridTemplateColumns={"1fr 1fr"}
            className="div-show"
            gap="12px"
          >
            <Box className="box-infos">
              <Typography className="infos-icon">
                <ArticleIcon fontSize="small" /> Order Details
              </Typography>
              <Box display="flex" gap={2} padding="5px 0 0 8px">
                <Typography variant="body1">
                  Order Reference : {orderDetails?.data?.id}
                </Typography>
              </Box>
              <Box display="flex" gap={2} padding="5px 0 0 8px">
                <Typography variant="body1">
                  Date :{" "}
                  {orderDetails?.data?.iso_date &&
                    moment(orderDetails?.data?.iso_date).format("DD MMM YYYY")}
                </Typography>
              </Box>
              <Box display="flex" gap={2} padding="5px 0 0 8px">
                <Typography variant="body1">
                  Time :{" "}
                  {orderDetails?.data?.iso_date &&
                    moment(orderDetails?.data?.iso_date).format("hh:mm a")}
                </Typography>
              </Box>
              <Box display="flex" gap={2} padding="5px 0 0 8px">
                <Typography variant="body1">Order status :</Typography>
                <Typography variant="body1">
                  {
                    orderDetails?.data?.order_current_status
                      ?.order_status_translation?.display_status
                  }
                  {idStatus === 7
                    ? ` by ${
                        orderDetails?.data?.cancelled_by_guard_name ===
                        "store_user"
                          ? "store"
                          : orderDetails?.data?.cancelled_by_guard_name
                      }`
                    : ""}
                </Typography>
              </Box>
            </Box>
            <Box className="box-infos">
              <Typography className="infos-icon">
                <LocalShippingIcon fontSize="small" /> Delivery Details
              </Typography>
              <Box display="flex" gap={2} padding="5px 0 0 8px">
                <Typography variant="body1">Delivery Data : </Typography>
                <Typography variant="body1">
                  {orderDetails?.data?.details?.date}
                </Typography>
              </Box>
              <Box display="flex" gap={2} padding="5px 0 0 8px">
                <Typography variant="body1">Delivery Area : </Typography>
                <Typography variant="body1">
                  {orderDetails?.data?.details?.formatted_address}
                </Typography>
              </Box>

              <Typography className="infos-icon" sx={{ marginTop: 1 }}>
                <PaymentIcon fontSize="small" /> Payment Method
              </Typography>
              <Box display="flex" gap={2} padding="5px 0 0 8px">
                <Typography
                  variant="body1"
                  sx={{ textTransform: "capitalize" }}
                >
                  {orderDetails?.data?.payment_method}
                </Typography>
              </Box>
            </Box>
            <Box className="box-infos">
              <Typography className="infos-icon">
                <StoreIcon fontSize="small" /> Store Infos
              </Typography>
              <Box display="flex" gap={2} padding="5px 0 0 8px">
                <Typography variant="body1">Store Name : </Typography>
                <Typography variant="body1">
                  {orderDetails?.data?.store?.store_translation?.name}
                </Typography>
              </Box>
              <Box display="flex" gap={2} padding="5px 0 0 8px">
                <Typography variant="body1">Mobile Number : </Typography>
                <Typography variant="body1">
                  {orderDetails?.data?.store?.phone}
                </Typography>
              </Box>
              <Box display="flex" gap={2} padding="5px 0 0 8px">
                <Typography variant="body1">Email :</Typography>
                <Typography variant="body1">
                  {orderDetails?.data?.store?.email}
                </Typography>
              </Box>
            </Box>

            <Box className="box-infos">
              <Typography className="infos-icon">
                <PersonIcon fontSize="small" /> Customer Infos
              </Typography>
              <Box display="flex" gap={2} padding="5px 0 0 8px">
                <Typography variant="body1">Customer Name : </Typography>
                <Typography variant="body1">
                  {orderDetails?.data?.customer?.fullname}
                </Typography>
              </Box>
              <Box display="flex" gap={2} padding="5px 0 0 8px">
                <Typography variant="body1">Mobile Number : </Typography>
                <Typography variant="body1">
                  {orderDetails?.data?.customer?.mobile}
                </Typography>
              </Box>
              <Box display="flex" gap={2} padding="5px 0 0 8px">
                <Typography variant="body1">Email :</Typography>
                <Typography variant="body1">
                  {orderDetails?.data?.customer?.email}
                </Typography>
              </Box>
              {orderDetails?.data?.address_name && (
                <Box display="flex" gap={2} padding="5px 0 0 8px">
                  <Typography variant="body1">Address :</Typography>
                  <Typography variant="body1">
                    {orderDetails?.data?.address_name}
                  </Typography>
                </Box>
              )}
            </Box>

            {orderDetails?.data?.cancel_reason && (
              <Box className="box-infos">
                <Typography className="infos-icon">
                  <InfoIcon fontSize="small" /> Cancel reason
                </Typography>
                <Box display="flex" gap={2} padding="5px 0 0 8px">
                  <Typography variant="body1">
                    {
                      orderDetails?.data?.cancel_reason
                        ?.order_cancel_reason_translation?.reason
                    }
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
          <div className="order-summary">
            <div className="order-summary-header">
              <Typography style={{ fontWeight: 600 }}>Order summary</Typography>
            </div>
            <div className="rows">
              <Typography style={{ fontWeight: 600, padding: 8 }}>
                Item
              </Typography>
              <Typography
                align="center"
                style={{ fontWeight: 600, padding: 8 }}
              >
                Quantity
              </Typography>
              <Typography
                align="center"
                style={{ fontWeight: 600, padding: 8 }}
              >
                Unit Price
              </Typography>
              <Typography
                align="center"
                style={{ fontWeight: 600, padding: 8 }}
              >
                Price
              </Typography>
              {orderDetails?.data?.details?.OrderSummary?.map(
                (item: any, index: number) => (
                  <React.Fragment key={index}>
                    <Typography style={{ padding: 8, fontWeight: 600 }}>
                      {item?.name_en} / {item?.name_ar}
                    </Typography>
                    <Typography align="center" style={{ padding: 8 }}>
                      {item?.quantity}
                    </Typography>
                    <Typography align="center" style={{ padding: 8 }}>
                      --------
                    </Typography>
                    <Typography
                      align="center"
                      style={{ padding: 8 }}
                    >{`QAR ${item?.promoted_price?.toLocaleString(
                      "en-US"
                    )}`}</Typography>
                    {/* Choices */}
                    {item?.choices?.map((choice: any, index: number) => (
                      <React.Fragment key={index}>
                        <Typography
                          style={{
                            padding: 8,
                            gridColumn: "1/5",
                            fontWeight: 600,
                            marginLeft: 16,
                          }}
                        >
                          {choice?.name_en} / {choice?.name_ar}
                        </Typography>
                        {choice?.item_choice_options?.map(
                          (itemChoice: any, index: number) => (
                            <React.Fragment key={index}>
                              <Typography
                                style={{
                                  padding: 8,
                                  marginLeft: 24,
                                }}
                              >
                                {itemChoice?.name_en} / {itemChoice?.name_ar}
                              </Typography>
                              <Typography
                                align="center"
                                style={{
                                  padding: 8,
                                }}
                              >
                                {itemChoice?.quantity}
                              </Typography>
                              <Typography
                                align="center"
                                style={{
                                  padding: 8,
                                }}
                              >
                                {`QAR ${itemChoice?.item_choice_price?.toLocaleString(
                                  "en-US"
                                )}`}
                              </Typography>
                              <Typography
                                align="center"
                                style={{
                                  padding: 8,
                                }}
                              >
                                {`QAR ${(
                                  itemChoice?.item_choice_price *
                                  itemChoice?.quantity
                                )?.toLocaleString("en-US")}`}
                              </Typography>
                            </React.Fragment>
                          )
                        )}
                      </React.Fragment>
                    ))}
                    {/* Addons */}
                    {item?.addons?.map((addon: any, index: number) => (
                      <React.Fragment key={index}>
                        <Typography
                          style={{
                            padding: 8,
                            gridColumn: "1/5",
                            fontWeight: 600,
                            marginLeft: 16,
                          }}
                        >
                          {addon?.name_en} / {addon?.name_ar}
                        </Typography>
                        {addon?.item_addon_options?.map(
                          (itemAddon: any, index: number) => (
                            <React.Fragment key={index}>
                              <Typography
                                style={{
                                  padding: 8,
                                  marginLeft: 24,
                                }}
                              >
                                {itemAddon?.name_en} / {itemAddon?.name_ar}
                              </Typography>
                              <Typography
                                align="center"
                                style={{
                                  padding: 8,
                                }}
                              >
                                {itemAddon?.quantity}
                              </Typography>
                              <Typography
                                align="center"
                                style={{
                                  padding: 8,
                                }}
                              >
                                {`QAR ${itemAddon?.item_addon_option_price?.toLocaleString(
                                  "en-US"
                                )}`}
                              </Typography>
                              <Typography
                                align="center"
                                style={{
                                  padding: 8,
                                }}
                              >
                                {`QAR ${(
                                  itemAddon?.item_addon_option_price *
                                  itemAddon?.quantity
                                )?.toLocaleString("en-US")}`}
                              </Typography>
                            </React.Fragment>
                          )
                        )}
                      </React.Fragment>
                    ))}
                    {item?.quantity - item?.enough_for > 0 && (
                      <>
                        <Typography style={{ padding: 8, fontWeight: 600 }}>
                          Additional person for {item?.name_en}
                        </Typography>
                        <Typography align="center" style={{ padding: 8 }}>
                          {item?.quantity - item?.enough_for}
                        </Typography>
                        <Typography align="center" style={{ padding: 8 }}>
                          {`QAR ${item?.price_per_unit?.toLocaleString(
                            "en-US"
                          )}`}
                        </Typography>
                        <Typography align="center" style={{ padding: 8 }}>
                          {`QAR ${(
                            (item?.quantity - item?.enough_for) *
                            item?.price_per_unit
                          )?.toLocaleString("en-US")}`}
                        </Typography>
                      </>
                    )}
                  </React.Fragment>
                )
              )}
            </div>
          </div>

          <div className="specil-request">
            <Typography
              variant="subtitle2"
              style={{
                gridColumn: "1/3",
                fontWeight: 500,
              }}
            >
              Specil Request
            </Typography>
            {orderDetails?.data?.details?.OrderSummary?.map((item: any) => (
              <React.Fragment key={item?.id}>
                <Typography variant="subtitle2" className="field">
                  {item?.name_en} / {item?.name_ar}
                </Typography>
                <Typography
                  variant="subtitle2"
                  className="field"
                  align="center"
                >
                  {item?.additionalDemandsText}
                </Typography>
              </React.Fragment>
            ))}
          </div>

          <Box
            display="grid"
            gridTemplateColumns="1fr 1fr"
            marginTop={1}
            gap={1}
          >
            <div className="table">
              <Typography className="item" sx={{ fontWeight: 500 }}>
                Order Sub Total
              </Typography>
              <Typography className="item" sx={{ fontWeight: 500 }}>
                QAR &nbsp;&nbsp;&nbsp;{" "}
                {(
                  orderDetails?.data?.order_total_price +
                  orderDetails?.data?.discount_value +
                  orderDetails?.data?.coupon_discount_value -
                  orderDetails?.data?.details?.catering_delivery_fees
                )?.toLocaleString("en-US")}
              </Typography>

              <Typography className="item">Delivery Charges</Typography>
              <Typography className="item">
                QAR &nbsp;&nbsp;&nbsp;{" "}
                {orderDetails?.data?.details?.catering_delivery_fees?.toLocaleString(
                  "en-US"
                )}
              </Typography>

              <Typography className="item">Tax Rate</Typography>
              <Typography className="item">QAR &nbsp;&nbsp;&nbsp; 0</Typography>
              {orderDetails?.data?.discount_value > 0 && (
                <>
                  <Typography className="item" sx={{ fontWeight: 500 }}>
                    Meiz Welcome Discount ({orderDetails?.data?.discount}%)
                  </Typography>
                  <Typography className="item" sx={{ fontWeight: 500 }}>
                    QAR &nbsp;&nbsp;&nbsp;{" "}
                    {orderDetails?.data?.discount_value?.toLocaleString(
                      "en-US"
                    )}
                  </Typography>
                </>
              )}
              {orderDetails?.data?.coupon_discount_value > 0 && (
                <>
                  <Typography className="item">
                    Coupon Discount (
                    {orderDetails?.data?.details?.coupon_discount}
                    %)
                  </Typography>
                  <Typography className="item">
                    QAR &nbsp;&nbsp;&nbsp;{" "}
                    {orderDetails?.data?.coupon_discount_value?.toLocaleString(
                      "en-US"
                    )}
                  </Typography>
                </>
              )}
              {orderDetails?.data?.payment_method !== "cashondelivery" && (
                <>
                  <Typography className="item">
                    Advance Paid{" "}
                    {`  ${orderDetails?.data?.store?.catering_advance}%`}
                  </Typography>
                  <Typography className="item">
                    QAR &nbsp;&nbsp;&nbsp;{" "}
                    {orderDetails?.data?.payed_amount?.toLocaleString("en-US")}
                  </Typography>
                </>
              )}
              <Typography className="item"> Balance Amount </Typography>
              <Typography className="item">
                QAR &nbsp;&nbsp;&nbsp;{" "}
                {orderDetails?.data?.remaining_amount?.toLocaleString("en-US")}
              </Typography>

              <Typography className="item" sx={{ fontWeight: 600 }}>
                Total Order
              </Typography>
              <Typography className="item" sx={{ fontWeight: 600 }}>
                QAR &nbsp;&nbsp;&nbsp;{" "}
                {orderDetails?.data?.order_total_price?.toLocaleString("en-US")}
              </Typography>
            </div>
            <Grid container width="100%" alignItems="center">
              <Grid item xs={7}>
                <Typography style={{ padding: 8 }}>
                  PGW Amount Recieved
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography style={{ padding: 8 }}>QAR </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography style={{ padding: 8 }}>
                  {orderDetails?.data?.payed_amount?.toLocaleString("en-US")}
                </Typography>
              </Grid>

              <Grid item xs={7}>
                <Typography style={{ padding: 8 }}>Meiz Commission</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography style={{ padding: 8 }}>QAR </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography style={{ padding: 8 }}>
                  {orderDetails?.data?.meiz_commission_value?.toLocaleString(
                    "en-US"
                  )}
                </Typography>
              </Grid>
              {orderDetails?.data?.coupon_meiz_contribution_value > 0 && (
                <>
                  <Grid item xs={7}>
                    <Typography style={{ padding: 8, fontWeight: 600 }}>
                      Meiz Discount Contribution (
                      {orderDetails?.data?.details?.coupon_meiz_contribution}
                      %)
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography style={{ padding: 8 }}>QAR </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography style={{ padding: 8 }}>
                      {orderDetails?.data?.coupon_meiz_contribution_value?.toLocaleString(
                        "en-US"
                      )}
                    </Typography>
                  </Grid>
                </>
              )}
              <Grid item xs={7}>
                <Typography style={{ padding: 8 }}>PGW Charge</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography style={{ padding: 8 }}>QAR </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography style={{ padding: 8 }}>
                  {orderDetails?.data?.pgw_charges_value?.toLocaleString(
                    "en-US"
                  )}
                </Typography>
              </Grid>
              <Grid item xs={7} className="total">
                <Typography sx={{ fontWeight: 600 }}>
                  DUE PAYMENT TO (FROM) STORE
                </Typography>
              </Grid>
              <Grid item xs={2} className="total">
                <Typography sx={{ fontWeight: 600 }}>QAR </Typography>
              </Grid>
              <Grid item xs={3} className="total">
                <Typography sx={{ fontWeight: 600 }}>
                  {(
                    orderDetails?.data?.coupon_meiz_contribution_value +
                    orderDetails?.data?.payed_amount -
                    (orderDetails?.data?.pgw_charges_value +
                      orderDetails?.data?.meiz_commission_value)
                  )?.toLocaleString("en-US")}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </ComponentToPrint>
  );
});
