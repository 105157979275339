import styled from "styled-components";
import { Tab as TabMui } from "@mui/material";

export const Tab = styled(TabMui)<{
  selected: boolean;
  index: number;
  lastIndex?: boolean;
}>`
  ${({ selected, index }) => `
    // position: fixed;
    min-width: ${selected ? "140px" : "120px"};
    background-color: ${selected ? "#fff" : "inherit"} ;
    border: 1px solid #dddddd;
    border-bottom: none;
    border-radius: 20px 20px 0 0;
    text-transform: capitalize;
    z-index: ${selected ? 999 : 9};
  `}
`;

// left :${
//   selected && index > 0
//     ? `${300 + 120 * index - 10}px`
//     : `${300 + 120 * index}px`
// };
