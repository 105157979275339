import isEmail from "validator/lib/isEmail";
import isEmpty from "validator/lib/isEmpty";

import { LoginState } from "./index";
import { message } from "../../utils/message";

type Error = Partial<LoginState>;

export const loginValidation = (values: LoginState): Error => {
  const errors: Error = {};

  Object.keys(values).forEach((key: string) => {
    switch (key) {
      case "email":
        if (isEmpty(values.email)) {
          errors.email = message.login.emailRequiered;
        } else if (!isEmail(values.email)) {
          errors.email = message.login.emailInvalid;
        }
        break;
      case "password":
        if (isEmpty(values.password)) {
          errors.password = message.login.password;
        }
        break;
      default:
        break;
    }
  });
  return errors;
};
