/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";

import { RegionCard, AreaCard } from "../../../components";
import { NameRestaurantContext } from "../../../App";

import { Page } from "./Regions-style";

export const Regions = (): JSX.Element => {
  const { setNameRestaurant } = React.useContext(NameRestaurantContext);
  const [idRegion, setIdRegion] = React.useState<number | undefined>();

  React.useEffect(() => {
    setNameRestaurant("");
  }, []);

  return (
    <Page>
      <RegionCard onSelectRegion={setIdRegion} />
      <AreaCard idRegion={idRegion} />
    </Page>
  );
};
