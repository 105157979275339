import * as React from "react";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useQuery, useMutation, useQueryClient } from "react-query";
import moment from "moment";

import {
  Button,
  LoadingButton,
  TextField,
  Status,
} from "../../../../../commons";
import Table, { ColumnsProps } from "../../../../../commons/Table";

import { SnackbarContext } from "../../../../../App";
import { serviceUnavailable } from "../../../../../utils/message";

import {
  RoleStoreUserPaymentRequestService,
  RoleStoreUserOrdersService,
} from "../../../../../services/openApi";

import { Page } from "./PaymentRequests-style";
import { PaymentRequestDialog } from "../../../../../components";

type PaymentRequestsStoreProps = {};

type openFormState = {
  open: boolean;
  title: string;
  action?: "update";
};

export const PaymentRequestsStore = (props: PaymentRequestsStoreProps) => {
  const { setSnack } = React.useContext(SnackbarContext);
  const queryClient = useQueryClient();

  const { saveStorePaymentRequest, getStorePaymentRequestList } =
    RoleStoreUserPaymentRequestService;
  const { getOrderByStoreId } = RoleStoreUserOrdersService;

  const [dateFrom, setDateFrom] = React.useState<Date | null>(null);
  const [dateTo, setDateTo] = React.useState<Date | null>(null);
  const [datePayment, setDatePayment] = React.useState<Date | null>(new Date());

  const [description, setDescription] = React.useState<string | undefined>();
  const [orderBalance, setOrderBalance] = React.useState<number>(0);

  const [referenceNumber, setReferenceNumber] = React.useState<
    any[] | undefined
  >();
  const [openForm, setOpenForm] = React.useState<openFormState>({
    open: false,
    title: "",
  });
  const [idRequest, setIdRequest] = React.useState<number | undefined>();
  const [custom, setCutom] = React.useState(false);
  const [selectedStore, setSelectedStore] = React.useState<
    number | undefined
  >();

  const [checked, setChecked] = React.useState<boolean>(false);

  const columns: ColumnsProps = [
    {
      header: "Store",
      accessor: "store",
    },
    {
      header: "Date",
      accessor: "date",
    },
    {
      header: "Reference #",
      accessor: "reference",
    },
    {
      header: "Type",
      accessor: "type",
    },
    {
      header: "Reference date",
      accessor: "referenceDate",
    },
    {
      header: "Description",
      accessor: "description",
    },
    {
      header: "Note",
      accessor: "note",
    },
    {
      header: "Status",
      accessor: "status",
    },
    {
      header: "Amount",
      accessor: "amount",
    },
    {
      header: "Actions",
      accessor: "actions",
      headerCellProps: { align: "center" },
      cellProps: { align: "center", width: "80px" },
    },
  ];

  const getListStoresPayment = useQuery(
    [
      "getStorePaymentRequestList",
      dateFrom ? moment(dateFrom).format("YYYY-MM-DD") : undefined,
      dateTo ? moment(dateTo).format("YYYY-MM-DD") : undefined,
    ],
    () =>
      getStorePaymentRequestList({
        start_date: dateFrom
          ? moment(dateFrom).format("YYYY-MM-DD")
          : undefined,
        end_date: dateTo ? moment(dateTo).format("YYYY-MM-DD") : undefined,
      }),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const renderTableRows = () => {
    console.log(
      "#getListStoresPayment?.data?.paymentRequests",
      getListStoresPayment?.data?.paymentRequests
    );
    return (
      getListStoresPayment?.data?.paymentRequests?.map((item: any) => ({
        store: (
          <Typography variant="body2" sx={{ p: "8px 0" }}>
            {item?.store?.store_translation?.name}
          </Typography>
        ),
        date: (
          <Typography variant="body2" sx={{ p: "8px 0" }}>
            {item?.created_at}
          </Typography>
        ),
        reference: (
          <Typography variant="body2" sx={{ p: "8px 0" }}>
            {item?.order_id}
          </Typography>
        ),
        type: (
          <Typography variant="body2" sx={{ p: "8px 0" }}>
            {item?.type}
          </Typography>
        ),
        referenceDate: (
          <Typography variant="body2" sx={{ p: "8px 0" }}>
            {item?.reference_date}
          </Typography>
        ),
        description: (
          <Typography variant="body2" sx={{ p: "8px 0" }}>
            {item?.description || "--"}
          </Typography>
        ),
        note: (
          <Typography variant="body2" sx={{ p: "8px 0" }}>
            {item?.note || "--"}
          </Typography>
        ),
        status: <Status status={item?.status?.toUpperCase()} />,
        amount: (
          <Typography variant="body2" sx={{ p: "8px 0" }}>
            {`QAR ${item?.amount}`}
          </Typography>
        ),
        actions: (
          <Button
            variant="contained"
            className="btn-action"
            onClick={() => {
              setOpenForm({
                open: true,
                title: "Accept or Reject payement request",
                action: "update",
              });
              setIdRequest(item?.id);
              item?.custom && setCutom(true);
            }}
            disabled={item?.status?.toUpperCase() !== "PENDING" || !item.custom}
          >
            Action
          </Button>
        ),
      })) || []
    );
  };

  const getListOrdersByStoreId = useQuery(
    "getOrderByStoreId",
    () => getOrderByStoreId(),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const handleCancelation = () => {
    setReferenceNumber(undefined);
    setDescription(undefined);
    setDatePayment(null);
  };

  const saveRequest = useMutation(saveStorePaymentRequest, {
    onSuccess: () => {
      queryClient.refetchQueries([
        "getStorePaymentRequestList",
        dateFrom ? moment(dateFrom).format("YYYY-MM-DD") : undefined,
        dateTo ? moment(dateTo).format("YYYY-MM-DD") : undefined,
      ]);
      handleCancelation();
    },
  });

  const handleOrdreIdsWithBalance = (value: any) => {
    const arrayOrderIds: any[] = [];
    let sumTotalPrice: number = 0;
    value?.forEach((item: any) => {
      if (item?.remaining_amount && item?.id) {
        sumTotalPrice += item.remaining_amount;
        arrayOrderIds.push({
          id: item.id,
          // amount: item.order_total_price,
          amount: item.remaining_amount,
        });
      }
    });
    setOrderBalance(sumTotalPrice);
    setReferenceNumber(arrayOrderIds);
  };

  const handleSubmit = () => {
    saveRequest.mutate({
      description,
      reference_date: moment(datePayment).format("YYYY-MM-DD"),
      orders: referenceNumber,
    });
  };

  return (
    <Page className="scrolbar">
      <Box
        display="grid"
        gap={2}
        mb={2}
        sx={{
          gridTemplateColumns: "1fr 1fr",
          alignItems: "center",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="From"
            value={dateFrom}
            inputFormat="dd/MM/yyyy"
            onChange={(newValue) => {
              setDateFrom(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} color="secondary" />
            )}
          />

          <DatePicker
            label="To"
            value={dateTo}
            inputFormat="dd/MM/yyyy"
            onChange={(newValue) => {
              setDateTo(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} color="secondary" />
            )}
          />
        </LocalizationProvider>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="containerCollapse">
            <Collapse
              in={checked}
              collapsedSize={50}
              sx={{ width: "100%", padding: "5px 16px 16px 16px" }}
            >
              <Box
                mb={1}
                display="flex"
                sx={{
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="subtitle1"
                  align="center"
                  sx={{
                    fontWeight: 600,
                  }}
                >
                  Add New Payment
                </Typography>
                <IconButton onClick={() => setChecked(!checked)}>
                  <ArrowDropDownIcon />
                </IconButton>
              </Box>
              <Box
                display="grid"
                gap={2}
                mb={2}
                sx={{
                  gridTemplateColumns: "1fr 250px",
                  alignItems: "center",
                }}
              >
                <Autocomplete
                  options={getListOrdersByStoreId.data || []}
                  getOptionLabel={(option: any) => option?.id || ""}
                  filterSelectedOptions
                  multiple
                  onChange={(_, value: any) => handleOrdreIdsWithBalance(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Enter Reference Number"
                      color="secondary"
                    />
                  )}
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={datePayment}
                    inputFormat="dd/MM/yyyy"
                    onChange={(newValue) => {
                      setDatePayment(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} color="secondary" fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </Box>
              <Box
                display="grid"
                gap={2}
                mb={2}
                sx={{
                  gridTemplateColumns: "1fr 250px",
                  alignItems: "center",
                }}
              >
                <TextField
                  name="description"
                  placeholder="Enter Description"
                  multiline
                  rows={2}
                  value={description || ""}
                  onChange={(e) => setDescription(e.target.value)}
                  color="secondary"
                  fullWidth
                />

                <Typography align="center" sx={{ fontWeight: 600 }}>
                  Due Amount : QAR {orderBalance?.toLocaleString("en-US")}
                </Typography>
              </Box>

              <Box display="flex" height={45} justifyContent="flex-end" gap={2}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleCancelation}
                >
                  Cancel
                </Button>
                <LoadingButton
                  loading={saveRequest.isLoading}
                  variant="contained"
                  color="secondary"
                  onClick={handleSubmit}
                  disabled={!referenceNumber}
                  style={{ width: "13em" }}
                >
                  Send
                </LoadingButton>
              </Box>
            </Collapse>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Table
              columns={columns}
              loading={getListStoresPayment?.isLoading}
              nbrRowLoading={10}
              data={renderTableRows()}
              emptyMessage="No data available"
            />
          </Box>
        </Grid>
      </Grid>
      {openForm.open && (
        <PaymentRequestDialog
          idRequest={idRequest}
          idStore={selectedStore}
          open={openForm.open}
          title={openForm.title}
          action={openForm.action}
          startDate={moment(dateFrom).format("YYYY-MM-DD")}
          endDate={moment(dateTo).format("YYYY-MM-DD")}
          onClose={() => {
            getListStoresPayment.refetch();
            setOpenForm({
              open: false,
              title: "",
            });
            setIdRequest(undefined);
            setCutom(false);
          }}
          custom={custom}
          storeCustom={true}
        />
      )}
    </Page>
  );
};
