import React from "react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TimePicker from "@mui/lab/TimePicker";
import { useMutation, useQueryClient } from "react-query";
import moment from "moment";

import { Dialog, TextField } from "../../commons";

import { SnackbarContext } from "../../App";
import { serviceUnavailable, successfulOperation } from "../../utils/message";

import { StoreWorkingHoursService } from "../../services/openApi";

type WorkingHoursDialogProps = {
  idStore?: string;
  open: boolean;
  title: string;
  action?: "delete" | "add" | "update";
  onClose: () => void;
};

type formType = {
  start_time: string;
  end_time: string;
  week_days?: Array<string>;
};

export const WorkingHoursDialog = ({
  idStore,
  open,
  title,
  action,
  onClose,
}: WorkingHoursDialogProps): JSX.Element => {
  const { applyWorkingHoursForAll } = StoreWorkingHoursService;
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);

  const [stateForm, setStateForm] = React.useState<formType>({
    start_time: "10:00",
    end_time: "12:00",
    week_days: [],
  });

  const updateApplyAll = useMutation(
    () => applyWorkingHoursForAll(+idStore!, { ...stateForm }),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["getHoursByStoreId", idStore]);
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        onClose();
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const handleChangeTime =
    (prop: "start_time" | "end_time") => (event: any) => {
      setStateForm({
        ...stateForm,
        [prop]: moment(event).format("HH:mm") as string,
      });
    };

  const handleChangeAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setStateForm({
        ...stateForm,
        week_days: [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ],
      });
    } else {
      setStateForm({
        ...stateForm,
        week_days: [],
      });
    }
  };

  const handleChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const arrayDays = [...(stateForm?.week_days || [])];
    const position = stateForm?.week_days?.findIndex((o) => o === "Monday");
    if (event.target.checked) {
      arrayDays.push("Monday");
    } else if (position !== undefined) {
      arrayDays.splice(position, 1);
    }
    setStateForm({
      ...stateForm,
      week_days: arrayDays,
    });
  };

  const handleChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const arrayDays = [...(stateForm?.week_days || [])];
    const position = stateForm?.week_days?.findIndex((o) => o === "Tuesday");
    if (event.target.checked) {
      arrayDays.push("Tuesday");
    } else if (position !== undefined) {
      arrayDays.splice(position, 1);
    }
    setStateForm({
      ...stateForm,
      week_days: arrayDays,
    });
  };

  const handleChange3 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const arrayDays = [...(stateForm?.week_days || [])];
    const position = stateForm?.week_days?.findIndex((o) => o === "Wednesday");
    if (event.target.checked) {
      arrayDays.push("Wednesday");
    } else if (position !== undefined) {
      arrayDays.splice(position, 1);
    }
    setStateForm({
      ...stateForm,
      week_days: arrayDays,
    });
  };

  const handleChange4 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const arrayDays = [...(stateForm?.week_days || [])];
    const position = stateForm?.week_days?.findIndex((o) => o === "Thursday");
    if (event.target.checked) {
      arrayDays.push("Thursday");
    } else if (position !== undefined) {
      arrayDays.splice(position, 1);
    }
    setStateForm({
      ...stateForm,
      week_days: arrayDays,
    });
  };

  const handleChange5 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const arrayDays = [...(stateForm?.week_days || [])];
    const position = stateForm?.week_days?.findIndex((o) => o === "Friday");
    if (event.target.checked) {
      arrayDays.push("Friday");
    } else if (position !== undefined) {
      arrayDays.splice(position, 1);
    }
    setStateForm({
      ...stateForm,
      week_days: arrayDays,
    });
  };

  const handleChange6 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const arrayDays = [...(stateForm?.week_days || [])];
    const position = stateForm?.week_days?.findIndex((o) => o === "Saturday");
    if (event.target.checked) {
      arrayDays.push("Saturday");
    } else if (position !== undefined) {
      arrayDays.splice(position, 1);
    }
    setStateForm({
      ...stateForm,
      week_days: arrayDays,
    });
  };

  const handleChange7 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const arrayDays = [...(stateForm?.week_days || [])];
    const position = stateForm?.week_days?.findIndex((o) => o === "Sunday");
    if (event.target.checked) {
      arrayDays.push("Sunday");
    } else if (position !== undefined) {
      arrayDays.splice(position, 1);
    }
    setStateForm({
      ...stateForm,
      week_days: arrayDays,
    });
  };

  const children = (
    <Box sx={{ display: "flex", flexWrap: "wrap", ml: 3 }}>
      <FormControlLabel
        label="Monday"
        control={
          <Checkbox
            checked={
              stateForm?.week_days?.findIndex((o) => o === "Monday") !== -1 ||
              false
            }
            onChange={handleChange1}
            color="secondary"
          />
        }
      />
      <FormControlLabel
        label="Tuesday"
        control={
          <Checkbox
            checked={
              stateForm?.week_days?.findIndex((o) => o === "Tuesday") !== -1 ||
              false
            }
            onChange={handleChange2}
            color="secondary"
          />
        }
      />
      <FormControlLabel
        label="Wednesday"
        control={
          <Checkbox
            checked={
              stateForm?.week_days?.findIndex((o) => o === "Wednesday") !==
                -1 || false
            }
            onChange={handleChange3}
            color="secondary"
          />
        }
      />
      <FormControlLabel
        label="Thursday"
        control={
          <Checkbox
            checked={
              stateForm?.week_days?.findIndex((o) => o === "Thursday") !== -1 ||
              false
            }
            onChange={handleChange4}
            color="secondary"
          />
        }
      />
      <FormControlLabel
        label="Friday"
        control={
          <Checkbox
            checked={
              stateForm?.week_days?.findIndex((o) => o === "Friday") !== -1 ||
              false
            }
            onChange={handleChange5}
            color="secondary"
          />
        }
      />
      <FormControlLabel
        label="Saturday"
        control={
          <Checkbox
            checked={
              stateForm?.week_days?.findIndex((o) => o === "Saturday") !== -1 ||
              false
            }
            onChange={handleChange6}
            color="secondary"
          />
        }
      />
      <FormControlLabel
        label="Sunday"
        control={
          <Checkbox
            checked={
              stateForm?.week_days?.findIndex((o) => o === "Sunday") !== -1 ||
              false
            }
            onChange={handleChange7}
            color="secondary"
          />
        }
      />
    </Box>
  );

  const handleSubmit = () => {
    if (idStore) updateApplyAll.mutate();
  };

  return (
    <Dialog
      open={open}
      title={title}
      maxWidth="sm"
      handleContent={handleSubmit}
      loading={updateApplyAll.isLoading}
      onClose={onClose}
      action={action}
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          gap={3}
          pt={1}
        >
          <Typography sx={{ fontWeight: 500 }}>Select time </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              label="From"
              value={
                stateForm?.start_time
                  ? new Date(`December 17, 1995 ${stateForm?.start_time}`)
                  : null
              }
              onChange={handleChangeTime("start_time")}
              renderInput={(params) => (
                <TextField {...params} color="secondary" />
              )}
              ampm={false}
              mask="__:__"
              inputFormat="HH:mm"
            />
            <TimePicker
              label="To"
              value={
                stateForm?.end_time
                  ? new Date(`December 17, 1995 ${stateForm?.end_time}`)
                  : null
              }
              onChange={handleChangeTime("end_time")}
              renderInput={(params) => (
                <TextField {...params} color="secondary" />
              )}
              ampm={false}
              mask="__:__"
              inputFormat="HH:mm"
            />
          </LocalizationProvider>
        </Box>
        <div>
          <FormControlLabel
            label="All Days"
            control={
              <Checkbox
                checked={stateForm.week_days?.length === 7 || false}
                indeterminate={
                  (stateForm?.week_days?.length || 0) >= 1 &&
                  (stateForm?.week_days?.length || 0) < 7
                }
                onChange={handleChangeAll}
                color="secondary"
              />
            }
          />
          {children}
        </div>
      </Box>
    </Dialog>
  );
};
