/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useQuery, useMutation } from "react-query";

import { Button, LoadingButton } from "../../../commons";
import {
  ChooseMenuForm,
  GeneralInfoForm,
  Choices,
  Addons,
} from "../../../components";
import { GeneralInfoType } from "../../../components/GeneralInfoForm/GeneralInfoForm-type";

import { SnackbarContext } from "../../../App";
import { StoreItemsService } from "../../../services/openApi";

import {
  validation,
  Error,
} from "../FormCartingMenu/GeneralInfoForm-validation";
import { Page } from "./FormCatringMenuDuplicate-style";

const steps = ["Chose Menu", "Create Menu", "Choices", "Addons"];

export const FormCatringMenuDuplicate = (): JSX.Element => {
  const { setSnack } = React.useContext(SnackbarContext);
  const { getItemDetails, copyItem } = StoreItemsService;

  const history = useHistory();

  const [activeStep, setActiveStep] = React.useState(0);
  const [idMenu, setIdmenu] = React.useState<number | undefined>(undefined);
  const [newIdMenu, setNewIdMenu] = React.useState<number | undefined>(
    undefined
  );
  const [formMenu, setFormMenu] = React.useState<GeneralInfoType>({
    thumbfile: undefined,
    name_en: undefined,
    name_ar: undefined,
    short_description_en: undefined,
    short_description_ar: undefined,
    global_categories: undefined,
    filters: undefined,
    sub_category_id: undefined,
    quantity_per_day: undefined,
    max_quantity_per_day: undefined,
    item_unit_id: 1,
    original_price: undefined,
    promoted_price: undefined,
    price_per_unit: undefined,
    enough_for: undefined,
    lead_time: undefined,
    available_from_time: undefined,
    available_to_time: undefined,
    setup_time: undefined,
    max_time: undefined,
    item_requirements: undefined,
  });
  const [fromMenuError, setFormMenuError] = React.useState<Error>();
  const [enableQueryMenuDetails, setEnableQueryMenuDetails] =
    React.useState(false);

  const getMenuDetails = useQuery(
    ["getItemDetails", idMenu],
    () => getItemDetails(idMenu!),
    {
      onSuccess: () => {
        handleNext(1);
      },
      onError: (error: any) => {
        setSnack({
          open: true,
          severity: "error",
          message: JSON.stringify(error?.body?.message),
        });
      },
      enabled: enableQueryMenuDetails,
    }
  );

  React.useEffect(() => {
    if (getMenuDetails?.data) {
      const data = getMenuDetails?.data;
      const arrayIdsGlabalGategory: number[] = [];
      const arrayIdsFilters: number[] = [];
      const arrayIdsRequirement: number[] = [];
      const idsGallery: number[] = [];
      const {
        item_translations,
        // sub_category_id,
        quantity_per_day,
        max_quantity_per_day,
        item_units_id,
        original_price,
        promoted_price,
        enough_for,
        lead_time,
        available_from_time,
        available_to_time,
        setup_time,
        max_time,
        thumb,
        price_per_unit,
      } = data?.item;

      data?.item?.global_categories?.forEach((item: any) =>
        arrayIdsGlabalGategory.push(item?.category_id)
      );

      data?.item?.filters?.forEach((item: any) =>
        arrayIdsFilters.push(item?.id)
      );

      data?.item?.requirements?.forEach((item: any) =>
        arrayIdsRequirement.push(item?.id)
      );

      data?.item?.gallery?.forEach((item: any) =>
        idsGallery.push(item?.imagefid)
      );

      setFormMenu({
        ...formMenu,
        thumbfile: thumb?.id,
        images: idsGallery,
        name_en: item_translations?.[0]?.name,
        name_ar: item_translations?.[1]?.name,
        dish_en: item_translations?.[0]?.dish,
        dish_ar: item_translations?.[1]?.dish,
        short_description_en: item_translations?.[0]?.short_description,
        short_description_ar: item_translations?.[1]?.short_description,
        description_en: item_translations?.[0]?.description,
        description_ar: item_translations?.[1]?.description,
        global_categories: arrayIdsGlabalGategory,
        // sub_category_id: sub_category_id,
        filters: arrayIdsFilters,
        quantity_per_day,
        max_quantity_per_day,
        item_unit_id: item_units_id,
        original_price,
        promoted_price,
        enough_for,
        lead_time,
        available_from_time,
        available_to_time,
        setup_time,
        max_time,
        item_requirements: arrayIdsRequirement,
        price_per_unit,
        copy_from_item_id: idMenu,
      });
    }
  }, [getMenuDetails?.data]);

  const urlsGallery = getMenuDetails?.data?.item?.gallery?.map(
    (item: any) => item?.image?.uri
  );

  const addMenu = useMutation(() => copyItem(formMenu), {
    onSuccess: (data) => {
      setEnableQueryMenuDetails(false);
      setNewIdMenu(+data?.item_id!);
      handleNext(2);
    },
    onError: (error: any) => {
      setSnack({
        open: true,
        severity: "error",
        message: JSON.stringify(error?.body?.message),
      });
    },
  });

  const handleNameBtn = (step: number) => {
    switch (step) {
      case 0:
        return "Select Menu";
      case 1:
        return "Create Menu";
      case 3:
        return "Next";
      case 4:
        return "Finish";
      default:
        return "Next";
    }
  };

  const handleGetIdMenu = (id?: number) => {
    setIdmenu(id);
  };

  const handleError = (attribute: string) => {
    setFormMenuError({
      ...fromMenuError,
      [attribute]: "",
    });
  };

  const handleBack = () => {
    setActiveStep((c) => c - 1);
  };

  const handleNext = (step: number) => {
    setActiveStep(step);
  };

  const handleClick = () => {
    if (activeStep === 0) {
      setEnableQueryMenuDetails(true);
    } else if (activeStep === 1) {
      const resultValidation = validation(formMenu);
      if (Object.entries(resultValidation).length === 0) {
        addMenu.mutate();
      } else {
        setFormMenuError({ ...resultValidation });
      }
    } else if (activeStep >= 2 && activeStep < 4) {
      setActiveStep((c) => c + 1);
    } else if (activeStep === 4) {
      history.goBack();
    }
  };

  return (
    <Page className="scrolbar">
      <Stepper activeStep={activeStep} alternativeLabel color="secondary">
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box
        display="flex"
        justifyContent="center"
        width="100%"
        mt={2}
        border="1px solid #dddddd"
        borderRadius="20px"
      >
        {activeStep === 0 && <ChooseMenuForm onChooseMenu={handleGetIdMenu} />}
        {activeStep === 1 && !getMenuDetails?.isLoading && (
          <GeneralInfoForm
            inputData={formMenu}
            formValidation={fromMenuError}
            onChange={setFormMenu}
            onError={handleError}
            urlThumbfile={getMenuDetails?.data?.item?.thumb?.uri}
            urlsGallery={urlsGallery}
          />
        )}
        {activeStep === 2 && (
          <Choices
            idMenu={newIdMenu}
            style={{ borderRadius: "20px 0 0 20px" }}
          />
        )}
        {(activeStep === 3 || activeStep === 4) && (
          <Addons
            idMenu={newIdMenu}
            style={{ borderRadius: "20px 0 0 20px" }}
          />
        )}
      </Box>
      <Box
        display="flex"
        minHeight={40}
        justifyContent="flex-end"
        marginTop="20px"
        gap={2}
      >
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleBack}
          disabled={activeStep === 0 || activeStep === 2 ? true : false}
        >
          Back
        </Button>
        <LoadingButton
          variant="contained"
          color="secondary"
          onClick={handleClick}
          // disabled={!idMenu || activeStep === 4}
          disabled={!idMenu}
          loading={getMenuDetails?.isLoading || addMenu?.isLoading}
        >
          {handleNameBtn(activeStep)}
        </LoadingButton>
      </Box>
    </Page>
  );
};
