import styled from "styled-components";

export const Page = styled.div`
  padding: 25px 20px 10px 20px;
  .MuiChip-root {
    background: #302d60;
    color: #fff;
    .MuiChip-deleteIcon {
      color: rgb(237 235 235 / 26%);
    }
  }
  .containerCollapse {
    display: flex;
    border: 1px solid #dddddd;
    background-color: #fff;
    height: auto;
    border-radius: 20px;
  }
  .btn-search {
    svg {
      margin-right: 10px;
    }
  }

  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
    .MuiAutocomplete-input {
    padding: 4px;
  }
`;
