import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PersonIcon from "@mui/icons-material/Person";
import ArticleIcon from "@mui/icons-material/Article";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PaymentIcon from "@mui/icons-material/Payment";
import StoreIcon from "@mui/icons-material/Store";
import moment from "moment";
import { useQuery } from "react-query";

import { handleNamePaymentMethod } from "../../utils";

import {
  RoleStoreUserOrdersService,
  DashboardOrdersService,
} from "../../services/openApi";

import { meizLog } from "../../img";

type PrintViewOrderProps = {
  onLoading: (value: boolean) => void;
  kind?: "admin";
};

export const PrintViewOrder = React.forwardRef<
  HTMLDivElement,
  PrintViewOrderProps
>(({ onLoading, kind }, ref) => {
  const { orderId } = useParams<{
    orderId?: string;
    token?: string;
  }>();
  const { getOrderDetails: getOrderDetailsForAdmin } = DashboardOrdersService;
  const { getOrderDetails: getOrderDetailsFormStoreUser } =
    RoleStoreUserOrdersService;

  const getOrderDetailsAdmin = useQuery(
    "getOrderDetailsForAdmin",
    () => getOrderDetailsForAdmin(+orderId!),
    {
      onSuccess: () => {
        onLoading(false);
      },
      onError: () => {
        onLoading(false);
      },
      enabled: !!orderId && kind === "admin",
    }
  );

  const getOrderDetailsStoreUser = useQuery(
    "getOrderDetailsStoreUser",
    () => getOrderDetailsFormStoreUser(+orderId!),
    {
      onSuccess: () => {
        onLoading(false);
      },
      onError: () => {
        onLoading(false);
      },
      enabled: !!orderId && kind !== "admin",
    }
  );

  return (
    <ComponentToPrint ref={ref}>
      <div className="header">
        <img src={meizLog} className="meiz-Log" alt="meiz-Log" />
      </div>
      <div className="list-cards">
        {/* ********************* Order Details **************************/}
        <Box display="flex" flexDirection="column" className="card-details">
          <Box display="flex" alignItems="center" gap={1}>
            <ArticleIcon sx={{ color: "secondary.main" }} />
            <Typography sx={{ color: "secondary.main", fontWeight: 600 }}>
              Order Details
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            sx={{ margin: "8px 0 0 24px" }}
          >
            <Typography variant="subtitle2">
              <b style={{ fontWeight: 500 }}>Order status :</b>{" "}
              {
                (getOrderDetailsStoreUser?.data || getOrderDetailsAdmin?.data)
                  ?.order_current_status?.order_status_translation
                  ?.display_status
              }
            </Typography>
            <Typography variant="subtitle2">
              <b style={{ fontWeight: 500 }}>Order :</b>{" "}
              {
                (getOrderDetailsStoreUser?.data || getOrderDetailsAdmin?.data)
                  ?.id
              }
            </Typography>
            <Typography variant="subtitle2">
              <b style={{ fontWeight: 500 }}>Order Time :</b>{" "}
              {(getOrderDetailsStoreUser?.data || getOrderDetailsAdmin?.data)
                ?.iso_date &&
                moment(
                  (getOrderDetailsStoreUser?.data || getOrderDetailsAdmin?.data)
                    ?.iso_date
                ).format("DD MMM YYYY")}
            </Typography>
            <Typography variant="subtitle2">
              <b style={{ fontWeight: 500 }}>Order Date :</b>{" "}
              {(getOrderDetailsStoreUser?.data || getOrderDetailsAdmin?.data)
                ?.iso_date &&
                moment(
                  (getOrderDetailsStoreUser?.data || getOrderDetailsAdmin?.data)
                    ?.iso_date
                ).format("hh:mm a")}
            </Typography>
          </Box>
        </Box>
        {/* ********************* Customer Details *************************/}
        <Box display="flex" flexDirection="column" className="card-details">
          <Box display="flex" alignItems="center" gap={1}>
            <PersonIcon sx={{ color: "secondary.main" }} />
            <Typography sx={{ color: "secondary.main", fontWeight: 600 }}>
              Customer Details
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            sx={{ margin: "8px 0 0 24px" }}
          >
            <Typography variant="subtitle2">
              <b style={{ fontWeight: 500 }}>Name :</b>{" "}
              {
                (getOrderDetailsStoreUser?.data || getOrderDetailsAdmin?.data)
                  ?.customer?.fullname
              }
            </Typography>
            <Typography variant="subtitle2">
              <b style={{ fontWeight: 500 }}>Contact :</b>{" "}
              {
                (getOrderDetailsStoreUser?.data || getOrderDetailsAdmin?.data)
                  ?.customer?.mobile
              }
            </Typography>
            <Typography variant="subtitle2">
              <b style={{ fontWeight: 500 }}>Email :</b>{" "}
              {
                (getOrderDetailsStoreUser?.data || getOrderDetailsAdmin?.data)
                  ?.customer?.email
              }
            </Typography>
          </Box>
        </Box>
        {/* ******************** Delivery Details **************************/}
        <Box display="flex" flexDirection="column" className="card-details">
          <Box display="flex" alignItems="center" gap={1}>
            <LocalShippingIcon sx={{ color: "secondary.main" }} />
            <Typography sx={{ color: "secondary.main", fontWeight: 600 }}>
              Delivery Details
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            sx={{ margin: "8px 0 0 24px" }}
          >
            <Typography variant="subtitle2">
              <b style={{ fontWeight: 500 }}>Delivery Data :</b>{" "}
              {(getOrderDetailsStoreUser?.data || getOrderDetailsAdmin?.data)
                ?.details?.date &&
                moment(
                  (getOrderDetailsStoreUser.data || getOrderDetailsAdmin?.data)
                    .details.date
                ).format("DD MMM YYYY")}
            </Typography>
            <Typography variant="subtitle2">
              <b style={{ fontWeight: 500 }}>Delivery Time :</b>{" "}
              {(getOrderDetailsStoreUser?.data || getOrderDetailsAdmin?.data)
                ?.details?.date &&
                moment(
                  (getOrderDetailsStoreUser?.data || getOrderDetailsAdmin?.data)
                    .details.date
                ).format("hh:mm a")}
            </Typography>
            <Typography variant="subtitle2">
              <b style={{ fontWeight: 500 }}>Delivery Area :</b>{" "}
              {
                (getOrderDetailsStoreUser?.data || getOrderDetailsAdmin?.data)
                  ?.details?.formatted_address
              }
            </Typography>
          </Box>
        </Box>
        {/********************* Payment Method **************************/}
        {/* {kind !== "admin" && (
          <Box display="flex" flexDirection="column" className="card-details">
            <Box display="flex" alignItems="center" gap={1}>
              <PaymentIcon sx={{ color: "secondary.main" }} />
              <Typography sx={{ color: "secondary.main", fontWeight: 600 }}>
                Payment Method
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              sx={{ margin: "8px 0 0 24px" }}
            >
              <Typography variant="subtitle2">
                {handleNamePaymentMethod(
                  (getOrderDetailsStoreUser?.data || getOrderDetailsAdmin?.data)
                    ?.payment_method
                )}
              </Typography>
            </Box>
          </Box>
        )} */}
        {/********************* Store Infos **************************/}
        {/* {kind === "admin" && ( */}
        <Box display="flex" flexDirection="column" className="card-details">
          <Box display="flex" alignItems="center" gap={1}>
            <StoreIcon sx={{ color: "secondary.main" }} />
            <Typography sx={{ color: "secondary.main", fontWeight: 600 }}>
              Store Infos
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            sx={{ margin: "8px 0 0 24px" }}
          >
            <Typography variant="subtitle2">
              <b style={{ fontWeight: 500 }}>Store Name :</b>{" "}
              {
                (getOrderDetailsStoreUser?.data || getOrderDetailsAdmin?.data)
                  ?.store?.store_translation?.name
              }
            </Typography>
            <Typography variant="subtitle2">
              <b style={{ fontWeight: 500 }}>Mobile Number :</b>{" "}
              {
                (getOrderDetailsStoreUser?.data || getOrderDetailsAdmin?.data)
                  ?.store?.phone
              }
            </Typography>
            <Typography variant="subtitle2">
              <b style={{ fontWeight: 500 }}>Email :</b>{" "}
              {
                (getOrderDetailsStoreUser?.data || getOrderDetailsAdmin?.data)
                  ?.store?.email
              }
            </Typography>
          </Box>
        </Box>
        {/* )} */}
        {/* {kind === "admin" && ( */}
        <Box display="flex" alignItems="center">
          <Box display="flex" gap={1} mr={1}>
            <PaymentIcon sx={{ color: "secondary.main" }} />
            <Typography sx={{ color: "secondary.main", fontWeight: 600 }}>
              Payment Method
            </Typography>
          </Box>

          <Typography variant="subtitle2">
            {handleNamePaymentMethod(
              (getOrderDetailsStoreUser?.data || getOrderDetailsAdmin?.data)
                ?.payment_method
            )}
          </Typography>
        </Box>
        {/* )} */}
      </div>
      <Box className="title-order-summary">
        <Typography sx={{ fontWeight: 600 }}>Order summary</Typography>
      </Box>
      <div className="table-order-summary">
        <Typography
          variant="subtitle2"
          className="field"
          sx={{ fontWeight: 500 }}
        >
          Item
        </Typography>
        <Typography
          variant="subtitle2"
          className="field"
          sx={{ fontWeight: 500 }}
        >
          Quantity
        </Typography>
        <Typography
          variant="subtitle2"
          className="field"
          sx={{ fontWeight: 500 }}
        >
          Unit Price
        </Typography>
        <Typography
          variant="subtitle2"
          className="field"
          sx={{ fontWeight: 500 }}
        >
          Total
        </Typography>
        {(
          getOrderDetailsStoreUser?.data || getOrderDetailsAdmin?.data
        )?.details?.OrderSummary?.map((item: any) => (
          <React.Fragment key={item?.id}>
            <Typography
              variant="subtitle2"
              className="field"
              style={{ fontWeight: 600 }}
            >
              {item?.name_en} / {item?.name_ar}
            </Typography>
            <Typography variant="subtitle2" className="field">
              {item?.quantity}
            </Typography>
            <Typography variant="subtitle2" className="field">
              {item?.promoted_price
                ? `QAR ${item?.promoted_price?.toLocaleString("en-US")}`
                : "_____"}
            </Typography>
            <Typography />
            {/* Choices */}
            {item?.choices?.map((choice: any) => (
              <React.Fragment key={choice?.id}>
                <Typography
                  variant="subtitle2"
                  className="field"
                  style={{
                    gridColumn: "1/5",
                    fontWeight: 600,
                    marginLeft: 16,
                  }}
                >
                  {choice?.name_en} / {choice?.name_ar}
                </Typography>
                {choice?.item_choice_options?.map((itemChoice: any) => (
                  <React.Fragment key={itemChoice?.id}>
                    <Typography
                      variant="subtitle2"
                      className="field"
                      style={{
                        marginLeft: 24,
                      }}
                    >
                      {itemChoice?.name_en} / {itemChoice?.name_ar}
                    </Typography>
                    <Typography variant="subtitle2" className="field">
                      {itemChoice?.quantity}
                    </Typography>
                    <Typography variant="subtitle2" className="field">
                      {`QAR ${itemChoice?.item_choice_price?.toLocaleString(
                        "en-US"
                      )}`}
                    </Typography>
                    <Typography variant="subtitle2" className="field">
                      {`QAR ${(
                        itemChoice?.item_choice_price * itemChoice?.quantity
                      )?.toLocaleString("en-US")}`}
                    </Typography>
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
            {/* Addons */}
            {item?.addons?.map((addon: any) => (
              <React.Fragment key={addon?.id}>
                <Typography
                  variant="subtitle2"
                  className="field"
                  style={{
                    gridColumn: "1/5",
                    fontWeight: 600,
                    marginLeft: 16,
                  }}
                >
                  {addon?.name_en} / {addon?.name_ar}
                </Typography>
                {addon?.item_addon_options?.map((itemAddon: any) => (
                  <React.Fragment key={itemAddon?.id}>
                    <Typography
                      variant="subtitle2"
                      className="field"
                      style={{
                        marginLeft: 24,
                      }}
                    >
                      {itemAddon?.name_en} / {itemAddon?.name_ar}
                    </Typography>
                    <Typography variant="subtitle2" className="field">
                      {itemAddon?.quantity}
                    </Typography>
                    <Typography variant="subtitle2" className="field">
                      {`QAR ${itemAddon?.item_addon_option_price?.toLocaleString(
                        "en-US"
                      )}`}
                    </Typography>
                    <Typography variant="subtitle2" className="field">
                      {`QAR ${(
                        itemAddon?.item_addon_option_price * itemAddon?.quantity
                      )?.toLocaleString("en-US")}`}
                    </Typography>
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
      </div>
      <div className="section">
        <div className="section-sub-1">
          <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
            Order Sub Total
          </Typography>
          <Typography variant="subtitle2">
            QAR &nbsp;&nbsp;&nbsp;{" "}
            {(
              (getOrderDetailsStoreUser?.data || getOrderDetailsAdmin?.data)
                ?.order_total_price +
              (getOrderDetailsStoreUser?.data || getOrderDetailsAdmin?.data)
                ?.discount_value -
              (getOrderDetailsStoreUser?.data || getOrderDetailsAdmin?.data)
                ?.details?.catering_delivery_fees
            )?.toLocaleString("en-US")}
          </Typography>
          <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
            Meiz Welcome Discount (
            {
              (getOrderDetailsStoreUser?.data || getOrderDetailsAdmin?.data)
                ?.discount
            }
            %)
          </Typography>
          <Typography variant="subtitle2">
            QAR &nbsp;&nbsp;&nbsp;{" "}
            {(
              getOrderDetailsStoreUser?.data || getOrderDetailsAdmin?.data
            )?.discount_value?.toLocaleString("en-US")}
          </Typography>
          {(getOrderDetailsStoreUser?.data || getOrderDetailsAdmin?.data)
            ?.coupon_discount_value > 0 && (
            <>
              <Typography className="item">
                Coupon Discount (
                {
                  (getOrderDetailsStoreUser?.data || getOrderDetailsAdmin?.data)
                    ?.details?.coupon_discount
                }
                %)
              </Typography>
              <Typography className="item">
                QAR &nbsp;&nbsp;&nbsp;{" "}
                {(
                  getOrderDetailsStoreUser?.data || getOrderDetailsAdmin?.data
                )?.coupon_discount_value?.toLocaleString("en-US")}
              </Typography>
            </>
          )}
          <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
            VAT
          </Typography>
          <Typography variant="subtitle2">
            QAR &nbsp;&nbsp;&nbsp;{" "}
            {(
              getOrderDetailsStoreUser?.data || getOrderDetailsAdmin?.data
            )?.details?.tax?.toLocaleString("en-US")}
          </Typography>
          <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
            Delivery Charges
          </Typography>
          <Typography variant="subtitle2">
            QAR &nbsp;&nbsp;&nbsp;{" "}
            {(
              getOrderDetailsStoreUser?.data || getOrderDetailsAdmin?.data
            )?.details?.catering_delivery_fees?.toLocaleString("en-US")}
          </Typography>
          <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
            Advance Paid
            {`  ${
              (
                getOrderDetailsStoreUser?.data || getOrderDetailsAdmin?.data
              )?.catering_advance?.toLocaleString("en-US") || 0
            }%`}
          </Typography>
          <Typography variant="subtitle2">
            QAR &nbsp;&nbsp;&nbsp;{" "}
            {(
              getOrderDetailsStoreUser?.data || getOrderDetailsAdmin?.data
            )?.payed_amount?.toLocaleString("en-US")}
          </Typography>
        </div>
        {kind === "admin" && (
          <div className="section-sub-3">
            <Typography variant="subtitle2">PGW Amount Recieved</Typography>
            <Typography variant="subtitle2">QAR </Typography>
            <Typography variant="subtitle2">
              {getOrderDetailsAdmin?.data?.payed_amount?.toLocaleString(
                "en-US"
              )}
            </Typography>

            <Typography variant="subtitle2">Meiz Commission</Typography>
            <Typography variant="subtitle2">QAR </Typography>
            <Typography variant="subtitle2">
              {getOrderDetailsAdmin?.data?.meiz_commission_value?.toLocaleString(
                "en-US"
              )}
            </Typography>

            <Typography variant="subtitle2">PGW Charge</Typography>
            <Typography variant="subtitle2">QAR </Typography>
            <Typography variant="subtitle2">
              {getOrderDetailsAdmin?.data?.pgw_charges_value?.toLocaleString(
                "en-US"
              )}
            </Typography>

            <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
              DUE PAYMENT TO (FROM) STORE
            </Typography>
            <Typography variant="subtitle2">QAR </Typography>
            <Typography variant="subtitle2">
              {(
                getOrderDetailsAdmin?.data?.payed_amount -
                (getOrderDetailsAdmin?.data?.pgw_charges_value +
                  getOrderDetailsAdmin?.data?.meiz_commission_value)
              )?.toLocaleString("en-US")}
            </Typography>
          </div>
        )}
        <div className="specil-request">
          <Typography
            variant="subtitle2"
            style={{
              gridColumn: "1/3",
              fontWeight: 500,
              marginBottom: 5,
            }}
          >
            Specil Request
          </Typography>
          {(
            getOrderDetailsStoreUser?.data || getOrderDetailsAdmin?.data
          )?.details?.OrderSummary?.map((item: any) => (
            <React.Fragment key={item?.id}>
              <Typography variant="subtitle2" className="field">
                {item?.name_en} / {item?.name_ar}
              </Typography>
              <Typography variant="subtitle2" className="field" align="center">
                {item?.additionalDemandsText}
              </Typography>
            </React.Fragment>
          ))}
        </div>
        <div className="section-sub-2">
          <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
            Total
          </Typography>
          <Typography variant="subtitle2">
            QAR &nbsp;&nbsp;&nbsp;{" "}
            {(
              getOrderDetailsStoreUser?.data || getOrderDetailsAdmin?.data
            )?.order_total_price?.toLocaleString("en-US")}
          </Typography>
          <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
            Balance Amount
          </Typography>
          <Typography variant="subtitle2">
            QAR &nbsp;&nbsp;&nbsp;{" "}
            {(
              getOrderDetailsStoreUser?.data || getOrderDetailsAdmin?.data
            )?.remaining_amount?.toLocaleString("en-US")}
          </Typography>
        </div>
        <div className="cancellation-policy">
          <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
            Cancellation Policy
          </Typography>
          <ul>
            <li>
              <Typography variant="subtitle2">
                In case of cancellation within the last 24 hours, the 50% down
                payment will be deducted
              </Typography>
            </li>
          </ul>
        </div>
      </div>
    </ComponentToPrint>
  );
});

const ComponentToPrint = styled.div`
  padding: 20px;
  .header {
    display: flex;
    background-color: #edfaff;
    padding: 10px;
    border-radius: 15px;
    .meiz-Log {
      width: 150px;
    }
    margin-bottom: 20px;
    justify-content: space-between;
  }
  .list-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    gap: 20px;
    .card-details {
      border: 2px solid ${(props) => props.theme.palette.secondary.main};
      border-radius: 15px;
      padding: 10px;
    }
  }
  .title-order-summary {
    background-color: #edfaff;
    padding: 10px;
    margin: 20px 0;
    border-radius: 15px;
  }
  .table-order-summary {
    display: grid;
    grid-template-columns: 1fr 150px 150px 200px;
    border: 2px solid ${(props) => props.theme.palette.secondary.main};
    padding: 5px;
    border-radius: 15px;
    .field {
      padding: 5px 10px;
    }
  }
  .section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 20px;
    gap: 20px;
    .section-sub-1 {
      display: grid;
      grid-template-columns: 1fr 100px;
      background: #edfaff;
      border-radius: 15px;
      padding: 10px;
    }
    .section-sub-2 {
      display: grid;
      grid-template-columns: 1fr 100px;
      align-items: center;
      background: #b7e7f9;
      border-radius: 15px;
      padding: 10px;
    }
    .section-sub-3 {
      display: grid;
      grid-template-columns: 1fr 40px 70px;
      border-radius: 15px;
      padding: 10px;
    }
    .specil-request {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      border: 2px solid ${(props) => props.theme.palette.secondary.main};
      padding: 10px;
      border-radius: 15px;
    }
    .cancellation-policy {
      ul {
        margin: 0;
      }
    }
  }

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    padding: 7px;
    .header {
      justify-content: space-between;
    }
    .list-cards {
      grid-template-columns: 1fr;
    }
    .table-order-summary {
      grid-template-columns: 3fr 70px 80px 80px;
    }
    .section {
      grid-template-columns: 1fr;
    }
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    .header {
      justify-content: space-between;
    }
    .list-cards {
      grid-template-columns: 1fr;
    }
    .table-order-summary {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .section {
      grid-template-columns: 1fr;
    }
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    .header {
      justify-content: space-between;
    }
    .list-cards {
      grid-template-columns: repeat(2, 1fr);
    }
    .table-order-summary {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .section {
      grid-template-columns: 1fr 1fr;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  /* @media only screen and (min-width: 992px) {...} */
`;
