/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { Dialog, TextField } from "../../commons";

import { SnackbarContext } from "../../App";
import { serviceUnavailable, successfulOperation } from "../../utils/message";
import { FilterCollectionsService } from "../../services/openApi";

import { AssignCollectionType } from "./AssignCollectionType";

type AssignCollectionDialogProps = {
  idCollection?: number;
  nameCollectionEn?: string;
  nameCollectionAr?: string;
  storesId?: number[];
  menusId?: number[];
  type?: string;
  open: boolean;
  title: string;
  action?: "delete" | "add" | "update" | "view" | "assign";
  onClose: () => void;
};

export const AssignCollectionDialog = ({
  idCollection,
  nameCollectionEn,
  nameCollectionAr,
  storesId,
  menusId,
  type,
  open,
  title,
  action,
  onClose,
}: AssignCollectionDialogProps): JSX.Element => {
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);
  const { updateFilterCollection, getAllStoresList, getItemListByStoresId } =
    FilterCollectionsService;
  const [formState, setFormState] = React.useState<AssignCollectionType>();

  React.useEffect(() => {
    setFormState({
      ...formState,
      filter_collection_id: idCollection,
      name_en: nameCollectionEn,
      name_ar: nameCollectionAr,
      stores: storesId,
      items: menusId,
      type,
    });
  }, [storesId, menusId, type, idCollection]);

  const listStores = useQuery("getAllStoresList", () => getAllStoresList(), {
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const listMenu = useQuery(
    [
      "getItemListByStoresId",
      [formState?.stores?.[formState?.stores?.length - 1] || 0],
    ],
    () =>
      getItemListByStoresId({
        stores_id: [formState?.stores?.[formState?.stores?.length - 1] || 0],
      }),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
      enabled: type === "Item",
    }
  );

  // const listStoreFilter =
  //   formState?.stores?.map((id) =>
  //     listStores?.data?.find((item: any) => item?.id === id)
  //   ) || [];

  // const listItemFilter =
  //   formState?.items?.map((id) =>
  //     listMenu?.data?.find((item: any) => item?.id === id)
  //   ) || [];

  const updateCollection = useMutation(
    () => updateFilterCollection(formState),
    {
      onSuccess: () => {
        queryClient.refetchQueries("getFilterCollections");
        queryClient.refetchQueries([
          "getFilterCollectionDetails",
          idCollection,
        ]);
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        onClose();
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const handleChangeAutoCompleteStore = (value: any) => {
    let arrayIds = [...(formState?.stores || [])];
    if (type?.toLowerCase() === "store") {
      arrayIds = value?.map((item: any) => item?.id);
    } else {
      arrayIds.push(value?.id);
    }
    setFormState({
      ...formState,
      stores: arrayIds,
    });
  };

  const handleChangeAutoCompleteItem = (value: any) => {
    let arrayIds = [...(formState?.items || [])];
    arrayIds = value?.map((item: any) => item?.id);
    setFormState({
      ...formState,
      items: arrayIds,
    });
  };

  const handleSave = () => {
    updateCollection?.mutate();
  };

  return (
    <Dialog
      open={open}
      title={title}
      maxWidth="lg"
      handleContent={handleSave}
      loading={updateCollection?.isLoading}
      loadingUseQuery={
        listStores?.isLoading || (type === "Item" && listMenu?.isLoading)
      }
      onClose={onClose}
      action={action}
    >
      <Box display="flex" width={450} flexDirection="column" gap={2}>
        <Autocomplete
          multiple={type === "Store" ? true : false}
          options={listStores.data || []}
          getOptionLabel={(option: any) => option?.name || ""}
          // value={listStoreFilter}
          filterSelectedOptions
          onChange={(_, value) => value && handleChangeAutoCompleteStore(value)}
          renderInput={(params) => (
            <TextField {...params} label="List of stores" color="secondary" />
          )}
          style={{ marginTop: 10 }}
        />
        {type === "Item" && (
          <Autocomplete
            multiple
            options={listMenu?.data || []}
            getOptionLabel={(option: any) => option?.name || ""}
            // value={listItemFilter}
            filterSelectedOptions
            onChange={(_, value) =>
              value && handleChangeAutoCompleteItem(value)
            }
            renderInput={(params) => (
              <TextField label="List of items" {...params} color="secondary" />
            )}
            style={{ marginTop: 10 }}
          />
        )}
      </Box>
    </Dialog>
  );
};
