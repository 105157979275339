/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useQuery, useMutation, useQueryClient } from "react-query";
import moment from "moment";

import { Button, LoadingButton, TextField } from "../../../commons";
import Table, { ColumnsProps } from "../../../commons/Table";

import { SnackbarContext } from "../../../App";
import { serviceUnavailable } from "../../../utils/message";

import {
  FilterCollectionsService,
  PaymentRequestService,
  DashboardOrdersService,
} from "../../../services/openApi";

import { Page } from "./StoresPayments-style";

type StoresPaymentsProps = {};

export const StoresPayments = (props: StoresPaymentsProps) => {
  const { setSnack } = React.useContext(SnackbarContext);
  const queryClient = useQueryClient();
  const { getAllStoresList } = FilterCollectionsService;
  const { getAdminPaymentRequestList, savePaymentRequest } =
    PaymentRequestService;
  const { getOrderByStoreId } = DashboardOrdersService;

  const [dateFrom, setDateFrom] = React.useState<Date | null>(null);
  const [dateTo, setDateTo] = React.useState<Date | null>(null);
  const [datePayment, setDatePayment] = React.useState<Date | null>(new Date());
  const [selectedStore, setSelectedStore] = React.useState<
    number | undefined
  >();
  const [description, setDescription] = React.useState<string | undefined>();
  const [orderBalance, setOrderBalance] = React.useState<number>(0);

  const [referenceNumber, setReferenceNumber] = React.useState<
    any[] | undefined
  >();

  const [checked, setChecked] = React.useState(false);

  const listStores = useQuery("getAllStoresList", () => getAllStoresList(), {
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const listStoreFilter =
    listStores?.data?.find((item: any) => item?.id === selectedStore) || "";

  const columns: ColumnsProps = [
    {
      header: "Store",
      accessor: "store",
    },
    {
      header: "Date",
      accessor: "date",
    },
    {
      header: "Reference #",
      accessor: "reference",
    },
    {
      header: "Type",
      accessor: "type",
    },
    {
      header: "Reference date",
      accessor: "referenceDate",
    },
    {
      header: "Description",
      accessor: "description",
    },
    {
      header: "Amount",
      accessor: "amount",
    },
  ];

  const getListStoresPayment = useQuery(
    [
      "getAdminPaymentRequestList",
      selectedStore,
      dateFrom ? moment(dateFrom).format("YYYY-MM-DD") : undefined,
      dateTo ? moment(dateTo).format("YYYY-MM-DD") : undefined,
    ],
    () =>
      getAdminPaymentRequestList({
        restaurant_store_id: selectedStore,
        start_date: dateFrom
          ? moment(dateFrom).format("YYYY-MM-DD")
          : undefined,
        end_date: dateTo ? moment(dateTo).format("YYYY-MM-DD") : undefined,
      }),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const renderTableRows = () => {
    return (
      getListStoresPayment?.data?.paymentRequests?.map((item: any) => ({
        store: (
          <Typography variant="body2" sx={{ p: "8px 0" }}>
            {item?.store?.store_translation?.name || "--"}
          </Typography>
        ),
        date: (
          <Typography variant="body2" sx={{ p: "8px 0" }}>
            {item?.created_at || "--"}
          </Typography>
        ),
        reference: (
          <Typography variant="body2" sx={{ p: "8px 0" }}>
            {item?.order_id || "--"}
          </Typography>
        ),
        type: (
          <Typography variant="body2" sx={{ p: "8px 0" }}>
            {item?.type?.replaceAll("_", " ") || "--"}
          </Typography>
        ),
        referenceDate: (
          <Typography variant="body2" sx={{ p: "8px 0" }}>
            {item?.reference_date || "--"}
          </Typography>
        ),
        description: (
          <Typography variant="body2" sx={{ p: "8px 0" }}>
            {item?.description || "--"}
          </Typography>
        ),
        amount: (
          <Typography variant="body2" sx={{ p: "8px 0" }}>
            {`QAR ${item?.amount?.toLocaleString("en-US")}`}
          </Typography>
        ),
      })) || []
    );
  };

  const getListOrdersByStoreId = useQuery(
    "getOrderByStoreId",
    () => getOrderByStoreId(selectedStore!),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
      enabled: !!selectedStore,
    }
  );

  const handleCancelation = () => {
    setReferenceNumber([]);
    setDescription(undefined);
    setDatePayment(new Date());
    setOrderBalance(0);
  };

  const saveRequest = useMutation(savePaymentRequest, {
    onSuccess: () => {
      queryClient.refetchQueries([
        "getAdminPaymentRequestList",
        selectedStore,
        moment(dateFrom).format("YYYY-MM-DD"),
        moment(dateTo).format("YYYY-MM-DD"),
      ]);
      handleCancelation();
    },
  });

  const handleOrdreIdsWithBalance = (value: any) => {
    console.log("#value", value);
    const arrayOrderIds: any[] = [];
    let sumTotalPrice: number = 0;
    value?.forEach((item: any) => {
      // if (item?.balance && item?.id) {
      // sumTotalPrice += item.balance;
      sumTotalPrice += item.remaining_amount;
      arrayOrderIds.push({
        id: item.id,
        amount: item.remaining_amount,
      });
      // }
    });

    setOrderBalance(sumTotalPrice);
    setReferenceNumber(arrayOrderIds);
  };

  const handleSubmit = () => {
    saveRequest.mutate({
      restaurant_store_id: selectedStore,
      description,
      reference_date: moment(datePayment).format("YYYY-MM-DD"),
      orders: referenceNumber,
    });
  };

  React.useEffect(() => {
    getListOrdersByStoreId.refetch();
  }, [selectedStore]);

  return (
    <Page className="scrolbar">
      <Box
        display="grid"
        gap={1}
        mb={2}
        sx={{
          gridTemplateColumns: "2fr 40px 1fr 40px 1fr",
          alignItems: "center",
        }}
      >
        <Autocomplete
          options={listStores.data || []}
          getOptionLabel={(option: any) => option?.name || ""}
          filterSelectedOptions
          value={listStoreFilter}
          onChange={(_, value: any) => setSelectedStore(value?.id)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Please select a store"
              color="secondary"
            />
          )}
          fullWidth
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Typography variant="body2" align="center" sx={{ color: "#DDDDDD" }}>
            From
          </Typography>
          <DatePicker
            value={dateFrom}
            inputFormat="dd/MM/yyyy"
            onChange={(newValue) => {
              setDateFrom(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} color="secondary" />
            )}
          />
          <Typography variant="body2" align="center" sx={{ color: "#DDDDDD" }}>
            To
          </Typography>
          <DatePicker
            value={dateTo}
            inputFormat="dd/MM/yyyy"
            onChange={(newValue) => {
              setDateTo(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} color="secondary" />
            )}
          />
        </LocalizationProvider>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="containerCollapse">
            <Collapse
              in={checked}
              collapsedSize={50}
              sx={{ width: "100%", padding: "5px 16px 16px 16px" }}
            >
              <Box
                mb={1}
                display="flex"
                sx={{
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="subtitle1"
                  align="center"
                  sx={{
                    fontWeight: 600,
                  }}
                >
                  Add New Payment
                </Typography>
                <IconButton onClick={() => setChecked(!checked)}>
                  <ArrowDropDownIcon />
                </IconButton>
              </Box>
              <Box
                display="grid"
                gap={2}
                mb={2}
                sx={{
                  gridTemplateColumns: "1fr 250px",
                  alignItems: "center",
                }}
              >
                <Autocomplete
                  options={getListOrdersByStoreId.data || []}
                  getOptionLabel={(option: any) => option?.id || ""}
                  filterSelectedOptions
                  multiple
                  value={
                    referenceNumber?.map((item: any) =>
                      getListOrdersByStoreId?.data?.find(
                        (el: any) => item.id === el.id
                      )
                    ) || []
                  }
                  onChange={(_, value: any) => handleOrdreIdsWithBalance(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Enter Reference Number"
                      color="secondary"
                    />
                  )}
                  disabled={!listStoreFilter}
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={datePayment}
                    inputFormat="dd/MM/yyyy"
                    onChange={(newValue) => {
                      setDatePayment(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} color="secondary" fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </Box>
              <Box
                display="grid"
                gap={2}
                mb={2}
                sx={{
                  gridTemplateColumns: "1fr 250px",
                  alignItems: "center",
                }}
              >
                <TextField
                  name="description"
                  placeholder="Enter Description"
                  multiline
                  rows={2}
                  value={description || ""}
                  onChange={(e) => setDescription(e.target.value)}
                  color="secondary"
                  fullWidth
                />

                <Typography align="center" sx={{ fontWeight: 600 }}>
                  Orders balance : QAR {orderBalance.toLocaleString("en-US")}
                </Typography>
              </Box>

              <Box display="flex" height={45} justifyContent="flex-end" gap={2}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleCancelation}
                >
                  Cancel
                </Button>
                <LoadingButton
                  loading={saveRequest.isLoading}
                  variant="contained"
                  color="secondary"
                  onClick={handleSubmit}
                  disabled={!referenceNumber?.length}
                  style={{ width: "13em" }}
                >
                  Send
                </LoadingButton>
              </Box>
            </Collapse>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Table
            columns={columns}
            loading={getListStoresPayment?.isLoading}
            nbrRowLoading={10}
            data={renderTableRows()}
            emptyMessage="No data available"
          />
        </Grid>
      </Grid>
    </Page>
  );
};
