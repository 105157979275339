/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class RestaurantItemAddonOptionsService {

    /**
     * save an addon option
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static saveItemAddonOption(
        requestBody?: {
            name_en?: string;
            name_ar?: string;
            item_addon_option_price?: number;
            max_selection?: number;
            item_addon_id?: number;
            show_onmenu?: boolean;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/restaurant/addonOption/store`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * get choice by id
     * @param id ID of choice
     * @returns any Successful operation
     * @throws ApiError
     */
    public static getChoiceById(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/admins/restaurant/addonOption/show/${id}`,
            errors: {
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * update an addon option
     * @param id ID of addon option
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static updateItemAddonOption(
        id: number,
        requestBody?: {
            name_en?: string;
            name_ar?: string;
            item_addon_option_price?: number;
            max_selection?: number;
            show_onmenu?: boolean;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admins/restaurant/addonOption/update/${id}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * delete an addon option
     * @param id ID of addon option
     * @returns any Successful operation
     * @throws ApiError
     */
    public static deleteItemAddonOption(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/api/admins/restaurant/addonOption/delete/${id}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthenticated`,
                403: `Forbidden`,
            },
        });
    }

}