import * as React from "react";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useQuery, useMutation, useQueryClient } from "react-query";
import moment from "moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import { Button, LoadingButton, TextField } from "../../../commons";
import Table, { ColumnsProps } from "../../../commons/Table";

import { SnackbarContext } from "../../../App";
import { serviceUnavailable } from "../../../utils/message";

import {
  FilterCollectionsService,
  OtherChargesService,
} from "../../../services/openApi";

import { SheetIcon } from "../../../img/icon";

import { Page } from "./OtherCharges-style";

const ExportToExcel = ({ apiData, fileName }: any) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData: any, fileName: any) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Button
      variant="outlined"
      color="secondary"
      onClick={(e) => exportToCSV(apiData, fileName)}
      sx={{ height: 42 }}
    >
      <SheetIcon /> &nbsp; Export a XLS
    </Button>
  );
};

export const OtherCharges = (): JSX.Element => {
  const { setSnack } = React.useContext(SnackbarContext);
  const queryClient = useQueryClient();
  const { getAllStoresList } = FilterCollectionsService;
  const {
    getOtherChargesList,
    saveOtherCharges,
    getOtherChargesOrdersByStoreId,
  } = OtherChargesService;

  const [dateFrom, setDateFrom] = React.useState<Date | null>(null);
  const [dateTo, setDateTo] = React.useState<Date | null>(null);
  const [datePayment, setDatePayment] = React.useState<Date | null>(new Date());
  const [selectedStore, setSelectedStore] = React.useState<
    number | undefined
  >();

  const [description, setDescription] = React.useState<string | undefined>();
  const [orderBalance, setOrderBalance] = React.useState<number>(0);

  const [idOrder, setIdOrder] = React.useState<any>();
  const [blance, setBlance] = React.useState<any>();

  const [checked, setChecked] = React.useState(false);

  const listStores = useQuery("getAllStoresList", () => getAllStoresList(), {
    onError: () => {
      setSnack({
        open: true,
        severity: "error",
        message: serviceUnavailable,
      });
    },
  });

  const listStoreFilter =
    listStores?.data?.find((item: any) => item?.id === selectedStore) || "";

  const getListOrdersByStoreId = useQuery(
    "getOtherChargesOrdersByStoreId",
    () => getOtherChargesOrdersByStoreId(selectedStore!),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
      enabled: !!selectedStore,
    }
  );

  const getListOtherCharges = useQuery(
    [
      "getOtherChargesList",
      selectedStore,
      dateFrom ? moment(dateFrom).format("YYYY-MM-DD") : undefined,
      dateTo ? moment(dateTo).format("YYYY-MM-DD") : undefined,
    ],
    () =>
      getOtherChargesList({
        restaurant_store_id: selectedStore,
        start_date: dateFrom
          ? moment(dateFrom).format("YYYY-MM-DD")
          : undefined,
        end_date: dateTo ? moment(dateTo).format("YYYY-MM-DD") : undefined,
      }),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const saveRequest = useMutation(saveOtherCharges, {
    onSuccess: () => {
      queryClient.refetchQueries([
        "getOtherChargesList",
        selectedStore,
        dateFrom ? moment(dateFrom).format("YYYY-MM-DD") : undefined,
        dateTo ? moment(dateTo).format("YYYY-MM-DD") : undefined,
      ]);
      handleCancelation();
    },
  });

  const columns: ColumnsProps = [
    {
      header: "Store",
      accessor: "store",
    },
    {
      header: "Date",
      accessor: "date",
    },
    {
      header: "Reference #",
      accessor: "reference",
    },
    {
      header: "Order Date",
      accessor: "referenceDate",
    },
    {
      header: "Description",
      accessor: "description",
    },
    {
      header: "Amount",
      accessor: "balance",
    },
  ];

  const renderTableRows = () => {
    return (
      getListOtherCharges?.data?.otherCharges?.map((item: any) => ({
        store: (
          <Typography variant="body2" sx={{ p: "8px 0" }}>
            {item?.store?.store_translation?.name || "--"}
          </Typography>
        ),
        date: (
          <Typography variant="body2" sx={{ p: "8px 0" }}>
            {moment(item?.iso_date).format("DD MMM YYYY hh:mm a") || "--"}
          </Typography>
        ),
        reference: (
          <Typography variant="body2" sx={{ p: "8px 0" }}>
            {item?.order_id || "--"}
          </Typography>
        ),
        referenceDate: (
          <Typography variant="body2" sx={{ p: "8px 0" }}>
            {item?.reference_date || "--"}
          </Typography>
        ),
        description: (
          <Typography variant="body2" sx={{ p: "8px 0" }}>
            {item?.description || "--"}
          </Typography>
        ),
        balance: (
          <Typography variant="body2" sx={{ p: "8px 0" }}>
            {`QAR ${item?.balance?.toLocaleString("en-US")}`}
          </Typography>
        ),
      })) || []
    );
  };

  const handleCancelation = () => {
    setIdOrder(undefined);
    setBlance(undefined);
    setDescription(undefined);
    setDatePayment(null);
  };

  const handleSubmit = () => {
    saveRequest.mutate({
      restaurant_store_id: selectedStore,
      description,
      reference_date: moment(datePayment).format("YYYY-MM-DD"),
      order_id: idOrder,
      balance: blance,
    });
  };

  return (
    <Page className="scrolbar">
      <Box
        display="grid"
        gap={2}
        mb={2}
        sx={{
          gridTemplateColumns: "2fr 40px 1fr 40px 1fr 150px",
          alignItems: "center",
        }}
      >
        <Autocomplete
          options={listStores.data || []}
          getOptionLabel={(option: any) => option?.name || ""}
          filterSelectedOptions
          value={listStoreFilter}
          onChange={(_, value: any) => setSelectedStore(value?.id)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Please select a store"
              color="secondary"
            />
          )}
          fullWidth
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Typography variant="body2" align="center" sx={{ color: "#DDDDDD" }}>
            From
          </Typography>
          <DatePicker
            value={dateFrom}
            inputFormat="dd/MM/yyyy"
            onChange={(newValue) => {
              setDateFrom(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} color="secondary" />
            )}
          />
          <Typography variant="body2" align="center" sx={{ color: "#DDDDDD" }}>
            To
          </Typography>
          <DatePicker
            value={dateTo}
            inputFormat="dd/MM/yyyy"
            onChange={(newValue) => {
              setDateTo(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} color="secondary" />
            )}
          />
        </LocalizationProvider>
        <ExportToExcel apiData={renderTableRows()} fileName={new Date()} />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="containerCollapse">
            <Collapse
              in={checked}
              collapsedSize={50}
              sx={{ width: "100%", padding: "5px 16px 16px 16px" }}
            >
              <Box
                mb={1}
                display="flex"
                sx={{
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="subtitle1"
                  align="center"
                  sx={{
                    fontWeight: 600,
                  }}
                >
                  Add New Payment
                </Typography>
                <IconButton onClick={() => setChecked(!checked)}>
                  <ArrowDropDownIcon />
                </IconButton>
              </Box>
              <Box
                display="grid"
                gap={2}
                mb={2}
                sx={{
                  gridTemplateColumns: "1fr 250px",
                  alignItems: "center",
                }}
              >
                <Autocomplete
                  options={getListOrdersByStoreId.data || []}
                  getOptionLabel={(option: any) => option?.id || ""}
                  filterSelectedOptions
                  onChange={(_, value: any) => {
                    value?.id && setIdOrder(value.id);
                    setOrderBalance(value?.order_total_price);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Enter Reference Number"
                      color="secondary"
                    />
                  )}
                  disabled={!listStoreFilter}
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={datePayment}
                    inputFormat="dd/MM/yyyy"
                    onChange={(newValue) => {
                      setDatePayment(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} color="secondary" fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </Box>
              <Box
                display="grid"
                gap={2}
                mb={2}
                sx={{
                  gridTemplateColumns: "1fr 1fr 250px",
                  alignItems: "center",
                }}
              >
                <TextField
                  name="description"
                  placeholder="Enter Description"
                  multiline
                  rows={2}
                  value={description || ""}
                  onChange={(e) => setDescription(e.target.value)}
                  color="secondary"
                  fullWidth
                />

                <TextField
                  type="number"
                  name="chargesAmount"
                  placeholder="Enter Charges Amount"
                  onChange={(e) => {
                    e.persist();
                    setBlance(e.target.value);
                  }}
                  color="secondary"
                  fullWidth
                  style={{ marginBottom: "auto" }}
                />

                <Typography align="center" sx={{ fontWeight: 600 }}>
                  Amount Received : QAR {orderBalance.toLocaleString("en-US")}
                </Typography>
              </Box>

              <Box display="flex" height={45} justifyContent="flex-end" gap={2}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleCancelation}
                >
                  Cancel
                </Button>
                <LoadingButton
                  loading={saveRequest.isLoading}
                  variant="contained"
                  color="secondary"
                  onClick={handleSubmit}
                  disabled={!blance && !idOrder}
                  style={{ width: "13em" }}
                >
                  Add Payment
                </LoadingButton>
              </Box>
            </Collapse>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Table
            columns={columns}
            loading={getListOtherCharges?.isLoading}
            nbrRowLoading={10}
            data={renderTableRows()}
            emptyMessage="No data available"
          />
        </Grid>
      </Grid>
    </Page>
  );
};
