import styled from "styled-components";
import { AppMenuItemComponent } from "..";

export const AppMenuItemComponentStyled = styled(AppMenuItemComponent)<{
  parentChiled?: boolean;
  chiled?: boolean;
}>`
  color: #231f56;
  .MuiListItemIcon-root {
    min-width: 40px;
    margin-left: 10px;
  }
  background: ${({ parentChiled }) => (parentChiled ? "#5bc0de" : "none")};
  &.active {
    background: ${({ chiled }) => (chiled ? "none" : "#5bc0de")};
    color: ${({ chiled }) => (chiled ? "#5bc0de" : "")};
  }
  .MuiListItemText-root {
    padding-left: 0;
    margin: 1px 0;
  }
`;
