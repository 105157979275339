import styled from "styled-components";

export const Page = styled.div`
  display: grid;
  grid-template-columns: 300px minmax(600px, 1fr);
  height: calc(100vh - 130px);
  gap: 20px;
  padding: 20px;
  .form-region {
    display: grid;
    /* grid-template-columns: 1fr 1fr 1fr 1fr 1fr auto; */
    grid-template-columns: 1fr 1fr 1fr 1fr auto;
    background: #edfaff;
    gap: 8px;
    padding: 2px 5px;
    margin-top: auto;
  }
  .area-form {
    display: grid;
    /* grid-template-columns: 1fr 1fr 1fr 1fr 1fr minmax(70px, auto); */
    grid-template-columns: 1fr 1fr 1fr 1fr minmax(70px, auto);
    padding: 8px 0;
    border-bottom: 1px solid #dddddd;
    h6 {
      background: #f7f7f7;
      padding: 15px 0;
    }
    & > div {
      padding: 10px 5px 0 0;
    }
    p {
      grid-column: 1/7;
      padding: 14px 0 8px 0;
    }
  }

  .MuiSelect {
    padding: 9px;
  }
  .MuiInputLabel-root {
    top: -8px;
  }
  .MuiOutlinedInput-input {
    padding: 9px;
  }
  .btn-save {
    height: 36px;
    background: #fff;
    border: 1px solid #ecf4df;
    color: #75bd00;
    svg {
      font-size: 18px;
    }
  }
  .btn-cancel {
    height: 36px;
    background: #fff;
    border: 1px solid #fdf4f7;
    color: #b93e5c;
    svg {
      font-size: 18px;
    }
  }
  .btn-add {
    color: #231f56;
    svg {
      margin-right: 8px;
    }
  }
  .Mui-disabled {
    opacity: 0.5;
  }
`;
