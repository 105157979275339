import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Skeleton from "@mui/material/Skeleton";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { LazyLoadingImage } from "../../commons";

import DefaultImage from "../../img/Default_Image_Thumbnail.png";

import { CardCartingMenuStyled } from "./CardCartingMenu-styles";

type CardCartingMenuProps = {
  picture?: string;
  name?: string;
  price?: string;
  status?: boolean;
  cashOnDelivery?: boolean;
  showOnRestaurantManager?: boolean;
  requiredLocation?: boolean;
  loading?: boolean;
  onAction?: (event: React.MouseEvent<HTMLElement>) => void;
  onStatusActive?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onCashActive?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onShowActive?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onRequiredLocation?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const CardCartingMenu = ({
  picture,
  name,
  price,
  status,
  cashOnDelivery,
  showOnRestaurantManager,
  loading,
  requiredLocation,
  onAction,
  onStatusActive,
  onCashActive,
  onShowActive,
  onRequiredLocation,
}: CardCartingMenuProps): JSX.Element => {
  return (
    <CardCartingMenuStyled p={loading}>
      {loading ? (
        <Skeleton
          variant="rectangular"
          width="100%"
          height={218}
          sx={{ borderRadius: "20px" }}
        />
      ) : (
        <>
          <LazyLoadingImage
            alt="carting Menu"
            src={picture} // use normal <img> attributes as props
            placeholderSrc={DefaultImage}
          />
          <Box display="flex" flex="1" flexDirection="column">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              padding="5px 0"
            >
              <Typography variant="subtitle1" className="title">
                {name}
              </Typography>
              {onAction && (
                <IconButton onClick={onAction}>
                  <MoreVertIcon />
                </IconButton>
              )}
            </Box>
            <div className="price">{price}</div>
            <FormGroup>
              {showOnRestaurantManager && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showOnRestaurantManager}
                      onChange={onShowActive}
                      color="secondary"
                      size="small"
                    />
                  }
                  label={
                    <Typography variant="subtitle2">
                      Show on restaurant manager
                    </Typography>
                  }
                />
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    size="small"
                    checked={status}
                    onChange={onStatusActive}
                  />
                }
                label={<Typography variant="subtitle2">Show</Typography>}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    size="small"
                    checked={cashOnDelivery}
                    onChange={onCashActive}
                  />
                }
                label={
                  <Typography variant="subtitle2">
                    Accept cash on delivery
                  </Typography>
                }
              />
              {onRequiredLocation && (
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      size="small"
                      checked={requiredLocation}
                      onChange={onRequiredLocation}
                    />
                  }
                  label={
                    <Typography variant="subtitle2">
                      Required location
                    </Typography>
                  }
                />
              )}
            </FormGroup>
          </Box>
        </>
      )}
    </CardCartingMenuStyled>
  );
};
