import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  .choice-details {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr auto;
    background: #edfaff;
    margin: 25px 0 15px 0;
    padding: 3px;
    gap: 8px;
    .MuiOutlinedInput-input {
      padding: 10px;
    }
    .disabled {
      background: #f9f8f8;
      opacity: 0.4;
    }
  }

  .choice-option {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 100px;
    margin-top: 15px;
    .header-element {
      background: #f7f7f7;
      padding: 16px;
      font-weight: 600;
    }
    .value-element {
      padding: 10px;
    }
    .MuiOutlinedInput-input {
      padding: 9px 9px 8px 9px;
    }
    .price {
      .MuiOutlinedInput-input {
        border-right: 1px solid #e5e5e5;
      }
    }
    .Mui-disabled {
      background: #f9f8f8;
      opacity: 0.4;
    }
  }

  .MuiInputLabel-root {
    top: -6px;
  }

  .btn-save {
    height: 40px;
    width: 40px;
    background: #fff;
    border: 1px solid #ecf4df;
    color: #75bd00;
    svg {
      font-size: 18px;
    }
  }
  .btn-cancel {
    height: 40px;
    width: 40px;
    background: #fff;
    border: 1px solid #fdf4f7;
    color: #b93e5c;
    svg {
      font-size: 18px;
    }
  }
`;
