import * as React from "react";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { Input, Button, Dialog } from "../../commons";
import Table, { ColumnsProps } from "../../commons/Table";
import { CardStatusCoupons, FormCouponModal } from "../../components";
import { SnackbarContext } from "../../App";
import { successfulOperation, serviceUnavailable } from "../../utils/message";

import { CateringCouponService } from "../../services/openApi";

import { EditIcon, TrashIcon } from "../../img/icon";

import { Page } from "./Coupons-style";

type modalType = {
  open: boolean;
  title: string;
  action?: "add" | "update" | "delete";
};

export const Coupons = (): JSX.Element => {
  const { setSnack } = React.useContext(SnackbarContext);
  const { getCatringCouponsList } = CateringCouponService;
  const queryClient = useQueryClient();

  const [openModalForm, setOpenModalForm] = React.useState<modalType>({
    open: false,
    title: "",
  });
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [search, setSearch] = React.useState<string>("");
  const [idCoupon, setIdCoupon] = React.useState<number | undefined>();
  const [openDelete, setOpenDelete] = React.useState<modalType>({
    open: false,
    title: "",
  });
  const [openStatus, setOpenStatus] = React.useState<modalType>({
    open: false,
    title: "",
  });
  const [status, setStatus] = React.useState<boolean>(false);
  const [filterStatus, setFilterStatus] = React.useState<string | undefined>();

  const columns: ColumnsProps = [
    {
      header: "CODE",
      accessor: "code",
    },
    {
      header: "Short Description",
      accessor: "storeDescription",
    },
    {
      header: "% Discount",
      accessor: "discount",
    },
    {
      header: "Validation Date",
      accessor: "validationDate",
    },
    {
      header: "Status",
      accessor: "status",
      headerCellProps: { align: "center" },
      cellProps: { align: "center", width: "80px" },
    },
    {
      header: "Actions",
      accessor: "actions",
      headerCellProps: { align: "center" },
      cellProps: { align: "center", width: "235px" },
    },
  ];

  const listCoupons = useQuery(
    "getCatringCouponsList",
    () => getCatringCouponsList({ search }),
    {
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const listCouponsFilter = filterStatus
    ? listCoupons?.data?.cateringCoupons?.filter((o: any) => o?.[filterStatus])
    : listCoupons?.data?.cateringCoupons;

  const renderTableRows = () => {
    return (
      listCouponsFilter
        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        ?.map((item: any) => ({
          code: item?.code,
          storeDescription: item?.short_description,
          discount: item?.discount,
          validationDate: item?.end_date,
          status: (
            <Switch
              checked={item?.status || false}
              onChange={(e) => {
                e.stopPropagation();
                setOpenStatus({
                  open: true,
                  title: "Update Status",
                  action: "update",
                });
                setIdCoupon(item?.id);
                setStatus(e.target.checked);
              }}
              color="secondary"
            />
          ),
          actions: (
            <Box display="flex" justifyContent="center" gap={1}>
              <Button
                variant="contained"
                className="btn-edit"
                onClick={(e) => {
                  e.stopPropagation();
                  handleModalForm(true, "Edit Coupon", "update");
                  setIdCoupon(item?.id);
                }}
              >
                Edit
                <EditIcon />
              </Button>
              <Button
                variant="contained"
                className="btn-delete"
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenDelete({
                    open: true,
                    title: "Delete",
                    action: "delete",
                  });
                  setIdCoupon(item?.id);
                }}
              >
                Delete <TrashIcon />
              </Button>
            </Box>
          ),
        })) || []
    );
  };

  const editStatus = useMutation(
    () => CateringCouponService.updateStatus(idCoupon!, { status }),
    {
      onSuccess: () => {
        queryClient.refetchQueries("getCatringCouponsList");
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        setOpenStatus({
          open: false,
          title: "",
        });
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const deleteCoupons = useMutation(
    () => CateringCouponService.delete(idCoupon!),
    {
      onSuccess: () => {
        queryClient.refetchQueries("getCatringCouponsList");
        setSnack({
          open: true,
          severity: "success",
          message: successfulOperation,
        });
        setOpenDelete({
          open: false,
          title: "",
        });
      },
      onError: () => {
        setSnack({
          open: true,
          severity: "error",
          message: serviceUnavailable,
        });
      },
    }
  );

  const handleModalForm = (
    open: boolean,
    title: string,
    action: "add" | "update"
  ) => {
    setOpenModalForm({
      open,
      title,
      action,
    });
  };

  const handleCloseModalForm = () => {
    setOpenModalForm({
      open: false,
      title: "",
    });
    setIdCoupon(undefined);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilter = (filter: string | undefined) => {
    if (filter === filterStatus) {
      setFilterStatus(undefined);
    } else {
      setFilterStatus(filter);
    }
  };

  return (
    <Page className="scrolbar">
      <Box display="grid" gridTemplateColumns="1fr 1fr 1fr 1fr" gap={3}>
        <CardStatusCoupons
          loading={listCoupons?.isLoading}
          status="All"
          count={listCoupons?.data?.couponsCount}
          onClick={() => handleFilter(undefined)}
        />
        <CardStatusCoupons
          loading={listCoupons?.isLoading}
          status="Active"
          count={listCoupons?.data?.activeCount}
          selected={filterStatus === "is_active"}
          onClick={() => handleFilter("is_active")}
        />
        <CardStatusCoupons
          loading={listCoupons?.isLoading}
          status="Deactivated"
          count={listCoupons?.data?.deactivatedCount}
          selected={filterStatus === "is_deactivated"}
          onClick={() => handleFilter("is_deactivated")}
        />
        <CardStatusCoupons
          loading={listCoupons?.isLoading}
          status="Expitred"
          count={listCoupons?.data?.expiredCount}
          selected={filterStatus === "is_expired"}
          onClick={() => handleFilter("is_expired")}
        />
      </Box>
      <Box display="grid" gridTemplateColumns="1fr 150px" gap={2}>
        <Input
          placeholder="Search by code"
          onChange={handleChange}
          onKeyPress={(e) => e.key === "Enter" && listCoupons.refetch()}
          startAdornment={
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          }
        />
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleModalForm(true, "Add Coupon", "add")}
        >
          Add Coupon
        </Button>
      </Box>
      <Table
        columns={columns}
        loading={listCoupons.isLoading}
        data={renderTableRows()}
        nbrRowLoading={rowsPerPage}
        emptyMessage="No data available"
        tablePaginationProps={{
          rowsPerPageOptions: [5, 10, 25],
          count: listCouponsFilter?.length || 0,
          page: page,
          onPageChange: (_, newPage) => setPage(newPage),
          rowsPerPage,
          onRowsPerPageChange: handleChangeRowsPerPage,
        }}
      />
      {openModalForm.open && (
        <FormCouponModal
          idCoupon={idCoupon}
          open={openModalForm.open}
          title={openModalForm.title}
          action={openModalForm.action}
          onClose={handleCloseModalForm}
        />
      )}
      <Dialog
        open={openDelete.open}
        title={openDelete.title}
        action="delete"
        handleContent={() => idCoupon && deleteCoupons.mutate()}
        loading={deleteCoupons.isLoading}
        onClose={() =>
          setOpenDelete({
            open: false,
            title: "",
          })
        }
      >
        <Typography>Are you sure to delete this coupon?</Typography>
      </Dialog>
      <Dialog
        open={openStatus.open}
        title={openStatus.title}
        action="update"
        handleContent={() => idCoupon && editStatus.mutate()}
        loading={editStatus.isLoading}
        onClose={() =>
          setOpenStatus({
            open: false,
            title: "",
          })
        }
      >
        <Typography>Are you sure to update status this coupon?</Typography>
      </Dialog>
    </Page>
  );
};
